import { Button, Paper, Typography } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Facebook from "../../components/assets/png/facebook.jpeg";
import Google from "../../components/assets/png/icon/google.png";
import { gapi } from "gapi-script";
import { loggedinUser, userDetails } from "../../redux/commonReducer/action";
import { postRequest } from "../../apis/baseApi";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
    maxWidth: "150px",
  },
  btnGoBack: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
    maxWidth: "200px",
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
  googlebtn: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "30px",
    paddingLeft: "10px",
    paddingRight: "10px",
    minWidth: "150px",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      border: "1px solid",
    },
  },
}));

function UserRegistrationSocial() {
  const classes = useStyles();
  const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const redirectUrl = params.get("redirectUrl");
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "303020332087-p91ke2ehr7klb0cae9446fuoncush23m.apps.googleusercontent.com",
        scope:
          "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const responseGoogleSuccess = (response) => {
    const postData = {
      token: response?.accessToken,
      provider: "google",
    };
    postRequest("authentication/users/google_fb_auth/", postData)
      .then((res) => {
        if (res?.data?.status === false) {
          // setSignupData()
          history.push({
            pathname: "/user-registration",
            state: {
              firstName: response?.name,
              email: res?.data?.email,
            },
          });
        } else if (res?.data) {
          dispatch(loggedinUser(true));
          dispatch(
            userDetails({
              name: res?.data,
              username: res?.data?.username,
              token: res?.data?.token,
              slug: res?.data?.slug,
              isHasNgo: res?.data?.is_has_ngo,
              email: res?.data?.email,
              profileImage: res?.data?.user_profile?.image,
            })
          );
          if (res?.data?.is_has_ngo) {
            alert.error(
              "You are not registered as a member. Please login as NGO"
            );
            return;
          }
          localStorage.setItem("user_token", res?.data?.token);
          localStorage.setItem("slug", res?.data?.slug);
          localStorage.setItem("user_type", "DONOR");
          localStorage.setItem(
            "name",
            `${res?.data?.first_name} ${res?.data?.last_name}`
          );
          localStorage.setItem("profile_image", res?.data?.image);
          localStorage.setItem("has_ngo", res?.data?.is_has_ngo);
          localStorage.setItem("email", res?.data?.email);
          localStorage.setItem("username", res?.data?.username);
          alert.success("Login Successful!!");
          if (redirectUrl) {
            history.push(redirectUrl);
          } else history.push("/user/profile");
        } else {
          // alert.error("Something went wrong!!");
        }
      })
      .catch((err) => {
        // alert.error("Something went wrong!!")
      });
  };

  const responseGoogleFailure = (response) => {
    // alert.error("Something went wrong!!");
    // alert.error("Error in Google SignUp/Login!!");
  };

  const responseFacebook = (response) => {
    const postData = {
      token: response?.accessToken,
      provider: "facebook",
    };
    postRequest("authentication/users/google_fb_auth/", postData)
      .then((res) => {
        if (res?.data?.status === false) {
          // setSignupData()
          history.push({
            pathname: "/user-registration",
            state: {
              firstName: response?.name,
              email: res?.data?.email,
            },
          });
        } else if (res?.data) {
          dispatch(loggedinUser(true));
          dispatch(
            userDetails({
              name: res?.data,
              username: res?.data?.username,
              token: res?.data?.token,
              slug: res?.data?.slug,
              isHasNgo: res?.data?.is_has_ngo,
              email: res?.data?.email,
              profileImage: res?.data?.user_profile?.image,
            })
          );
          if (res?.data?.is_has_ngo) {
            alert.error(
              "You are not registered as a member. Please login as NGO"
            );
            return;
          }
          localStorage.setItem("user_token", res?.data?.token);
          localStorage.setItem("slug", res?.data?.slug);
          localStorage.setItem("user_type", "DONOR");
          localStorage.setItem(
            "name",
            `${res?.data?.first_name} ${res?.data?.last_name}`
          );
          localStorage.setItem("profile_image", res?.data?.image);
          localStorage.setItem("has_ngo", res?.data?.is_has_ngo);
          localStorage.setItem("email", res?.data?.email);
          localStorage.setItem("username", res?.data?.username);
          alert.success("Login Successful!!");
          if (redirectUrl) {
            history.push(redirectUrl);
          } else history.push("/user/profile");
        } else {
          // alert.error("Something went wrong!!");
        }
      })
      .catch((err) => {
        // alert.error("Something went wrong!!");
      });
  };

  return (
    // <Layout>
    <Paper className={classes.paper}>
      <>
        <Typography variant="h3" color="primary" style={{ marginTop: "20px" }}>
          User SignUp Using
        </Typography>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <div style={{ marginBottom: "20px" }}>
              <GoogleLogin
                clientId={
                  "303020332087-p91ke2ehr7klb0cae9446fuoncush23m.apps.googleusercontent.com"
                }
                render={(renderProps) => (
                  <Button
                    className={classes.googlebtn}
                    onClick={renderProps.onClick}
                  >
                    <img
                      src={Google}
                      height="25px"
                      alt="google-icon"
                      style={{ marginRight: "10px" }}
                    />
                    <span style={{ fontSize: "14px" }}> Google </span>
                  </Button>
                )}
                cookiePolicy={"single_host_origin"}
                onSuccess={responseGoogleSuccess}
                onFailure={responseGoogleFailure}
              />
            </div>
            <FacebookLogin
              appId="479517474027882"
              callback={responseFacebook}
              fields="name,email,picture"
              render={(renderProps) => (
                // <button onClick={renderProps.onClick}>This is my custom FB button</button>
                <Button
                  className={classes.googlebtn}
                  onClick={() => {
                    renderProps.onClick();
                  }}
                >
                  <img
                    src={Facebook}
                    height="25px"
                    alt="twitter-icon"
                    style={{ marginRight: "10px" }}
                  />
                  <span style={{ fontSize: "14px" }}> Facebook </span>
                </Button>
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <Typography variant="body2">
              OR
              <div>
                SignUp with{" "}
                <span
                  style={{ color: "blue", fontSize: "16px", cursor: "pointer" }}
                  onClick={() => {
                    history.push("user-registration");
                  }}
                >
                  {" "}
                  Email{" "}
                </span>
              </div>
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <Typography variant="body2">
              <div>
                Already have an account?
                <span
                  style={{ color: "blue", fontSize: "16px", cursor: "pointer" }}
                  onClick={() => {
                    history.push("member-login");
                  }}
                >
                  {" "}
                  Login Here!{" "}
                </span>
              </div>
            </Typography>
          </div>
        </>
      </>
    </Paper>
    // </Layout>
  );
}

export default UserRegistrationSocial;
