import {
  Typography,
  FormControl,
  Grid,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider
} from "@material-ui/core";
import React from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Autocomplete } from "@material-ui/lab";
import Calendar from "../../Common/Calendar/Calendar";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  googlebtn: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "30px",
    paddingLeft: "15px",
    paddingRight: "15px",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      border: "1px solid",
    },
  },
  fieldtext: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "10px",
    display: "flex",
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
  option: {
    fontSize: 13,
    "& > span": {
      marginRight: 10,
      fontSize: 12,
    },
  },
}));

export default function MemberReg() {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [gender, setGender] = React.useState("male");

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            User Name
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Password
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <FormControl className={classes.margin}>
            <OutlinedInput
              style={{
                backgroundColor: "#fff",
                paddingTop: 25,
                paddingBottom: 25,
              }}
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              //labelWidth={70}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            First Name
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={6} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Last Name
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography
            style={{
              fontSize: "12px",
              textAlign: "left",
              marginBottom: "7px",
            }}
          >
            Mobile Number
          </Typography>
          <PhoneInput
            style={{ width: "100%" }}
            country={"in"}
            inputProps={{
              name: "phone",
            }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography
            style={{
              fontSize: "12px",
              textAlign: "left",
              marginBottom: "7px",
            }}
          >
            Alternate Mobile / Phone Number
          </Typography>
          <PhoneInput
            style={{ width: "100%" }}
            country={"in"}
            inputProps={{
              name: "phone",
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Email Address
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>
            Alternate Email Address
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>

        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Communication Address
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>

          <TextField
            variant="outlined"
            multiline
            rows={6}
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Pin Code
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography className={classes.fieldtext}>District</Typography>
          <Autocomplete
            id="country-select-demo"
            style={{ backgroundColor: "#fff" }}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            options={demo}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "district",
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography className={classes.fieldtext}>State</Typography>
          <Autocomplete
            id="country-select-demo"
            style={{ backgroundColor: "#fff" }}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            options={demo}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "State",
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography className={classes.fieldtext}>Date of Birth</Typography>
          <Calendar />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography className={classes.fieldtext}>Gender</Typography>
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <RadioGroup
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              aria-label="gender"
              name="gender1"
              value={gender}
              onChange={handleGenderChange}
            >
              <FormControlLabel
                value="male"
                control={<Radio color="primary" size="small" />}
                label="Male"
              />
              <FormControlLabel
                value="female"
                control={<Radio color="primary" size="small" />}
                label="Female"
              />

              <FormControlLabel
                value="other"
                control={<Radio color="primary" size="small" />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography className={classes.fieldtext}>Education</Typography>
          <Autocomplete
            id="country-select-demo"
            style={{ backgroundColor: "#fff" }}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            options={demo}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "State",
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>Occupation</Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={classes.fieldtext}>Marital Status</Typography>
          <Autocomplete
            id="country-select-demo"
            style={{ backgroundColor: "#fff" }}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            options={demo}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "State",
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography className={classes.fieldtext}>Income Range</Typography>
          <Autocomplete
            id="country-select-demo"
            style={{ backgroundColor: "#fff" }}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            options={demo}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "State",
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Divider />
          <Typography
            variant="body2"
            style={{
              color: "#A6A6A6",
              fontStyle: "italic",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            Guardian Information
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>Guardian's Name</Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>Relation</Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>Occupation</Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>Guardian's Profession</Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>Guardian's Address</Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>Guardian's Email</Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>Guardian's Phone</Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Divider />
        </Grid>
      </Grid>
      <div>
        <Button className={classes.btn} color="primary" variant="contained">
          REGISTER
        </Button>
      </div>
    </>
  );
}

const demo = [{ title: "Option 1" }, { title: "option 2" }];
