import {
  alpha, makeStyles
} from "@material-ui/core";
import Layout from "../../components/Layout/Layout";
import AdvisorSection from "../../components/Section/AboutSection/AdvisorSection";
import BoardDirectors from "../../components/Section/AboutSection/BoardDirectors";
import OurTeamSection from "../../components/Section/AboutSection/OurTeamSection";
import TeamSection from "../../components/Section/AboutSection/TeamSection";
import PublicLayout from "../../components/Layout/PublicLayout";

const useStyles = makeStyles((theme) => ({
  learn: {
    paddingLeft: "40px",
    paddingRight: "40px",
    color: "#fff",
    height: "45px",
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
  },
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
    marginBottom: "70px",
  },
}));

export default function OurTeam() {
  const classes = useStyles();
  return (
    <>
      {/* <Layout> */}
      <PublicLayout keyss={"about"}>
        <>
        <OurTeamSection />
        <BoardDirectors />
        <AdvisorSection />
        {/* <TeamSection /> */}
        </>
        </PublicLayout>
      {/* </Layout> */}
    </>
  );
}
