import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import ngo from "../../assets/png/team-work/ngo.jpg";
import React, { useState, useEffect } from "react";
import { getRequest } from "../../../apis/baseApi";

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: "10px",
    boxShadow: "-22px 25px 0px 0px",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)",
    borderRadius: "10px",
  },
  mainFeaturedPostContent: {
    position: "relative",
    height: "200px",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(12),
      //paddingRight: 0,
      borderRadius: "10px",
    },
  },
  overflow: {
    boxShadow: "0px 0px 30px 0px rgb(0 0 0 / 15%)",
    padding: "13px 9px 5px 49px",
    borderRadius: "10px",
    background: "#fff",
    margin: "-305px -25px 0px 0px",
    fontSize: "14px",
    height: "290px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  maingrid: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  text: {
    paddingLeft: "40px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
}));

export default function HeroHeadline() {
  const classes = useStyles();
  const [heroHeadline, setHeroHeadline] = useState({});

  useEffect(() => {
    getRequest("home_page/get_hero_headlines/")
      .then((res) => {
        if (res?.data?.status) {
          setHeroHeadline({
            title: res?.data?.data?.title,
            content: res?.data?.data?.content,
            headline: res?.data?.data?.headline,
            location: res?.data?.data?.location,
            image: res?.data?.data?.image,
          });
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "20px" }}
        className={classes.maingrid}
      >
        <Grid item md={6} sm={12}>
          <div>
            <h2>Featured NGO</h2>
            <Paper
              className={classes.mainFeaturedPost}
              style={{ backgroundImage: `url(${heroHeadline?.image})` }}
            >
              <div className={classes.overlay} />
              <Grid container style={{ textAlign: "center" }}>
                <Grid item md={12}>
                  <div className={classes.mainFeaturedPostContent} />
                </Grid>
              </Grid>
            </Paper>

            <div className={classes.overflow} />
          </div>
        </Grid>
        <Grid
          item
          md={6}
          sm={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <div className={classes.text}>
            <Typography variant="h5">{heroHeadline?.title}</Typography>
            <Typography
              variant="body2"
              color="primary"
              style={{ marginTop: "30px" }}
            >
              {heroHeadline?.content}
            </Typography>
          </div>
          <Typography
            variant="caption"
            style={{ textAlign: "right", color: "#626262" }}
          >
            {heroHeadline?.location}
          </Typography>
        </Grid>
      </Grid>
      {/* <WhatWeDo /> */}
    </>
  );
}
