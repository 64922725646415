import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
	image: {
		height: "5rem",
		width: "5rem",
		borderRadius: "50%",
		transition: "transform .2s",
		"&:hover": {
			transform: " scale(1.2)",
		},
	},

	box: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const AvatarComponent = ({ image }) => {
	const classes = useStyles();
	return (
		<div className={classes.box}>
			<img src={image} alt="avatar" className={classes.image} />
		</div>
	);
};

export default AvatarComponent;
