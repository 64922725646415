import {
  Typography,
  ListItem,
  Collapse,
  List,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  text: {
    // marginLeft: "16px",
    // marginRight: "16px",
    fontSize: "16px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  textlink: {
    fontSize: "14px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  selecttextlink: {
    fontSize: "14px",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },

  sidebarlink: {
    display: "flex",
    color: "black",
    // padding: "8px 0px 8px 16px",
    textDecoration: "none",
    listStyle: "none",

    height: "50px",
    alignItems: "center",
    // padding: "0 16px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  blueSideBar: {
    backgroundColor: "transparent",
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    display: "flex",
    // padding: "8px 0px 8px 16px",
    listStyle: "none",
    height: "50px",
    alignItems: "center",
    //padding: "0 16px",
    borderRadius: "4px",
  },
  subNavSelected: {
    backgroundColor: "#fff",
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    display: "flex",
    padding: "8px 0px 8px 16px",
    listStyle: "none",

    height: "50px",
    alignItems: "center",
    //padding: "0 16px",
    // borderRadius: "4px",
  },
  dropdownlink: {
    display: "flex",
    color: "#060b26",
    textDecoration: "none",
    listStyle: "none",
    backgroundColor: "#ffff",
    height: "30px",
    color: "#000",
    alignItems: "center",
    // padding: "0px 16px 0px 55px",
    justifyContent: "flex-start",
    position: "relative",
    height: "40px",
    padding: "15px",
    paddingTop: "0px",
    paddingBottom: "0px",
    "&:hover": {
      backgroundColor: "#fff",
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  blueLink: {
    display: "flex",
    padding: "15px",
    paddingTop: "0px",
    paddingBottom: "0px",
    textDecoration: "none",
    listStyle: "none",
    fontWeight: 600,
    alignItems: "center",
    //padding: "0px 16px 0px 55px",
    justifyContent: "flex-start",
    borderRadius: "4px",
    backgroundColor: "#ffff",
    textDecoration: "none",
    color: theme.palette.primary.main,
    position: "relative",
    height: "40px",
  },
}));

const DashBoardSubMenu = ({ items, key }) => {
  const [subnav, setSubnav] = useState(false);

  const classes = useStyles();
  const location = useLocation();
  const [item, setItem] = useState({});

  const showSubnav = () => {
    setSubnav(!subnav);
  };

  useEffect(() => {
    if (location.pathname === items.path)
      setItem({
        ...items,
        selected: true,
      });
    else
      setItem({
        ...items,

        selected: false,
      });

    if (items.subNav && items.subNav.length) {
      items.subNav.forEach((i) => {
        if (i.path === location.pathname) i["selected"] = true;
        else i["selected"] = false;
      });
    }
  }, [items, location.pathname]);

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div
      // style={{ height: "20px" }}
      onMouseEnter={() => {
        if (item.subNav) showSubnav();
      }}
      onMouseLeave={() => {
        if (item.subNav) showSubnav();
      }}
    >
      <div>
        <Link
          style={{}}
          className={
            item.selected
              ? classes.blueSideBar
              : item.subNav &&
                item.subNav.length &&
                item.subNav.some((i) => i.path === location.pathname)
              ? classes.subNavSelected
              : classes.sidebarlink
          }
          to={item.path}

          // onClick={() => {
          //   if (item.subNav) showSubnav();
          // }}
        >
          <div style={{ display: "contents" }}>
            {/* {item.type} */}
            <Typography
              variant="body2"
              color="inherit"
              className={
                item.selected ? classes.selecttextlink : classes.textlink
              }
              // className={classes.text}
            >
              {item.title}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            {item.subNav && subnav ? (
              <KeyboardArrowUpIcon />
            ) : item.subNav ? (
              <KeyboardArrowDownIcon />
            ) : null}
          </div>
        </Link>

        {/* <ListItem button onClick={handleClick}>
          <ListItemText primary="Inbox" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemText primary="Starred" />
            </ListItem>
          </List>
        </Collapse> */}
      </div>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <Link
              className={
                item.selected ? classes.blueLink : classes.dropdownlink
              }
              to={item.path}
              key={index}
            >
              <Typography variant="body2" color="inherit">
                {item.title}
              </Typography>
            </Link>
          );
        })}
    </div>
  );
};

export default DashBoardSubMenu;
