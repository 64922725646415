import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { postRequest } from "../../apis/baseApi";
import CircularProgress from "../../components/CircularProgress";
import { loggedinUser, userDetails } from "../../redux/commonReducer/action";
import {
  cityStateSelectorFn,
  StateSelectorFn,
} from "../../redux/commonReducer/selector";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
}));

function NgoSignup() {
  const classes = useStyles();
  // const initialState = localStorage.getItem('ngo_register');
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    city: "",
    dateOfBirth: "",
    phone: "",
    showPassword: false,
    showConfirmPassword: false,
    ngoName: "",
    isSignupChecked: false,
  });

  const [errorValues, setErrorValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    city: "",
    dateOfBirth: "",
    phone: "",
    ngoName: "",
  });

  const [selectedDate, setSelectedDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const cityStateNew = useSelector(cityStateSelectorFn);
  const [cityState, setCityState] = useState([]);

  const states = useSelector(StateSelectorFn);

  useEffect(() => {
    if (selectedDate) {
      setValues({
        ...values,
        dateOfBirth: moment(selectedDate).format("YYYY-MM-DD"),
      });
    }
  }, [selectedDate]);

  function getSelectedCity(data, value) {
    if (data) {
      const item = data?.find((opt) => {
        if (opt?.id === value) return opt;
      });
      return item || {};
    }
    return {};
  }

  const alert = useAlert();
  const history = useHistory();

  const [isError, setIsError] = React.useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: "" });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateForm = () => {
    let error = false;
    let emailError = "";
    let passwordError = "";
    let confirmPasswordError = "";
    let firstNameError = "";
    let lastNameError = "";
    let cityError = "";
    let phoneError = "";
    let dateOfBirthError = "";
    let ngoNameError = "";
    setIsError(false);
    // if(!values?.lastName)
    // {
    //   lastNameError = "*Please Enter last name";
    //   setIsError(true);
    //   error = true;
    // }
    // if(!values?.firstName)
    // {
    //   firstNameError = "*Please Enter first name";
    //   setIsError(true);
    //   error = true;
    // }
    if (!values?.ngoName) {
      ngoNameError = "*Please Enter NGO Name";
      setIsError(true);
      error = true;
    }
    if (!values?.email) {
      emailError = "*Please Enter Email Id";
      setIsError(true);
      error = true;
    }
    if (!values?.password) {
      passwordError = "*Please Enter Password";
      setIsError(true);
      error = true;
    }
    // else if(checkPassword(values?.password)){
    //   passwordError = "*Password should be alphanumeric with atleast one character and one number.";
    //   setIsError(true);
    //   error = true;
    // }
    if (
      !values?.confirmPassword ||
      values?.password !== values?.confirmPassword
    ) {
      confirmPasswordError = "*Password & Confirm Password doesn't matched.";
      setIsError(true);
      error = true;
    }
    if (!values?.phone) {
      phoneError = "*Please Enter Mobile No.";
      setIsError(true);
      error = true;
    }
    if (!values?.city) {
      cityError = "*Please Enter City";
      setIsError(true);
      error = true;
    }
    // if(!values?.dateOfBirth)
    // {
    //   dateOfBirthError = "*Please Enter Date Of Birth";
    //   setIsError(true);
    //   error = true;
    // }
    setErrorValues({
      email: emailError,
      password: passwordError,
      confirmPassword: confirmPasswordError,
      firstName: firstNameError,
      lastName: lastNameError,
      city: cityError,
      phone: phoneError,
      dateOfBirth: dateOfBirthError,
      ngoName: ngoNameError,
    });
    return error ? false : true;
  };

  const signUp = () => {
    if (validateForm()) {
      if (!values.isSignupChecked) {
        alert.error("Please tick the accept terms and conditions checkbox");
        return;
      }
      const postData = {
        email: values?.email,
        password: values?.password,
        city: values?.city,
        first_name: values?.firstName,
        last_name: values?.lastName,
        phone: values?.phone,
        dob: values?.dateOfBirth ? values?.dateOfBirth : "1990-01-01",
        city: values?.city,
      };
      setIsLoading(true);
      postRequest("authentication/ngo/register/", postData)
        .then((res) => {
          if (res?.data?.data) {
            dispatch(loggedinUser(true));
            dispatch(
              userDetails({
                name: res?.data?.data,
                username: res?.data?.user_data?.username,
                token: res?.data?.user_data?.token,
                slug: res?.data?.user_data?.slug,
                isHasNgo: res?.data?.user_data?.is_has_ngo,
                profileImage: res?.data?.user_data?.user_profile?.image,
              })
            );
            localStorage.setItem("user_token", res?.data?.user_data?.token);
            localStorage.setItem("slug", res?.data?.user_data?.slug);
            localStorage.setItem("user_type", "NGO");
            localStorage.setItem("name", values?.ngoName);
            localStorage.setItem(
              "profile_image",
              res?.data?.user_data?.user_profile?.image
            );
            localStorage.setItem("has_ngo", res?.data?.user_data?.is_has_ngo);
            localStorage.setItem("ngo_name", values?.ngoName);
            getOTP();
            localStorage.setItem("ngo_register", postData);
            history.push("ngo-registration-otp");
            alert.success("NGO registration successfull!!");
          } else {
            alert.error("Some Error Occurred!");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          alert.error(
            "Oops! It seems like this email is already in use. Please choose another email address"
          );
          setIsLoading(false);
        });
      // getOTP();
    }
  };

  const getOTP = () => {
    postRequest("authentication/get_otp/", {})
      .then((res) => {
        if (res?.data?.status) {
          history.push("ngo-registration-otp");
          alert.success("NGO registration successfull!!");
          alert.success("OTP send to the registered Email!!");
        } else {
          alert.error("Error in email sending");
        }
      })
      .catch((err) => {});
  };

  return (
    // <Layout>
    <Paper className={classes.paper}>
      <Typography variant="h3" color="primary" style={{ marginTop: "20px" }}>
        NGO Registration
      </Typography>

      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item md={4}></Grid>
        <Grid item md={4} xs={12} style={{ display: "grid" }}>
          <div style={{ display: "grid" }}>
            {/* <Typography className={classes.reqfieldtext}>
            First Name
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            onChange={handleChange("firstName")}
            error={isError}
            helperText={isError ? errorValues?.firstName : ""}
          />
          <Typography className={classes.reqfieldtext}>
            Last Name
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            onChange={handleChange("lastName")}
            error={isError}
            helperText={isError ? errorValues?.lastName : ""}
          /> */}
            <Typography className={classes.reqfieldtext}>
              NGO Name
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={handleChange("ngoName")}
              error={isError}
              helperText={
                isError && errorValues?.ngoName ? errorValues?.ngoName : ""
              }
            />

            <Typography className={classes.reqfieldtext}>
              NGO Email Id
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={handleChange("email")}
              error={isError}
              helperText={
                isError && errorValues?.email ? errorValues?.email : ""
              }
            />
            <Grid item md={12}>
              <div style={{ display: "flex" }}>
                <span style={{ color: "#A6A6A6", textAlign: "left" }}>
                  We will send you an OTP on the above Email{" "}
                </span>
              </div>
            </Grid>
            <br />
            <Typography className={classes.reqfieldtext}>
              Password
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <FormControl className={classes.margin}>
              <OutlinedInput
                style={{
                  backgroundColor: "#fff",
                  paddingTop: 26,
                  paddingBottom: 26,
                }}
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                //labelWidth={70}
              />
              {isError && errorValues?.password && (
                <FormHelperText error id="accountId-error">
                  {errorValues?.password}
                </FormHelperText>
              )}
            </FormControl>
            <br />
            <Typography className={classes.reqfieldtext}>
              Confirm Password
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <FormControl className={classes.margin}>
              <OutlinedInput
                style={{
                  backgroundColor: "#fff",
                  paddingTop: 26,
                  paddingBottom: 26,
                }}
                id="outlined-adornment-password"
                type={values.showConfirmPassword ? "text" : "password"}
                value={values.confirmPassword}
                onChange={handleChange("confirmPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                //labelWidth={70}
              />
              {isError && errorValues?.confirmPassword && (
                <FormHelperText error id="accountId-error">
                  {errorValues?.confirmPassword}
                </FormHelperText>
              )}
            </FormControl>
            {/* <Grid item md={12}> */}
            <Typography className={classes.reqfieldtext}>
              Mobile Number
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <FormControl style={{ display: "flex" }}>
              <PhoneInput
                style={{ width: "100%" }}
                country={"in"}
                inputProps={{
                  name: "phone",
                }}
                onChange={(event) => {
                  setValues({ ...values, phone: event });
                }}
              />
              {isError && errorValues?.phone && (
                <FormHelperText error id="accountId-error">
                  {errorValues?.phone}
                </FormHelperText>
              )}
            </FormControl>
            {/* <Typography className={classes.reqfieldtext}>Date of Birth
          <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography> */}
            {/* <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate}/> */}
            <Typography className={classes.reqfieldtext}>
              Headquarter State
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={states}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "cityState",
                  }}
                />
              )}
              onChange={(event, item) => {
                // setValues({...values, city: item?.id});
                if (item) setCityState(item?.city);
                else {
                  setValues({ ...values, city: "" });
                  setCityState([]);
                }
              }}
            />
            <Typography className={classes.reqfieldtext}>
              Headquarter City
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              disabled={cityState && cityState.length > 0 ? false : true}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={cityState}
              getOptionLabel={(option) => option?.city_name}
              value={cityState ? getSelectedCity(cityState, values?.city) : {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "cityState",
                  }}
                  error={isError}
                  helperText={
                    isError && errorValues?.city ? errorValues?.city : ""
                  }
                />
              )}
              onChange={(event, item) => {
                setValues({ ...values, city: item?.id });
              }}
            />

            <Grid item md={12}>
              <div style={{ display: "flex", marginTop: "20px" }}>
                <FormControlLabel
                  style={{ display: "flex", alignItems: "flex-start" }}
                  control={
                    <Checkbox
                      name="checkedB"
                      color="primary"
                      checked={
                        values?.isSignupChecked
                          ? values?.isSignupChecked
                          : false
                      }
                      onChange={(event, value) => {
                        setValues({
                          ...values,
                          isSignupChecked: !values.isSignupChecked,
                        });
                      }}
                    />
                  }
                  // label={

                  // }
                />
                <span style={{ color: "#A6A6A6", textAlign: "left" }}>
                  By signing up you indicate that you have read and agree to the{" "}
                  <span
                    style={{ color: "#C88FEF", cursor: "pointer" }}
                    onClick={() => {
                      window.open("terms-and-conditions", "_blank");
                    }}
                  >
                    Terms and Conditions
                  </span>{" "}
                </span>
              </div>
            </Grid>
          </div>
        </Grid>

        <Grid item md={4}>
          {" "}
        </Grid>
      </Grid>
      <Button
        variant="contained"
        className={classes.btn}
        color="primary"
        onClick={() => {
          signUp();
        }}
      >
        {isLoading ? <CircularProgress /> : "Next"}
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <Typography variant="body2">
          <div>
            Already have an account?
            <span
              style={{ color: "blue", fontSize: "16px", cursor: "pointer" }}
              onClick={() => {
                history.push("ngo-login");
              }}
            >
              {" "}
              Login Here!{" "}
            </span>
          </div>
        </Typography>
      </div>
      <div>
        <form className={classes.root} noValidate></form>
      </div>
    </Paper>
    // </Layout>
  );
}

export default NgoSignup;
