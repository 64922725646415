import React from "react";
import { Typography } from "@material-ui/core";
import vactor from "../../assets/png/team-work/cuate.png";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  img: {
    [theme.breakpoints.only("sm")]: {
      width: "auto",
      height: "200px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "auto",
      height: "100px",
    },
  },
}));

function OurTeamSection() {
  const classes = useStyles();
  return (
    <>
      <div>
        <Typography
          variant="h2"
          component="h1"
          color="primary"
          style={{ fontFamily: "Marcellus", textAlign: "center" }}
        >
          Our Team
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          We are in the process of building a great team. This section will see a lot of activity in the near future, so keep checking back on this page. In the meanwhile, if you believe and connect with our idea and philosophy and would like to join and be part of the team, please visit our ‘Get Involved’ page or write to us with your interest and CV at 
          <div style={{textAlign: "center",}}>
          <a
                  href="mailto:support@ikigai.com"
                  // style={{ textDecoration: "none", display: "flex" }}
                >hr@ikigaiportfolio.com</a>.
          </div>
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={vactor} height="400px" className={classes.img} />
      </div>
    </>
  );
}

export default OurTeamSection;
