import React from "react";
import { makeStyles, Button, Paper, InputBase, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "30px",
    borderColor: theme.palette.primary.main,
    border: "2px solid",
    width: "auto",
    borderRadius: "10px",
  },

  root: {
    boxShadow: "none",
    borderColor: theme.palette.primary.main,
    border: "2px solid",
    padding: "6px 4px",
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      width: "50px",
      height: "auto",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  btn: {
    color: "#fff",
    boxShadow: "none",
  },
}));

export default function Subscribe() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.card}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid container spacing={2}>
            <Grid item md={8} style={{ display: "grid" }}>
              <Paper component="form" className={classes.root}>
                <InputBase
                  className={classes.input}
                  placeholder="Enter your email address"
                  inputProps={{ "aria-label": "search google maps" }}
                />
              </Paper>
            </Grid>
            <Grid item md={4} style={{ display: "grid" }}>
              {" "}
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
              >
                Subscribe Newsletter
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
