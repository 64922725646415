import {
  Button, Collapse, Drawer, IconButton, Link, List, ListItem,
  ListItemText, MenuItem, Typography
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/icons";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import door from "../assets/png/icon/Door.png";
import Logo from "../assets/svg/logo.svg";
import DialogSearch from "../Dialogs/DialogSearch";
import NestedList from "./NestedList";

const StyledMenu = withStyles({
  paper: {
    border: ".5px solid #d3d4d5",
    borderRadius: "10px",
  },
})((props) => (
  <Menu
    style={{ top: "14px" }}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `black`,
  },
  img: {
    borderRadius: "50%",
  },
  user: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    padding: "10px",
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  becomeLoggedIn: {
    width: "100%",
    color: "#fff",
    marginBottom: "10px",
    borderRadius: "8px",
    boxShadow: "0px 10px 40px 0px #C88FEF73",
  },
  become: {
    width: "100%",
    color: "#fff",
    marginBottom: "10px",
    borderRadius: "8px",
    boxShadow: "0px 10px 40px 0px #C88FEF73",
  },
}));

const SideDrawer = ({ navLinks }) => {
  const classes = useStyles();
  const [state, setState] = useState({ right: false });

  // var localStorage = require("localStorage");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ [anchor]: open });
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const loggedIn = localStorage.getItem("user_token") ? false : true;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openLogin, setOpenLogin] = React.useState(true);

  const handleClickLogin = () => {
    setOpenLogin(!openLogin);
  };

  const sideDrawerList = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      //  onKeyDown={toggleDrawer(anchor, false)}
    >
      <>
        <div className={classes.user}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "white",
            }}
          >
            <Typography
              varient="h6"
              style={{ color: "white", marginLeft: "8px" }}
            >
              Hi
              <span
                style={{ textTransform: "capitalize", marginLeft: "10px" }}
                onClick={() => {
                  history.push(
                    localStorage.getItem("user_type") === "DONOR"
                      ? "/user/profile"
                      : "/ngo/profile"
                  );
                  handleClose();
                }}
              >
                {localStorage.getItem("user_type") === "DONOR"
                  ? localStorage.getItem("name")
                  : localStorage.getItem("ngo_slug")
                  ? localStorage.getItem("ngo_slug")
                  : localStorage.getItem("name")}
              </span>
            </Typography>
          </div>
        </div>
      </>
      <NestedList />

      <div style={{ padding: "10px" }}>
        <div className={classes.sectionDesktop}>
          {loggedIn ? (
            <>
              <List component="nav">
                {/* {navLinks.map(({ title, path, type }) => (
          <Link href={path} key={title} id="navBtn" passHref>
            <a className={classes.linkText}>
              <ListItem button id="navBtn">
                <ListItemText primary={title} style={{ flex: "0 0 auto" }} />
                {type && type == "SUPERAPP" && (
                  <div
                    style={{
                      display: "flex",
                      //transform: "rotate(90deg)",
                      // marginTop: "3px",
                      marginLeft: "-5px",
                    }}
                  ></div>
                )}
              </ListItem>
            </a>
          </Link>
        ))} */}
                {/* {localStorage.getItem("gw_token") && (
          <ListItem button onClick={() => Logout()} id="navBtn">
            <ListItemText primary={"Log Out"} />
          </ListItem>
        )} */}

                <ListItem button onClick={handleClickLogin}>
                  <ListItemText
                    primary={<Typography color="primary">Login </Typography>}
                    color="primary"
                  />
                  {openLogin ? (
                    <ExpandLess color="primary" />
                  ) : (
                    <ExpandMore color="primary" />
                  )}
                </ListItem>
                <Collapse in={openLogin} unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <ListItemText
                        onClick={() => {
                          history.push("/member-login");
                        }}
                        primary="As Member"
                      />
                    </ListItem>
                    <ListItem button className={classes.nested}>
                      <ListItemText
                        onClick={() => {
                          history.push("/ngo-login");
                        }}
                        primary="As NGO"
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
              <Button
                variant="contained"
                color="primary"
                className={classes.become}
                onClick={() => history.push("/donate-now")}
              >
                Donate Now
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.become}
                onClick={() => history.push("/user-registration-social")}
              >
                Become a Member
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.become}
                onClick={() => history.push("/ngo-registration-1")}
                width="50px"
              >
                NGO Sign Up
              </Button>
            </>
          ) : (
            <>
              {localStorage.getItem("user_type") === "DONOR" && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.becomeLoggedIn}
                  onClick={() => history.push("/donate-now")}
                >
                  Donate Now
                </Button>
              )}
              {/* <Button
                onClick={handleClick}
                variant="outlined"
                color="primary"
                style={{
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  padding: "10px",
                  // marginLeft: "20px",
                  borderRadius: "8px",
                  boxShadow: "none",
                  minWidth: "175px",
                  borderColor: "primary",
                }}
                endIcon={<ExpandMoreIcon />}
              >
                {localStorage.getItem("user_type") === "DONOR"
                  ? localStorage.getItem("name")
                  : localStorage.getItem("ngo_slug")
                  ? localStorage.getItem("ngo_slug")
                  : localStorage.getItem("name")}
              </Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <StyledMenuItem
                  onClick={() => {
                    history.push(
                      localStorage.getItem("user_type") === "DONOR"
                        ? "/user/profile"
                        : "/ngo/profile"
                    );
                    handleClose();
                  }}
                >
                  <ListItemText primary="Dashboard" />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    localStorage.clear();
                    history.push("/home");
                  }}
                >
                  <ListItemText primary="LogOut" />
                </StyledMenuItem>{" "}
              </StyledMenu> */}
              <Link href="">
                <div
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    marginTop: "15px",
                  }}
                  onClick={() => {
                    localStorage.clear();
                    history.push("/home");
                  }}
                >
                  <Typography variant="h6" color="primary">
                    LogOut
                  </Typography>
                  <img
                    src={door}
                    height="30px"
                    alt="logout"
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <IconButton
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer("right", true)}
        id="navBtn"
      >
        <Menu fontSize="large" style={{ color: `#D3D3D3` }} />
      </IconButton>

      <a href="/">
        <Button
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          style={{ flexGox: 1 }}
        >
          <img src={Logo} alt="logo" height="30px" />
        </Button>
      </a>
      <IconButton
        style={{ padding: "0px" }}
        edge="start"
        aria-label="menu"
        id="navBtn"
      >
        <DialogSearch />
      </IconButton>
      <Drawer
        anchor="left"
        open={state.right}
        onOpen={toggleDrawer("right", true)}
        onClose={toggleDrawer("right", false)}
      >
        {sideDrawerList("left")}
      </Drawer>
    </React.Fragment>
  );
};

export default SideDrawer;
