import "./App.css";
import MuiCustomTheme from "./components/Theme/theme";
import Home from "./pages/Home/Home";

import { positions, Provider as AlertProvider, transitions } from "react-alert";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import AlertTemplate from "./components/Theme/AlertTemplate";
import { useAlert } from "react-alert";
import Layout from "./components/Layout/Layout";
import OurTeam from "./pages/About-us/OurTeam";
import Philosophy from "./pages/About-us/Philosophy";
import Support from "./pages/About-us/Support";
import WhatWeDo from "./pages/About-us/WhatWeDo";
import DonationDetails from "./pages/DashBoard/DonationDetails/DonationDetails";
import FeedBack from "./pages/DashBoard/FeedBack/FeedBack";
import Membership from "./pages/DashBoard/Membership/Membership";
import NgoRegDoc from "./pages/DashBoard/Ngo_Profile/NgoRegDoc";
import NgoRegFinal from "./pages/DashBoard/Ngo_Profile/NgoRegFinal";
import Ngo_Profile from "./pages/DashBoard/Ngo_Profile/Ngo_profile";
import Ngo_Profile_2 from "./pages/DashBoard/Ngo_Profile/Ngo_profile_2";
import Ngo_Profile_3 from "./pages/DashBoard/Ngo_Profile/Ngo_profile_3";
import Payment from "./pages/DashBoard/Payment/Payment";
import Report from "./pages/DashBoard/Report/Report";
import Login from "./pages/Login/Login";
import MemberRegistration from "./pages/Login/MemberRegistration";
import NgoOtp from "./pages/Login/NgoOtp";
import NgoRegistration from "./pages/Login/NgoRegistration";
import NgoSignup from "./pages/Login/NgoSignup";
import UserRegistration from "./pages/Login/UserRegistration";
import NgoDetails from "./pages/NgoDetails/NgoDetails";
import NgoListing from "./pages/NgoListing/NgoListing";
import NgoRating from "./pages/NgoRating/NgoRating";
import NotFound from "./pages/NotFound";
import UserDownloadReports from "./pages/UserDashboard/DownloadReports";
import MyProfile from "./pages/UserDashboard/MyProfile";
import MyPortfolio from "./pages/UserDashboard/MyProfolio";
import UserNewsUpdates from "./pages/UserDashboard/NewsUpdates";
import UserNotifications from "./pages/UserDashboard/Notifications";
import UserQueries from "./pages/UserDashboard/QueriesFeedback";
// import
import { useEffect } from "react";
import BecomeMember from "./pages/BecomeMember/BecomeMember";
import ContactUs from "./pages/ContactUs/ContactUs";
import MasterAdmin from "./pages/DashBoard/MasterAdmin";
import NgoNotifications from "./pages/DashBoard/Notifications";
import PendingActivities from "./pages/DashBoard/PendingActivities";
import Rating from "./pages/DashBoard/Rating";
import DonateNow from "./pages/DonateNow/DonateNow";
import Job from "./pages/Job/Job";
import Volunteer from "./pages/Job/Volunteer";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ForgotPasswordChange from "./pages/Login/ForgotPasswordChange";
import UserRegistrationSocial from "./pages/Login/UserRegistrationSocial";
import Measurement from "./pages/Measurement/Measurement";
import NGOCause from "./pages/NGOCause";
import ReferAnNGO from "./pages/ReferNgo";
import StaticPages from "./pages/StaticPages";
import MyRecurringCommitments from "./pages/UserDashboard/MyRecurringCommitments";
import NgoPartners from "./pages/NgoPartners/NgoPartners";

function App() {
	const alert = (type, msg) => alert[type](msg);

	const options = {
		// you can also just use 'bottom center'
		position: positions.TOP_RIGHT,
		timeout: 5000,
		offset: "30px",
		// you can also just use 'scale'
		transition: transitions.SCALE,
		containerStyle: {
			zIndex: 10001,
			fontSize: "14px",
			textTransform: "lowercase",
		},
	};

	const location = useLocation();

	var loggedIn = true;

	useEffect(() => {
		loggedIn = localStorage.getItem("user_token") ? true : false;
	}, [location?.pathname]);
	const msg = "hello";
	console.log("window", window.innerWidth);
	// useEffect(() => {
	//   if (window?.innerWidth <= 480) {
	//     console.log("size not under 480");
	//     // alert(msg);
	//   } else {
	//     console.log("size not under 480");
	//   }
	// }, []);

	return (
		<>
			{/* <Router> */}
			<Switch>
				<MuiCustomTheme>
					<AlertProvider template={AlertTemplate} {...options}>
						<Layout>
							<Route exact path="/" component={Home} />
							<Route exact path="/home" component={Home} />

							<Route exact path="/member-login" component={Login} />
							<Route
								exact
								path="/create-new-password"
								component={ForgotPasswordChange}
							/>
							<Route exact path="/reset-password" component={ForgotPassword} />
							<Route exact path="/ngo-login">
								<Login isNgo={true} />
							</Route>
							<Route exact path="/about-us-support" component={Philosophy} />
							<Route exact path="/about-us-team" component={OurTeam} />
							<Route exact path="/about-us-what" component={WhatWeDo} />
							<Route exact path="/ngo-cause" component={NGOCause} />
							<Route exact path="/about-us" component={Support} />
							<Route exact path="/our-ngo-partner" component={NgoPartners} />
							<Route exact path="/ngo-details" component={NgoDetails} />
							<Route exact path="/sign-in" component={Login} />
							<Route exact path="/ngo-registration-otp" component={NgoOtp} />
							<Route
								exact
								path="/user-registration"
								component={UserRegistration}
							/>
							<Route
								exact
								path="/user-registration-social"
								component={UserRegistrationSocial}
							/>
							<Route
								exact
								path="/member-registration"
								component={MemberRegistration}
							/>
							<Route exact path="/ngo-registration-1" component={NgoSignup} />
							<Route
								exact
								path="/ngo-registration"
								component={NgoRegistration}
							/>
							<Route exact path="/report" component={Report} />
							<Route exact path="/member" component={Membership} />
							<Route exact path="/payment" component={Payment} />
							<Route exact path="/profile" component={Ngo_Profile} />
							<Route exact path="/ngo-rating" component={NgoRating} />
							<Route exact path="/ngo-impact" component={Measurement} />
							{/* <Route exact path="/ngo-points" component={KeyDataPoints} /> */}
							{/* <Route exact path="/case-studies" component={CaseStudies} /> */}
							<Route exact path="/become-a-member" component={BecomeMember} />
							<Route exact path="/donate-now" component={DonateNow} />
							<Route exact path="/jobs" component={Job} />
							<Route exact path="/volunteer" component={Volunteer} />
							<Route exact path="/refer-an-ngo" component={ReferAnNGO} />
							<Route exact path="/contact" component={ContactUs} />
							<Route exact path="/ngo-registration-doc" component={NgoRegDoc} />
							<Route
								exact
								path="/ngo-registration-final"
								component={NgoRegFinal}
							/>
							<Route
								exact
								path="/donation_details"
								component={DonationDetails}
							/>
							{/* <Route exact path="/ngo/feedback" component={FeedBack} /> */}

							<Route exact path="/user/profile">
								{loggedIn ? <MyProfile /> : <Redirect to="/home" />}
							</Route>
							<Route exact path="/user/portfolio">
								{loggedIn ? <MyPortfolio /> : <Redirect to="/home" />}
							</Route>
							<Route exact path="/user/recurring-commitments">
								{loggedIn ? (
									<MyRecurringCommitments />
								) : (
									<Redirect to="/home" />
								)}
							</Route>
							<Route exact path="/user/news-updates">
								{loggedIn ? <UserNewsUpdates /> : <Redirect to="/home" />}
							</Route>
							<Route exact path="/user/notifications">
								{loggedIn ? <UserNotifications /> : <Redirect to="/home" />}
							</Route>
							<Route exact path="/user/downloads-reports">
								{loggedIn ? <UserDownloadReports /> : <Redirect to="/home" />}/
							</Route>
							<Route exact path="/user/feedback">
								{loggedIn ? <UserQueries /> : <Redirect to="/home" />}
							</Route>

							<Route exact path="/ngo/profile">
								{loggedIn ? <Ngo_Profile /> : <Redirect to="/home" />}
							</Route>
							<Route exact path="/ngo/profile-doc">
								{loggedIn ? <Ngo_Profile_2 /> : <Redirect to="/home" />}
							</Route>
							<Route exact path="/ngo/profile-final">
								{loggedIn ? <Ngo_Profile_3 /> : <Redirect to="/home" />}
							</Route>
							<Route exact path="/ngo/member">
								{loggedIn ? <Membership /> : <Redirect to="/home" />}
							</Route>
							<Route exact path="/ngo/rating">
								{loggedIn ? <Rating /> : <Redirect to="/home" />}
							</Route>
							<Route exact path="/ngo/notifications">
								{loggedIn ? <NgoNotifications /> : <Redirect to="/home" />}
							</Route>
							<Route exact path="/ngo/donation_details">
								{loggedIn ? <DonationDetails /> : <Redirect to="/home" />}
							</Route>
							<Route exact path="/ngo/report">
								{loggedIn ? <Report /> : <Redirect to="/home" />}/
							</Route>

							<Route exact path="/ngo/payment">
								{loggedIn ? <Payment /> : <Redirect to="/home" />}
							</Route>
							<Route exact path="/ngo/pending">
								{loggedIn ? <PendingActivities /> : <Redirect to="/home" />}
							</Route>
							<Route exact path="/ngo/master_admin">
								{loggedIn ? <MasterAdmin /> : <Redirect to="/home" />}/
							</Route>

							<Route exact path="/ngo/feedback">
								{loggedIn ? <FeedBack /> : <Redirect to="/home" />}
							</Route>
							{/* <Route exact path="/pricing" component={StaticPages} url={"pricing"}/> */}
							<Route exact path="/:token" component={StaticPages} />
							<Route exact path="/page-not-found" component={NotFound} />
						</Layout>
					</AlertProvider>
				</MuiCustomTheme>
			</Switch>
			{/* </Router> */}
		</>
	);
}

export default App;
