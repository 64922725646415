import { Typography, Grid, Button, Avatar } from "@material-ui/core";
import React from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles((theme) => ({
  person: {
    display: "flex",
    alignItems: "center",
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function UserBasicInfo({ data }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.person}>
        {data?.image ? (
          <Avatar
            alt="Remy Sharp"
            className={classes.large}
            src={data?.image}
          ></Avatar>
        ) : (
          <Avatar alt="Remy Sharp" className={classes.large}>
            <PersonIcon style={{ fontSize: "70px" }} />
          </Avatar>
        )}
        <div style={{ marginLeft: "25px" }}>
          <Typography variant="h6">{data?.name}</Typography>
          <Typography variant="body2" style={{ color: "#94939F" }}>
            {data?.email}
          </Typography>
          <Typography variant="body2" style={{ color: "#94939F" }}>
            {data?.role}
          </Typography>
          <Typography variant="body2" style={{ color: "#94939F" }}>
            {data?.occupation}
          </Typography>
          <Typography variant="body2" style={{ color: "#94939F" }}>
            {data?.qualification}
          </Typography>
        </div>
      </div>
    </>
  );
}
