import React, { useEffect } from "react";
import ChooseYourMode from "../Cards/Details/ChooseYourMode";
import {
  makeStyles,
  Button,
  Dialog,
  Slide,
  withStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  rootdialog: {
    borderRadius: "20px",
    borderColor: theme.palette.primary.main,
    border: "1px solid",
    backgroundColor: "#f7effc",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingBottom: "0px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DonationDialog({ngoId, isAnonymous=false ,open=false, setOpen = () => {}, ngoName}) {
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setOpenState()
  };

  // useEffect(() => {
  //   if(openState){

  //   }
  // }, [openState])

  const classes = useStyles();

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Donation Dialog
      </Button> */}
      <Dialog
        PaperProps={{ classes: { root: classes.rootdialog } }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Donating {ngoName ? `to ${ngoName}` : ""}
        </DialogTitle>
        <DialogContent>
          <ChooseYourMode ngoId={ngoId} isAnonymous={isAnonymous} ngoName={ngoName} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
