import React from "react";
import user from "../../assets/png/team-work/user.jpg";
import { makeStyles, Typography } from "@material-ui/core";
import TeamToolTip from "../../Dialogs/TeamToolTip";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    textAlign: "center",
  },
  user: {
    borderRadius: "50%",
    [theme.breakpoints.only("sm")]: {
      width: "auto",
      height: "200px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "auto",
      height: "100px",
    },
  },
}));

function Team(props) {
  const classes = useStyles();
  return (
    <>
    <TeamToolTip>
      <div className={classes.card}>
        <img
          src={user}
          height="150px"
          width="150px"
          alt="user"
          className={classes.user}
        />
        <Typography variant="h6" noWrap style={{ marginTop: "20px" }}>
          {props.name}
        </Typography>
        <Typography variant="body2" style={{ color: "#626262" }}>
          Director Marketing
        </Typography>
      </div>
      </TeamToolTip>
    </>
  );
}

export default Team;
