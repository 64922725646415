import React from "react";
import { Typography } from "@material-ui/core";
import vactor from "../../assets/png/happy-earth/cuate.png";
import { makeStyles } from "@material-ui/styles";
import Heading from "../../Common/Heading/Heading";

const useStyles = makeStyles((theme) => ({
  img: {
    [theme.breakpoints.only("sm")]: {
      width: "auto",
      height: "200px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "auto",
      height: "100px",
    },
  },
}));

export default function Solution() {
  const classes = useStyles();
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography
          variant="h2"
          component="h1"
          color="primary"
          style={{ fontFamily: "Marcellus", textAlign: "center" }}
        >
          Solution
        </Typography>

        <img src={vactor} height="400px" className={classes.img} />

        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum,
          ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo
          in tristique feugiat faucibus arcu tempor. Vestibulum, congue vitae
          eleifend nec maecenas tristique bibendum egestas fermentum. Cursus
          nunc vulputate cras felis suspendisse convallis nisl.
        </Typography>
        <br />
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum,
          ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo
          in tristique feugiat faucibus arcu tempor.
        </Typography>
      </div>
    </>
  );
}
