import { FormHelperText, Grid, TextField, Typography, withStyles, Chip, Button, Radio, RadioGroup, FormControl, FormControlLabel } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useSelector } from "react-redux";
import { postRequest } from "../../../apis/baseApi";
import { cityStateSelectorFn, StateSelectorFn, categoriesListSelectorFn } from "../../../redux/commonReducer/selector";
import CustomButtonCircularProgress from "../../CircularProgress";
  

const ChipAutocomplete = withStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        height: "100%",
      },
    },
  })(Autocomplete);



  const useStyles = makeStyles((theme) => ({
    paper: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      boxShadow: "none",
      textAlign: "center",
      padding: 20,
      borderRadius: 22,
    },
    btn: {
      color: "#fff",
      paddingLeft: "60px",
      paddingRight: "60px",
      boxShadow: "0px 10px 40px 0px #C88FEF73",
      marginTop: "50px",
      marginBottom: "50px",
      borderRadius: 8,
      height: 46,
    },
    googlebtn: {
      boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
      backgroundColor: "#fff",
      padding: "10px",
      borderRadius: "30px",
      paddingLeft: "15px",
      paddingRight: "15px",
      "&:hover": {
        borderColor: theme.palette.primary.main,
        border: "1px solid",
      },
    },
    fieldtext: {
      fontSize: "12px",
      textAlign: "left",
      marginBottom: "10px",
      display: "flex",
    },
    reqfieldtext: {
      fontSize: "12px",
      textAlign: "left",
      display: "flex",
    },
    option: {
      fontSize: 13,
      "& > span": {
        marginRight: 10,
        fontSize: 12,
      },
    },
    upload: {
      boxShadow: "none",
      borderRadius: "8px",
      color: "#fff",
      height: "40px",
      display: "flex",
    },
  }));
  
  export default function ReferNGO() {
    const classes = useStyles();
  
    const states = useSelector(StateSelectorFn);
    const allCities = useSelector(cityStateSelectorFn);

    const causes = useSelector(categoriesListSelectorFn);
  
    const [cities, setCities] = useState([]);
    const alert = useAlert();
    const [isLoading, setIsLoading] = useState(false);
  
    const initialState = {
      ngoName: "",
      ngoState: "",
      ngoCity: "",
      ngoPersonName: "",
      ngoPersonPhone: "",
      ngoPersonDesignation: "",
      email: "",
      theme: [],
      geography: [],
      yourName: "",
      yourOccupation: "",
      yourOrganization: "",
      yourDesignation: "",
      yourPhone: "",
      yourEmail: ""
    }
  
    const [values, setValues] = React.useState(initialState);
  
    const [removeFileName, setRemoveFileName] = React.useState(false);
  
    const [errorValues, setErrorValues] = React.useState({
        ngoName: "",
        ngoState: "",
        ngoCity: "",
        ngoPersonName: "",
        ngoPersonPhone: "",
        ngoPersonDesignation: "",
        email: "",
        theme: [],
        geography: [],
        yourName: "",
        yourOccupation: "",
        yourOrganization: "",
        yourDesignation: "",
        yourPhone: "",
        yourEmail: ""
    });
  
    const [isError, setIsError] = React.useState(false);
    const [selectedDate, setSelectedDate] = useState();
  
    function submitApplcation(){
    //   if(validateData()){
    //     const postData = new FormData();
    //     postData.set("first_name" , values?.firstName);
    //     postData.set("last_name" , values?.lastName);
    //     postData.set("mobile_number" , values?.mobileNumber);
    //     postData.set("email" , values?.email);
    //     postData.set("address" , values?.address);
    //     postData.set("pincode" , values?.pincode);
    //     postData.set("city" , values?.city);
    //     postData.set("state" , values?.state);
    //     postData.set("dob" , values?.dateOfBirth);
    //     postData.set("form_type" , values?.formType);
    //     postData.set("resume", values?.resume);
        
    //     setIsLoading(true);
    //     postRequest("miscellaneous_tables/create_job_volunteer_form/", postData).then(res => {
    //       if(res?.data?.data){
    //         alert.success("Application submitted succesfully!.We will get back to you shortly");
    //         setValues(initialState);
    //         setSelectedDate("");
    //         setRemoveFileName(true);
    //       }
    //       else{
    //         alert.error("Something went wrong!!")
    //       }
    //       setIsLoading(false);
    //     }).catch(err => {
    //       setIsLoading(false);
    //       alert.error("Something went wrong!!")
    //     })
    //   }
    }
  
    function validateData(){
          let error = false;
          let ngoNameError = "";
          let ngoStateError = "";
          let ngoCityError = "";
          let ngoPersonNameError = "";
          let ngoPersonPhoneError = "";
          let emailError = "";
          let themeError = "";
          let geographyError = "";
          let yourNameError =  "";
            let yourOccupationError =  "";
        let yourOrganizationError =  "";
      let yourDesignationError = "";
      let yourPhoneError = "";
      let yourEmailError =  "";
      let ngoPersonDesignationError = "";
  
          setIsError(false);

          if(!values?.ngoPersonDesignation)
          {
            ngoPersonDesignationError = "*Please Enter NGO Contact Person Designation";
            setIsError(true);
            error = true;
          }

          if(!values?.yourName)
          {
            yourNameError = "*Please Enter Your name";
            setIsError(true);
            error = true;
          }

          if(!values?.yourDesignation)
          {
            yourDesignationError = "*Please Enter Your Designation";
            setIsError(true);
            error = true;
          }

          if(!values?.yourPhoneError)
          {
            yourPhoneError = "*Please Enter Your Phone";
            setIsError(true);
            error = true;
          }

          if(!values?.yourEmail)
          {
            yourEmailError = "*Please Enter Your Email";
            setIsError(true);
            error = true;
          }
  
          if(!values?.ngoName)
          {
            ngoNameError = "*Please Enter Ngo name";
            setIsError(true);
            error = true;
          }
          
          if(!values?.ngoState)
          {
            ngoStateError = "*Please Enter NGO HQ State";
            setIsError(true);
            error = true;
          }

          if(!values?.ngoCity)
          {
            ngoCityError = "*Please Enter NGO HQ City";
            setIsError(true);
            error = true;
          }
          
          if(!values?.email)
          {
            emailError = "*Please Enter Email Id";
            setIsError(true);
            error = true;
          }
          
          if(!values?.ngoPersonPhone)
          {
            ngoPersonPhoneError = "*Please Enter NGO Person Phone";
            setIsError(true);
            error = true;
          }

          if(!values?.ngoPersonName)
          {
            ngoPersonNameError = "*Please Enter NGO Person Name";
            setIsError(true);
            error = true;
          }

          if(!values?.theme)
          {
            themeError = "*Please Enter NGO Thematic Areas of Work";
            setIsError(true);
            error = true;
          }

          if(!values?.geography)
          {
            geographyError = "*Please Enter NGO Geographical Areas of Work";
            setIsError(true);
            error = true;
          }
          
  
          setErrorValues({
            ngoName: ngoNameError,
            ngoState: ngoStateError,
            ngoCity: ngoCityError,
            ngoPersonName: ngoPersonNameError,
            ngoPersonPhone: ngoPersonPhoneError,
            email: emailError,
            theme: themeError,
            geography: geographyError,
            yourName: yourNameError,
            yourOccupation: yourOccupationError,
            yourOrganization: yourOrganizationError,
            yourDesignation: yourDesignationError,
            yourPhone: yourPhoneError,
            yourEmail: yourEmailError,
            ngoPersonDesignation: ngoPersonDesignationError
          })
        
        return error ? false : true;
      }
  
    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
      setErrorValues({...errorValues, [prop]: " "})
    };
  
    function getSelectedCity(value){
      if(allCities){
      const item = allCities?.find((opt)=>{
        if (opt?.city_name === value)
          return opt;
      })
      return item || {};
    }
    return {}
  }
  
    return (
      <>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
              NGO Name
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.firstName}
              onChange={handleChange("firstName")}
              error={isError}
              helperText={isError ? errorValues?.ngoName : ""}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography className={classes.fieldtext}>
              
              NGO HQ State
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
              </Typography>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={states}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "State",
                  }}
                  error={isError}
                  helperText={isError ? errorValues?.ngoState : ""}
                />
              )}
            //   onChange={(event, item) => {
            //     if(item){
            //     setValues({...values, state: item.name, city: ""})
            //     }
            //     else{
            //       setValues({...values, city: ""})
            //     }
            //     setCities(item?.city ? item?.city : []);
  
            //   }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className={classes.fieldtext}>NGO HQ City
            {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={cities}
              getOptionLabel={(option) => option.city_name}
            //   disabled={cities && cities?.length > 0 ? false : true}
            //   value={cities ? getSelectedCity(values?.city) : {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                  error={isError}
                  helperText={isError ? errorValues?.ngoCity : ""}
                />
              )}
            //   onChange={(event, item) => {
            //     if(item){
            //     setValues({...values, city: item?.city_name})
            //     }
            //     else{
            //       setValues({...values, city: ""})
            //     }
            //   }}
            />
          </Grid>


          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
            NGO Contact Person Name
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            //   value={values?.firstName}
              onChange={handleChange("ngoPersonName")}
              error={isError}
              helperText={isError ? errorValues?.ngoPersonName : ""}
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
            NGO Contact Person Designation
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.ngo}
              onChange={handleChange("ngoPersonDesignation")}
              error={isError}
              helperText={isError ? errorValues?.ngoPersonDesignation : ""}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <Typography
              style={{
                fontSize: "12px",
                textAlign: "left",
                marginBottom: "7px",
              }}
            >
              NGO Contact Person Phone
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
            <PhoneInput
              style={{ width: "100%" }}
              value={values?.mobileNumber}
              country={"in"}
              inputProps={{
                name: "phone",
              }}
            //   onChange={(event, value) => {
            //     setValues({...values, mobileNumber: event})
            //   }}
            />
            {isError && errorValues?.ngoPersonPhone && (
                  <FormHelperText error id="accountId-error">
                    {errorValues?.ngoPersonPhone}
                  </FormHelperText>
                )}
          </Grid>
  
          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
            NGO Contact Person Email
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.email}
              onChange={handleChange("email")}
              error={isError}
              helperText={isError ? errorValues?.email : ""}
            />
          </Grid>

        <Grid item md={12} xs={12} style={{ display: "grid" }}>
        <Typography className={classes.reqfieldtext}>
        NGO Thematic Areas of Work
            {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
          </Typography>
        
        {/* <Grid item md={8} xs={12} style={{ display: "grid" }}> */}
        <ChipAutocomplete
            style={{ backgroundColor: "#fff" }}
            multiple
            id="tags-outlined"
            options={causes ? causes : []}
            getOptionLabel={(option) => option.category_name}
            // defaultValue={[demo[1]]}
            // value={subCauses ? getSubCausesList(): []}
            // value={tempSelectedSubcause}
            filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  color="primary"
                  variant="outlined"
                  style={{maxWidth: "600px"}}
                  label={option.category_name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" 
              label="Select Thematic Area Of Work"
              />
            )}
            // onChange={(e, item) => {
            //   if(item){
            //   const loc1 = item.map(item1 => {
            //       return item1?.id;
            //   })
            //   setValues({...values, subcategories: loc1});
            //   }else{
            //     setValues({...values, subcategories: []});
            //   }
            // }}
            // onChange={(e, item) => {
            //   if(item){
            //     setTempSelectedSubcause(item);
            //   }
            //   else{
            //     setTempSelectedSubcause([]);
            //   }
            // }}
          />
        {/* </Grid> */}
        </Grid>
        

        <Grid item md={12} xs={12} style={{ display: "grid" }}>
        <Typography className={classes.reqfieldtext}>
        NGO Geographical Areas of Work
            {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
          </Typography>
        
        {/* <Grid item md={8} xs={12} style={{ display: "grid" }}> */}
        <ChipAutocomplete
            style={{ backgroundColor: "#fff" }}
            multiple
            id="tags-outlined"
            options={states ? states : []}
            getOptionLabel={(option) => option.name}
            // defaultValue={[demo[1]]}
            // value={subCauses ? getSubCausesList(): []}
            // value={tempSelectedSubcause}
            filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  color="primary"
                  variant="outlined"
                  style={{maxWidth: "600px"}}
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" 
              label="Select Geographical Area Of Work"
              />
            )}
            // onChange={(e, item) => {
            //   if(item){
            //   const loc1 = item.map(item1 => {
            //       return item1?.id;
            //   })
            //   setValues({...values, subcategories: loc1});
            //   }else{
            //     setValues({...values, subcategories: []});
            //   }
            // }}
            // onChange={(e, item) => {
            //   if(item){
            //     setTempSelectedSubcause(item);
            //   }
            //   else{
            //     setTempSelectedSubcause([]);
            //   }
            // }}
          />
        {/* </Grid> */}
        </Grid>


        </Grid>
        
        
    
        
        <Grid container spacing={2} style={{marginTop: "20px"}}>


        <Grid item md={12}>
              <Typography
                variant="h4"
                color="primary"
                style={{ textAlign: "center" }}
              >
                Details of Referrer
              </Typography>
            </Grid>



          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
            Your Name
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.yourName}
              onChange={handleChange("yourName")}
              error={isError}
              helperText={isError ? errorValues?.yourName : ""}
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
            Your Occupation
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.yourOccupation}
              onChange={handleChange("yourOccupation")}
              error={isError}
              helperText={isError ? errorValues?.yourOccupation : ""}
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
            Name of Your Organisation
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.yourOrganization}
              onChange={handleChange("yourOrganization")}
              error={isError}
              helperText={isError ? errorValues?.yourOrganization : ""}
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
            Your Designation
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.yourDesignation}
              onChange={handleChange("yourDesignation")}
              error={isError}
              helperText={isError ? errorValues?.yourDesignation : ""}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <Typography
              style={{
                fontSize: "12px",
                textAlign: "left",
                marginBottom: "7px",
              }}
            >
              Your Phone
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
            <PhoneInput
              style={{ width: "100%" }}
              value={values?.mobileNumber}
              country={"in"}
              inputProps={{
                name: "phone",
              }}
            //   onChange={(event, value) => {
            //     setValues({...values, mobileNumber: event})
            //   }}
            />
            {isError && errorValues?.yourPhone && (
                  <FormHelperText error id="accountId-error">
                    {errorValues?.yourPhone}
                  </FormHelperText>
                )}
          </Grid>
  
          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
            Your Email
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.yourEmail}
              onChange={handleChange("yourEmail")}
              error={isError}
              helperText={isError ? errorValues?.yourEmail : ""}
            />
          </Grid>
        </Grid>

        <Grid item md={12} xs={12} style={{ display: "grid", marginTop: "20px" }}>
        <Typography className={classes.reqfieldtext}>
        Can we use your name as reference when we connect with the NGO
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
        
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <RadioGroup
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            //   value={values?.internationalPayment ? "yes" : "no"}
            //   onChange={(event, item) => {
            //     if (item === "yes")
            //       setValues({ ...values, internationalPayment: true });
            //     else {
            //       setValues({ ...values, internationalPayment: false, intlAccountName: "", intlBankName: "", intlIfscCode: "", intlAccountNo: "", intlBranch: "" })
            //     };
            //   }}
            >
              <FormControlLabel
                name="refrence"
                value="yes"
                control={<Radio color="primary" size="small" />}
                label="Yes"
              />
              <FormControlLabel
                name="refrence"
                value="no"
                control={<Radio color="primary" size="small" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>

        </Grid>



        <div>
        <Button className={classes.btn} color="primary" variant="contained" onClick={() => {
          submitApplcation()
        }}>
          {isLoading ? <CustomButtonCircularProgress /> : "Refer"}
        </Button>
      </div>
      </>
    );
  }
  