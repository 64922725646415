// src/CarouselComponent.js
import React from "react";
import "./CarouselComponent.css"; // You can add your custom CSS here
import Image from "../../../assets/images/esteban-benites-_bZzytRTSvo-unsplash.jpg";
import Carousel from "react-multi-carousel";

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const CarouselComponent = () => {
	const slides = [
		{
			id: 1,
			imgSrc: Image,
			caption: "Appreciation from Government",
			description: "Description",
		},
		{
			id: 2,
			imgSrc: "path/to/your/image2.jpg",
			caption: "Another Slide",
			description: "Another Description",
		},
		// Add more slides as needed
	];

	return (
		<Carousel responsive={responsive}>
			<div>
				<h3>Appreciation 1</h3>
			</div>
			<div>
				<h3>Appreciation 2</h3>
			</div>
			<div>
				<h3>Appreciation 3</h3>
			</div>
			{/* Add more appreciation messages here */}
		</Carousel>
	);
};

export default CarouselComponent;
