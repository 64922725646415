import React from "react";
import DashboardLayout from "../../../components/Layout/DashBoardLayout";
import PendingActivityGrid from "../../../components/Section/DashBoard/Report/PendingActivityGrid";

export default function Report() {
  return (
    <>
      <DashboardLayout>
        <PendingActivityGrid />
      </DashboardLayout>
    </>
  );
}
