import { LOGGEDIN_USER, USER_DETAILS, CITY_STATE_LIST, CATEGORIES_LIST, NGO_DOCS, SUB_CATEGORIES_LIST } from "./type";

export const loggedinUser = (payload) => {
    return async (dispatch) => {
        dispatch({
          type: LOGGEDIN_USER,
          payload: payload,
        });
      }
    };

export const userDetails = (payload) => {
  return async (dispatch) => {
      dispatch({
        type: USER_DETAILS,
        payload: payload,
      });
    }
  };

export const cityStateDetails = (payload) => {
  return async (dispatch) => {
      dispatch({
        type: CITY_STATE_LIST,
        payload: payload,
      });
    }
  };

export const categoryListDetails = (payload) => {
  return async (dispatch) => {
      dispatch({
        type: CATEGORIES_LIST,
        payload: payload,
      });
    }
  };

  export const subCategoryListDetails = (payload) => {
    return async (dispatch) => {
        dispatch({
          type: SUB_CATEGORIES_LIST,
          payload: payload,
        });
      }
    };

  export const NGODocDetails = (payload) => {
    return async (dispatch) => {
        dispatch({
          type: NGO_DOCS,
          payload: payload,
        });
      }
    };
  