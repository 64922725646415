import { Typography, Grid, Button, TextField } from "@material-ui/core";
import React from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  upload: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    color: "#fff",
    borderRadius: 8,
    height: 46,
  },

  fieldtext: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "10px",
    display: "flex",
  },
  reqfieldtext: {
    fontSize: "14px",
    textAlign: "left",
  },
  option: {
    fontSize: 13,
    "& > span": {
      marginRight: 10,
      fontSize: 12,
    },
  },
  gridtext: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

export default function NgoRegThirdCard() {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography
            variant="body2"
            style={{
              color: "#A6A6A6",
              fontStyle: "italic",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            Add your social media links
          </Typography>
        </Grid>

        <Grid item md={2} xs={12} className={classes.gridtext}>
          <Typography className={classes.reqfieldtext}>
            Facebook
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
        </Grid>
        <Grid item md={4} xs={12} style={{ display: "grid" }}>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>

        <Grid item md={2} xs={12} className={classes.gridtext}>
          <Typography className={classes.reqfieldtext}>
            Twitter
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
        </Grid>
        <Grid item md={4} xs={12} style={{ display: "grid" }}>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={2} xs={12} className={classes.gridtext}>
          <Typography className={classes.reqfieldtext}>
            Instagram
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
        </Grid>
        <Grid item md={4} xs={12} style={{ display: "grid" }}>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>

        <Grid item md={2} xs={12} className={classes.gridtext}>
          <Typography className={classes.reqfieldtext}>
            Youtube
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
        </Grid>
        <Grid item md={4} xs={12} style={{ display: "grid" }}>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={2} xs={12} className={classes.gridtext}>
          <Typography className={classes.reqfieldtext}>Featured</Typography>
        </Grid>
        <Grid item md={4} xs={12} style={{ display: "grid" }}>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item md={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.upload}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
