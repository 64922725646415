import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {
  makeStyles,
  alpha,
  Avatar,
  Grid,
  Typography,
  TextField,
  Badge,
  IconButton,
} from "@material-ui/core";
import CustomButtonCircularProgress from "../CircularProgress";
import { useState, useRef } from "react";
import { postRequest } from "../../apis/baseApi";
import { useAlert } from "react-alert";
import ImageCropperDialog from "../Common/ImageCropper";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles((theme) => ({
  rootdialog: {
    borderRadius: "20px",
    borderColor: theme.palette.primary.main,
    border: "1px solid",
    backgroundColor: "#f7effc",
    width: "500px",
  },
  fileInput: {
    display: "none",
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
  },
  btn: {
    boxShadow: "none",
    borderRadius: "8px",
    color: "#fff",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "12px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BasicInfoDialog({
  open,
  handleClose,
  newUser,
  setNewUser,
  setRefreshData,
  edit
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();

  const validateMemberDetail = () => {
    if (
      !newUser?.name ||
      !newUser?.occupation ||
      !newUser?.email ||
      !newUser?.qualification
    )
      return false;
    return true;
  };

  const addTeamMember = () => {
    if (validateMemberDetail()) {
      setIsLoading(true);
      let formData = new FormData();
      formData.set("name", newUser?.name);
      formData.set("occupation", newUser?.occupation);
      formData.set("email", newUser?.email);
      formData.set("qualification", newUser?.qualification);
      formData.set("role", newUser?.role);
      formData.set("description", newUser?.description);
      if (previewImage) {
        formData.set("image", dataURLtoFile(previewImage, fileName));
      }

      postRequest(
        `ngo/key_team_member_list/${localStorage.getItem("ngo_slug")}/`,
        formData
      )
        .then((res) => {
          alert.success("Member Added successfully!!");
          setRefreshData(true);
          handleClose();
          setIsLoading(false);
        })
        .catch((err) => {
          // alert.error("Something went wrong!");
          setIsLoading(false);
        });
    } else {
      alert.error("Please fill all details!");
    }
  };

  const [profilePic, setProfilePic] = useState();
  const [openProfile, setOpenProfile] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [fileName, setFileName] = useState();
  const fileInputRefNew = useRef();
  const [profilePicChanged, setProfilePicChanged] = useState(false);

  const fileInputClickedNew = (e) => {
    fileInputRefNew.current.click();
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setProfilePic(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setFileName(e.target.files[0].name);
      handleClickOpen();
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const addDetailsImage = (prevImg) => {
    const fd = new FormData();
    // if(prevImg){
    // fd.append("image", dataURLtoFile(prevImg, fileName));
    // }
    // else{
    //   alert.error("Image Error!!");
    //   return;
    // }
    // setSaveLoaderImage(true);
    // putRequest(`authentication/user_profile/${localStorage.getItem('slug')}/`, fd).then(res => {
    //   if(res?.data?.data){
    //     alert.success("Profile Updated successfully!!");
    //     localStorage.setItem('profile_image', res.data.data?.image);
    //   }
    //   else{
    //     alert.error("Something went wrong!");
    //   }
    //   // setIsLoading(false);
    //   setSaveLoaderImage(false);
    // }).catch(err => {
    //   alert.error("Something went wrong!");
    //   setSaveLoaderImage(false);
    // })
  };

  const handleClickOpen = () => {
    setOpenProfile(true);
  };

  const handleCloseCropper = () => {
    setOpenProfile(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        User Dialog
      </Button> */}
      <Dialog
        PaperProps={{ classes: { root: classes.rootdialog } }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <Avatar alt="Remy Sharp" className={classes.large}>
              <PhotoCameraIcon style={{ fontSize: "50px" }} />
            </Avatar> */}
            <ImageCropperDialog
              open={openProfile}
              setOpen={setOpenProfile}
              handleClickOpen={handleClickOpen}
              handleClose={handleCloseCropper}
              profilePic={profilePic}
              setProfilePic={setProfilePic}
              setPreviewImage={setPreviewImage}
              setProfilePicChanged={setProfilePicChanged}
              addDetailsImage={addDetailsImage}
            />
            <div>
              <input
                ref={fileInputRefNew}
                className={classes.fileInput}
                type="file"
                id="file"
                onChange={(e) => {
                  onSelectFile(e);
                }}
                accept="image/*"
              />
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                style={{ marginBottom: 40 }}
                badgeContent={
                  <IconButton
                    style={{
                      background: "#fff",
                      padding: 6,
                      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                      border: "solid 1px #f1f1f1",
                    }}
                    onClick={(e) => fileInputClickedNew(e)}
                  >
                    <PhotoCamera style={{ fontSize: 20, color: "#C88FEF" }} />
                  </IconButton>
                }
              >
                {/* <Avatar alt="Travis Howard" style={{ height: "100px", width: "100px" }} src={saveLoaderImage ? circular_loader : previewImage} /> */}
                {previewImage ? (
                  <Avatar
                    alt="Travis Howard"
                    style={{ height: "100px", width: "100px" }}
                    src={previewImage}
                  />
                ) : (
                  <Avatar alt="Remy Sharp" className={classes.large}>
                    <PersonIcon style={{ fontSize: "70px" }} />
                  </Avatar>
                )}
              </Badge>
            </div>
          </div>
          <Grid container spacing={2} style={{ padding: "10px" }}>
            <Grid item md={3} style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2 " style={{ fontSize: "12px" }}>
                Name<span style={{ color: "red", fontSize: "20px" }}>*</span>
              </Typography>
            </Grid>
            <Grid item md={9} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                placeholder="Add Name"
                color="primary"
                value={newUser?.name}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                }}
                onChange={(e) => {
                  setNewUser({ ...newUser, name: e.target.value });
                }}
              />
            </Grid>
            <Grid item md={3} style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "12px" }}>Email</Typography>
            </Grid>
            <Grid item md={9} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                placeholder="Add Email"
                color="primary"
                value={newUser?.email}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                }}
                onChange={(e) => {
                  setNewUser({ ...newUser, email: e.target.value });
                }}
              />
            </Grid>
            <Grid item md={3} style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "12px" }}>
                Occupation
                {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
              </Typography>
            </Grid>
            <Grid item md={9} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                placeholder="Add Occupation"
                color="primary"
                value={newUser?.occupation}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                }}
                onChange={(e) => {
                  setNewUser({ ...newUser, occupation: e.target.value });
                }}
              />
            </Grid>
            <Grid item md={3} style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "12px" }}>
                Qualification
                {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
              </Typography>
            </Grid>
            <Grid item md={9} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                placeholder="Add Qualification"
                color="primary"
                value={newUser?.qualification}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                }}
                onChange={(e) => {
                  setNewUser({ ...newUser, qualification: e.target.value });
                }}
              />
            </Grid>
            <Grid item md={3} style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "12px" }}>
                Role
                {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
              </Typography>
            </Grid>
            <Grid item md={9} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                placeholder="Add Role"
                color="primary"
                value={newUser?.role}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                }}
                onChange={(e) => {
                  setNewUser({ ...newUser, role: e.target.value });
                }}
              />
            </Grid>
            <Grid item md={3} style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "12px" }}>
                Description
                {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
              </Typography>
            </Grid>
            <Grid item md={9} style={{ display: "grid" }}>
              <TextField
                variant="outlined"
                multiline
                rows={3}
                placeholder="Add Description"
                value={newUser?.description}
                color="primary"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                }}
                onChange={(e) => {
                  setNewUser({ ...newUser, description: e.target.value });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btn}
            color="primary"
            onClick={() => {
              addTeamMember();
            }}
          >
            <span style={{ color: "#fff", fontSize: "14px" }}>
              {isLoading ? <CustomButtonCircularProgress /> : "Add Member"}
            </span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
