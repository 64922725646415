import {
	Card,
	CardContent,
	CardMedia,
	Typography,
	makeStyles,
	Box,
	Grid,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
	card: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "1rem",
		width: "25%",
		marginBottom: theme.spacing(3),
		borderRadius: theme.shape.borderRadius,
		boxShadow: theme.shadows[4],
		transition: "transform 0.3s",
		"&:hover": {
			transform: "scale(1.05)",
		},
	},
	cardMedia: {
		width: 160,
		height: 160,
		objectFit: "cover",
		borderRadius: theme.shape.borderRadius,
	},
	cardContent: {
		flex: "1 0 auto",
		padding: theme.spacing(2),
	},
	title: {
		marginBottom: theme.spacing(1),
		fontSize: "1.2rem",
		color: "#b084fa",
	},
	description: {
		fontSize: "0.8rem",
		marginBottom: theme.spacing(1),
	},
	goals: {
		display: "flex",
		alignItems: "center",
		marginBottom: theme.spacing(1),
	},
	goalItem: {
		marginRight: theme.spacing(1),
	},
	image: {
		width: "90%",
		aspectRatio: "16:9",
		borderRadius: "10px",
	},
	grid: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	titleButton: {
		padding: "0.3rem 0.5rem",
		backgroundColor: "#f5effd",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "10px",
		margin: "0.6rem",
	},
	buttonText: {
		color: "#b892fb",
		textTransform: "uppercase",
		fontSize: "12px",
		fontWeight: "600",
	},
}));

const NgoDetialsCard = ({ details }) => {
	const classes = useStyles();
	console.log("Details are", details);
	return (
		<Card className={classes.card}>
			<img src={details.image} className={classes.image} />
			<CardContent className={classes.cardContent}>
				<Typography variant="h6" className={classes.title}>
					{details.ngoName}
				</Typography>
				<Typography variant="body1" className={classes.description}>
					{details.description}
				</Typography>
				<Grid className={classes.grid}>
					{details?.achivements?.map((el, index) => {
						console.log("Achievement is", el);
						return (
							<Box className={classes.titleButton}>
								<Typography className={classes.buttonText}>{el}</Typography>
								{/* <p>{el}</p> */}
							</Box>
						);
					})}
				</Grid>
			</CardContent>
		</Card>
	);
};

export default NgoDetialsCard;
