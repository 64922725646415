import React, { useState } from "react";
import { alpha, makeStyles, Paper, Typography } from "@material-ui/core";
import NgoRegSecondCard from "../../../Form/Reg/NgoRegTwo/NgoRegSecondCard";
import NgoRegTwo from "../../../Form/Reg/NgoRegTwo/NgoRegTwo";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderRadius: "20px",
    padding: "25px",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  percentage: {
    color: theme.palette.primary.main,
  },
  person: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function NgoRegTwoSection({
  docs,
  setDocs,
  uploadDocs,
  fromProfile = false,
  isLoading = false,
}) {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(!edit);
  };
  console.log("lkjhgfghj", edit);
  return (
    <>
      <Paper className={classes.paper}>
        <div>
          <Typography
            variant="h3"
            color="primary"
            style={{ marginBottom: "20px", marginTop: "20px" }}
          >
            {fromProfile ? "NGO Profile 2/3" : "NGO Registration Form Part 2/3"}
          </Typography>
          <NgoRegTwo
            setDocs={setDocs}
            docs={docs}
            fromProfile={fromProfile}
            edit={edit}
            handleEdit={handleEdit}
          />
        </div>
      </Paper>
      <br />
      <Paper className={classes.paper}>
        <NgoRegSecondCard
          docs={docs}
          setDocs={setDocs}
          uploadDocs={uploadDocs}
          fromProfile={fromProfile}
          isLoading={isLoading}
          edit={edit}
          handleEdit={handleEdit}
        />
      </Paper>
      {/* <br />
      <Paper className={classes.paper}>
        <NgoRegThirdCard />
      </Paper> */}
    </>
  );
}
