import React from "react";
import CenterHeading from "../../Common/Heading/CenterHeading";
import Grid from "@material-ui/core/Grid";
import Abhishek from "../../../components/assets/png/abhishek.jpeg";
import Ankita from "../../../components/assets/png/Ankita.JPG";
import DirectorsPerson from "../../Cards/DonationPerson/DirectorsPerson";

function BoardDirectors() {
  return (
    <>
      <CenterHeading title="Board Of Directors" />
      <Grid container spacing={2} style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
        {donator.map((item) => (
          <Grid item md={4} sm={4} xs={12}>
            <DirectorsPerson image={item.image} name={item.title} data={item}/>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default BoardDirectors;

const donator = [
  { image: Abhishek, title: `Mr. Abhishek Choudhury`, position: "Founder and Director", popUp: "Abhishek Choudhury is the Founder and Director of Ikigai Portfolio Private Limited. He has over 17 years of diverse experience in the social sector having worked in Microfinance and Financial Inclusion, Corporate Social Responsibility, Government Advisory and Consulting. He has worked in multiple roles for reputed organisations like CRISIL Foundation, GIZ, Ernst & Young, MicroSave and Friends of Women’s World Banking India (now Ananya Finance). Abhishek has also provided consulting and advisory services to Indian Institute of Bank Management (IIBM) Guwahati, Sa-Dhan, ACCION, Agriculture Finance Corporation (AFC) among others. He has also been a guest faculty and resource person at Indian Institute of Forest Management (IIFM) Bhopal, Lal Bahadur Shastri Institute of Management (LBSIM) Delhi, Gaur Hari Singhania Institute of Management & Research (GHSIMR) Kanpur, RBI College of Agriculture Banking (CAB) Pune and Bankers’ Institute of Rural Development (BIRD) in Lucknow. Abhishek is a postgraduate from Indian Institute of Forest Management (IIFM) Bhopal. " },
  { image: Ankita, title: `Dr. Ankita Mukherjee`, position: "Director", popUp: "Dr. Ankita Mukherjee is a public health professional and currently works with The George Institute for Global Health as a researcher in the mental health domain.  She has expertise in using qualitative research methods for programme evaluation.  In addition to mental health, Ankita has also worked in the areas of child rights and sexual and reproductive health with grassroot non-profits like Timbuktu Collective  and Sahayog. She has also undertaken consulting assignments for Centre for Health and Social Justice (CHSJ), Karnataka Health Promotion Trust (KHPT) and National Foundation of India (NFI). Her other areas of interest are implementation research, health policy and systems research and community based participatory research. Ankita has presented research papers at a number of national and international conferences and has publications in national and international peer reviewed journals of repute. She has a  Masters in Social Work (MSW) from the prestigious Tata Institute of Social Sciences (TISS), Mumbai, M.Phil. and PhD in Social Medicine and Community Health  from Jawaharlal Nehru University (JNU)." },
  // { title: `Mrs. Mrinali Thakral` },
];
