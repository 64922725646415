import { Paper, Typography, Grid } from "@material-ui/core";
import React from "react";
import Layout from "../../components/Layout/Layout";
import { alpha, makeStyles } from "@material-ui/core/styles";
import MemberReg from "../../components/Form/Reg/MemberReg";
import NgoReg from "../../components/Form/Reg/Ngoreg";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
    paddingBottom: "50px",
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
}));

export default function NgoRegistration() {
  const classes = useStyles();

  return (
    // <Layout>
      <Paper className={classes.paper}>
        <Typography
          variant="h3"
          color="primary"
          style={{ marginTop: "20px", textAlign: "left" }}
        >
          NGO Registration Form Part 1/3
        </Typography>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <NgoReg />
          </Grid>
        </Grid>
      </Paper>
    // </Layout>
  );
}
