import React from "react";
import { Typography, Grid } from "@material-ui/core";
import vactor from "../../assets/png/good-team/cuate.png";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  img: {
    [theme.breakpoints.only("sm")]: {
      width: "auto",
      height: "200px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "auto",
      height: "100px",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    borderRadius: "8px",
    /// width: "300px",
    boxShadow: "0px 4px 40px 0px #C88FEF73",
    //marginLeft: "30px",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
}));

function CausesWeSupport() {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item md={3} xs={12}>
          <div className={classes.card}>
            <Typography
              variant="h2"
              color="primary"
              noWrap
              style={{ fontFamily: "Marcellus" }}
            >
              0
            </Typography>
            <Typography variant="body1" style={{ color: "#626262" }}>
              NGOs
            </Typography>
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={classes.card}>
            <Typography
              variant="h2"
              color="primary"
              noWrap
              style={{ fontFamily: "Marcellus" }}
            >
              13 +
            </Typography>
            <Typography variant="body1" style={{ color: "#626262" }}>
              Causes
            </Typography>
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={classes.card}>
            <Typography
              variant="h2"
              color="primary"
              noWrap
              style={{ fontFamily: "Marcellus" }}
            >
              111 +
            </Typography>
            <Typography variant="body1" style={{ color: "#626262" }}>
              Sub Causes
            </Typography>
          </div>
        </Grid>

        <Grid item md={12}>
          <div>
            <Typography
              variant="h2"
              component="h1"
              color="primary"
              style={{ fontFamily: "Marcellus", textAlign: "center" }}
            >
              Causes We Support
            </Typography>
            <Typography
              variant="body2"
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              One of the key objectives of the Ikigai portfolio platform is that individual donors get the choice to donate to a cause that they are passionate to support. As of now, we have categorised various themes into 13 causes and 109 sub causes. Since we are in our early stages and still developing the Partner NGO Pool, all the causes and sub causes are not covered yet. We at Ikigai Portfolio are making every effort to expand our Partner NGO Pool to cover as many causes and sub causes as possible and hope to cover all causes and sub causes through our Partner NGO Pool. In case you feel we have missed out and should include some cause or sub cause in our list, kindly suggest at <a
                  href="mailto:support@ikigai.com"
                  
                >feedback@ikigaiportfolio.com</a>. If you would like to refer an NGO for inclusion in our Partner NGO Pool, you may do so through the <a href="refer-an-ngo">Refer an NGO</a> section from the <a href="refer-an-ngo">Get Involved</a> menu of this website.
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={vactor} height="400px" className={classes.img} />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default CausesWeSupport;
