import React from "react";
import user from "../../assets/png/team-work/user.jpg";
import { makeStyles, Typography } from "@material-ui/core";
import TeamToolTip from "../../Dialogs/TeamToolTip";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "30px",
  },
  user: {
    borderRadius: "10px",
  },
}));

function Advisors(props) {
  const classes = useStyles();
  return (
    <>
    <TeamToolTip text={props?.popUp}>
      <div className={classes.card}>
        <img
          src={props?.image || user}
          height="200px"
          width="200px"
          alt="user"
          className={classes.user}
        />
        <div style={{ textAlign: "left" }}>
          <Typography variant="h6" noWrap style={{ marginTop: "20px" }}>
            {props.name}
          </Typography>
          <Typography variant="body2" style={{ color: "#626262" }}>
            Advisor
          </Typography>
        </div>
      </div>
      </TeamToolTip>
    </>
  );
}

export default Advisors;
