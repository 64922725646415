import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Rating from "@material-ui/lab/Rating";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Ngo from "../../assets/png/team-work/ngo.jpg";
import TeamToolTip from "../../Dialogs/TeamToolTip";
import { Button } from "@material-ui/core";
import DonationDialog from "../../Dialogs/DonationDialog";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    boxShadow: "none",
    border: "none",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btn: {
    paddingLeft: "15px",
    paddingRight: "15px",
    marginTop: "8px",
    paddingBottom: "8px",
    color: "#fff",
    borderRadius: "8px",
    // boxShadow: "0px 10px 40px 0px #C88FEF73",
    marginLeft: "20px",
    // minWidth: "175px",
  },
});

const StyledRating = withStyles({
  iconFilled: {
    color: "#C88FEF",
  },
  iconHover: {
    color: "#C88FEF",
  },
})(Rating);

export default function PartnerList({ item }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(4);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  console.log("item", item);
  return (
    <>
      <Card
        className={classes.root}
        onClick={() => {
          history.push(
            `ngo-details/?ngo_slug=${item?.slug}&ngo_id=${item?.id}`
          );
        }}
      >
        {/* <CardActionArea> */}
        <TeamToolTip>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="240"
            image={
              item?.ngo_images && item?.ngo_images.length > 0
                ? item?.ngo_images[0]
                : Ngo
            }
            style={{ borderRadius: "10px" }}
            title="Contemplative Reptile"
          />
        </TeamToolTip>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <Grid md={8}>
            <CardContent style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Typography gutterBottom variant="h5" component="h2">
                {item?.ngo_name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {item?.reg_area} , {item?.reg_city}
              </Typography>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold ",
                  }}
                  color="primary"
                >
                  {item?.subcategory}
                </Typography>
              </div>
            </CardContent>
          </Grid>
          <Grid md={4}>
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              onClick={(event) => {
                event.stopPropagation();
                setOpen(true);
              }}
            >
              Donate Now
            </Button>
          </Grid>
        </Grid>
        {/* </CardActionArea> */}
      </Card>
      <DonationDialog
        open={open}
        setOpen={setOpen}
        ngoId={item?.id}
        ngoName={item?.ngo_name}
      />
    </>
  );
}
