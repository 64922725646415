import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Typography,
} from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomButtonCircularProgress from "../../components/CircularProgress";
import { postRequest } from "../../apis/baseApi";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
    maxWidth: "250px",
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
  googlebtn: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "30px",
    paddingLeft: "10px",
    paddingRight: "10px",
    minWidth: "150px",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      border: "1px solid",
    },
  },
}));

function ForgotPasswordChange() {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: "",
    confirmPassword: "",
    showConfirmPassword: false,
    showPassword: false,
  });

  const [errorValues, setErrorValues] = React.useState({
    password: "",
    confirmPassword: "",
    showConfirmPassword: false,
    showPassword: false,
  });
  const location = useLocation();

  const [isLoading, setIsLoading] = React.useState(false);
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const alert = useAlert();
  const history = useHistory();

  const dispatch = useDispatch();

  const [isError, setIsError] = React.useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: "" });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateForm = () => {
    let error = false;
    let passwordError = "";
    let confirmPasswordError = "";
    setIsError(false);
    if (!values?.password) {
      passwordError = "*Please Enter Password";
      setIsError(true);
      error = true;
    }
    // else if(checkPassword(values?.password)){
    //   passwordError = "*Password should be alphanumeric with atleast one character and one number.";
    //   setIsError(true);
    //   error = true;
    // }
    if (
      !values?.confirmPassword ||
      values?.confirmPassword !== values?.password
    ) {
      confirmPasswordError = "*New Password & Confirm Password doesn't matched";
      setIsError(true);
      error = true;
    }
    setErrorValues({
      password: passwordError,
      confirmPassword: confirmPasswordError,
    });
    return error ? false : true;
  };

  const onCreateNewPassword = () => {
    if (validateForm()) {
      const postData = {
        token: token,
        password: values?.password,
      };
      setIsLoading(true);
      postRequest("authentication/confirm_change_password/", postData)
        .then((res) => {
          if (res?.data?.status === "success")
            alert.success(
              "Password Changed successfully. Please login again!!"
            );
          else {
            // alert.error("Something went wrong");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          alert.error(err?.response?.data?.msg);
          setIsLoading(false);
        });
    }
  };

  //   const loginUser = () => {
  //     const postData = {
  //       user:{
  //         email:  values?.email,
  //         "password": values?.password
  //     }
  // }
  //     const url = isNgo ? "authentication/ngo/login/" : "authentication/users/login/";
  //       postRequest(url, postData).then((res) => {
  //         if(res?.data){
  //         dispatch(loggedinUser(true));
  //         dispatch(userDetails({
  //           name: res?.data,
  //           username: res?.data?.username,
  //           token: res?.data?.token,
  //           slug: res?.data?.slug,
  //           isHasNgo: res?.data?.is_has_ngo,
  //           email: res?.data?.email,
  //           profileImage: res?.data?.user_profile?.image
  //         }))
  //         localStorage.setItem('user_token', res?.data?.token);
  //         localStorage.setItem('slug', res?.data?.slug);
  //         localStorage.setItem('user_type', isNgo ? 'NGO' : 'DONOR');
  //         localStorage.setItem('name', `${res?.data?.first_name} ${res?.data?.last_name}`);
  //         localStorage.setItem('profile_image', res?.data?.user_profile?.image);
  //         localStorage.setItem('has_ngo', res?.data?.is_has_ngo);
  //         localStorage.setItem('email', res?.data?.email);
  //         alert.success("Login Successful!!");
  //         isNgo ? history.push("/profile") : history.push('/user/profile');
  //       }else{
  //         alert.error("Email & Password doesn't match");
  //       }
  //     }).catch(err => {
  //       alert.error("Email & Password doesn't match");
  //       // history.push("/profile");
  //     })
  //   }

  return (
    // <Layout>
    <Paper className={classes.paper}>
      <Typography variant="h3" color="primary" style={{ marginTop: "20px" }}>
        Create New Password
      </Typography>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "20px", display: "flex", flexDirection: "col" }}
      >
        <Grid item md={4}></Grid>
        <Grid item md={4} xs={12} style={{ display: "grid" }}>
          <div style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
              New Password
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <FormControl className={classes.margin}>
              <OutlinedInput
                style={{
                  backgroundColor: "#fff",
                  paddingTop: 26,
                  paddingBottom: 26,
                }}
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                //labelWidth={70}
              />
              {isError && errorValues?.password && (
                <FormHelperText error id="accountId-error">
                  {errorValues?.password}
                </FormHelperText>
              )}
            </FormControl>
            <br />
            <Typography className={classes.reqfieldtext}>
              Confirm Password
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <FormControl className={classes.margin}>
              <OutlinedInput
                style={{
                  backgroundColor: "#fff",
                  paddingTop: 26,
                  paddingBottom: 26,
                }}
                id="outlined-adornment-password"
                type={values.showConfirmPassword ? "text" : "password"}
                value={values.confirmPassword}
                onChange={handleChange("confirmPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                //labelWidth={70}
              />
              {isError && errorValues?.confirmPassword && (
                <FormHelperText error id="accountId-error">
                  {errorValues?.confirmPassword}
                </FormHelperText>
              )}
            </FormControl>
          </div>
        </Grid>

        <Grid item md={4}>
          {" "}
        </Grid>
      </Grid>
      <Button
        variant="contained"
        className={classes.btn}
        color="primary"
        onClick={() => {
          onCreateNewPassword();
        }}
      >
        {isLoading ? <CustomButtonCircularProgress /> : "Create Password"}
      </Button>

      <div>
        <form className={classes.root} noValidate></form>
      </div>
    </Paper>
    // </Layout>
  );
}

export default ForgotPasswordChange;
