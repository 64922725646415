import React from "react";
import { Typography } from "@material-ui/core";
import vactor from "../../assets/png/Sustainable_Devel.png";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  img: {
    [theme.breakpoints.only("sm")]: {
      width: "auto",
      height: "200px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "auto",
      height: "100px",
    },
  },
}));

function Alignment() {
  const classes = useStyles();
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <img src={vactor} height="400px" className={classes.img} /> */}

        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum,
          ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo
          in tristique feugiat faucibus arcu tempor. Vestibulum, congue vitae
          eleifend nec maecenas tristique bibendum egestas fermentum. Cursus
          nunc vulputate cras felis suspendisse convallis nisl.
        </Typography>
        <br />
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum,
          ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo
          in tristique feugiat faucibus arcu tempor.
        </Typography>
      </div>
    </>
  );
}

export default Alignment;
