import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import { postRequest } from "../../apis/baseApi";
import CustomButtonCircularProgress from "../../components/CircularProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
}));

export default function NgoOtp() {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const [call, setCall] = useState(false);
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (code) => {
    setCode(code);
  };

  const submitOTP = () => {
    if (!code || code.length !== 4) {
      alert.error("Please enter 4 digit OTP send to your email Id");
      return false;
    }
    return true;
  };

  const validateOTP = () => {
    setIsLoading(true);
    postRequest("authentication/confirm_otp/", { otp: code })
      .then((res) => {
        if (res?.data?.status) {
          alert.success("Email has been successfully verified");
          history.push("ngo-registration");
        } else {
          alert.error("Email verification failed!!");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getOTP1 = () => {
    postRequest("authentication/get_otp/", {})
      .then((res) => {
        if (res?.data?.status) {
          // setCall(true);
          alert.success("OTP send to the registered Email!!");
        } else {
          alert.error("Error in email sending");
        }
      })
      .catch((err) => {});
  };

  // useEffect(() => {
  //   const getOTP = () => {
  //     postRequest("authentication/get_otp/", {})
  //       .then((res) => {
  //         if (res?.data?.status) {
  //           setCall(true);
  //           alert.success("OTP send to the registered Email!!");
  //         } else {
  //           alert.error("Error in email sending");
  //         }
  //       })
  //       .catch((err) => {});
  //   };
  //   getOTP();
  // }, [call]);
  return (
    // <Layout>
    <Paper className={classes.paper}>
      <Typography variant="h3" color="primary" style={{ marginTop: "20px" }}>
        NGO Registration
      </Typography>
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item md={4}></Grid>
        <Grid item md={4} xs={12} style={{ display: "grid" }}>
          <Typography
            variant="body2"
            style={{
              textAlign: "left",
              marginBottom: "20px",
              marginTop: "50px",
            }}
          >
            OTP
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <OtpInput
            value={code}
            onChange={(e) => handleChange(e)}
            numInputs={4}
            separator={<span style={{ width: "20px" }}></span>}
            isInputNum={true}
            shouldAutoFocus={true}
            inputStyle={{
              border: ".5px solid #C88FEF",
              borderRadius: "8px",
              width: "54px",
              height: "54px",
              fontSize: "20px",

              color: "#000",
              fontWeight: "400",
              caretColor: "blue",
            }}
            focusStyle={{
              border: "1px solid #CFD3DB",
              outline: "none",
            }}
          />
          <div onClick={getOTP1}>
            <Typography
              variant="body2"
              style={{
                textAlign: "right",
                marginTop: "10px",
                color: "#626262",
                cursor: "pointer",
              }}
            >
              Resend OTP
            </Typography>
          </div>
          {/* <UserReg />
            <MemberReg /> */}
        </Grid>

        <Grid item md={4}></Grid>
      </Grid>
      <Button
        variant="contained"
        className={classes.btn}
        color="primary"
        style={{ marginRight: "20px" }}
        onClick={() => {
          history.push("/ngo-registration-1");
        }}
      >
        Back
      </Button>
      <Button
        variant="contained"
        className={classes.btn}
        color="primary"
        onClick={() => {
          if (submitOTP()) validateOTP();
        }}
      >
        {isLoading ? <CircularProgress /> : "NEXT"}
      </Button>
      <div>
        <form className={classes.root} noValidate></form>
      </div>
    </Paper>
    // </Layout>
  );
}
