import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
// import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import "react-phone-input-2/lib/bootstrap.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getRequest, postRequest, putRequest } from "../../../apis/baseApi";
import {
  ageData,
  fundingData,
  geographicalScaleData,
  legalFormData,
  sizeData,
  specialInterestData,
} from "../../../constant";
import {
  categoriesListSelectorFn,
  cityStateSelectorFn,
  StateSelectorFn,
} from "../../../redux/commonReducer/selector";
import CustomButtonCircularProgress from "../../CircularProgress";
import Calendar from "../../Common/Calendar/Calendar";
import NgoToolTipComponent from "../../Dialogs/NgoDescripitonToolTip";
import { useDispatch } from "react-redux";
import { ngoDocDetails } from "../../../redux/ngoReducer/action";
import {
  AddCircleRounded,
  Cancel,
  DeleteOutlineOutlined,
} from "@material-ui/icons";

const ChipAutocomplete = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
  },
})(Autocomplete);

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
  },
  btn: {
    margin: "auto",
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  upload: {
    color: "#fff",
    // paddingLeft: "60px",
    // paddingRight: "60px",
    boxShadow: "none",

    borderRadius: 8,
    height: 46,
  },
  googlebtn: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "30px",
    paddingLeft: "15px",
    paddingRight: "15px",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      border: "1px solid",
    },
  },
  fieldtext: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "10px",
    display: "flex",
  },
  reqfieldtext: {
    fontSize: "14px",
    textAlign: "left",
  },
  option: {
    fontSize: 13,
    "& > span": {
      marginRight: 10,
      fontSize: 12,
    },
  },
  gridtext: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));
export default function NgoReg({
  data,
  fromProfile = false,
  edit,
  handleEdit,
}) {
  var fromProfile = fromProfile;

  const [fromProfile2, setfromProfile2] = useState(false);

  const [data1, setData1] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const pathname = window?.location.pathname;
  const [values, setValues] = React.useState({
    ngoName: "",
    ngoBackgroud: "",
    legalForm: "",
    registrationNumber: "",
    panNumber: "",
    weblink: "",
    ngoImage: "",
    ngoTheme: "",
    registrationYear: "",
    registeredAddress: "",
    registeredCity: "",
    registeredPincode: "",
    registeredArea: "",
    communicationAddress: "",
    communicationCity: "",
    communicationArea: "",
    communicationPincide: "",
    isCommunicationSame: "",
    primaryEmail: "",
    alternateEmail: "",
    chiefName: "",
    chiefDesignation: "",
    chiefPhone: "",
    chiefAlternatePhone: "",
    chiefEmail: "",
    chiefAlternateEmail: "",
    nodalName: "",
    nodalDesignation: "",
    nodalPhone: "",
    nodalAlternatePhone: "",
    nodalEmail: "",
    nodalAlternateEmail: "",
    sameAsChief: true,
    beneficiariesNo: "",
    annualTurnover: "",
    staffNo: 0,
    projectLocation: [],
    internationalPayment: false,
    mobaliseInternationPayment: false,
    accountNo: "",
    bankName: "",
    accountName: "",
    ifscCode: "",
    branch: "",
    intlAccountNo: "",
    intlBankName: "",
    intlAccountName: "",
    intlIfscCode: "",
    intlBranch: "",
    sameAsDomestic: false,
    geographicalScale: null,
    specialInterest: [],
    funding: null,
    size: null,
    age: null,
    categories: [],
    subcategories: [],
  });

  const cityStateDetailsNew = useSelector(cityStateSelectorFn);

  const [citydataList, setCityDataList] = useState([]);

  const [statedataList, setStateDataList] = useState([]);
  const stateDetails = useSelector(StateSelectorFn);

  useEffect(() => {
    if (data && cityStateDetailsNew) {
      setValues(data);
      setSelectedDate(data?.registrationYear);
      let stateCities = getStateSelected();

      setSelectedStates(stateCities ? stateCities[0] : []);
      setCityStateDetails(stateCities ? stateCities[1] : []);
      setCityDataList(stateCities ? stateCities[3] : []);
      setStateDataList(stateCities ? stateCities[2] : []);

      if (data?.registeredCity || data?.communicationCity) {
        stateDetails?.forEach((state) => {
          state.city.forEach((city) => {
            if (city?.city_name === values?.registeredCity) {
              setAddressState(state);
              setAddressCity(state.city);
            }
            if (city?.city_name === values?.communicationCity) {
              setCommAddressState(state);
              setCommAddressCity(state.city);
            }
          });
        });
      }
    }
  }, [data, cityStateDetailsNew, stateDetails]);
  useEffect(() => {
    if (values && cityStateDetailsNew) {
      setValues(values);
      setSelectedDate(values?.registrationYear);
      let stateCities = getStateSelected1();

      setSelectedStates(stateCities ? stateCities[0] : []);
      setCityStateDetails(stateCities ? stateCities[1] : []);
      setCityDataList(stateCities ? stateCities[3] : []);
      setStateDataList(stateCities ? stateCities[2] : []);

      if (values?.registeredCity || values?.communicationCity) {
        stateDetails?.forEach((state) => {
          state.city.forEach((city) => {
            if (city?.city_name === values?.registeredCity) {
              setAddressState(state);
              setAddressCity(state.city);
            }
            if (city?.city_name === values?.communicationCity) {
              setCommAddressState(state);
              setCommAddressCity(state.city);
            }
          });
        });
      }
    }
  }, [values, cityStateDetailsNew, stateDetails]);

  const [errorValues, setErrorValues] = React.useState({
    ngoName: "",
    legalForm: "",
    registrationNumber: "",
    panNumber: "",
    weblink: "",
    ngoImage: "",
    ngoTheme: "",
    registrationYear: "",
    registeredAddress: "",
    registeredCity: "",
    registeredPincode: "",
    registeredArea: "",
    communicationAddress: "",
    communicationCity: "",
    communicationArea: "",
    communicationPincide: "",
    isCommunicationSame: "",
    primaryEmail: "",
    alternateEmail: "",
    chiefName: "",
    chiefDesignation: "",
    chiefPhone: "",
    chiefAlternatePhone: "",
    chiefEmail: "",
    chiefAlternateEmail: "",
    nodalName: "",
    nodalDesignation: "",
    nodalPhone: "",
    nodalAlternatePhone: "",
    nodalEmail: "",
    nodalAlternateEmail: "",
    sameAsChief: true,
    beneficiariesNo: "",
    annualTurnover: "",
    staffNo: 0,
    projectLocation: [],
    internationalPayment: false,
    mobaliseInternationPayment: false,
    accountNo: "",
    bankName: "",
    accountName: "",
    ifscCode: "",
    branch: "",
    intlAccountNo: "",
    intlBankName: "",
    intlAccountName: "",
    intlIfscCode: "",
    intlBranch: "",
    sameAsDomestic: false,
    geographicalScale: "",
    specialInterest: [],
    funding: "",
    size: "",
    age: "",
  });

  const history = useHistory();
  const alert = useAlert();

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState("");

  const [tempSelectedCause, setTempSelectedCause] = useState({});

  const [tempSelectedSubcause, setTempSelectedSubcause] = useState([]);

  const [tempSelectedSubcauseList, setTempSelectedSubcauseList] = useState([]);

  useEffect(() => {
    if (tempSelectedCause && tempSelectedCause?.id)
      getRequest(
        "ngo/ngo_sub_category_list/?categories=" + tempSelectedCause?.id
      )
        .then((res) => {
          if (res?.data?.data) setTempSelectedSubcauseList(res?.data?.data);
          // else alert.error("Something went wrong");
        })
        .catch((err) => {
          // alert.error("Something went wrong");
        });
  }, [tempSelectedCause]);

  const [selectedStates, setSelectedStates] = useState([]);
  const [tempSelectedStates, setTempSelectedStates] = useState([]);
  const [specialInterests, setSpecialInterests] = useState([]);
  const [cityStateDetails, setCityStateDetails] = useState([]);
  var locationDatas = [];
  const [addressCity, setAddressCity] = useState([]);
  const [addressState, setAddressState] = useState({});

  const [commAddressCity, setCommAddressCity] = useState([]);
  const [commAddressState, setCommAddressState] = useState({});

  const [projectLocationCity, setProjectLocationCity] = useState([]);
  const [tempSelectedProjectCity, setTempSelectedProjectCity] = useState({});
  const [tempSelectedProjectState, setTempSelectedProjectState] = useState({});

  const [showToolTip, setShowToolTip] = useState(false);

  useEffect(() => {
    if (!fromProfile) {
      setValues({ ...values, ngoName: localStorage?.getItem("ngo_name") });

      // setValues(localStorage?.getItem("ngoDetails"));
    }
  }, [fromProfile]);

  useEffect(() => {
    if (selectedDate) {
      setValues({
        ...values,
        registrationYear: moment(selectedDate).format("YYYY"),
      });
    }
  }, [selectedDate]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: "" });
  };
  const handleChange2 = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: "" });
  };
  const onSelectFile = (prop) => (e) => {
    const image = e.target.files[0];
    const fd = new FormData();
    if (fd.getAll("image")[0] === "null") {
      fd.delete("image");
    } else {
      fd.set("ngo_image", image);
      fd.set("ngo_name", localStorage?.getItem("ngo_name"));
    }

    if (fromProfile) {
      putRequest(`ngo/ngo_details/${localStorage.getItem("ngo_slug")}/`, fd)
        .then((res) => {
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          // alert.error("Something went wrong");
        });
    } else {
      postRequest("ngo/ngo_user_ngo_list/", fd).then((res) => {
        setIsLoading(false);
      });
      // .catch((err) => {
      //   setIsLoading(false);
      //   alert.error(
      //     "Something went wrong ! Please fill All Mendatory Feilds"
      //   );
      // });
    }
    // const reader = new FileReader();
    // reader.addEventListener("load", () => setProfilePic(reader.result));
    // reader.readAsDataURL(e.target.files[0]);
    // setFileName(e.target.files[0].name);
    // handleClickOpen();
  };
  const handleCatSubmit = (e) => {
    if (values?.categories.includes(tempSelectedCause?.id)) {
      alert.error("Cause Already Selected!");
      return;
    }
    // if (!tempSelectedCause || !tempSelectedCause?.id) {
    //   alert.error("Please select a cause!");
    //   return;
    // }
    // if (!tempSelectedSubcause || tempSelectedSubcause.length === 0) {
    //   alert.error("Please select atleast one subcause!");
    //   return;
    // }
    let category = [...values.categories];
    category.push(tempSelectedCause?.id);
    // setValues({...values, categories: category});
    let subcategories = [...values.subcategories];
    tempSelectedSubcause.forEach((iii) => {
      subcategories.push(iii.id);
    });
    setValues({
      ...values,
      subcategories: subcategories,
      categories: category,
    });
    setTempSelectedSubcause([]);
    setTempSelectedSubcauseList([]);
    setTempSelectedCause({});
  };
  const addNGODetailNext = () => {
    history.push("/ngo/profile-doc");
  };

  const addNGODetail = () => {
    handleCatSubmit();
    // if (validateDetails()) {
    const postData = {
      username: values?.username,
      ngo_name: values?.ngoName,
      ngo_email: values?.primaryEmail,
      alternative_email: values?.alternateEmail ? values?.alternateEmail : null,
      legal_Form: values?.legalForm,
      pan_card_number: values?.panNumber,
      website_link: values?.weblink,

      // ngo_image: values?.ngoImage,
      ngo_registration_id: values?.registrationNumber,
      year_of_registration: values?.registrationYear,
      reg_address_1: values?.registeredAddress,
      reg_city: values?.registeredCity,
      reg_area: values?.registeredArea,
      reg_pincode: values?.registeredPincode,
      comm_address_1: values?.communicationAddress,
      // "comm_address_2": null,
      comm_city: values?.communicationCity,
      comm_area: values?.communicationArea,
      comm_pincode: values?.communicationPincide,
      chief_name: values?.chiefName,
      chief_destination: values?.chiefDesignation,
      chief_mobile_number: values?.chiefPhone,
      chief_alternative_mobile_number: values?.chiefAlternatePhone,
      chief_email: values?.chiefEmail,
      nodal_name: values?.nodalName,
      nodal_destination: values?.nodalDesignation,
      nodal_mobile_number: values?.nodalPhone,
      nodal_alternative_mobile_number: values?.nodalAlternatePhone,
      nodal_email: values?.nodalEmail,
      number_of_beneficiaries_served: values?.beneficiariesNo,
      average_annual_turnover_of_last_3_year: values?.annualTurnover,
      number_of_full_time_employees: values?.staffNo,
      is_eligible_for_foreign_fund: values?.internationalPayment,
      want_to_mob_foreign_fund_here: values?.mobaliseInternationPayment,
      domestic_ngo_bank_account_numer: values?.accountNo,
      domestic_bank_name: values?.bankName,
      dom_account_holder_name: values?.accountName,
      domestic_ifsc_code: values?.ifscCode,
      domestic_branch: values?.branch,
      international_ngo_bank_account_numer: values?.intlAccountNo,
      international_bank_name: values?.intlBankName,
      international_account_holder_name: values?.accountName,
      international_ifsc_code: values?.intlIfscCode,
      international_branch: values?.intlBranch,
      // "ngo_background": null,
      byGeographicalScale: values?.geographicalScale,
      bySpecialInterest: values?.specialInterest,
      byFunding: values?.funding,
      byThematicFocus: values?.ngoTheme,
      bySize: values?.size,
      byAge: values?.age,
      ngo_background: values?.ngoBackgroud,
      // "tier": null,
      // "user": 8,
      categories: values?.categories,
      subcategories: values?.subcategories,
      project_locations: values?.projectLocation,
    };

    setIsLoading(true);
    if (fromProfile) {
      putRequest(
        `ngo/ngo_details/${localStorage.getItem("ngo_slug")}/`,
        postData
      )
        .then((res) => {
          if (res?.data?.status) {
            alert.success("NGO Profile Updates!");
            history.push("/ngo/profile-doc");
          }
          // else {
          //   alert.error("Something went wrong");
          // }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          // alert.error("Something went wrong");
        });
    } else {
      postRequest("ngo/ngo_user_ngo_list/", postData)
        .then((res) => {
          if (res?.data?.status) {
            alert.success("NGO Registration Step 1 Completed!");
            localStorage.setItem("ngo_slug", res?.data?.data?.slug);

            localStorage.setItem("ngoDetails", JSON.stringify(res?.data?.data));
            history.push("ngo-registration-doc");
          } else {
            // alert.error("Something went wrong");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          alert.error(
            "Something went wrong ! Please fill All Mendatory Feilds"
          );
        });
    }
    // }
  };

  const updateNGODetail = () => {
    handleCatSubmit();
    // if (validateDetails()) {
    const postData = {
      username: values?.username,
      ngo_name: values?.ngoName,
      ngo_email: values?.primaryEmail,
      alternative_email: values?.alternateEmail ? values?.alternateEmail : null,
      legal_Form: values?.legalForm,
      pan_card_number: values?.panNumber,
      website_link: values?.weblink,
      // ngo_image: values?.ngoImage,
      ngo_registration_id: values?.registrationNumber,
      year_of_registration: values?.registrationYear,
      reg_address_1: values?.registeredAddress,
      reg_city: values?.registeredCity,
      reg_area: values?.registeredArea,
      reg_pincode: values?.registeredPincode,
      comm_address_1: values?.communicationAddress,
      // "comm_address_2": null,
      comm_city: values?.communicationCity,
      comm_area: values?.communicationArea,
      comm_pincode: values?.communicationPincide,
      chief_name: values?.chiefName,
      chief_destination: values?.chiefDesignation,
      chief_mobile_number: values?.chiefPhone,
      chief_alternative_mobile_number: values?.chiefAlternatePhone,
      chief_email: values?.chiefEmail,
      nodal_name: values?.nodalName,
      nodal_destination: values?.nodalDesignation,
      nodal_mobile_number: values?.nodalPhone,
      nodal_alternative_mobile_number: values?.nodalAlternatePhone,
      nodal_email: values?.nodalEmail,
      number_of_beneficiaries_served: values?.beneficiariesNo,
      average_annual_turnover_of_last_3_year: values?.annualTurnover,
      number_of_full_time_employees: values?.staffNo,
      is_eligible_for_foreign_fund: values?.internationalPayment,
      want_to_mob_foreign_fund_here: values?.mobaliseInternationPayment,
      domestic_ngo_bank_account_numer: values?.accountNo,
      domestic_bank_name: values?.bankName,
      dom_account_holder_name: values?.accountName,
      domestic_ifsc_code: values?.ifscCode,
      domestic_branch: values?.branch,
      international_ngo_bank_account_numer: values?.intlAccountNo,
      international_bank_name: values?.intlBankName,
      international_account_holder_name: values?.accountName,
      international_ifsc_code: values?.intlIfscCode,
      international_branch: values?.intlBranch,
      // "ngo_background": null,
      byGeographicalScale: values?.geographicalScale,
      bySpecialInterest: values?.specialInterest,
      byFunding: values?.funding,
      byThematicFocus: values?.ngoTheme,
      bySize: values?.size,
      byAge: values?.age,
      ngo_background: values?.ngoBackgroud,
      // "tier": null,
      // "user": 8,
      categories: values?.categories,
      subcategories: values?.subcategories,
      project_locations: values?.projectLocation,
    };

    setIsLoading(true);
    if (!fromProfile) {
      putRequest(
        `ngo/ngo_details/${localStorage.getItem("ngo_slug")}/`,
        postData
      )
        .then((res) => {
          if (res?.data?.status) {
            alert.success("NGO Profile Updates!");
            history.push("ngo-registration-doc");
          } else {
            // alert.error("Something went wrong");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          // alert.error("Something went wrong");
        });
    } else {
      putRequest(
        `ngo/ngo_details/${localStorage.getItem("ngo_slug")}/`,
        postData
      )
        .then((res) => {
          if (res?.data?.status) {
            alert.success("NGO Profile Updates!");
            history.push("/ngo/profile-doc");
          } else {
            // alert.error("Something went wrong");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          // alert.error("Something went wrong");
        });
    }
    // }
  };

  const validateDetails = () => {
    let error = false;
    let intlBranchError = "";
    let intlIfscCodeError = "";
    let intlAccountNameError = "";
    let intlBankNameError = "";
    let intlAccountNoError = "";
    let domBranchError = "";
    let domIfscCodeError = "";
    let domAccountNameError = "";
    let domBankNameError = "";
    let domAccountNumberError = "";
    let projectLocationError = [];
    let staffNoError = "";
    let annualTurnoverError = "";
    let beneficiariesNoError = "";
    let nodalNameError = "";
    let nodalDesignationError = "";
    let nodalPhoneError = "";
    let nodalEmailError = "";
    let chiefNameError = "";
    let chiefDesignationError = "";
    let chiefPhoneError = "";
    let chiefEmailError = "";
    let registrationYearError = "";
    let registeredAddressError = "";
    let registeredCityError = "";
    let registeredPincodeError = "";
    let registeredAreaError = "";
    let communicationAddressError = "";
    let communicationCityError = "";
    let communicationAreaError = "";
    let communicationPincideError = "";
    let primaryEmailError = "";
    let alternateEmailError = "";
    let legalFormError = "";
    let registrationNumberError = "";
    let panNumberError = "";
    let geographicalScaleError = "";
    let specialInterestError = "";
    let fundingError = "";
    let sizeError = "";
    let ageError = "";
    let ngoThemeError = "";
    let ngoNameError = "";

    // if (!values?.ngoName) {
    //   ngoNameError = "*Please Enter Ngo Name";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.ngoTheme) {
    //   ngoThemeError = "*Please Select NGO Theme";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.panNumber) {
    //   // setErrorValues({...errorValues, phone: "Please Enter Phone No."});
    //   panNumberError = "*Please Enter PAN No.";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.registrationNumber) {
    //   // setErrorValues({...errorValues, email: "Please Enter Email Id"});
    //   registrationNumberError = "*Please Enter Registration Number";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.legalForm) {
    //   // setErrorValues({...errorValues, password: "Please Enter Password"});
    //   legalFormError = "*Please Enter Legal Form";
    //   setIsError(true);
    //   error = true;
    // }

    // if (!values?.registrationYear) {
    //   registrationYearError = "*Please Enter Registration Year";
    //   setIsError(true);
    //   error = true;
    // }

    // if (!values?.registeredAddress) {
    //   registeredAddressError = "*Please Enter Registered Address";
    //   setIsError(true);
    //   error = true;
    // }

    // if (!values?.registeredArea) {
    //   registeredAreaError = "*Please Enter Registered Area";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.registeredPincode) {
    //   registeredPincodeError = "*Please Enter Registered Pincode";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.registeredCity) {
    //   registeredCityError = "*Please Enter Registered City";
    //   setIsError(true);
    //   error = true;
    // }

    // if (!values?.primaryEmail) {
    //   primaryEmailError = "*Please Enter primary Email";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.communicationAddress) {
    //   communicationAddressError = "*Please Enter communication Address";
    //   setIsError(true);
    //   error = true;
    // }

    // if (!values?.communicationCity) {
    //   communicationCityError = "*Please Enter Communication City";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.communicationPincide) {
    //   communicationPincideError = "*Please Enter Communication Pincode";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.communicationArea) {
    //   communicationAreaError = "*Please Enter Communication Area";
    //   setIsError(true);
    //   error = true;
    // }

    // if (!values?.chiefName) {
    //   chiefNameError = "*Please Enter Chief Name";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.chiefDesignation) {
    //   chiefDesignationError = "*Please Enter Chief Designation";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.chiefPhone) {
    //   chiefPhoneError = "*Please Enter Chief Phone";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.chiefEmail) {
    //   chiefEmailError = "*Please Enter Chief Email";
    //   setIsError(true);
    //   error = true;
    // }

    // if (!values?.nodalName) {
    //   nodalNameError = "*Please Enter Nodal Name";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.nodalDesignation) {
    //   nodalDesignationError = "*Please Enter Nodal Designation";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.nodalPhone) {
    //   nodalPhoneError = "*Please Enter Nodal Phone";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.nodalEmail) {
    //   nodalEmailError = "*Please Enter Nodal Email";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.accountNo) {
    //   domAccountNumberError = "*Please Enter Account Number";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.bankName) {
    //   domBankNameError = "*Please Enter Bank Name";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.accountName) {
    //   domAccountNameError = "*Please Enter Account Name";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.ifscCode) {
    //   domIfscCodeError = "*Please Enter IFSC Code";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.branch) {
    //   domBranchError = "*Please Enter Branch Name";
    //   setIsError(true);
    //   error = true;
    // }
    //Ram
    // if (values?.internationalPayment) {
    //   if (!values?.intlAccountNo) {
    //     intlAccountNoError = "*Please Enter Account Number";
    //     setIsError(true);
    //     error = true;
    //   }
    // }
    // if (!values?.intlBankName) {
    //   intlBankNameError = "*Please Enter Bank Name";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.intlAccountName) {
    //   intlAccountNameError = "*Please Enter Account Name";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.intlIfscCode) {
    //   intlIfscCodeError = "*Please Enter IFSC Code";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.intlBranch) {
    //   intlBranchError = "*Please Enter Branch Name";
    //   setIsError(true);
    //   error = true;
    // }

    // if (!values?.beneficiariesNo) {
    //   beneficiariesNoError = "*Please Enter Beneficiaries No.";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.annualTurnover) {
    //   annualTurnoverError = "*Please Enter Annual Turnover.";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.staffNo) {
    //   staffNoError = "*Please Enter Staff No.";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.geographicalScale) {
    //   geographicalScaleError = "*Please Select Geographical Scale";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.specialInterest) {
    //   specialInterestError = "*Please Select Special Interest";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.funding) {
    //   fundingError = "*Please Select Funding";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.size) {
    //   sizeError = "*Please Select Ngo Size";
    //   setIsError(true);
    //   error = true;
    // }
    // if (!values?.age) {
    //   ageError = "*Please Select Age Group";
    //   setIsError(true);
    //   error = true;
    // }

    setErrorValues({
      ngoName: ngoNameError,
      legalForm: legalFormError,
      registrationNumber: registrationNumberError,
      panNumber: panNumberError,
      ngoTheme: ngoThemeError,
      registrationYear: registrationYearError,
      registeredAddress: registeredAddressError,
      registeredCity: registeredCityError,
      registeredPincode: registeredPincodeError,
      registeredArea: registeredAreaError,
      communicationAddress: communicationAddressError,
      communicationCity: communicationCityError,
      communicationArea: communicationAreaError,
      communicationPincide: communicationPincideError,
      primaryEmail: primaryEmailError,
      alternateEmail: alternateEmailError,
      chiefName: chiefNameError,
      chiefDesignation: chiefDesignationError,
      chiefPhone: chiefPhoneError,
      chiefEmail: chiefEmailError,
      nodalName: nodalNameError,
      nodalDesignation: nodalDesignationError,
      nodalPhone: nodalPhoneError,
      nodalEmail: nodalEmailError,
      beneficiariesNo: beneficiariesNoError,
      annualTurnover: annualTurnoverError,
      staffNo: staffNoError,
      projectLocation: projectLocationError,
      accountNo: domAccountNumberError,
      bankName: domBankNameError,
      accountName: domAccountNameError,
      ifscCode: domIfscCodeError,
      branch: domBranchError,
      intlAccountNo: intlAccountNoError,
      intlBankName: intlBankNameError,
      intlAccountName: intlAccountNameError,
      intlIfscCode: intlIfscCodeError,
      intlBranch: intlBranchError,
      geographicalScale: geographicalScaleError,
      specialInterest: specialInterestError,
      funding: fundingError,
      size: sizeError,
      age: ageError,
    });

    return error ? false : true;
  };

  function getLabelSelected(data, value) {
    const item = data?.find((opt) => {
      if (opt.name == value) return opt;
    });
    return item || {};
  }

  function getSelectedCity(data, value) {
    const item = data?.find((opt) => {
      if (opt.id == value) return opt;
    });
    return item || {};
  }

  function getSelectedCityLocation(data, value) {
    const item = data?.find((opt) => {
      if (opt.city_name == value) return opt;
    });
    return item || {};
  }

  function getProjectLocation(data) {
    if (data) {
      let temp = [];
      const aa = data.filter((item) => {
        if (
          values?.projectLocation &&
          values?.projectLocation.includes(item?.id)
        ) {
          temp.push(item?.id);
          return true;
        }
      });
      // setFinalCity(temp);
      return aa;
    }
  }

  function getSelectedSubcategories(categoryId) {
    if (categoryId) {
      // let temp = [];
      const aa = subCauses.filter((item) => {
        if (
          values?.subcategories &&
          item?.category?.id === categoryId &&
          values?.subcategories.includes(item?.id)
        ) {
          // temp.push(item?.id);
          return true;
        }
      });
      // setFinalCity(temp);
      return aa;
    }
    return [];
  }

  function getStateSelected() {
    if (data?.projectLocation) {
      let stateIds = [];
      let tempStates = [];
      let tempCityList = [];
      let tempStateCities = [];
      let tempStates1 = [];
      cityStateDetailsNew?.forEach((item) => {
        if (data?.projectLocation.includes(item?.id)) {
          if (!stateIds.includes(item?.state?.id)) {
            stateIds.push(item?.state?.id);
            stateDetails?.map((item1) => {
              if (item1?.id === item?.state?.id) {
                tempStates.push(item1);
                tempCityList = tempCityList.concat(item1?.city);
                tempStates1.push(item1);
                tempStateCities.push(item1?.city);
              }
            });
          } else {
            stateDetails?.map((item1) => {
              if (item1?.id === item?.state?.id) {
                // tempStates1.push(item1);
                tempStateCities.push(item1?.city);
              }
            });
          }
        }
      });
      return [tempStates, tempCityList, tempStates1, tempStateCities];
    }
  }

  var refresh = window.localStorage.getItem("refresh");

  if (refresh === null) {
    window.location.reload();
    window.localStorage.setItem("refresh", "1");
  }

  function getStateSelected1() {
    if (values?.projectLocation) {
      let stateIds = [];
      let tempStates = [];
      let tempCityList = [];
      let tempStateCities = [];
      let tempStates1 = [];
      cityStateDetailsNew?.forEach((item) => {
        if (values?.projectLocation.includes(item?.id)) {
          if (!stateIds.includes(item?.state?.id)) {
            stateIds.push(item?.state?.id);
            stateDetails?.map((item1) => {
              if (item1?.id === item?.state?.id) {
                tempStates.push(item1);
                tempCityList = tempCityList.concat(item1?.city);
                tempStates1.push(item1);
                tempStateCities.push(item1?.city);
              }
            });
          } else {
            stateDetails?.map((item1) => {
              if (item1?.id === item?.state?.id) {
                // tempStates1.push(item1);
                tempStateCities.push(item1?.city);
              }
            });
          }
        }
      });
      return [tempStates, tempCityList, tempStates1, tempStateCities];
    }
  }

  function getLabelSelected(data, value) {
    const item = data?.find((opt) => {
      if (opt.name == value) return opt;
    });
    return item || {};
  }

  const causes = useSelector(categoriesListSelectorFn);
  const [subCauses, setSubCauses] = useState([]);

  const getCausesList = () => {
    if (causes) {
      const aa = causes.filter((item) => {
        if (values?.categories && values?.categories.includes(item?.id))
          return true;
      });
      return aa;
    }
  };

  const getCauseItem = (data) => {
    const item = causes?.find((opt) => {
      if (opt.id == data) return opt;
    });
    return item || {};
  };

  useEffect(() => {
    if (values?.categories && values?.categories?.length > 0) {
      const url = values?.categories.join(",");
      getRequest("ngo/ngo_sub_category_list/?categories=" + url)
        .then((res) => {
          if (res?.data?.data) setSubCauses(res?.data?.data);
          // else alert.error("Something went wrong");
        })
        .catch((err) => {
          // alert.error("Something went wrong");
        });
    }
  }, [values?.categories]);
  useEffect(() => {
    getRequest("ngo/get_special_interest/")
      .then((res) => {
        setSpecialInterests(res?.data);
      })
      .catch((err) => {
        // alert.error("Something went wrong");
      });
  }, []);

  const getSubCausesList = () => {
    if (subCauses) {
      const aa = subCauses.filter((item) => {
        if (values?.subcategories && values?.subcategories.includes(item?.id))
          return true;
      });
      return aa;
    }
  };

  const getSubCausesNewList = (selectedCause) => {
    if (subCauses) {
      const aa = subCauses.filter((item) => {
        if (
          values?.subcategories &&
          selectedCause === item?.category?.id &&
          values?.subcategories.includes(item?.id)
        )
          return true;
      });

      return aa;
    }
  };

  useEffect(() => {
    getRequest("ngo/ngo_user_ngo_list/")
      .then((res) => {
        if (res?.data?.data?.length !== 0) {
          setfromProfile2(true);
          localStorage.setItem("ngo_slug", res?.data?.data[0].slug);
          const ngoData = res?.data?.data[0];
          console.log("fghghj", ngoData?.ngo_image);
          localStorage.setItem("ngo_name", ngoData?.ngo_name);
          localStorage.setItem("ngo_image", ngoData?.ngo_image);
          setValues({
            ngoName: ngoData?.ngo_name,
            image:
              ngoData?.ngo_images && ngoData?.ngo_images.length > 0
                ? ngoData?.ngo_images.at(-1)
                : null,
            ngoBackgroud: ngoData?.ngo_background,
            legalForm: ngoData?.legal_Form,
            registrationNumber: ngoData?.ngo_registration_id,
            panNumber: ngoData?.pan_card_number,
            weblink: ngoData?.website_link,
            ngoImage: ngoData?.ngo_image,
            ngoTheme: ngoData?.byThematicFocus,
            registrationYear: ngoData?.year_of_registration,
            registeredAddress: ngoData?.reg_address_1,
            registeredCity: ngoData?.reg_city,
            registeredPincode: ngoData?.reg_pincode,
            registeredArea: ngoData?.reg_area,
            communicationAddress: ngoData?.comm_address_1,
            communicationCity: ngoData?.comm_city,
            communicationArea: ngoData?.comm_area,
            communicationPincide: ngoData?.comm_pincode,
            isCommunicationSame: ngoData?.ngo_name,
            primaryEmail: ngoData?.ngo_email,
            alternateEmail: ngoData?.alternative_email,
            chiefName: ngoData?.chief_name,
            chiefDesignation: ngoData?.chief_destination,
            chiefPhone: ngoData?.chief_mobile_number,
            chiefAlternatePhone: ngoData?.chief_alternative_mobile_number,
            chiefEmail: ngoData?.chief_email,
            chiefAlternateEmail: ngoData?.alternative_email,
            nodalName: ngoData?.nodal_name,
            nodalDesignation: ngoData?.nodal_destination,
            nodalPhone: ngoData?.nodal_mobile_number,
            nodalAlternatePhone: ngoData?.nodal_alternative_mobile_number,
            nodalEmail: ngoData?.nodal_email,
            nodalAlternateEmail: ngoData?.nodal_email,
            sameAsChief: ngoData?.ngo_name,
            beneficiariesNo: ngoData?.number_of_beneficiaries_served,
            annualTurnover: ngoData?.average_annual_turnover_of_last_3_year,
            staffNo: ngoData?.number_of_full_time_employees,
            projectLocation: ngoData?.project_locations,
            internationalPayment: ngoData?.is_eligible_for_foreign_fund,
            mobaliseInternationPayment: ngoData?.want_to_mob_foreign_fund_here,
            accountNo: ngoData?.domestic_ngo_bank_account_numer,
            bankName: ngoData?.domestic_bank_name,
            accountName: ngoData?.dom_account_holder_name,
            ifscCode: ngoData?.domestic_ifsc_code,
            branch: ngoData?.domestic_branch,
            intlAccountNo: ngoData?.international_ngo_bank_account_numer,
            intlBankName: ngoData?.international_bank_name,
            intlAccountName: ngoData?.international_account_holder_name,
            intlIfscCode: ngoData?.international_ifsc_code,
            intlBranch: ngoData?.international_branch,
            sameAsDomestic: ngoData?.ngo_name,
            geographicalScale: ngoData?.byGeographicalScale,
            specialInterest: ngoData?.bySpecialInterest,
            specialInterest2: ngoData?.bySpecialInterest2,
            funding: ngoData?.byFunding,
            size: ngoData?.bySize,
            age: ngoData?.byAge,
            categories: ngoData?.categories,
            subcategories: ngoData?.subcategories,
          });
          dispatch(
            ngoDocDetails({
              itrCopy: ngoData?.ngo_docs_images?._ITR_certificate,
              itrCopy2: ngoData?.ngo_docs_images?._ITR_2_certificate,
              itrCopy3: ngoData?.ngo_docs_images?._ITR_3_certificate,
              cancelledChequeDom: ngoData?.ngo_docs_images?.dom_cancel_chaque,
              cancelledChequeDom: ngoData?.ngo_docs_images?.dom_cancel_chaque_2,
              cancelledChequeIntl:
                ngoData?.ngo_docs_images?.internation_cancel_chaque,
              cancelledChequeIntl:
                ngoData?.ngo_docs_images?.internation_cancel_chaque_2,
              annualReport: ngoData?.ngo_docs_images?.annual_report,
              annualReport2: ngoData?.ngo_docs_images?.annual_report_2,
              annualReport3: ngoData?.ngo_docs_images?.annual_report_3,
              gstRegistrationNo: ngoData?.ngo_docs_images?.gst_certificate,
              auditedFinancial:
                ngoData?.ngo_docs_images?.audited_financial_statement,
              auditedFinancial2:
                ngoData?.ngo_docs_images?.audited_financial_statement_2,
              auditedFinancial3:
                ngoData?.ngo_docs_images?.audited_financial_statement_3,
              registrationNo: ngoData?.ngo_docs_images?.ngo_registration_id,
              panCardCert: ngoData?.ngo_docs_images?.pan_card_certificate,
              certificate12a: ngoData?.ngo_docs_images?._12_a_certificate,
              certificateFcra: ngoData?.ngo_docs_images?._FCRA_certificate,
              certificate80G: ngoData?.ngo_docs_images?._80_g_certificate,
              internalAuditReport:
                ngoData?.ngo_docs_images?.internal_audit_reports,
              projectCompletionReport:
                ngoData?.ngo_docs_images?.project_completion,
              impactAssessmentReport:
                ngoData?.ngo_docs_images?.impact_assessment_reports,
              caseStudy: ngoData?.ngo_docs_images?.case_study_and_testimonials,
              ratingReport: ngoData?.ngo_docs_images?.rating_report,
              relevantPic: ngoData?.ngo_docs_images?.relevant_photo_1,
              projectStatusReport: ngoData?.ngo_docs_images?.project_status,
              otherDoc: null,
            })
          );
        } else {
          // alert.error("Something went wrong");
        }
      })
      .catch((err) => {
        // alert.error("Something went wrong");
      });
  }, []);
  console.log("ghjhbjk", values);
  const handleChange3 = (item) => {
    const interestId = item.map((e) => e.id);
    setValues({ ...values, specialInterest: interestId });
  };

  var specialInt = values?.specialInterest2?.map((value) => value?.name);
  // console.log("values", values);
  return (
    <>
      {!edit && pathname === "/ngo/profile" ? (
        <Grid container spacing={2}>
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>NGO Name</Typography>
          </Grid>

          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.ngoName}
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}></Grid>

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              NGO Cover Image
            </Typography>
          </Grid>

          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <img
              src={localStorage?.getItem("ngo_image")}
              alt="cover image"
              style={{ maxWidth: "600px" }}
            />
          </Grid>
          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
            <Typography
              variant="body2"
              style={{
                color: "#A6A6A6",
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              NGO Registration Details
            </Typography>
          </Grid>

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Legal Form</Typography>
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.legalForm}
          </Grid>
          {/* <Grid item md={6} /> */}
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Registration Number
            </Typography>
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.registrationNumber}
          </Grid>
          {/* <Grid
            md={6}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          ></Grid> */}
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>PAN Number</Typography>
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.panNumber}
          </Grid>
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Website Link
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.weblink}
          </Grid>
          <Grid
            md={6}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          ></Grid>

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
          </Grid>

          {/* <Grid item md={2} />

          <Grid item md={2}></Grid> */}

          {values?.categories &&
            values?.categories.map((item, index) => {
              return (
                <>
                  {/* <Grid item md={2} xs={12} className={classes.gridtext}></Grid> */}
                  <Grid item md={4} xs={12} className={classes.gridtext}>
                    <Typography className={classes.reqfieldtext}>
                      Causes
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12} style={{ display: "grid" }}>
                    <Autocomplete
                      style={{ backgroundColor: "#F9F4FD" }}
                      // multiple
                      id="tags-outlined"
                      disabled
                      options={causes ? causes : []}
                      getOptionLabel={(option) => option.category_name}
                      value={getCauseItem(item)}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Cause"
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={2} />

                  {/* <Grid item md={2} xs={12} className={classes.gridtext}></Grid> */}
                  <Grid item md={4} xs={12} className={classes.gridtext}>
                    <Typography className={classes.reqfieldtext}>
                      Sub Causes
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={12} style={{ display: "grid" }}>
                    <ChipAutocomplete
                      style={{ backgroundColor: "#F9F4FD", display: "grid" }}
                      multiple
                      id="tags-outlined"
                      options={subCauses ? subCauses : []}
                      disabled
                      getOptionLabel={(option) => option.sub_category_name}
                      value={subCauses ? getSubCausesNewList(item) : []}
                      filterSelectedOptions
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            variant="outlined"
                            style={{ maxWidth: "260px" }}
                            label={option.sub_category_name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub Causes"
                          variant="outlined"
                        />
                      )}
                      // }}
                    />
                  </Grid>

                  {/* <Grid item md={2}></Grid> */}
                </>
              );
            })}

          {/* <Grid item md={1} /> */}

          <Grid md={12} sm={12} xs={12}>
            <Divider />
          </Grid>

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Year Of Registration
            </Typography>
          </Grid>
          <Grid item md={8} xs={12} style={{ display: "grid" }}>
            {values?.registrationYear}
          </Grid>
          {/* <Grid item md={6} /> */}
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Address of Registered Office
            </Typography>
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.registeredAddress}
          </Grid>

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Area</Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.registeredArea}
          </Grid>

          <Grid item md={4} />
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Pincode</Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.registeredPincode}
          </Grid>

          <Grid item md={4} />
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>State</Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {addressState?.value}
          </Grid>

          <Grid item md={4} />
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>City</Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.registeredCity}
          </Grid>

          <Grid item md={4} />
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Communication Address
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.communicationAddress}
          </Grid>
          <Grid ite md={4}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              {" "}
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    onChange={(e, item) => {
                      if (item) {
                        setValues({
                          ...values,
                          communicationAddress: values?.registeredAddress,
                          communicationCity: values?.registeredCity,
                          communicationArea: values?.registeredArea,
                          communicationPincide: values?.registeredPincode,
                        });
                        setCommAddressState(addressState);
                        setCommAddressCity(addressCity);
                      }
                    }}
                  />
                }
                label="Same as registered address"
              />
            </div>
          </Grid>

          {/*  */}
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Communication Area
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.communicationArea}
          </Grid>
          {/* <Grid item md={6} /> */}
          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Communicaton Pincode
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.communicationPincide}
          </Grid>

          <Grid item md={4} />
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Communication State
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {commAddressState?.value}
          </Grid>

          <Grid item md={4} />
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Communication City
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.communicationCity}
          </Grid>

          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Primary Email ID for Communication
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.primaryEmail}
          </Grid>
          <Grid item md={4} />
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Alternate Email
              {/* */}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.alternateEmail}
          </Grid>
          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
            <Typography
              variant="body2"
              style={{
                color: "#A6A6A6",
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              Chief Functionary Details
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Name</Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.chiefName}
          </Grid>
          <Grid item md={4} />
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Designation
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.chiefDesignation}
          </Grid>
          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Phone/Mobile Number
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.chiefPhone}
          </Grid>
          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Alternate Phone/Mobile Number
              {/* */}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.chiefAlternatePhone}
          </Grid>

          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Primary Email ID
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.chiefEmail}
          </Grid>
          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Alternate Email ID
              {/*  */}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.chiefAlternateEmail}
          </Grid>

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
            <div style={{ display: "flex" }}>
              {" "}
              <Typography
                variant="body2"
                style={{
                  color: "#A6A6A6",
                  fontStyle: "italic",
                  textAlign: "left",
                  marginTop: "10px",
                }}
              >
                Details of Contact/Nodal Person
              </Typography>
              <div style={{ marginLeft: "20px" }}>
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkedB"
                      color="primary"
                      onChange={(e, item) => {
                        if (item) {
                          setValues({
                            ...values,
                            nodalName: values.chiefName,
                            nodalDesignation: values?.chiefDesignation,
                            nodalPhone: values?.chiefPhone,
                            nodalAlternatePhone: values?.chiefAlternatePhone,
                            nodalEmail: values?.chiefEmail,
                            nodalAlternateEmail: values?.chiefAlternateEmail,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <span style={{ color: "#A6A6A6" }}>
                      Same as Chief Functionary
                    </span>
                  }
                />
              </div>
            </div>
          </Grid>
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Name</Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.nodalName}
          </Grid>
          <Grid item md={4} />
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Designation
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.nodalDesignation}
          </Grid>
          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Phone/Mobile Number
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.nodalPhone}
          </Grid>
          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Alternate Phone/Mobile Number
              {/* */}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.nodalAlternatePhone}
          </Grid>

          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Primary Email ID
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.nodalEmail}
          </Grid>
          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Alternate Email ID
              {/* */}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.nodalAlternateEmail}
          </Grid>

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
          </Grid>
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              No. of beneficiaries served
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.beneficiariesNo}
          </Grid>
          <Grid item md={4} />
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Average annual turnover of last 3 years (in INR Lakh)
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.annualTurnover}
          </Grid>
          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              No. of full time staff
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.staffNo}
          </Grid>
          <Grid item md={4} />

          {statedataList &&
            statedataList.map((item, index) => {
              return (
                <>
                  {/* <Grid item md={2} /> */}
                  <Grid item md={4} xs={12} className={classes.gridtext}>
                    <Typography className={classes.reqfieldtext}>
                      Project Locations
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12} style={{ display: "grid" }}>
                    {item?.value}
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                    style={{ display: "grid", backgroundColor: "#F9F4FD" }}
                  >
                    <ChipAutocomplete
                      id="country-select-demo"
                      style={{ backgroundColor: "#F9F4FD", border: "none" }}
                      classes={{
                        option: classes.option,
                      }}
                      autoHighlight
                      disableClearable
                      disabled
                      multiple
                      options={
                        statedataList &&
                        statedataList.length > 0 &&
                        statedataList[index]?.city
                          ? statedataList[index]?.city
                          : []
                      }
                      getOptionLabel={(option) => option.city_name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            deleteIcon={<></>}
                            variant="outlined"
                            label={option.city_name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onChange={(e, item) => {
                        let projectLocationIds = [...values?.projectLocation];
                        projectLocationIds[index] = item?.id;
                        setValues({
                          ...values,
                          projectLocation: projectLocationIds,
                        });
                      }}
                      value={getProjectLocation(statedataList[index]?.city)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          sx={{
                            border: "none",
                            "& fieldset": { border: "none" },
                          }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "district",
                            disableUnderline: true,
                          }}
                          error={isError}
                          helperText={
                            isError ? errorValues?.geographicalScale : ""
                          }
                        />
                      )}
                      inputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Grid>
                </>
              );
            })}

          <Grid item md={6} xs={12} className={classes.gridtext}>
            <Typography variant="body2">
              Is your NGO eligible to accept international payments and
              donations?
            </Typography>
          </Grid>
          <Grid item md={6} xs={12} className={classes.gridtext}>
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <RadioGroup
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
                value={values?.internationalPayment ? "yes" : "no"}
                onChange={(event, item) => {
                  if (item === "yes")
                    setValues({ ...values, internationalPayment: true });
                  else {
                    setValues({
                      ...values,
                      internationalPayment: false,
                      intlAccountName: "",
                      intlBankName: "",
                      intlIfscCode: "",
                      intlAccountNo: "",
                      intlBranch: "",
                    });
                  }
                }}
              >
                <FormControlLabel
                  name="intlPayment"
                  value="yes"
                  control={<Radio color="primary" size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  name="intlPayment"
                  value="no"
                  control={<Radio color="primary" size="small" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {values?.internationalPayment === false ? (
            ""
          ) : (
            <>
              <Grid item md={6} xs={12} className={classes.gridtext}>
                <Typography variant="body2">
                  Would you like to mobilise international donations from this
                  platform?
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} className={classes.gridtext}>
                <FormControl component="fieldset" style={{ width: "100%" }}>
                  <RadioGroup
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                    value={values?.mobaliseInternationPayment ? "yes" : "no"}
                    onChange={(event, item) => {
                      if (item === "yes")
                        setValues({
                          ...values,
                          mobaliseInternationPayment: true,
                        });
                      else
                        setValues({
                          ...values,
                          mobaliseInternationPayment: false,
                        });
                    }}
                  >
                    <FormControlLabel
                      name="intlDonation"
                      value="yes"
                      control={<Radio color="primary" size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      name="intlDonation"
                      value="no"
                      control={<Radio color="primary" size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
            <Typography
              variant="body2"
              style={{
                color: "#A6A6A6",
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              Bank Account Details for Receiving Domestic Donations
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              NGO Bank Account Number
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {values?.accountNo}
          </Grid>
          <Grid item md={4} />
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Name of Bank
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.bankName}
          </Grid>
          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Account Name
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.accountName}
          </Grid>
          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>IFSC Code</Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.ifscCode}
          </Grid>

          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Branch</Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.branch}
          </Grid>
          <Grid item md={4} />

          {values?.mobaliseInternationPayment && (
            <>
              <Grid
                item
                md={12}
                xs={12}
                style={{ display: "grid", textTransform: "capitalize" }}
              >
                <Divider />
                <div style={{ display: "flex" }}>
                  {" "}
                  <Typography
                    variant="body2"
                    style={{
                      color: "#A6A6A6",
                      fontStyle: "italic",
                      textAlign: "left",
                      marginTop: "10px",
                    }}
                  >
                    Bank Account Details for Receiving International Donations
                  </Typography>
                  <div style={{ marginLeft: "20px" }}>
                    {" "}
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedB"
                          color="primary"
                          onChange={(e, item) => {
                            if (item) {
                              setValues({
                                ...values,
                                intlAccountName: values.accountName,
                                intlAccountNo: values?.accountNo,
                                intlBankName: values?.bankName,
                                intlBranch: values?.branch,
                                intlIfscCode: values?.ifscCode,
                              });
                            }
                          }}
                        />
                      }
                      label={
                        <span style={{ color: "#A6A6A6" }}>
                          Same as Above Bank
                        </span>
                      }
                    /> */}
                  </div>
                </div>
              </Grid>
              <Grid item md={4} xs={12} className={classes.gridtext}>
                <Typography className={classes.reqfieldtext}>
                  NGO Bank Account Number
                </Typography>
              </Grid>
              <Grid item md={4} xs={12} style={{ display: "grid" }}>
                {values?.intlAccountNo}
              </Grid>
              <Grid item md={4} />
              <Grid item md={4} xs={12} className={classes.gridtext}>
                <Typography className={classes.reqfieldtext}>
                  Name of Bank
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                style={{ display: "grid", textTransform: "capitalize" }}
              >
                {values?.intlBankName}
              </Grid>
              <Grid item md={4} />

              <Grid item md={4} xs={12} className={classes.gridtext}>
                <Typography className={classes.reqfieldtext}>
                  Account Name
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                style={{ display: "grid", textTransform: "capitalize" }}
              >
                {values?.intlAccountName}
              </Grid>
              <Grid item md={4} />

              <Grid item md={4} xs={12} className={classes.gridtext}>
                <Typography className={classes.reqfieldtext}>
                  IFSC Code
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                style={{ display: "grid", textTransform: "capitalize" }}
              >
                {values?.intlIfscCode}
              </Grid>

              <Grid item md={4} />

              <Grid item md={4} xs={12} className={classes.gridtext}>
                <Typography className={classes.reqfieldtext}>Branch</Typography>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                style={{ display: "grid", textTransform: "capitalize" }}
              >
                {values?.intlBranch}
              </Grid>
              <Grid item md={4} />
            </>
          )}

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
            <Typography
              variant="body2"
              style={{
                color: "#A6A6A6",
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              NGO Background
            </Typography>
          </Grid>

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Background and Description
              <NgoToolTipComponent>
                <HelpOutlineOutlinedIcon
                  style={{ color: "#C88FEF", width: "15px" }}
                />
              </NgoToolTipComponent>
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.ngoBackgroud}
          </Grid>
          <Grid item md={4} />
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Geographical Scale
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.geographicalScale}
          </Grid>
          <Grid item md={4} />
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Special Interest
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{
              display: "grid",
              textTransform: "capitalize",
            }}
          >
            {values?.specialInterest2?.map((specialInt) => {
              return <Grid> {specialInt?.name}</Grid>;
            })}
          </Grid>
          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Funding</Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.funding}
          </Grid>
          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Size</Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values.size}
          </Grid>

          <Grid item md={4} />

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Age Group</Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ display: "grid", textTransform: "capitalize" }}
          >
            {values?.age}
          </Grid>
          <Grid item md={4} />
          <div
            style={{
              width: "40%",
              display: "flex",
              justifyContent: "space-between",
              margin: "auto",
            }}
          >
            <Button
              className={classes.btn}
              color="primary"
              variant="contained"
              onClick={() => {
                addNGODetailNext();
              }}
            >
              NEXT
            </Button>

            <Button
              className={classes.btn}
              color="primary"
              variant="contained"
              onClick={handleEdit}
            >
              <Typography
                variant="h6"
                style={{ color: "#fff", cursor: "pointer" }}
              >
                EDIT
              </Typography>
            </Button>
          </div>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>NGO Name</Typography>
          </Grid>

          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={handleChange("ngoName")}
              value={values?.ngoName}
              error={isError}
              helperText={isError ? errorValues?.ngoName : ""}
            />
          </Grid>
          <Grid item md={6} xs={12} style={{ display: "grid" }}></Grid>
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              NGO Cover Image
            </Typography>
          </Grid>

          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <input
              className={classes.fileInput}
              type="file"
              id="file"
              onChange={onSelectFile("ngoImage")}
              accept="image/*"
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
            <Typography
              variant="body2"
              style={{
                color: "#A6A6A6",
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              NGO Registration Details
            </Typography>
          </Grid>

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Legal Form</Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={legalFormData}
              getOptionLabel={(option) => option?.value}
              // getOptionSelected={(option) => option.value === 1994}
              onChange={(e, item) => {
                setValues({ ...values, legalForm: item.name });
                setErrorValues({ ...errorValues, legalForm: "" });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                  error={isError}
                  helperText={isError ? errorValues?.legalForm : ""}
                />
              )}
              value={getLabelSelected(legalFormData, values?.legalForm)}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Registration Number
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.registrationNumber}
              onChange={handleChange("registrationNumber")}
              error={isError}
              helperText={isError ? errorValues?.registrationNumber : ""}
            />
          </Grid>
          <Grid
            md={6}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          ></Grid>
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>PAN Number</Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.panNumber}
              onChange={handleChange("panNumber")}
              error={isError}
              helperText={isError ? errorValues?.panNumber : ""}
            />
          </Grid>
          <Grid
            md={6}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          ></Grid>
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Website Link
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.weblink}
              onChange={handleChange("weblink")}
              error={isError}
            />
          </Grid>
          <Grid
            md={6}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          ></Grid>

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
          </Grid>

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Causes</Typography>
          </Grid>
          <Grid item md={8} xs={12} style={{ display: "grid" }}>
            <Autocomplete
              style={{ backgroundColor: "#fff" }}
              // multiple
              id="tags-outlined"
              options={causes ? causes : []}
              getOptionLabel={(option) => option.category_name}
              value={tempSelectedCause ? tempSelectedCause : {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Cause"
                />
              )}
              onChange={(e, item) => {
                if (item) {
                  setTempSelectedCause(item);
                } else {
                  setTempSelectedCause(item);
                  setTempSelectedSubcauseList([]);
                  setTempSelectedSubcause([]);
                }
              }}
            />
          </Grid>

          <Grid item md={2} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Sub Causes</Typography>
          </Grid>
          <Grid item md={8} xs={12} style={{ display: "grid" }}>
            <ChipAutocomplete
              style={{ backgroundColor: "#fff", display: "grid" }}
              multiple
              id="tags-outlined"
              options={tempSelectedSubcauseList ? tempSelectedSubcauseList : []}
              getOptionLabel={(option) => option.sub_category_name}
              value={tempSelectedSubcause}
              filterSelectedOptions
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color="primary"
                    variant="outlined"
                    style={{ maxWidth: "600px" }}
                    label={option.sub_category_name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Sub Causes"
                />
              )}
              onChange={(e, item) => {
                if (item) {
                  setTempSelectedSubcause(item);
                } else {
                  setTempSelectedSubcause([]);
                }
              }}
            />
          </Grid>

          <Grid item md={2}>
            <Button
              variant="contained"
              color="primary"
              className={classes.upload}
              startIcon={<AddCircleRounded />}
              style={{ minWidth: "105px" }}
              onClick={handleCatSubmit}
            >
              {" "}
              Save & Add
            </Button>
          </Grid>

          {values?.categories &&
            values?.categories.map((item, index) => {
              return (
                <>
                  <Grid item md={2} xs={12} className={classes.gridtext}></Grid>
                  <Grid item md={8} xs={12} style={{ display: "grid" }}>
                    <Autocomplete
                      style={{ backgroundColor: "#fff" }}
                      // multiple
                      id="tags-outlined"
                      disabled
                      options={causes ? causes : []}
                      getOptionLabel={(option) => option.category_name}
                      value={getCauseItem(item)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Cause"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={2} />

                  <Grid item md={2} xs={12} className={classes.gridtext}></Grid>
                  <Grid item md={8} xs={12} style={{ display: "grid" }}>
                    <ChipAutocomplete
                      style={{ backgroundColor: "#fff", display: "grid" }}
                      multiple
                      id="tags-outlined"
                      options={subCauses ? subCauses : []}
                      disabled
                      getOptionLabel={(option) => option.sub_category_name}
                      // defaultValue={[demo[1]]}
                      value={subCauses ? getSubCausesNewList(item) : []}
                      // value={tempSelectedSubcause}
                      filterSelectedOptions
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            variant="outlined"
                            style={{ maxWidth: "600px" }}
                            label={option.sub_category_name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Sub Causes"
                        />
                      )}
                      onChange={(e, item) => {
                        if (item) {
                          setTempSelectedSubcause(item);
                        } else {
                          setTempSelectedSubcause([]);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item md={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.upload}
                      startIcon={<DeleteOutlineOutlined />}
                      style={{ minWidth: "105px" }}
                      onClick={(e) => {
                        let categories = [...values?.categories];
                        let subcategories = [...values?.subcategories];
                        let selectedSubcategories = getSelectedSubcategories(
                          categories[index]
                        );

                        selectedSubcategories.forEach((item) => {
                          categories = categories.filter(
                            (aa) => item.id !== aa
                          );
                        });
                        // locationData.splice(index, 1);
                        setValues({
                          ...values,
                          subcategories: subcategories,
                          categories: categories,
                        });

                        categories.splice(index, 1);
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </>
              );
            })}

          {/* <Grid item md={1} /> */}

          <Grid md={12} sm={12} xs={12}>
            <Divider />
          </Grid>

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Year Of Registration
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <div style={{ backgroundColor: "#fff" }}>
              <Calendar
                selectedDate={values?.registrationYear}
                setSelectedDate={setSelectedDate}
                isYear={true}
                // value={values?.registrationYear}
              />
            </div>
          </Grid>
          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Address of Registered Office
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              multiline
              rows={6}
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.registeredAddress}
              onChange={handleChange("registeredAddress")}
              error={isError}
              helperText={isError ? errorValues?.registeredAddress : ""}
            />
          </Grid>

          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Area</Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              multiline
              rows={3}
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.registeredArea}
              onChange={handleChange("registeredArea")}
              error={isError}
              helperText={isError ? errorValues?.registeredArea : ""}
            />
          </Grid>

          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Pincode</Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              type="number"
              // multiline
              // rows={3}
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.registeredPincode}
              onChange={handleChange("registeredPincode")}
              error={isError}
              helperText={isError ? errorValues?.registeredPincode : ""}
            />
          </Grid>

          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>State</Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={stateDetails}
              getOptionLabel={(option) => option.name}
              value={addressState ? addressState : {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(e, value) => {
                if (value) {
                  setAddressState(value);
                  // setValues({...values, state: value?.name, city: ""});
                  setAddressCity(value?.city);
                } else {
                  // setValues({...values, city: "", state: ""});
                  setAddressState({});
                  setAddressCity([]);
                }
              }}
            />
          </Grid>

          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>City</Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={addressCity}
              getOptionLabel={(option) => option.city_name}
              value={getSelectedCityLocation(
                addressCity,
                values?.registeredCity
              )}
              // value={values?.registeredCity}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(e, value) => {
                setValues({ ...values, registeredCity: value?.city_name });
              }}
            />
          </Grid>

          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Communication Address
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              multiline
              rows={6}
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.communicationAddress}
              onChange={handleChange("communicationAddress")}
              error={isError}
              helperText={isError ? errorValues?.communicationAddress : ""}
            />
          </Grid>
          <Grid ite md={6}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              {" "}
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    onChange={(e, item) => {
                      if (item) {
                        setValues({
                          ...values,
                          communicationAddress: values?.registeredAddress,
                          communicationCity: values?.registeredCity,
                          communicationArea: values?.registeredArea,
                          communicationPincide: values?.registeredPincode,
                        });
                        setCommAddressState(addressState);
                        setCommAddressCity(addressCity);
                      }
                    }}
                  />
                }
                label="Same as registered address"
              />
            </div>
          </Grid>

          {/*  */}
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Communication Area
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              multiline
              rows={3}
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.communicationArea}
              onChange={handleChange("communicationArea")}
              error={isError}
              helperText={isError ? errorValues?.communicationArea : ""}
            />
          </Grid>
          {/* <Grid item md={6} /> */}
          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Communicaton Pincode
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              type="number"
              // multiline
              // rows={3}
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.communicationPincide}
              onChange={handleChange("communicationPincide")}
              error={isError}
              helperText={isError ? errorValues?.communicationPincide : ""}
            />
          </Grid>

          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Communication State
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={stateDetails}
              getOptionLabel={(option) => option.name}
              value={commAddressState}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(e, value) => {
                if (value) {
                  setCommAddressState(value);
                  // setValues({...values, communicationCity: ""});
                  setCommAddressCity(value?.city);
                } else {
                  // setValues({...values, city: "", state: ""});
                  setCommAddressState({});
                  setCommAddressCity([]);
                }
              }}
            />
          </Grid>

          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Communication City
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={commAddressCity}
              getOptionLabel={(option) => option.city_name}
              // value={getSelectedCity(addressCity, values?.registeredCity)}
              value={getSelectedCityLocation(
                commAddressCity,
                values?.communicationCity
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(e, value) => {
                setValues({ ...values, communicationCity: value?.city_name });
                // setCommAddressCity([]);
              }}
            />
          </Grid>

          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Primary Email ID for Communication
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.primaryEmail}
              onChange={handleChange("primaryEmail")}
              error={isError}
              helperText={isError ? errorValues?.primaryEmail : ""}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Alternate Email
              {/* */}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.alternateEmail}
              onChange={handleChange("alternateEmail")}
              error={isError}
              helperText={isError ? errorValues?.alternateEmail : ""}
            />
          </Grid>
          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
            <Typography
              variant="body2"
              style={{
                color: "#A6A6A6",
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              Chief Functionary Details
            </Typography>
          </Grid>
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Name</Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.chiefName}
              onChange={handleChange("chiefName")}
              error={isError}
              helperText={isError ? errorValues?.chiefName : ""}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Designation
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.chiefDesignation}
              onChange={handleChange("chiefDesignation")}
              error={isError}
              helperText={isError ? errorValues?.chiefDesignation : ""}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Phone/Mobile Number
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              type="number"
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.chiefPhone}
              onChange={handleChange("chiefPhone")}
              error={isError}
              helperText={isError ? errorValues?.chiefPhone : ""}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Alternate Phone/Mobile Number
              {/* */}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              type="number"
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.chiefAlternatePhone}
              onChange={handleChange("chiefAlternatePhone")}
              error={isError}
              helperText={isError ? errorValues?.chiefAlternatePhone : ""}
            />
          </Grid>

          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Primary Email ID
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.chiefEmail}
              onChange={handleChange("chiefEmail")}
              error={isError}
              helperText={isError ? errorValues?.chiefEmail : ""}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Alternate Email ID
              {/*  */}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.chiefAlternateEmail}
              onChange={handleChange("chiefAlternateEmail")}
              error={isError}
              helperText={isError ? errorValues?.chiefAlternateEmail : ""}
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
            <div style={{ display: "flex" }}>
              {" "}
              <Typography
                variant="body2"
                style={{
                  color: "#A6A6A6",
                  fontStyle: "italic",
                  textAlign: "left",
                  marginTop: "10px",
                }}
              >
                Details of Contact/Nodal Person
              </Typography>
              <div style={{ marginLeft: "20px" }}>
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkedB"
                      color="primary"
                      onChange={(e, item) => {
                        if (item) {
                          setValues({
                            ...values,
                            nodalName: values.chiefName,
                            nodalDesignation: values?.chiefDesignation,
                            nodalPhone: values?.chiefPhone,
                            nodalAlternatePhone: values?.chiefAlternatePhone,
                            nodalEmail: values?.chiefEmail,
                            nodalAlternateEmail: values?.chiefAlternateEmail,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <span style={{ color: "#A6A6A6" }}>
                      Same as Chief Functionary
                    </span>
                  }
                />
              </div>
            </div>
          </Grid>
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Name</Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.nodalName}
              onChange={handleChange("nodalName")}
              error={isError}
              helperText={isError ? errorValues?.nodalName : ""}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Designation
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.nodalDesignation}
              onChange={handleChange("nodalDesignation")}
              error={isError}
              helperText={isError ? errorValues?.nodalDesignation : ""}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Phone/Mobile Number
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              type="number"
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.nodalPhone}
              onChange={handleChange("nodalPhone")}
              error={isError}
              helperText={isError ? errorValues?.nodalPhone : ""}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Alternate Phone/Mobile Number
              {/* */}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              type="number"
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.nodalAlternatePhone}
              onChange={handleChange("nodalAlternatePhone")}
              error={isError}
              helperText={isError ? errorValues?.nodalAlternatePhone : ""}
            />
          </Grid>

          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Primary Email ID
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.nodalEmail}
              onChange={handleChange("nodalEmail")}
              error={isError}
              helperText={isError ? errorValues?.nodalEmail : ""}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Alternate Email ID
              {/* */}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.nodalAlternateEmail}
              onChange={handleChange("nodalAlternateEmail")}
              error={isError}
              helperText={isError ? errorValues?.nodalAlternateEmail : ""}
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
          </Grid>
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              No. of beneficiaries served
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              type="number"
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.beneficiariesNo}
              onChange={handleChange("beneficiariesNo")}
              error={isError}
              helperText={isError ? errorValues?.beneficiariesNo : ""}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Average annual turnover of last 3 years (in INR Lakh)
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              type="number"
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.annualTurnover}
              onChange={handleChange("annualTurnover")}
              error={isError}
              helperText={isError ? errorValues?.annualTurnover : ""}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              No. of full time staff
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              type="number"
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.staffNo}
              onChange={handleChange("staffNo")}
              error={isError}
              helperText={isError ? errorValues?.staffNo : ""}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Project Locations
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            {/* <ChipAutocomplete
            style={{ backgroundColor: "#fff" }}
            multiple
            id="tags-outlined"
            options={stateDetails ? stateDetails : []}
            getOptionLabel={(option) => option?.name}
            value={selectedStates ? selectedStates : []}
            filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  color="primary"
                  variant="outlined"
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" 
              label="Select States"
              />
            )}
            onChange={(e, item) => {
              if (item) {
                setSelectedStates(item);
                let cityList = [];
                item.map(data => {
                  cityList = cityList.concat(data?.city);
                })
                setCityStateDetails(cityList);
              } else {
                setCityStateDetails([]);
                setSelectedStates([]);
              }
            }}
          /> */}
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={stateDetails ? stateDetails : []}
              getOptionLabel={(option) => option?.name}
              onChange={(e, item) => {
                if (item) {
                  setProjectLocationCity(item?.city);
                  setTempSelectedProjectState(item);
                  setTempSelectedProjectCity([]);
                } else {
                  setTempSelectedProjectCity([]);
                  setProjectLocationCity([]);
                }
              }}
              // value={getLabelSelected(
              //   geographicalScaleData,
              //   values?.geographicalScale
              // )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                  label={"Select State"}
                  // error={isError}
                  // helperText={isError ? errorValues?.geographicalScale : ""}
                />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <ChipAutocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              multiple
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={projectLocationCity}
              getOptionLabel={(option) => option.city_name}
              onChange={(e, item) => {
                if (item) {
                  setTempSelectedProjectCity(item);
                }
              }}
              value={tempSelectedProjectCity ? tempSelectedProjectCity : []}
              // filterSelectedOptions
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color="primary"
                    variant="outlined"
                    label={option.city_name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                  label={"Select City"}
                  // error={isError}
                  // helperText={isError ? errorValues?.geographicalScale : ""}
                />
              )}
            />
          </Grid>

          <Grid item md={2}>
            <Button
              variant="contained"
              color="primary"
              className={classes.upload}
              startIcon={<AddCircleRounded />}
              style={{ minWidth: "105px" }}
              onClick={(e) => {
                let locationData = [...values?.projectLocation];
                if (
                  !tempSelectedProjectCity ||
                  tempSelectedProjectCity.length === 0
                ) {
                  alert.error("Please Select a Project  !");
                  return;
                }
                let alreadyAdded = false;
                tempSelectedProjectCity &&
                  tempSelectedProjectCity.forEach((item) => {
                    if (locationData.includes(item?.id)) {
                      alert.error("Project Location Already Added!!");
                      alreadyAdded = true;
                      return;
                    }
                    locationData?.push(item?.id);
                  });
                if (!alreadyAdded) {
                  setStateDataList([
                    ...statedataList,
                    tempSelectedProjectState,
                  ]);
                  setValues({ ...values, projectLocation: locationData });
                  setTempSelectedProjectCity([]);
                }
              }}
            >
              Save & Add
            </Button>
          </Grid>

          {statedataList &&
            statedataList.map((item, index) => {
              return (
                <>
                  <Grid item md={2} />
                  <Grid item md={4} xs={12} style={{ display: "grid" }}>
                    {/* <ChipAutocomplete
            style={{ backgroundColor: "#fff" }}
            multiple
            id="tags-outlined"
            options={stateDetails ? stateDetails : []}
            getOptionLabel={(option) => option?.name}
            value={selectedStates ? selectedStates : []}
            filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  color="primary"
                  variant="outlined"
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" 
              label="Select States"
              />
            )}
            onChange={(e, item) => {
              if (item) {
                setSelectedStates(item);
                let cityList = [];
                item.map(data => {
                  cityList = cityList.concat(data?.city);
                })
                setCityStateDetails(cityList);
              } else {
                setCityStateDetails([]);
                setSelectedStates([]);
              }
            }}
          /> */}
                    <Autocomplete
                      id="country-select-demo"
                      style={{ backgroundColor: "#fff" }}
                      classes={{
                        option: classes.option,
                      }}
                      autoHighlight
                      options={stateDetails ? stateDetails : []}
                      getOptionLabel={(option) => option?.name}
                      disableClearable
                      disabled
                      onChange={(e, item) => {
                        setStateDataList([...statedataList, item]);
                      }}
                      value={
                        statedataList && statedataList.length > 0
                          ? statedataList[index]
                          : {}
                      }
                      // value={getLabelSelected(
                      //   geographicalScaleData,
                      //   values?.geographicalScale
                      // )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "district",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} xs={12} style={{ display: "grid" }}>
                    {/* <ChipAutocomplete
            style={{ backgroundColor: "#fff" }}
            multiple
            id="tags-outlined"
            options={cityStateDetails ? cityStateDetails : []}
            getOptionLabel={(option) => option.city_name}
            // defaultValue={[demo[1]]}
            value={cityStateDetailsNew ? getProjectLocation() : []}
            filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  color="primary"
                  variant="outlined"
                  label={option.city_name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Select Cities"/>
            )}
            onChange={(e, item) => {
              if (item) {
                const loc1 = item.map((item1) => {
                  return item1?.id;
                });
                setValues({ ...values, projectLocation: loc1 });
              } else {
                setValues({ ...values, projectLocation: [] });
              }
            }}
          /> */}
                    <ChipAutocomplete
                      id="country-select-demo"
                      style={{ backgroundColor: "#fff" }}
                      classes={{
                        option: classes.option,
                      }}
                      autoHighlight
                      disableClearable
                      disabled
                      multiple
                      options={
                        statedataList &&
                        statedataList.length > 0 &&
                        statedataList[index]?.city
                          ? statedataList[index]?.city
                          : []
                      }
                      getOptionLabel={(option) => option.city_name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            deleteIcon={<></>}
                            variant="outlined"
                            label={option.city_name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onChange={(e, item) => {
                        let projectLocationIds = [...values?.projectLocation];
                        projectLocationIds[index] = item?.id;
                        setValues({
                          ...values,
                          projectLocation: projectLocationIds,
                        });
                      }}
                      // value={getSelectedCity(
                      //   statedataList[index]?.city,
                      //   values?.projectLocation[index]
                      // )}
                      value={getProjectLocation(statedataList[index]?.city)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "district",
                          }}
                          error={isError}
                          helperText={
                            isError ? errorValues?.geographicalScale : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.upload}
                      startIcon={<DeleteOutlineOutlined />}
                      onClick={() => {
                        let locationData = [...values?.projectLocation];
                        let selectedCities = getProjectLocation(
                          statedataList[index]?.city
                        );
                        selectedCities.forEach((item) => {
                          locationData = locationData.filter(
                            (aa) => item.id !== aa
                          );
                        });
                        // locationData.splice(index, 1);
                        setValues({ ...values, projectLocation: locationData });
                        let stateDataList1 = [...statedataList];
                        stateDataList1.splice(index, 1);
                        setStateDataList(stateDataList1);
                      }}
                    >
                      {" "}
                      Delete
                    </Button>
                  </Grid>
                </>
              );
            })}

          <Grid item md={6} xs={12} className={classes.gridtext}>
            <Typography variant="body2">
              Is your NGO eligible to accept international payments and
              donations?
            </Typography>
          </Grid>
          <Grid item md={6} xs={12} className={classes.gridtext}>
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <RadioGroup
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
                value={values?.internationalPayment ? "yes" : "no"}
                onChange={(event, item) => {
                  if (item === "yes")
                    setValues({ ...values, internationalPayment: true });
                  else {
                    setValues({
                      ...values,
                      internationalPayment: false,
                      intlAccountName: "",
                      intlBankName: "",
                      intlIfscCode: "",
                      intlAccountNo: "",
                      intlBranch: "",
                    });
                  }
                }}
              >
                <FormControlLabel
                  name="intlPayment"
                  value="yes"
                  control={<Radio color="primary" size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  name="intlPayment"
                  value="no"
                  control={<Radio color="primary" size="small" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {values?.internationalPayment === false ? (
            ""
          ) : (
            <>
              <Grid item md={6} xs={12} className={classes.gridtext}>
                <Typography variant="body2">
                  Would you like to mobilise international donations from this
                  platform?
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} className={classes.gridtext}>
                <FormControl component="fieldset" style={{ width: "100%" }}>
                  <RadioGroup
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                    value={values?.mobaliseInternationPayment ? "yes" : "no"}
                    onChange={(event, item) => {
                      if (item === "yes")
                        setValues({
                          ...values,
                          mobaliseInternationPayment: true,
                        });
                      else
                        setValues({
                          ...values,
                          mobaliseInternationPayment: false,
                        });
                    }}
                  >
                    <FormControlLabel
                      name="intlDonation"
                      value="yes"
                      control={<Radio color="primary" size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      name="intlDonation"
                      value="no"
                      control={<Radio color="primary" size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
            <Typography
              variant="body2"
              style={{
                color: "#A6A6A6",
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              Bank Account Details for Receiving Domestic Donations
            </Typography>
          </Grid>
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              NGO Bank Account Number
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.accountNo}
              onChange={handleChange("accountNo")}
              error={isError}
              helperText={isError ? errorValues?.accountNo : ""}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Name of Bank
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.bankName}
              onChange={handleChange("bankName")}
              error={isError}
              helperText={isError ? errorValues?.bankName : ""}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Account Name
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.accountName}
              onChange={handleChange("accountName")}
              error={isError}
              helperText={isError ? errorValues?.accountName : ""}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>IFSC Code</Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.ifscCode}
              onChange={handleChange("ifscCode")}
              error={isError}
              helperText={isError ? errorValues?.ifscCode : ""}
            />
          </Grid>

          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Branch</Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.branch}
              onChange={handleChange("branch")}
              error={isError}
              helperText={isError ? errorValues?.branch : ""}
            />
          </Grid>
          <Grid item md={6} />

          {values?.mobaliseInternationPayment && (
            <>
              <Grid item md={12} xs={12} style={{ display: "grid" }}>
                <Divider />
                <div style={{ display: "flex" }}>
                  {" "}
                  <Typography
                    variant="body2"
                    style={{
                      color: "#A6A6A6",
                      fontStyle: "italic",
                      textAlign: "left",
                      marginTop: "10px",
                    }}
                  >
                    Bank Account Details for Receiving International Donations
                  </Typography>
                  {/* <div style={{ marginLeft: "20px" }}>
                    {" "}
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedB"
                          color="primary"
                          onChange={(e, item) => {
                            if (item) {
                              setValues({
                                ...values,
                                intlAccountName: values.accountName,
                                intlAccountNo: values?.accountNo,
                                intlBankName: values?.bankName,
                                intlBranch: values?.branch,
                                intlIfscCode: values?.ifscCode,
                              });
                            }
                          }}
                        />
                      }
                      label={
                        <span style={{ color: "#A6A6A6" }}>
                          Same as Above Bank
                        </span>
                      }
                    />
                  </div> */}
                </div>
              </Grid>
              <Grid item md={2} xs={12} className={classes.gridtext}>
                <Typography className={classes.reqfieldtext}>
                  NGO Bank Account Number
                </Typography>
              </Grid>
              <Grid item md={4} xs={12} style={{ display: "grid" }}>
                <TextField
                  variant="outlined"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                  value={values?.intlAccountNo}
                  onChange={handleChange("intlAccountNo")}
                  error={isError}
                  helperText={isError ? errorValues?.intlAccountNo : ""}
                />
              </Grid>
              <Grid item md={6} />
              <Grid item md={2} xs={12} className={classes.gridtext}>
                <Typography className={classes.reqfieldtext}>
                  Name of Bank
                </Typography>
              </Grid>
              <Grid item md={4} xs={12} style={{ display: "grid" }}>
                <TextField
                  variant="outlined"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                  value={values?.intlBankName}
                  onChange={handleChange("intlBankName")}
                  error={isError}
                  helperText={isError ? errorValues?.intlBankName : ""}
                />
              </Grid>
              <Grid item md={6} />

              <Grid item md={2} xs={12} className={classes.gridtext}>
                <Typography className={classes.reqfieldtext}>
                  Account Name
                </Typography>
              </Grid>
              <Grid item md={4} xs={12} style={{ display: "grid" }}>
                <TextField
                  variant="outlined"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                  value={values?.intlAccountName}
                  onChange={handleChange("intlAccountName")}
                  error={isError}
                  helperText={isError ? errorValues?.intlAccountName : ""}
                />
              </Grid>
              <Grid item md={6} />

              <Grid item md={2} xs={12} className={classes.gridtext}>
                <Typography className={classes.reqfieldtext}>
                  IFSC Code
                </Typography>
              </Grid>
              <Grid item md={4} xs={12} style={{ display: "grid" }}>
                <TextField
                  variant="outlined"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                  value={values?.intlIfscCode}
                  onChange={handleChange("intlIfscCode")}
                  error={isError}
                  helperText={isError ? errorValues?.intlIfscCode : ""}
                />
              </Grid>

              <Grid item md={6} />

              <Grid item md={2} xs={12} className={classes.gridtext}>
                <Typography className={classes.reqfieldtext}>Branch</Typography>
              </Grid>
              <Grid item md={4} xs={12} style={{ display: "grid" }}>
                <TextField
                  variant="outlined"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                  value={values?.intlBranch}
                  onChange={handleChange("intlBranch")}
                  error={isError}
                  helperText={isError ? errorValues?.intlBranch : ""}
                />
              </Grid>
              <Grid item md={6} />
            </>
          )}

          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Divider />
            <Typography
              variant="body2"
              style={{
                color: "#A6A6A6",
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              NGO Background
            </Typography>
          </Grid>

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Background and Description
              <NgoToolTipComponent>
                <HelpOutlineOutlinedIcon
                  style={{ color: "#C88FEF", width: "15px" }}
                />
              </NgoToolTipComponent>
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <TextField
              variant="outlined"
              multiline
              rows={6}
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              value={values?.ngoBackgroud}
              onChange={handleChange("ngoBackgroud")}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Geographical Scale
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={geographicalScaleData}
              getOptionLabel={(option) => option.value}
              onChange={(e, item) => {
                setValues({ ...values, geographicalScale: item.name });
              }}
              value={getLabelSelected(
                geographicalScaleData,
                values?.geographicalScale
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                  error={isError}
                  helperText={isError ? errorValues?.geographicalScale : ""}
                />
              )}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Special Interest
            </Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <ChipAutocomplete
              style={{ backgroundColor: "#fff", display: "grid" }}
              multiple
              id="tags-outlined"
              // options={stateDetails ? stateDetails : []}
              // getOptionLabel={(option) => option?.name}
              options={specialInterests}
              getOptionLabel={(option) => option.name}
              onChange={(e, item) => handleChange3(item)}
              // value={selectedStates ? selectedStates : []}
              filterSelectedOptions
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color="primary"
                    variant="outlined"
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                  error={isError}
                />
              )}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}></Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <ChipAutocomplete
              style={{ backgroundColor: "#fff", display: "grid" }}
              multiple
              disabled
              id="tags-outlined"
              // options={stateDetails ? stateDetails : []}
              // getOptionLabel={(option) => option?.name}
              options={specialInterests}
              getOptionLabel={(option) => option.name}
              onChange={(e, item) => handleChange3(item)}
              value={values?.specialInterest2}
              // value={selectedStates ? selectedStates : []}
              filterSelectedOptions
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color="primary"
                    variant="outlined"
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                  error={isError}
                />
              )}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Funding</Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={fundingData}
              getOptionLabel={(option) => option.value}
              onChange={(e, item) => {
                setValues({ ...values, funding: item.name });
              }}
              value={getLabelSelected(fundingData, values?.funding)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                  error={isError}
                  helperText={isError ? errorValues?.funding : ""}
                />
              )}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Size</Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlightz
              options={sizeData}
              getOptionLabel={(option) => option.value}
              onChange={(e, item) => {
                setValues({ ...values, size: item.name });
              }}
              value={getLabelSelected(sizeData, values?.size)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                  error={isError}
                  helperText={isError ? errorValues?.size : ""}
                />
              )}
            />
          </Grid>

          <Grid item md={6} />

          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>Age Group</Typography>
          </Grid>
          <Grid item md={4} xs={12} style={{ display: "grid" }}>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={ageData}
              getOptionLabel={(option) => option.value}
              onChange={(e, item) => {
                setValues({ ...values, age: item.name });
              }}
              value={getLabelSelected(ageData, values?.age)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                  error={isError}
                  helperText={isError ? errorValues?.age : ""}
                />
              )}
            />
          </Grid>
          <Grid item md={6} />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              margin: "auto",
            }}
          >
            {!fromProfile2 ? (
              <Button
                className={classes.btn}
                color="primary"
                variant="contained"
                onClick={() => {
                  addNGODetail();
                }}
              >
                {isLoading ? <CustomButtonCircularProgress /> : "SAVE & NEXT"}
              </Button>
            ) : (
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "auto",
                }}
              >
                <Button
                  className={classes.btn}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateNGODetail();
                  }}
                >
                  {isLoading ? <CustomButtonCircularProgress /> : "SAVE & NEXT"}
                </Button>

                {/* <Button
                  className={classes.btn}
                  color="primary"
                  variant="contained"
                  onClick={handleEdit}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "#fff", cursor: "pointer" }}
                  >
                    EDIT
                  </Typography>
                </Button> */}

                {/* <Button
                  className={classes.btn}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    history.push("/ngo/profile-doc");
                  }}
                >
                  {isLoading ? <CustomButtonCircularProgress /> : "SAVE & NEXT"}
                </Button> */}
              </div>
            )}
          </div>
        </Grid>
      )}
    </>
  );
}
