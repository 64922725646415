import React from "react";
import { Container, makeStyles } from "@material-ui/core";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: "35px",
    paddingRight: "35px",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
}));

function Layout({ children }) {
  const classes = useStyles();
  return (
    <>
      <Navbar />
      <Container
        maxWidth="xl"
        component="main"
        className={classes.header}
        disableGutters
      >
        {children}
      </Container>
      <Footer />
    </>
  );
}

export default Layout;
