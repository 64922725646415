import React from "react";
import user from "../../assets/png/team-work/user.jpg";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
  },
  user: {
    borderRadius: "50%",
  },
}));

function DonationPerson({data}) {
  const classes = useStyles();
  return (
    <>
    {/* <TeamToolTip> */}
      <div className={classes.card}>
        <img
          src={data?.image}
          height="100px"
          width="100px"
          alt="user "
          className={classes.user}
        />
        <Typography variant="h6" noWrap>
          {data.name}
        </Typography>
        <Typography variant="body2" color="primary">
          ₹ {data?.ammount}
        </Typography>
      </div>
      {/* </TeamToolTip> */}
    </>
  );
}

export default DonationPerson;
