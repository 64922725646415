import React, { useEffect } from "react";
import DashboardLayout from "../../../components/Layout/DashBoardLayout";
import NgoProfileSection from "../../../components/Section/DashBoard/Profile/NgoProfileSection";
import { getRequest } from "../../../apis/baseApi";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { ngoDocDetails } from "../../../redux/ngoReducer/action";
import { NoEncryption } from "@material-ui/icons";

export default function Ngo_Profile() {
  const [values, setValues] = React.useState({
    ngoName: "",
    ngoBackgroud: "",
    legalForm: "",
    registrationNumber: "",
    panNumber: "",
    weblink: "",
    ngoImage: "",
    ngoTheme: "",
    registrationYear: "",
    registeredAddress: "",
    registeredCity: "",
    registeredPincode: "",
    registeredArea: "",
    communicationAddress: "",
    communicationCity: "",
    communicationArea: "",
    communicationPincide: "",
    isCommunicationSame: "",
    primaryEmail: "",
    alternateEmail: "",
    chiefName: "",
    chiefDesignation: "",
    chiefPhone: "",
    chiefAlternatePhone: "",
    chiefEmail: "",
    chiefAlternateEmail: "",
    nodalName: "",
    nodalDesignation: "",
    nodalPhone: "",
    nodalAlternatePhone: "",
    nodalEmail: "",
    nodalAlternateEmail: "",
    sameAsChief: true,
    beneficiariesNo: "",
    annualTurnover: "",
    staffNo: "",
    projectLocation: "",
    internationalPayment: false,
    mobaliseInternationPayment: false,
    accountNo: "",
    bankName: "",
    accountName: "",
    ifscCode: "",
    branch: "",
    intlAccountNo: "",
    intlBankName: "",
    intlAccountName: "",
    intlIfscCode: "",
    intlBranch: "",
    sameAsDomestic: false,
    geographicalScale: null,
    specialInterest: null,
    funding: null,
    size: null,
    age: null,
  });

  const dispatch = useDispatch();

  const alert = useAlert();
  useEffect(() => {
    getRequest("ngo/ngo_user_ngo_list/")
      .then((res) => {
        if (res?.data?.data) {
          localStorage.setItem("ngo_slug", res?.data?.data[0].slug);
          const ngoData = res?.data?.data[0];
          localStorage.setItem("ngo_name", ngoData?.ngo_name);
          setValues({
            ngoName: ngoData?.ngo_name,
            image:
              ngoData?.ngo_images && ngoData?.ngo_images.length > 0
                ? ngoData?.ngo_images.at(-1)
                : null,
            ngoBackgroud: ngoData?.ngo_background,
            legalForm: ngoData?.legal_Form,
            registrationNumber: ngoData?.ngo_registration_id,
            panNumber: ngoData?.pan_card_number,
            weblink: ngoData?.website_link,
            ngoImage: ngoData?.ngo_images,
            ngoTheme: ngoData?.byThematicFocus,
            registrationYear: ngoData?.year_of_registration,
            registeredAddress: ngoData?.reg_address_1,
            registeredCity: ngoData?.reg_city,
            registeredPincode: ngoData?.reg_pincode,
            registeredArea: ngoData?.reg_area,
            communicationAddress: ngoData?.comm_address_1,
            communicationCity: ngoData?.comm_city,
            communicationArea: ngoData?.comm_area,
            communicationPincide: ngoData?.comm_pincode,
            isCommunicationSame: ngoData?.ngo_name,
            primaryEmail: ngoData?.ngo_email,
            alternateEmail: ngoData?.alternative_email,
            chiefName: ngoData?.chief_name,
            chiefDesignation: ngoData?.chief_destination,
            chiefPhone: ngoData?.chief_mobile_number,
            chiefAlternatePhone: ngoData?.chief_alternative_mobile_number,
            chiefEmail: ngoData?.chief_email,
            chiefAlternateEmail: ngoData?.ngo_name,
            nodalName: ngoData?.nodal_name,
            nodalDesignation: ngoData?.nodal_destination,
            nodalPhone: ngoData?.nodal_mobile_number,
            nodalAlternatePhone: ngoData?.nodal_alternative_mobile_number,
            nodalEmail: ngoData?.nodal_email,
            nodalAlternateEmail: ngoData?.ngo_name,
            sameAsChief: ngoData?.ngo_name,
            beneficiariesNo: ngoData?.number_of_beneficiaries_served,
            annualTurnover: ngoData?.average_annual_turnover_of_last_3_year,
            staffNo: ngoData?.number_of_full_time_employees,
            projectLocation: ngoData?.project_locations,
            internationalPayment: ngoData?.is_eligible_for_foreign_fund,
            mobaliseInternationPayment: ngoData?.want_to_mob_foreign_fund_here,
            accountNo: ngoData?.domestic_ngo_bank_account_numer,
            bankName: ngoData?.domestic_bank_name,
            accountName: ngoData?.dom_account_holder_name,
            ifscCode: ngoData?.domestic_ifsc_code,
            branch: ngoData?.domestic_branch,
            intlAccountNo: ngoData?.international_ngo_bank_account_numer,
            intlBankName: ngoData?.international_bank_name,
            intlAccountName: ngoData?.international_account_holder_name,
            intlIfscCode: ngoData?.international_ifsc_code,
            intlBranch: ngoData?.international_branch,
            sameAsDomestic: ngoData?.ngo_name,
            geographicalScale: ngoData?.byGeographicalScale,
            specialInterest: ngoData?.bySpecialInterest,
            specialInterest2: ngoData?.bySpecialInterest2,
            funding: ngoData?.byFunding,
            size: ngoData?.bySize,
            age: ngoData?.byAge,
            categories: ngoData?.categories,
            subcategories: ngoData?.subcategories,
          });

          dispatch(
            ngoDocDetails({
              itrCopy: ngoData?.ngo_docs_images?._ITR_certificate,
              itrCopy2: ngoData?.ngo_docs_images?._ITR_2_certificate,
              itrCopy3: ngoData?.ngo_docs_images?._ITR_3_certificate,
              cancelledChequeDom: ngoData?.ngo_docs_images?.dom_cancel_chaque,
              cancelledChequeDom2:
                ngoData?.ngo_docs_images?.dom_cancel_chaque_2,
              cancelledChequeIntl:
                ngoData?.ngo_docs_images?.internation_cancel_chaque,
              cancelledChequeIntl2:
                ngoData?.ngo_docs_images?.internation_cancel_chaque_2,
              annualReport: ngoData?.ngo_docs_images?.annual_report,
              annualReport2: ngoData?.ngo_docs_images?.annual_report_2,
              annualReport3: ngoData?.ngo_docs_images?.annual_report_3,
              gstRegistrationNo: ngoData?.ngo_docs_images?.gst_certificate,
              auditedFinancial:
                ngoData?.ngo_docs_images?.audited_financial_statement,
              auditedFinancial2:
                ngoData?.ngo_docs_images?.audited_financial_statement_2,
              auditedFinancial3:
                ngoData?.ngo_docs_images?.audited_financial_statement_3,
              registrationNo: ngoData?.ngo_docs_images?.ngo_registration_id,
              panCardCert: ngoData?.ngo_docs_images?.pan_card_certificate,
              certificate12a: ngoData?.ngo_docs_images?._12_a_certificate,
              certificateFcra: ngoData?.ngo_docs_images?._FCRA_certificate,
              certificate80G: ngoData?.ngo_docs_images?._80_g_certificate,
              internalAuditReport:
                ngoData?.ngo_docs_images?.internal_audit_reports,
              projectCompletionReport:
                ngoData?.ngo_docs_images?.project_completion,
              impactAssessmentReport:
                ngoData?.ngo_docs_images?.impact_assessment_reports,
              caseStudy: ngoData?.ngo_docs_images?.case_study_and_testimonials,
              ratingReport: ngoData?.ngo_docs_images?.rating_report,
              relevantPic: ngoData?.ngo_docs_images?.relevant_photo_1,
              projectStatusReport: ngoData?.ngo_docs_images?.project_status,
              otherDoc: null,
            })
          );
        } else {
          // alert.error("Something went wrong");
        }
      })
      .catch((err) => {
        // alert.error("Something went wrong");
      });
  }, []);
  return (
    <>
      <DashboardLayout>
        <NgoProfileSection data={values} fromProfile={true} />
      </DashboardLayout>
    </>
  );
}
