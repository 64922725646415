import { Grid, Hidden } from "@material-ui/core";
import React, { useEffect } from "react";
import DashBoardSidebar from "../Navbar/DashBoardSidebar";
import { getRequest } from "../../apis/baseApi";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import NgoBottomAppbar from "../Navbar/NgoBottomAppbar";

export default function DashboardLayout({ children }) {
  const alert = useAlert();
  const history = useHistory();
  useEffect(() => {
    getRequest("ngo/ngo_user_ngo_list/")
      .then((res) => {
        if (res?.data?.data) {
        } else {
          history.push("/ngo-login");
        }
      })
      .catch((err) => {
        history.push("/ngo-login");
      });
  }, []);
  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        <Hidden only={["xs", "sm"]}>
          <Grid item md={2}>
            <DashBoardSidebar />
          </Grid>
        </Hidden>
        <Grid item md={10}>
          {children}
        </Grid>
        <Hidden only={["md", "lg", "xl"]}>
          <NgoBottomAppbar />
        </Hidden>
      </Grid>
    </>
  );
}
