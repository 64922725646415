import React from "react";
import { Typography, Grid, Hidden } from "@material-ui/core";
import vactor from "../../assets/png/team-work/cuate.png";
import { makeStyles } from "@material-ui/styles";
import ImageSlider from "../../Cards/ImageSlider/ImageSlider";
import MobileImageSlider from "../../Cards/ImageSlider/MobileImageSlider";

const useStyles = makeStyles((theme) => ({
  img: {
    [theme.breakpoints.only("sm")]: {
      width: "auto",
      height: "200px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "auto",
      height: "100px",
    },
  },
}));

function WhatWeDoSection() {
  const classes = useStyles();
  return (
    <>
      <div>
        <Typography
          variant="h2"
          component="h1"
          color="primary"
          style={{ fontFamily: "Marcellus", textAlign: "center" }}
        >
          What We Do
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Our attempt is to make philanthropy simple and convenient for everyone. While doing so, we collaborate with some of the most credible not for profits who demonstrate impeccable integrity, positive intent and share our passion for building a better world. We offer our platform to our donors to connect with our Partner NGO Pool and make informed donations and track the usage and impact of such donations. We do this with utmost transparency, ensuring that we are compliant with all regulations and follow rigorous and robust processes for assessing/rating NGO partners and monitoring and assessing impact of the contributions made. 
        </Typography>
      </div>
      <Hidden only={["xs"]}>
        <Grid item md={12}>
          <ImageSlider />
        </Grid>
      </Hidden>
      <Hidden only={["sm", "md", "lg", "xl"]}>
        <Grid item xs={12}>
          <MobileImageSlider />
        </Grid>
      </Hidden>
    </>
  );
}

export default WhatWeDoSection;
