import NgoRegTwoSection from "../../../components/Section/DashBoard/Profile/NgoRegTwoSection";
import { postRequest, putRequest, getRequest } from "../../../apis/baseApi";
import { useAlert } from "react-alert";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DashboardLayout from "../../../components/Layout/DashBoardLayout";
import { ngoDocDetailsSelectorFn } from "../../../redux/ngoReducer/selector";
import { useSelector } from "react-redux";

export default function NgoRegDoc() {
  const alert = useAlert();
  const history = useHistory();
  const ngoDocs = useSelector(ngoDocDetailsSelectorFn);
  const [value, setValue] = useState();
  useEffect(() => {
    if (ngoDocs) {
      setDocs(ngoDocs);
    }
  }, [ngoDocs]);
  useEffect(() => {
    getRequest("ngo/ngo_user_ngo_list/")
      .then((res) => {
        setValue(res?.data?.data[0]?.ngo_docs_images);
      })
      .catch((err) => {
        // alert.error("Something went wrong");
      });
  }, []);

  const [docs, setDocs] = useState({
    itrCopy: null,
    itrCopy2: null,
    itrCopy3: null,
    cancelledChequeDom: null,
    cancelledChequeDom2: null,
    cancelledChequeIntl: null,
    cancelledChequeIntl2: null,
    annualReport: null,
    annualReport2: null,
    annualReport3: null,
    gstRegistrationNo: null,
    auditedFinancial: null,
    auditedFinancial2: null,
    auditedFinancial3: null,
    registrationNo: null,
    panCardCert: null,
    certificate12a: null,
    certificateFcra: null,
    certificate80G: null,
    internalAuditReport: null,
    projectCompletionReport: null,
    impactAssessmentReport: null,
    caseStudy: null,
    ratingReport: null,
    relevantPic: null,
    projectStatusReport: null,
    otherDoc: null,
  });
  const abc = "ghjh";

  function uploadDocs() {
    // if (validateDocs()) {
    const formData = new FormData();
    if (typeof docs?.panCardCert !== "string" && docs?.panCardCert !== null) {
      formData.set("pan_card_certificate", docs?.panCardCert);
    }
    if (
      typeof docs?.registrationNo !== "string" &&
      docs?.registrationNo !== null
    ) {
      formData.set("ngo_registration_id", docs?.registrationNo);
    }
    if (
      typeof docs?.certificate12a !== "string" &&
      docs?.certificate12a !== null
    ) {
      formData.set("_12_a_certificate", docs?.certificate12a);
    }
    if (
      typeof docs?.certificate80G !== "string" &&
      docs?.certificate80G !== null
    ) {
      formData.set("_80_g_certificate", docs?.certificate80G);
    }
    if (
      typeof docs?.certificateFcra !== "string" &&
      docs?.certificateFcra !== null
    ) {
      formData.set("_FCRA_certificate", docs?.certificateFcra);
    }
    if (typeof docs?.itrCopy !== "string" && docs?.itrCopy !== null) {
      formData.set("_ITR_certificate", docs?.itrCopy);
    }
    if (typeof docs?.itrCopy2 !== "string" && docs?.itrCopy2 !== null) {
      formData.set("_ITR_2_certificate", docs?.itrCopy2);
    }
    if (typeof docs?.itrCopy3 !== "string" && docs?.itrCopy3 !== null) {
      formData.set("_ITR_3_certificate", docs?.itrCopy3);
    }
    if (typeof docs?.annualReport !== "string" && docs?.annualReport !== null) {
      formData.set("annual_report", docs?.annualReport);
    }
    if (
      typeof docs?.annualReport2 !== "string" &&
      docs?.annualReport2 !== null
    ) {
      formData.set("annual_report_2", docs?.annualReport);
    }
    if (
      typeof docs?.annualReport3 !== "string" &&
      docs?.annualReport3 !== null
    ) {
      formData.set("annual_report_3", docs?.annualReport3);
    }
    if (
      typeof docs?.auditedFinancial !== "string" &&
      docs?.auditedFinancial !== null
    ) {
      formData.set("audited_financial_statement", docs?.auditedFinancial);
    }
    if (
      typeof docs?.auditedFinancial2 !== "string" &&
      docs?.auditedFinancial2 !== null
    ) {
      formData.set("audited_financial_statement_2", docs?.auditedFinancial2);
    }
    if (
      typeof docs?.auditedFinancial3 !== "string" &&
      docs?.auditedFinancial3 !== null
    ) {
      formData.set("audited_financial_statement_3", docs?.auditedFinancial3);
    }
    if (
      typeof docs?.cancelledChequeDom !== "string" &&
      docs?.cancelledChequeDom !== null
    ) {
      formData.set("dom_cancel_chaque", docs?.cancelledChequeDom);
    }
    if (
      typeof docs?.cancelledChequeDom2 !== "string" &&
      docs?.cancelledChequeDom2 !== null
    ) {
      formData.set("dom_cancel_chaque_2", docs?.cancelledChequeDom2);
    }
    if (
      typeof docs?.gstRegistrationNo !== "string" &&
      docs?.gstRegistrationNo !== null
    ) {
      formData.set("gst_certificate", docs?.gstRegistrationNo);
    }
    if (
      typeof docs?.cancelledChequeIntl !== "string" &&
      docs?.cancelledChequeIntl !== null
    ) {
      formData.set("internation_cancel_chaque", docs?.cancelledChequeIntl);
    }
    if (
      typeof docs?.cancelledChequeIntl2 !== "string" &&
      docs?.cancelledChequeIntl2 !== null
    ) {
      formData.set("internation_cancel_chaque_2", docs?.cancelledChequeIntl2);
    }
    if (
      typeof docs?.internalAuditReport !== "string" &&
      docs?.internalAuditReport !== null
    ) {
      formData.set("internal_audit_reports", docs?.internalAuditReport);
    }
    if (
      typeof docs?.impactAssessmentReport !== "string" &&
      docs?.impactAssessmentReport !== null
    ) {
      formData.set("impact_assessment_reports", docs?.impactAssessmentReport);
    }
    if (typeof docs?.ratingReport !== "string" && docs?.ratingReport !== null) {
      formData.set("rating_report", docs?.ratingReport);
    }
    if (
      typeof docs?.projectStatusReport !== "string" &&
      docs?.projectStatusReport !== null
    ) {
      formData.set("project_status", docs?.projectStatusReport);
    }
    if (
      typeof docs?.projectCompletionReport !== "string" &&
      docs?.projectCompletionReport !== null
    ) {
      formData.set("project_completion", docs?.projectCompletionReport);
    }
    if (typeof docs?.caseStudy !== "string" && docs?.caseStudy !== null) {
      formData.set("case_study_and_testimonials", docs?.caseStudy);
    }

    if (ngoDocs?.panCardCert === undefined) {
      postRequest(
        `ngo/ngo_docs_images/${localStorage.getItem("ngo_slug")}/`,
        formData
      )
        .then((res) => {
          if (res?.data) {
            alert.success("Docs uploaded Successfully");
            history.push("/ngo/profile-final");
          } else {
            // alert.error("Something Went Wrong");
          }
          // setLoading(false);
        })
        .catch((err) => {
          // setLoading(false);
          // alert.error("Something Went Wrong");
        });
    } else {
      const formData1 = new FormData();

      if (typeof docs?.panCardCert !== "string" && docs?.panCardCert !== null) {
        formData1.set("pan_card_certificate", docs?.panCardCert);
      }
      if (
        typeof docs?.registrationNo !== "string" &&
        docs?.registrationNo !== null
      ) {
        formData1.set("ngo_registration_id", docs?.registrationNo);
      }
      if (
        typeof docs?.certificate12a !== "string" &&
        docs?.certificate12a !== null
      ) {
        formData1.set("_12_a_certificate", docs?.certificate12a);
      }
      if (
        typeof docs?.certificate80G !== "string" &&
        docs?.certificate80G !== null
      ) {
        formData1.set("_80_g_certificate", docs?.certificate80G);
      }
      if (
        typeof docs?.certificateFcra !== "string" &&
        docs?.certificateFcra !== null
      ) {
        formData1.set("_FCRA_certificate", docs?.certificateFcra);
      }
      if (typeof docs?.itrCopy !== "string" && docs?.itrCopy !== null) {
        formData1.set("_ITR_certificate", docs?.itrCopy);
      }
      if (typeof docs?.itrCopy2 !== "string" && docs?.itrCopy2 !== null) {
        formData1.set("_ITR_2_certificate", docs?.itrCopy2);
      }
      if (typeof docs?.itrCopy3 !== "string" && docs?.itrCopy3 !== null) {
        formData1.set("_ITR_3_certificate", docs?.itrCopy3);
      }
      if (
        typeof docs?.annualReport !== "string" &&
        docs?.annualReport !== null
      ) {
        formData1.set("annual_report", docs?.annualReport);
      }
      if (
        typeof docs?.annualReport2 !== "string" &&
        docs?.annualReport2 !== null
      ) {
        formData1.set("annual_report_2", docs?.annualReport2);
      }
      if (
        typeof docs?.annualReport3 !== "string" &&
        docs?.annualReport3 !== null
      ) {
        formData1.set("annual_report_3", docs?.annualReport3);
      }
      if (
        typeof docs?.auditedFinancial !== "string" &&
        docs?.auditedFinancial !== null
      ) {
        formData1.set("audited_financial_statement", docs?.auditedFinancial);
      }
      if (
        typeof docs?.auditedFinancial2 !== "string" &&
        docs?.auditedFinancial2 !== null
      ) {
        formData1.set("audited_financial_statement_2", docs?.auditedFinancial2);
      }
      if (
        typeof docs?.auditedFinancial3 !== "string" &&
        docs?.auditedFinancial3 !== null
      ) {
        formData1.set("audited_financial_statement_3", docs?.auditedFinancial3);
      }
      if (
        typeof docs?.cancelledChequeDom !== "string" &&
        docs?.cancelledChequeDom !== null
      ) {
        formData1.set("dom_cancel_chaque", docs?.cancelledChequeDom);
      }
      if (
        typeof docs?.cancelledChequeDom2 !== "string" &&
        docs?.cancelledChequeDom2 !== null
      ) {
        formData1.set("dom_cancel_chaque_2", docs?.cancelledChequeDom2);
      }
      if (
        typeof docs?.gstRegistrationNo !== "string" &&
        docs?.gstRegistrationNo !== null
      ) {
        formData1.set("gst_certificate", docs?.gstRegistrationNo);
      }
      if (
        typeof docs?.cancelledChequeIntl !== "string" &&
        docs?.cancelledChequeIntl !== null
      ) {
        formData1.set("internation_cancel_chaque", docs?.cancelledChequeIntl);
      }
      if (
        typeof docs?.cancelledChequeIntl2 !== "string" &&
        docs?.cancelledChequeIntl2 !== null
      ) {
        formData1.set(
          "internation_cancel_chaque_2",
          docs?.cancelledChequeIntl2
        );
      }
      if (
        typeof docs?.internalAuditReport !== "string" &&
        docs?.internalAuditReport !== null
      ) {
        formData1.set("internal_audit_reports", docs?.internalAuditReport);
      }
      if (
        typeof docs?.impactAssessmentReport !== "string" &&
        docs?.impactAssessmentReport !== null
      ) {
        formData1.set(
          "impact_assessment_reports",
          docs?.impactAssessmentReport
        );
      }
      if (
        typeof docs?.ratingReport !== "string" &&
        docs?.ratingReport !== null
      ) {
        formData1.set("rating_report", docs?.ratingReport);
      }
      if (
        typeof docs?.projectStatusReport !== "string" &&
        docs?.projectStatusReport !== null
      ) {
        formData1.set("project_status", docs?.projectStatusReport);
      }
      if (
        typeof docs?.projectCompletionReport !== "string" &&
        docs?.projectCompletionReport !== null
      ) {
        formData1.set("project_completion", docs?.projectCompletionReport);
      }
      if (typeof docs?.caseStudy !== "string" && docs?.caseStudy !== null) {
        formData1.set("case_study_and_testimonials", docs?.caseStudy);
      }

      putRequest(
        `ngo/ngo_documents_update/${localStorage.getItem("ngo_slug")}/`,
        formData1
      )
        .then((res) => {
          if (res?.data) {
            alert.success("Docs uploaded Successfully");
            history.push("/ngo/profile-final");
          } else {
            // alert.error("Something Went Wrong");
          }
          // setLoading(false);
        })
        .catch((err) => {
          // setLoading(false);
          // alert.error("Something Went Wrong");
        });
    }
    // }
  }

  function validateDocs() {
    if (
      !docs?.itrCopy ||
      !docs?.cancelledChequeDom ||
      !docs?.cancelledChequeIntl ||
      !docs?.annualReport ||
      !docs?.gstRegistrationNo ||
      !docs?.auditedFinancial ||
      !docs?.registrationNo ||
      !docs?.panCardCert ||
      !docs?.certificate12a ||
      !docs?.certificateFcra ||
      !docs?.certificate80G
    ) {
      alert.error("Please add all compulsory documents");
      return false;
    }
    return true;
  }

  return (
    <>
      <DashboardLayout>
        <NgoRegTwoSection
          docs={docs}
          setDocs={setDocs}
          uploadDocs={uploadDocs}
          fromProfile={true}
        />
        {/* <h1>demo</h1> */}
      </DashboardLayout>
    </>
  );
}
