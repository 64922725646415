import {
  AppBar,
  Box,
  Button,
  Container,
  createStyles,
  makeStyles,
  Toolbar,
  Hidden,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect } from "react";

//   import Logo from "../../../assets/images/sidepanel/logo.png";
//   import CompanyNavbar_C from "../../../bundles/common/components/UI/CompanyNavbar_c";

const Details = () => {
  const classes = useStyles();
  const data = [
    {
      title: "Thematic Areas of Work",

      detail: [
        {
          text: `No Poverty - End poverty in all its forms everywhere`,
          subText: [
            "Income enhancement",
            "Coverage under Social Insurance/Protection",
            "Access to basic services like water, sanitation, electricity & clean cooking fuels and technologies",
            "Clothing and shelter",
            "Secure tenure rights to land and other forms of property",
          ],
        },
        {
          text: `Zero Hunger - End hunger, achieve food security and improved nutrition 
            and promote sustainable agriculture`,
          subText: [
            "Income enhancement",
            "Coverage under Social Insurance/Protection",
            "Access to basic services like water, sanitation, electricity & clean cooking fuels and technologies",
            "Clothing and shelter",
            "Secure tenure rights to land and other forms of property",
          ],
        },
      ],
    },
  ];

  // useEffect(() => {

  // }, []);

  return (
    <>
      <div
        style={{
          border: "1px solid #C4C4C4",
          borderRadius: "10px",
          padding: "30px",
        }}
      >
        <Fragment>
          <Box bgcolor="#ffffff">
            {data.map((item, index) => (
              <Box key={index}>
                <Typography className={classes.title3} variant="h6">
                  {item.title}
                </Typography>

                <ul>
                  {item.detail.map((item2, index2) => (
                    <Fragment key={index2}>
                      {item2.subtitle && (
                        <Typography
                          variant="body2"
                          color="primary"
                          className={classes.subtitle}
                        >
                          {item2.text}
                        </Typography>
                      )}{" "}
                      <li>
                        <div
                          key={index2}
                          className={classes.details}
                          dangerouslySetInnerHTML={{
                            __html: item2.text,
                          }}
                        />
                        {item2?.subText?.map((item3) => (
                          <ol style={{ listStyleType: "disc" }}>
                            <li>
                              <div
                                key={index2}
                                className={classes.details2}
                                dangerouslySetInnerHTML={{
                                  __html: item3,
                                }}
                              />
                            </li>
                          </ol>
                        ))}
                      </li>
                    </Fragment>
                  ))}
                </ul>
              </Box>
            ))}
          </Box>
        </Fragment>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      paddingLeft: "6px",
      paddingRight: "6px",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
      },
      marginTop: "60px",
    },
    EndPara: {
      paddingTop: 60,
      paddingBottom: 60,
      color: "#000",
      // fontSize: 14,
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",

      //   fontFamily: "Mark simonson proxima nova ,sans-serif",
    },
    title: {
      paddingTop: "20px",
      //fontSize: "28px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      //   fontWeight: "bold",
    },
    helpText: {
      // fontSize: "16px",
      fontStyle: "italic",
    },
    warningText: {
      // fontSize: "16px",
      //  fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    title2: {
      //  fontSize: "35px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
    },
    title3: {
      fontWeight: "bold",
    },
    details: {
      //fontSize: "13px",
      marginBottom: "8px",
      //fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      textAlign: "justify",
      color: theme.palette.primary.main,
    },
    details2: {
      //  fontSize: "13px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      textAlign: "justify",
      color: "#333",
    },
    subtitle: {
      //  fontSize: "22px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      marginTop: "3%",
    },
    appBar: {
      width: `100%`,
      background: "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      height: "36px",
      borderRadius: 4,
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
      backgroundColor: "#3282c4",
      color: "#ffffff",
      fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      "&:hover": {
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
        backgroundColor: "#3282c4",
      },
    },
    outlinedButton: {
      height: "36px",
      borderRadius: 4,
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
      border: "2px solid #3282c4",
      color: "#3282c4",

      //  fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      "&:hover": {
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
        backgroundColor: "#ffffff",
      },
    },
    buttonBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "12%",
    },
    "@media only screen and (max-width: 768px)": {
      buttonBox: {
        width: "48%",
      },
      details: {
        textAlign: "left",
      },
    },
  })
);

export default Details;
