import React, { useState, useEffect } from "react";
import Heading from "../../../Common/Heading/Heading";
import { Grid, makeStyles, Typography, Paper } from "@material-ui/core";
import Total from "../../../Cards/Total/Total";
import ngo from "../../../assets/png/team-work/ngo.jpg";
import { getRequest } from "../../../../apis/baseApi";

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: "10px",
    boxShadow: "-22px 25px 0px 0px",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)",
    borderRadius: "10px",
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(12),
      //paddingRight: 0,
      borderRadius: "10px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "50px",
      //paddingRight: 0,
      borderRadius: "10px",
    },
  },
  overflow: {
    boxShadow: "0px 0px 30px 0px rgb(0 0 0 / 15%)",
    padding: "103px 9px 5px 49px",
    borderRadius: "10px",
    background: "#fff",
    margin: "-305px 0px 0px -22px",
    fontSize: "14px",
    height: "190px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

export default function HighLight() {
  const classes = useStyles();
  const [highlightData, setHighlightData] = useState();

  useEffect(() => {
    getRequest("home_page/get_recent_highlight/")
      .then((res) => {
        if (res?.data?.status) {
          setHighlightData(res?.data?.data);
        }
      })
      .catch((err) => {});
  }, []);
  return (
    <>
      <Heading title={highlightData?.title} />
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item md={7} style={{ display: "flex", alignItems: "stretch" }}>
          <Grid container spacing={2} style={{ padding: "10px" }}>
            <Grid item md={6} sm={6}>
              <Total
                name={highlightData?.ammount ? highlightData?.ammount : 0}
                member={"Lakh Total Donations"}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Total
                name={
                  highlightData?.members
                    ? parseInt(highlightData?.members) - 20
                    : 0
                }
                member={"Members"}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Total
                name={highlightData?.donations ? highlightData?.donations : 0}
                member={"Donations"}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Total
                name={
                  highlightData?.ngo_partners ? highlightData?.ngo_partners : 0
                }
                member={"NGO Partners"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5}>
          <div>
            {/* <div className="img-container">
              <div className="img-inner">
                <div className="inner-skew"> */}
            <Paper
              className={classes.mainFeaturedPost}
              style={{ backgroundImage: `url(${highlightData?.image})` }}
            >
              <div className={classes.overlay} />
              <Grid container style={{ textAlign: "center" }}>
                <Grid item md={12}>
                  <div className={classes.mainFeaturedPostContent}>
                    <Typography
                      component="h1"
                      variant="h2"
                      color="inherit"
                      gutterBottom
                      style={{ fontWeight: 600 }}
                    >
                      {highlightData?.image_text}
                    </Typography>
                    {/* <Typography
                      variant="h2"
                      color="inherit"
                      style={{ fontWeight: 600 }}
                      paragraph
                    >
                      Social Events
                    </Typography> */}
                  </div>
                </Grid>
              </Grid>
            </Paper>

            <div className={classes.overflow} />
          </div>
        </Grid>
      </Grid>
      {/* <div className="a-box">
        <div className="img-container">
          <div className="img-inner">
            <div className="inner-skew">
              <img src="https://images.unsplash.com/photo-1584791097929-aa825fe8e1e1?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ" />
            </div>
          </div>
        </div>
        <div className="text-container"></div>
      </div> */}
    </>
  );
}
