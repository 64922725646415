import React from "react";
import CenterHeading from "../../Common/Heading/CenterHeading";
import Grid from "@material-ui/core/Grid";
import DirectorsPerson from "../../Cards/DonationPerson/DirectorsPerson";
import Team from "../../Cards/DonationPerson/Team";

function TeamSection() {
  return (
    <>
      <CenterHeading title="Board of Directors" />
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        {data.map((item) => (
          <Grid item md={3} sm={4} xs={6}>
            <Team name={item.title} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default TeamSection;

const data = [
  { title: `Mr. Ankur Mishra` },
  { title: `Mr. Nirmal Thakur` },
  { title: `Mrs. Mrinali Thakral` },
  { title: `Mr. Ankur Mishra` },
  { title: `Mr. Nirmal Thakur` },
  { title: `Mrs. Mrinali Thakral` },
  { title: `Mr. Ankur Mishra` },
  { title: `Mr. Nirmal Thakur` },
  { title: `Mrs. Mrinali Thakral` },
  { title: `Mr. Ankur Mishra` },
  { title: `Mr. Nirmal Thakur` },
  { title: `Mrs. Mrinali Thakral` },
];
