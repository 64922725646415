import {
	Paper,
	Typography,
	makeStyles,
	Link,
	alpha,
	Hidden,
} from "@material-ui/core";
import React from "react";
import door from "../assets/png/icon/Door.png";
import { useHistory } from "react-router-dom";
import SubMenu from "./SubMenu";
import DashBoardSubMenu from "./DashBoardSubMenu";

const useStyles = makeStyles((theme) => ({
	paper: {
		borderColor: theme.palette.primary.main,
		border: "1px solid ",
		boxShadow: "none",
		padding: "20px",
		borderRadius: "10px",
		backgroundColor: alpha(theme.palette.primary.main, 0.1),
		position: "sticky",
		top: "70px",
		[theme.breakpoints.down("sm")]: {
			border: "none",
			backgroundColor: "transparent",
		},
	},
	navlink: {
		color: "#94939F",
		marginBottom: "20px",
	},
}));

function UserDashBoardSidebar() {
	const history = useHistory();

	const classes = useStyles();
	return (
		<>
			<Paper className={classes.paper}>
				{/* {navLinks.map((item) => (
          <Link href={item.path}>
            <Typography variant="body1" key={item} className={classes.navlink}>
              {item.title}
            </Typography>
          </Link>
        ))} */}
				<div style={{ display: "flex", flexDirection: "column" }}>
					{" "}
					{navLinks &&
						navLinks.map((item, index) => {
							return <DashBoardSubMenu items={item} key={index} />;
						})}
				</div>
				<Hidden only={["sm", "xs"]}>
					<Link href="">
						<div
							style={{ display: "flex", cursor: "pointer", marginTop: "15px" }}
							onClick={() => {
								localStorage.clear();
								history.push("/home");
							}}
						>
							<Typography variant="h6" color="primary">
								LogOut
							</Typography>
							<img
								src={door}
								height="30px"
								alt="logout"
								style={{ marginLeft: "10px" }}
							/>
						</div>
					</Link>
				</Hidden>
			</Paper>
		</>
	);
}

export default UserDashBoardSidebar;

const navLinks = [
	{ title: `My Profile`, path: `/user/profile` },
	{ title: `My Philanthrophy Portfolio`, path: `/user/portfolio` },
	{
		title: `My recurring donation commitments`,
		path: `/user/recurring-commitments`,
	},
	// { title: `My News and Updates`, path: `/user/news-updates` },
	// { title: `My Notifications`, path: `/user/notifications` },
	// { title: `Downloads and Reports`, path: `/user/downloads-reports` },
	{ title: `Queries, Feedbacks and Complaints`, path: `/user/feedback` },
];
