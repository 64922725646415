import {
	Paper,
	makeStyles,
	alpha,
	Grid,
	Typography,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Table,
	Button,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
	paper: {
		boxShadow: "none",
		backgroundColor: alpha(theme.palette.primary.main, 0.1),
		padding: "25px",
		borderColor: theme.palette.primary.main,
		padding: "7px",
		borderRadius: "15px",
	},
	btn: {
		borderRadius: "8px",
		boxShadow: "none",
		color: "#fff",
		fontSize: "10",
		// marginTop: "50px",
	},
}));
const RecurringPayments = () => {
	const classes = useStyles();
	const rows = [
		{
			ngoName: "Charity for Elderly",
			amount: 500,
			nextAmountDueDate: "2024-04-10",
		},
		{
			ngoName: "Education for All Foundation",
			amount: 800,
			nextAmountDueDate: "2024-04-12",
		},
		{
			ngoName: "Healthcare Support Initiative",
			amount: 1200,
			nextAmountDueDate: "2024-04-15",
		},
		{
			ngoName: "Community Development Association",
			amount: 600,
			nextAmountDueDate: "2024-04-18",
		},
		{
			ngoName: "Wildlife Conservation Trust",
			amount: 1000,
			nextAmountDueDate: "2024-04-20",
		},
		{
			ngoName: "Disaster Relief Organization",
			amount: 1500,
			nextAmountDueDate: "2024-04-22",
		},
		{
			ngoName: "Women Empowerment Foundation",
			amount: 700,
			nextAmountDueDate: "2024-04-25",
		},
	];

	return (
		<>
			<Paper className={classes.paper}>
				<Grid container spacing={2}>
					<Grid item md={12}>
						<div style={{ padding: "20px" }}>
							<Typography variant="h5">Donation History</Typography>
							<br />
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>NGO</TableCell>
											<TableCell align="right">Amount</TableCell>
											<TableCell align="right">Next Due Date</TableCell>
											<TableCell align="right">Edit</TableCell>
											{/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row) => (
											<TableRow
												key={row.name}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 },
												}}
											>
												<TableCell component="th" scope="row">
													{row?.ngoName}
												</TableCell>
												<TableCell align="right">{row?.amount}</TableCell>
												<TableCell align="right">
													{row.nextAmountDueDate}
												</TableCell>
												<TableCell align="right">
													{/* <Button>Edit</Button> */}
													<Button
														variant="contained"
														color="primary"
														className={classes.btn}
														// onClick={() => history.push("/donate-now")}
													>
														Edit
													</Button>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
};

export default RecurringPayments;
