import React from "react";
import UserDashboardLayout from "../../components/Layout/UserDashboardLayout";
import FeedBackSection from "../../components/Section/DashBoard/FeedBackSection/FeedBackSection";
import RecurringPayments from "../../components/Section/DashBoard/RecurringPayments/RecurringPayments";

const MyRecurringCommitments = () => {
	return (
		<>
			<UserDashboardLayout>
				<RecurringPayments />
			</UserDashboardLayout>
		</>
	);
};

export default MyRecurringCommitments;
