import React from "react";
import RatingBanner from "../../components/Section/NgoRatingsection/RatingBanner";
import vactor from "../../components/assets/png/resource.png";
import {
  Grid,
  Paper,
  makeStyles,
  alpha,
  Button,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PublicLayout from "../../components/Layout/PublicLayout";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 40,
    borderRadius: 22,
    marginBottom: "70px",
    marginTop: "3%",
  },
  btn: {
    color: "#fff",
    boxShadow: "none",
    height: "60px",
    borderRadius: "8px",
  },
  text: {
    color: "#2B2D3F",
    textAlign: "left",
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },
}));

export default function BecomeMember() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <PublicLayout keyss={"involved"}>
    <>
      {/* {data.map((item) => (
        <RatingBanner
          title={item.heading}
          info={item.info}
          image={item.image}
        />
      ))} */}

<div>
        <Typography
          variant="h2"
          component="h1"
          color="primary"
          style={{ fontFamily: "Marcellus", textAlign: "center" }}
        >
          Become a Member
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          When you sign up to become a member of the Ikigai Portfolio platform, you also sign up to a commitment. A commitment to bring about a positive change in this world. You become part of a community of amazing people who care, people who do not blame others for what is wrong in this world but who take up the responsibility to change it for the better. You can track all your donations and their usage and impact through periodic updates and reports, as well as download receipts and tax exemption certificates whenever you want from your custom dashboard. You can get customised recommendations and can reach out to us for opportunities to volunteer, NGO visits, exposure and immersion experiences, purchase NGO products and services etc. We also engage our members by inviting them to our philanthropy events and feature our most active members on our website and other social media platforms. The platform also gives you the option to donate anonymously, if that is what you prefer. However, we encourage you to sign up so that you do not miss any of the benefits mentioned above and can do a lot more than simply donating money. 
As a member, you may choose the level of engagement and communication that you are comfortable with and we completely respect your decision and privacy.

        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={vactor} height="400px" className={classes.img} />
      </div>



      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={9}>
            <Typography variant="body2" className={classes.text} style={{fontSize: "28px"}}>
            Start your journey as an Ikigai Portfolio member today.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} style={{ display: "grid" }}>
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              onClick={() => {
                history.push("user-registration-social");
              }}
            >
              Become a Member
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
    </PublicLayout>
  );
}

const data = [
  {
    heading: "Become a Member",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum, ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo in tristique feugiat faucibus arcu tempor. Vestibulum, congue vitae eleifend nec maecenas tristique bibendum egestas fermentum. Cursus nunc vulputate cras felis suspendisse convallis nisl.",
    image: vactor,
  },
];
