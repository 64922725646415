import { makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRequest } from "../../../../apis/baseApi";
import {
  ageData,
  fundingData,
  geographicalScaleData,
  sizeData,
  specialInterestData,
  themeData,
  tierRating,
} from "../../../../constant";
import {
  categoriesListSelectorFn,
  cityStateSelectorFn,
} from "../../../../redux/commonReducer/selector";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #C4C4C4",
    boxShadow: "none",
    padding: "30px",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  btn: {
    color: "#fff",
    width: "100%",
    boxShadow: "0px 4px 40px 0px #C88FEF59",
    borderRadius: 8,
    height: 40,
  },
  googlebtn: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "30px",
    paddingLeft: "15px",
    paddingRight: "15px",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      border: "1px solid",
    },
  },
  fieldtext: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "10px",
    display: "flex",
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
  option: {
    fontSize: 13,
    "& > span": {
      marginRight: 10,
      fontSize: 12,
    },
  },
  root: {
    padding: `0 ${theme.spacing(1)}px`,
  },
}));

export default function Filter({
  appliedFilter,
  setAppliedFilter,
  setApplyFilter,
}) {
  const [value, setValue] = React.useState("female");
  const [selectedCategory, setSelectedCategory] = useState();
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    if (selectedCategory) {
      getRequest(`ngo/ngo_sub_category_list/?categories=${selectedCategory}`)
        .then((res) => {
          if (res?.data?.data) {
            setAppliedFilter({
              ...appliedFilter,
              subcategories: "",
            })
            setSubcategories(res?.data?.data);
          }
        })
        .catch((err) => {});
    }
  }, [selectedCategory]);

  const categoryList = useSelector(categoriesListSelectorFn);
  const cityStateDetails = useSelector(cityStateSelectorFn);
  const [states, setStates] = useState([]);

  function getLabelSelected(data, value) {
    const item = data.find((opt) => {
      if (opt.name == value) return opt;
    });
    return item || {};
  }
  
  
  const getSelectedSubcategory = (data, value) => {
    if(data){
      const item = data?.find((opt)=>{
        if (opt?.id === value)
          return opt;
      })
      return item || {};
    }
    return {}
}

const getSelectedCategory = (data, value) => {
  if(data){
    const item = data?.find((opt)=>{
      if (opt?.id === value)
        return opt;
    })
    return item || {};
  }
  return {}
}

  useEffect(() => {
    if (cityStateDetails) {
      let temp = [];
      let tempId = [];
      cityStateDetails.forEach((item) => {
        if (!tempId.includes(item?.state?.id)) {
          temp.push(item?.state);
          tempId.push(item?.state?.id);
        }
      });
      setStates(temp);
    }
  }, [cityStateDetails]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Filter</Typography>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setAppliedFilter({
                byGeographicalScale: "",
                bySpecialInterest: "",
                byFunding: "",
                byThematicFocus: "",
                bySize: "",
                byAge: "",
                tier: "",
                categories: "",
                subcategories: "",
              });
              setApplyFilter(true);
            }}
          >
            <Typography variant="BODY2">Clear</Typography>
          </div>
        </div>
        <div>
          <div>
            <Typography variant="h6" color="initial">
              By Causes
            </Typography>
            <Autocomplete
              id="country-select-demo"
              style={{
                backgroundColor: "#fff",
                marginTop: "10px",
                marginBottom: "20px",
              }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={categoryList}
              getOptionLabel={(option) => option.category_name}
              // value={categoryList ? getSelectedCategory(categoryList, appliedFilter?.categories) : {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Select"
                  style={{ height: "30px" }}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(event, item) => {
                setSelectedCategory(item?.id);
                setSubcategories([]);

                setAppliedFilter({
                  ...appliedFilter,
                  categories: item?.id,
                  page: 1,
                });
              }}
            />
          </div>

          <div>
            <Typography variant="h6" color="initial">
              By Sub-Causes
            </Typography>
            <Autocomplete
              id="country-select-demo"
              style={{
                backgroundColor: "#fff",
                marginTop: "10px",
                marginBottom: "20px",
              }}
              classes={{
                option: classes.option,
              }}
              value={subcategories ? getSelectedSubcategory(subcategories, appliedFilter?.subcategories) : {}}
              autoHighlight
              options={subcategories}
              getOptionLabel={(option) => option.sub_category_name}
              disabled={
                subcategories && subcategories.length > 0 ? false : true
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Select"
                  style={{ height: "30px" }}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(event, item) => {
                setAppliedFilter({
                  ...appliedFilter,
                  subcategories: item?.id,
                  page: 1,
                });
              }}
            />
          </div>
          <div>
            <Typography variant="h6" color="initial">
              By Rating Tier
            </Typography>

            <Autocomplete
              id="country-select-demo"
              style={{
                backgroundColor: "#fff",
                marginTop: "10px",
                marginBottom: "20px",
              }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={tierRating}
              getOptionLabel={(option) => option.value}
              value={tierRating ? getLabelSelected(tierRating, appliedFilter?.tier) : {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Select"
                  style={{ height: "30px" }}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(event, item) => {
                setAppliedFilter({ ...appliedFilter, tier: item?.name });
              }}
            />
          </div>
          <div>
            <Typography variant="h6" color="initial">
              By Geographical Scale
            </Typography>

            <Autocomplete
              id="country-select-demo"
              style={{
                backgroundColor: "#fff",
                marginTop: "10px",
                marginBottom: "20px",
              }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={geographicalScaleData}
              getOptionLabel={(option) => option.value}
              value={geographicalScaleData ? getLabelSelected(geographicalScaleData, appliedFilter?.byGeographicalScale) : {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Select"
                  style={{ height: "30px" }}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(event, item) => {
                setAppliedFilter({
                  ...appliedFilter,
                  byGeographicalScale: item?.name,
                });
              }}
            />
          </div>
          <div>
            <Typography variant="h6" color="initial">
              By Special Interest
            </Typography>

            <Autocomplete
              id="country-select-demo"
              style={{
                backgroundColor: "#fff",
                marginTop: "10px",
                marginBottom: "20px",
              }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={specialInterestData}
              getOptionLabel={(option) => option.value}
              value={specialInterestData ? getLabelSelected(specialInterestData, appliedFilter?.bySpecialInterest) : {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="select"
                  style={{ height: "30px" }}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(event, item) => {
                setAppliedFilter({
                  ...appliedFilter,
                  bySpecialInterest: item?.name,
                  page: 1,
                });
              }}
            />
          </div>
          <div>
            <Typography variant="h6" color="initial">
              By Funding
            </Typography>

            <Autocomplete
              id="country-select-demo"
              style={{
                backgroundColor: "#fff",
                marginTop: "10px",
                marginBottom: "20px",
              }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={fundingData}
              value={fundingData ? getLabelSelected(fundingData, appliedFilter?.byFunding) : {}}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="select"
                  style={{ height: "30px" }}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(event, item) => {
                setAppliedFilter({
                  ...appliedFilter,
                  byFunding: item?.name,
                  page: 1,
                });
              }}
            />
          </div>
          <div>
            <Typography variant="h6" color="initial">
              By Thematic Focus
            </Typography>
            <Autocomplete
              id="country-select-demo"
              style={{
                backgroundColor: "#fff",
                marginTop: "10px",
                marginBottom: "20px",
              }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={themeData}
              value={themeData ? getLabelSelected(themeData, appliedFilter?.byThematicFocus) : {}}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="select"
                  style={{ height: "30px" }}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(event, item) => {
                setAppliedFilter({
                  ...appliedFilter,
                  byThematicFocus: item?.name,
                  page: 1,
                });
              }}
            />
          </div>

          <div>
            <Typography variant="h6" color="initial">
              By Size
            </Typography>
            <Autocomplete
              id="country-select-demo"
              style={{
                backgroundColor: "#fff",
                marginTop: "10px",
                marginBottom: "20px",
              }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={sizeData}
              value={sizeData ? getLabelSelected(sizeData, appliedFilter?.bySize) : {}}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Select"
                  style={{ height: "30px" }}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(event, item) => {
                setAppliedFilter({
                  ...appliedFilter,
                  bySize: item?.name,
                  page: 1,
                });
              }}
            />
          </div>
          <div>
            <Typography variant="h6" color="initial">
              By Age
            </Typography>
            <Autocomplete
              id="country-select-demo"
              style={{
                backgroundColor: "#fff",
                marginTop: "10px",
                marginBottom: "20px",
              }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={ageData}
              getOptionLabel={(option) => option.value}
              value={ageData ? getLabelSelected(ageData, appliedFilter?.byAge) : {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Select"
                  style={{ height: "30px" }}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(event, item) => {
                setAppliedFilter({
                  ...appliedFilter,
                  byAge: item?.name,
                  page: 1,
                });
              }}
            />
          </div>

          <div>
            <Typography variant="h6" color="initial">
              By State/Union Territory
            </Typography>

            <Autocomplete
              id="country-select-demo"
              style={{
                backgroundColor: "#fff",
                marginTop: "10px",
                marginBottom: "20px",
              }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={states}
              getOptionLabel={(option) => option.state_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="select"
                  style={{ height: "30px" }}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "district",
                  }}
                />
              )}
              onChange={(event, item) => {
                setAppliedFilter({
                  ...appliedFilter,
                  byState: item?.id,
                  page: 1,
                });
              }}
            />
          </div>
        </div>
      </Paper>
    </>
  );
}
