import Heading from "../../Common/Heading/Heading";
import React, { Fragment, useEffect } from "react";
import {
  Typography,
  Box,
  Hidden,
  makeStyles,
  Container,
  createStyles,
  Grid,
  Button,
  Paper,
} from "@material-ui/core";
import demo from "../../assets/png/browser-stats/rafiki.png";
import CategorySection from "../DashBoard/CategorySection/CategorySection";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    boxShadow: "none",
    marginTop: "20px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    borderRadius: "8px",
    width: "300px",
    boxShadow: "0px 4px 40px 0px #C88FEF73",
    marginLeft: "30px"
  },
}));

function Introduction() {
  const classes = useStyles();
  
  return (
    <>
      <div style={{ marginTop: "10%" }}>
        <Heading title="Causes" />
        <Paper component="ul" className={classes.root}>
          
          <CategorySection />
        </Paper>
      </div>
    </>
  );
}

export default Introduction;
