import React from "react";
import { Typography } from "@material-ui/core";
import vactor from "../../assets/png/resource.png";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: "30%",
    [theme.breakpoints.only("sm")]: {
      width: "auto",
      height: "200px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "auto",
      height: "100px",
    },
  },
}));

export default function RatingBanner(props) {
  const classes = useStyles();
  return (
    <>
      <div>
        <Typography
          variant="h2"
          component="h1"
          color="primary"
          style={{ fontFamily: "Marcellus", textAlign: "center" }}
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          NGO Rating is one of Ikigai Portfolio’s most crucial offerings and also one of the aspects that differentiates it from most other philanthropy and crowdfunding platforms. 
Rating ensures that only NGOs of a certain quality benchmark are part of the Partner NGO Pool. In addition to credibility, it adds transparency to the NGO Partner Pool with the scores made publically available on the platform. The rating scores, findings and analyses are provided as one of the tools to the donors to aid them to make an informed decision on which NGO to donate to.   
<br></br>
Ikigai Portfolio makes it mandatory for NGOs to get rated before they can be onboarded to its NGO Partner Pool. NGOs must score over a predefined threshold in their rating to be eligible to be part of the NGO Partner Pool. 
<br></br>
The rating tool used for the NGO rating exercise is a scientifically developed quantitative tool. It uses a number of components and indicators and assigns weightages to each component and indicator to arrive at the final component and overall score for each NGO. Ikigai Portfolio also follows a very rigorous and robust rating process that ensures that the scores are accurate, objective and standardised.
<br></br><b>Limitations of the Rating:</b><br></br>
It is important to understand that the rating, including its scores, findings and analyses, while being useful and a good indicator of the quality of the NGO, may not always be able to determine the same. While most times one can gauge the same, in certain cases, certain limitations to the rating may not reflect the true picture. Donors must keep these limitations in mind when making a donation decision based on the NGO Rating and must also use other filters and criteria in combination with the rating score and analyses. These limitations could be insufficient/inaccurate data/information provided by the NGO and certified by a relevant certifying agency, difference in scores due to a difference in age, size, project locations, complexity of beneficiary groups etc., certain subjective calls on weightages of components and indicators etc. It may also be noted that the rating is done for a period in the past and based on the documents and evidence provided pertaining to that period. If there are any substantial changes between that period and the time at which the rating is conducted or post the rating, the rating scores, findings and analyses may not provide the complete updated picture. 
          {/* {props.info} */}
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={props.image} height="400px" className={classes.img} />
      </div>
    </>
  );
}
