import React, { useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  makeStyles,
  Button,
} from "@material-ui/core";
import upload from "../../../assets/svg/upload.svg";

const useStyles = makeStyles((theme) => ({
  download_main_grid: {
    display: "flex",
    alignItems: "center",
  },
  download: {
    boxShadow: "none",
    textDecoration: "underline",
  },

  approve_main: {
    border: "2px solid #1DDD48",
    borderRadius: "30px",
    height: "30px",
    display: "flex",
    padding: "2px",
    alignItems: "center",
    width: "100%",
  },
  approve: {
    width: "100%",
    borderRadius: "30px",
    boxShadow: "none",
    padding: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "#1DDD48",
    "&:hover": {
      backgroundColor: "rgb(7, 177, 77, 0.42)",
      boxShadow: "none",
    },
  },

  reject_main: {
    border: "2px solid #FF3737",
    borderRadius: "30px",
    height: "30px",
    display: "flex",
    padding: "2px",
    alignItems: "center",
    width: "100%",
  },
  reject: {
    width: "100%",
    borderRadius: "30px",
    boxShadow: "none",
    padding: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "#FF3737",
    "&:hover": {
      backgroundColor: "rgb(7, 177, 77, 0.42)",
      boxShadow: "none",
    },
  },
}));

export default function ReportUploadedFieldGrid({item}) {
  const classes = useStyles();

  return (
    <>
      <div>
        <Grid container spacing={2}>
          <Grid item md={3} style={{ display: "grid" }}>
            <TextField
              rows={3}
              multiline
              variant="outlined"
              placeholder="Lorem Ipsum"
              color="primary"
              style={{ borderRadius: "8px" }}
              disabled
              value={item?.name}
            />
          </Grid>
          <Grid item md={6} style={{ display: "grid" }}>
            <TextField
              rows={3}
              multiline
              variant="outlined"
              placeholder="Lorem Ipsum"
              color="primary"
              style={{ borderRadius: "8px" }}
              disabled
              value={item?.content}
            />
          </Grid>

          <Grid item md={3} className={classes.download_main_grid}>
            <Grid container spacing={2}>
              <Grid item md={6} style={{ display: "grid" }}>
                <Button
                  color="primary"
                  variant="text"
                  className={classes.download}
                  onClick={() => {
                    window.open(item?.doc, "_blank")
                  }}
                  disabled={item?.doc ? false : true}
                >
                  {item?.doc ? "Click Here" : "N/A"}
                </Button>
              </Grid>
              <Grid item md={6}>
                <div
                  className={
                    !item.is_active
                      ? classes.reject_main
                      : classes.approve_main
                  }
                >
                  <Button
                    variant="contained"
                    className={!item?.is_active ? classes.reject : classes.approve}
                  >
                    <Typography
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                      }}
                    >
                      { item?.is_active ? "APPROVED" : "REJECTED"}
                    </Typography>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
