import {
  Button,
  Dialog,
  Divider,
  InputAdornment,
  Slide,
  TextField,
  withStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Sicon from "../assets/png/icon/search.png";
import Ficon from "../assets/png/filter.png";
import { getRequest } from "../../apis/baseApi";
import Filter from "../Common/InputField/Filter/Filter";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  root: {
    margin: 0,

    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  search_btn: {
    display: "flex",
    padding: "20px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "-20px",
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogFilter({
  appliedFilter,
  setAppliedFilter,
  setApplyFilter,
}) {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (searchTerm) {
      // debouncedSave(searchTerm);
      getSearchNgo();
    }
  }, [searchTerm]);

  function getSearchNgo() {
    if (searchTerm) {
      getRequest(`ngo/ngo_list_filter/?ngo_name=${searchTerm}`)
        .then((res) => {
          setData(res?.data?.results);
        })
        .catch((err) => {});
    }
  }

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        color="inherit"
        onClick={handleClickOpen}
        style={{ padding: "0px" }}
      >
        <img src={Ficon} height="20px" />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Filter
        </DialogTitle>
        <div className={classes.search_btn}>
          <Filter
            appliedFilter={appliedFilter}
            setAppliedFilter={setAppliedFilter}
            setApplyFilter={setApplyFilter}
          />
        </div>

        <Divider />
      </Dialog>
    </div>
  );
}
