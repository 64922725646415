import React from "react";
import DashboardLayout from "../../../components/Layout/DashBoardLayout";

import ReportGrid from "../../../components/Section/DashBoard/Report/ReportGrid";

export default function Report() {
  return (
    <>
      <DashboardLayout>
        <ReportGrid />
      </DashboardLayout>
    </>
  );
}
