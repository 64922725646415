import Heading from "../../Common/Heading/Heading";
import React, { Fragment, useEffect } from "react";
import {
  Typography,
  Box,
  Hidden,
  makeStyles,
  Container,
  createStyles,
  Grid,
} from "@material-ui/core";
import demo from "../../assets/png/browser-stats/rafiki.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    cardimg: {
      [theme.breakpoints.only("sm")]: {
        width: "auto",
        height: "200px",
      },
      [theme.breakpoints.only("xs")]: {
        width: "auto",
        height: "300px",
      },
    },
    title: {
      paddingTop: "20px",
    },

    title2: {
      //  fontSize: "35px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
    },
    title3: {
      //fontSize: "22px",
      //fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      marginTop: "3%",
    },
    details: {
      //fontSize: "13px",
      marginBottom: "8px",
      //fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      textAlign: "justify",
      color: "#333",
    },
    details2: {
      //  fontSize: "13px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      textAlign: "justify",
      color: "#333",
    },
    gridimg: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

function Vision() {
  const classes = useStyles();
  return (
    <>
      <div style={{ marginTop: "10%" }}>
        {data.map((item, index) => (
          <Box paddingTop="2%" key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Hidden only={["sm", "xs"]}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "40px",
                    }}
                  >
                    <Typography
                      variant="h2"
                      style={{ fontFamily: "Marcellus" }}
                    >
                      {item.title}
                    </Typography>
                    <div
                      style={{
                        backgroundColor: "#C4C4C4",
                        height: "1px",
                        width: "40%",
                        marginLeft: "50px",
                      }}
                    />
                  </div>
                </Hidden>
                <Hidden only={["md", "lg", "xl"]}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      style={{ fontFamily: "Marcellus" }}
                    >
                      {item.title}
                    </Typography>
                    <div
                      style={{
                        backgroundColor: "#C4C4C4",
                        height: "1px",
                        width: "40%",
                        marginLeft: "50px",
                      }}
                    />
                  </div>
                </Hidden>
                {/* <ul style={{ paddingInlineStart: "15px" }}>
                  {item.detail.map((item2, index2) => (
                    <Fragment key={index2}>
                      <li>
                        <div key={index2} className={classes.details}>
                          <Typography variant="body2">{item2.text}</Typography>
                        </div>
                      </li>
                    </Fragment>
                  ))}
                </ul> */}
                <Typography variant="body2">
                To become the most trusted crowdfunding platform in India mobilising INR 250 crores in donations from 50,000 donors, deploying them into 100 rigorously rated NGOs to achieve demonstrable evidence based positive social impact by 2025 
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridimg}>
                <img
                  src={item.image}
                  height="400px"
                  className={classes.cardimg}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
      </div>
    </>
  );
}

export default Vision;

const data = [
  {
    title: "Vision",
    image: demo,
    detail: [
      {
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum, ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo in tristique feugiat faucibus arcu tempor. Vestibulum, congue vitae eleifend nec maecenas tristique bibendum egestas fermentum. Cursus nunc vulputate cras felis suspendisse convallis nisl.`,
      },
      {
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum, ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo in tristique feugiat faucibus arcu tempor.`,
      },

      {
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum, ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo in tristique feugiat faucibus arcu tempor.`,
      },
    ],
  },
];
