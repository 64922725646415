import { alpha, makeStyles, Typography, Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getRequest } from "../../apis/baseApi";
import { useAlert } from "react-alert";
import SubCategorySection from "../../components/Section/DashBoard/CategorySection/SubCategorySection";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  learn: {
    paddingLeft: "40px",
    paddingRight: "40px",
    color: "#fff",
    height: "45px",
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
  },
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
    marginBottom: "70px",
  },
  btn: {
    paddingLeft: "15px",
    paddingRight: "15px",
    marginTop: "8px",
    paddingBottom: "8px",
    color: "#fff",
    borderRadius: "8px",
    // boxShadow: "0px 10px 40px 0px #C88FEF73",
    marginLeft: "20px",
    // minWidth: "175px",
  },
}));

export default function NGOCause() {
  const classes = useStyles();
  const location = useLocation();
  const alert = useAlert();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const [data, setData] = useState();

  const causeId = params.get("causeId");
  const causeName = params.get("causeName");

  useEffect(() => {
    if (causeId) {
      getRequest("ngo/ngo_sub_category_list/?categories=" + causeId)
        .then((res) => {
          if (res?.data?.data) setData(res?.data?.data);
          // else alert.error("Something went wrong!!");
        })
        .catch((err) => {
          // alert.error("Something went wrong!!");
        });
    }
  }, [causeId]);

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Typography
          variant="h2"
          component="h1"
          color="primary"
          style={{ fontFamily: "Marcellus", textAlign: "center" }}
        >
          {causeName}
        </Typography>
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            marginBottom: "30px",
            justifyContent: "center",
          }}
        >
          {/* <div style={{
                      border: "2px solid #C88FEF",
                      borderRadius: "50%",
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      padding: "2px",
                      alignItems: "center",
                      marginRight: "20px",
                      justifyContent: "center"
                    }}
                    >
                      
                            0
                      
                      </div>
                      <div style={{
                      border: "2px solid #C88FEF",
                      borderRadius: "50%",
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      padding: "2px",
                      marginRight: "20px",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                    >
                            Rs. 0
                      </div>
                      <div style={{
                      border: "2px solid #C88FEF",
                      borderRadius: "50%",
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      padding: "2px",
                      marginRight: "20px",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                    >
                            0
                      </div>
                      <div style={{
                      border: "2px solid #C88FEF",
                      borderRadius: "50%",
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      padding: "2px",
                      marginRight: "20px",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                    >
                            0
                      </div> */}

          <div>
            <div
              style={{
                border: "2px solid #C88FEF",
                borderRadius: "50%",
                height: "100px",
                width: "100px",
                display: "flex",
                padding: "2px",
                alignItems: "center",
                marginRight: "20px",
                justifyContent: "center",
              }}
            >
              0
            </div>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                fontSize: "14px",
                paddingRight: "15px",
                paddingTop: "10px",
              }}
            >
              NGOs
            </div>
          </div>
          <div>
            <div
              style={{
                border: "2px solid #C88FEF",
                borderRadius: "50%",
                height: "100px",
                width: "100px",
                display: "flex",
                padding: "2px",
                marginRight: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Rs. 0
            </div>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                fontSize: "14px",
                paddingRight: "15px",
                paddingTop: "10px",
              }}
            >
              Funds Mobilised
            </div>
          </div>

          <div>
            <div
              style={{
                border: "2px solid #C88FEF",
                borderRadius: "50%",
                height: "100px",
                width: "100px",
                display: "flex",
                padding: "2px",
                marginRight: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              0
            </div>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                fontSize: "14px",
                paddingRight: "15px",
                paddingTop: "10px",
              }}
            >
              Efficiency Score
            </div>
          </div>

          <div>
            <div
              style={{
                border: "2px solid #C88FEF",
                borderRadius: "50%",
                height: "100px",
                width: "100px",
                display: "flex",
                padding: "2px",
                marginRight: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              0
            </div>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                fontSize: "14px",
                paddingTop: "10px",
              }}
            >
              Effectiveness Score
            </div>
          </div>
        </div>

        <SubCategorySection subCategoriesList={data} />
        <div style={{ marginTop: "30px" }}>
          <Button
            color="primary"
            variant="contained"
            className={classes.btn}
            onClick={(event) => {
              event.stopPropagation();
              history.push("/about-us-support");
            }}
          >
            Back to Causes
          </Button>
        </div>
      </div>
    </>
  );
}
