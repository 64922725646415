import {
	alpha,
	Button,
	Chip,
	Grid,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PiaChart from "../../../Common/Graph/PieChart";

const useStyles = makeStyles((theme) => ({
	paper: {
		boxShadow: "none",
		backgroundColor: alpha(theme.palette.primary.main, 0.1),
		padding: "25px",
		borderColor: theme.palette.primary.main,
		padding: "7px",
		borderRadius: "15px",
	},
	btn: {
		borderRadius: "8px",
		boxShadow: "none",
		color: "#fff",
		marginTop: "50px",
	},
}));

export default function DonationHistory() {
	const classes = useStyles();
	// function createData(name, calories, fat, carbs, protein) {
	// 	return { name, calories, fat, carbs, protein };
	// }

	// const rows = [
	// 	createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
	// 	createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
	// 	createData("Eclair", 262, 16.0, 24, 6.0),
	// 	createData("Cupcake", 305, 3.7, 67, 4.3),
	// 	createData("Gingerbread", 356, 16.0, 49, 3.9),
	// ];

	const rows = [
		{
			ngoName: "Charity Aid Foundation",
			dateOfDonation: "2023-05-12",
			amount: 5000,
			form80G: "https://example.com/form80g1234",
		},
		{
			ngoName: "Save the Children",
			dateOfDonation: "2023-08-27",
			amount: 10000,
			form80G: "https://example.com/form80g5678",
		},
		{
			ngoName: "Doctors Without Borders",
			dateOfDonation: "2023-11-03",
			amount: 8000,
			form80G: "https://example.com/form80g91011",
		},
	];

	return (
		<>
			<Paper className={classes.paper}>
				<Grid container spacing={2}>
					<Grid item md={12}>
						<div style={{ padding: "20px" }}>
							<Typography variant="h5">Donation History</Typography>
							<br />
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>NGO</TableCell>
											<TableCell align="right">Date</TableCell>
											<TableCell align="right">Amount</TableCell>
											<TableCell align="right">80G</TableCell>
											{/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row) => (
											<TableRow
												key={row.name}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 },
												}}
											>
												<TableCell component="th" scope="row">
													{row?.ngoName}
												</TableCell>
												<TableCell align="right">
													{row?.dateOfDonation}
												</TableCell>
												<TableCell align="right">{row.amount}</TableCell>
												<TableCell align="right">{row.form80G}</TableCell>
												{/* <TableCell align="right">{row.protein}</TableCell> */}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
}
