import {
	Button,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	TextField,
	Typography,
	Chip,
} from "@material-ui/core";
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useSelector } from "react-redux";
import { getRequest, putRequest } from "../../apis/baseApi";
import {
	ageData,
	CountriesData,
	EducationData,
	IncomeData,
	OccupationData,
	sizeData,
	specialInterestData,
} from "../../constant";
import {
	categoriesListSelectorFn,
	cityStateSelectorFn,
	StateSelectorFn,
} from "../../redux/commonReducer/selector";
import Calendar from "../Common/Calendar/Calendar";
import { useAlert } from "react-alert";
import CustomButtonCircularProgress from "../CircularProgress";

const ChipAutocomplete = withStyles({
	root: {
		"& .MuiOutlinedInput-root": {
			height: "100%",
		},
	},
})(Autocomplete);

const useStyles = makeStyles((theme) => ({
	paper: {
		backgroundColor: alpha(theme.palette.primary.main, 0.1),
		boxShadow: "none",
		textAlign: "center",
		padding: 20,
		borderRadius: 22,
	},
	btn: {
		color: "#fff",
		paddingLeft: "60px",
		paddingRight: "60px",
		boxShadow: "none",
		marginTop: "50px",
		marginBottom: "50px",
		borderRadius: 8,
		height: 46,
	},
	googlebtn: {
		boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
		backgroundColor: "#fff",
		padding: "10px",
		borderRadius: "30px",
		paddingLeft: "15px",
		paddingRight: "15px",
		"&:hover": {
			borderColor: theme.palette.primary.main,
			border: "1px solid",
		},
	},
	fieldtext: {
		fontSize: "12px",
		textAlign: "left",
		marginBottom: "10px",
		display: "flex",
	},
	reqfieldtext: {
		fontSize: "12px",
		textAlign: "left",
		display: "flex",
	},
}));

function UserProfileForm({ profileData }) {
	const classes = useStyles();

	const gender = [
		{ name: "male", value: "Male" },
		{ name: "female", value: "Female" },
		{ name: "others", value: "Others" },
	];
	const maritialStatus = [
		{ name: "married", value: "Married" },
		{ name: "unmarried", value: "Unmarried" },
	];
	const alert = useAlert();
	const states = useSelector(StateSelectorFn);
	const allCities = useSelector(cityStateSelectorFn);
	const causes = useSelector(categoriesListSelectorFn);
	const [subCauses, setSubCauses] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [city, setCity] = useState([]);
	const [edit, setEdit] = useState(false);
	const [selectedDate, setSelectedDate] = useState();
	const handleEdit = () => {
		setEdit(!edit);
	};
	useEffect(() => {
		if (selectedDate) {
			var a = moment(selectedDate);
			var b = moment(new Date());
			var d = b.diff(a, "years");
			var minor = false;
			if (d < 18) {
				minor = true;
			}
			setValues({
				...values,
				isMinor: minor,
				dateOfBirth: moment(selectedDate).format("YYYY-MM-DD"),
			});
		}
	}, [selectedDate]);

	const [values, setValues] = React.useState({
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		alternateEmail: "",
		alternatePhone: "",
		dateOfBirth: "",
		isMinor: false,
		guardianName: "",
		guardianRelation: "",
		guardianMobileNo: "",
		guardianEmail: "",
		guardianAddress: "",
		guardianProfession: "",
		address: "",
		line1: "",
		line2: "",
		city: "",
		state: "",
		pinCode: "",
		country: "",
		profession: "",
		occupation: "",
		maritialStatus: "",
		gender: "",
		causes: [],
		subCauses: [],
		ngoPreference: {},
		wantReceiveMail: false,
		wantVolunteer: false,
		eventInfo: false,
		weight: "",
		weightRange: "",
		showPassword: false,
		showConfirmPassword: false,
	});

	const [errorValues, setErrorValues] = React.useState({
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		password: "",
		confirmPassword: "",
		dateOfBirth: "",
		isMinor: "",
		guardianName: "",
		guardianRelation: "",
		guardianMobileNo: "",
		guardianEmail: "",
		guardianAddress: "",
		guardianProfession: "",
		address: "",
		line1: "",
		lin2: "",
		pinCode: "",
		city: "",
		country: "",
		profession: "",
	});

	useEffect(() => {
		if (profileData) {
			let causesData = [],
				subCausesData = [];
			profileData?.prefer_cat?.forEach((item) => {
				causesData.push(item?.id);
			});
			profileData?.prefer_subcategories?.forEach((item) => {
				subCausesData.push(item?.id);
			});
			setValues({
				firstName: profileData?.user?.first_name,
				lastName: profileData?.user?.last_name,
				email: profileData?.user?.email,
				phone: profileData?.phone,
				alternatePhone: profileData?.alternative_phone,
				alternateEmail: profileData?.alternative_email,
				password: "",
				confirmPassword: "",
				dateOfBirth: profileData?.dob,
				isMinor: profileData?.is_user_minor,
				guardianName: profileData?.gaurdian_name,
				guardianRelation: profileData?.relation,
				guardianMobileNo: profileData?.gaurdian_phone,
				guardianEmail: profileData?.gaurdian_email,
				guardianAddress: profileData?.gaurdian_address,
				guardianProfession: profileData?.gaurdian_profession,
				address: profileData?.address,
				line1: profileData?.line1,
				line2: profileData?.line2,
				city: profileData?.city,
				country: profileData?.country,
				pinCode: profileData?.pinCode,
				profession: profileData?.profession_details,
				weight: "",
				weightRange: "",
				showPassword: false,
				showConfirmPassword: false,
				wantReceiveMail: profileData?.want_to_recieve_mail,
				wantVolunteer: profileData?.want_to_volunteer,
				eventInfo: profileData?.event_info,
				ngoPreference: profileData?.nog_prefer,
				maritialStatus: profileData?.maritial_status,
				gender: profileData?.gender,
				causes: causesData,
				subCauses: subCausesData,
				incomeRange: profileData?.income_range,
				state: profileData?.state,
				education: profileData?.education,
			});
			setSelectedDate(profileData?.dob);
		}
	}, [profileData]);

	useEffect(() => {
		if (states) {
			const item1 = states?.find((item) => {
				if (item?.name === profileData?.state) return item?.city;
			});
			setCity(item1?.city || []);
		}
	}, [states, profileData?.state]);

	const [isError, setIsError] = React.useState(false);

	const handleChange = (prop) => (event) => {
		console.log("dsddd", event);
		setValues({ ...values, [prop]: event.target.value });
		setErrorValues({ ...errorValues, [prop]: "" });
	};
	console.log("nbvcvbn", values);
	const getCausesList = () => {
		if (causes) {
			const aa = causes.filter((item) => {
				if (values?.causes && values?.causes.includes(item?.id)) return true;
			});
			return aa;
		}
	};

	const getSubCausesList = () => {
		if (subCauses) {
			const aa = subCauses.filter((item) => {
				if (values?.subCauses && values?.subCauses.includes(item?.id))
					return true;
			});
			return aa;
		}
	};

	useEffect(() => {
		if (values?.causes && values?.causes?.length > 0) {
			const url = values?.causes.join(",");
			getRequest("ngo/ngo_sub_category_list/?categories=" + url)
				.then((res) => {
					if (res?.data?.data) setSubCauses(res?.data?.data);
					// else alert.error("Something went wrong");
				})
				.catch((err) => {
					// alert.error("Something went wrong");
				});
		}
	}, [values?.causes]);

	const validateForm = () => {
		let error = false;
		let firstNameError = "";
		let lastNameError = "";
		let phoneError = "";
		let emailError = "";
		let passwordError = "";
		let confirmPasswordError = "";
		let dateOfBirthError = "";
		let guardianNameError = "";
		let guardianRelationError = "";
		let guardianMobileNoError = "";
		let guardianEmailError = "";
		let guardianAddressError = "";
		let guardianProfessionError = "";
		let addressError = "";
		let cityError = "";
		let countryError = "";
		let professionError = "";
		let genderError = "";
		let line1Error = "";
		let line2Error = "";
		let pinCode = "";

		if (!values?.firstName) {
			firstNameError = "*Please Enter First Name";
			setIsError(true);
			error = true;
		}
		if (!values?.lastName) {
			lastNameError = "*Please Enter Last Name";
			setIsError(true);
			error = true;
		}
		if (!values?.phone) {
			phoneError = "*Please Enter Phone No.";
			setIsError(true);
			error = true;
		}
		if (!values?.email) {
			emailError = "*Please Enter Email Id";
			setIsError(true);
			error = true;
		}
		if (!values?.dateOfBirth) {
			dateOfBirthError = "*Please Enter Date of Birth";
			setIsError(true);
			error = true;
		}
		if (!values?.line1) {
			dateOfBirthError = "*Please Enter Address Line 1";
			setIsError(true);
			error = true;
		}
		if (!values?.pinCode) {
			dateOfBirthError = "*Please Enter Pin Code";
			setIsError(true);
			error = true;
		}
		//   if(values?.isMinor){
		//   if(!values?.guardianName)
		//   {
		//     guardianNameError = "*Please Enter Guardian's Name";
		//     setIsError(true);
		//     error = true;
		//   }
		//   if(!values?.guardianEmail)
		//   {
		//     guardianEmailError = "*Please Enter Guardian's Email";
		//     setIsError(true);
		//     error = true;
		//   }
		//   if(!values?.guardianRelation)
		//   {
		//     guardianRelationError = "*Please Enter Guardian's Relation";
		//     setIsError(true);
		//     error = true;
		//   }
		//   if(!values?.guardianMobileNo)
		//   {
		//     guardianMobileNoError = "*Please Enter Guardian's Mobile No.";
		//     setIsError(true);
		//     error = true;
		//   }
		//   if(!values?.guardianAddress)
		//   {
		//     guardianAddressError = "*Please Enter Guardian's Address";
		//     setIsError(true);
		//     error = true;
		//   }
		//   if(!values?.guardianProfession)
		//   {
		//     guardianProfessionError = "*Please Enter Guardian's Profession.";
		//     setIsError(true);
		//     error = true;
		//   }
		// }
		// if(!values?.city)
		//   {
		//     cityError = "*Please Enter City";
		//     setIsError(true);
		//     error = true;
		//   }

		if (!values?.country) {
			countryError = "*Please Enter Country";
			setIsError(true);
			error = true;
		}

		//   if(!values?.address)
		//   {
		//     addressError = "*Please Enter Address";
		//     setIsError(true);
		//     error = true;
		//   }
		//   if(!values?.profession)
		//   {
		//     professionError = "*Please Enter Profession";
		//     setIsError(true);
		//     error = true;
		//   }
		setErrorValues({
			firstName: firstNameError,
			lastName: lastNameError,
			email: emailError,
			phone: phoneError,
			password: passwordError,
			profession: professionError,
			guardianEmail: guardianEmailError,
			guardianMobileNo: guardianMobileNoError,
			guardianRelation: guardianRelationError,
			guardianName: guardianNameError,
			address: addressError,
			profession: professionError,
			guardianAddress: guardianAddressError,
			guardianProfession: guardianProfessionError,
			city: cityError,
			country: countryError,
			dateOfBirth: dateOfBirthError,
		});
		return error ? false : true;
	};

	const onProfileUpate = () => {
		setEdit(false);
		const postData = {
			address: values?.address,
			first_name: values?.firstName,
			last_name: values?.lastName,
			phone: values?.phone,
			email: values?.email,
			alternative_phone: values?.alternatePhone ? values?.alternatePhone : null,
			alternative_email: values?.alternateEmail ? values?.alternateEmail : null,
			education: values?.education,
			dob: values?.dateOfBirth,
			gaurdian_name: values?.guardianName,
			gaurdian_address: values?.guardianAddress,
			gaurdian_email: values?.guardianEmail,
			gaurdian_phone: values?.guardianMobileNo,
			gaurdian_profession: values?.guardianProfession,
			gender: values?.gender,
			income_range: values?.incomeRange,
			is_user_minor: values?.isMinor,
			maritial_status: values?.maritialStatus,
			nog_prefer: values?.ngoPreference,
			prefer_cat: values?.causes ? [values?.causes?.join(",")] : [],
			prefer_subcategories: values?.subCauses
				? [values?.subCauses?.join(",")]
				: [],
			profession_details: values?.profession,
			relation: values?.guardianRelation,
			state: values?.state,
			city: values?.city,
			country: values?.country,
			want_to_recieve_mail: values?.wantReceiveMail,
			want_to_volunteer: values?.wantVolunteer,
			event_info: values?.eventInfo,
			line1: values?.line1,
			line2: values?.line2,
			pinCode: values?.pinCode,
		};
		setIsLoading(true);
		putRequest(
			`authentication/user_profile/${localStorage.getItem("slug")}/`,
			postData
		)
			.then((res) => {
				if (res?.data?.data) {
					alert.success("Profile Updated successfully!!");
				} else {
					// alert.error("Something went wrong!");
				}
				setIsLoading(false);
			})
			.catch((err) => {
				// alert.error("Something went wrong!");
				setIsLoading(false);
			});
	};

	function getLabelSelected(data, value) {
		const item = data.find((opt) => {
			if (opt.name == value) return opt;
		});
		return item || {};
	}

	function getSelectedCity(data, value) {
		if (allCities) {
			const item = allCities?.find((opt) => {
				if (opt?.city_name === value) return opt;
			});
			return item || {};
		}
		return {};
	}

	const getChipsData = (data, value) => {
		if (data) {
			const aa = data.filter((item) => {
				if (value && value?.includes(item?.name)) return true;
			});
			return aa;
		}
	};

	return (
		<>
			{edit ? (
				<Grid
					container
					spacing={2}
					style={{ display: "flex", flexDirection: "column" }}
				>
					<Grid item md={6} style={{ display: "grid" }}>
						<Typography className={classes.reqfieldtext}>First Name</Typography>
						<TextField
							variant="outlined"
							style={{
								backgroundColor: "#fff",
								borderRadius: "8px",
							}}
							onChange={handleChange("firstName")}
							value={values?.firstName}
							error={isError}
							helperText={isError ? errorValues?.firstName : ""}
						/>
					</Grid>
					<Grid item md={6} style={{ display: "grid" }}>
						<Typography className={classes.reqfieldtext}>Last Name</Typography>
						<TextField
							variant="outlined"
							style={{
								backgroundColor: "#fff",
								borderRadius: "8px",
							}}
							onChange={handleChange("lastName")}
							value={values?.lastName}
							error={isError}
							helperText={isError ? errorValues?.lastName : ""}
						/>
					</Grid>

					<Grid item md={6} xs={12}>
						<Typography className={classes.fieldtext}>Date of Birth</Typography>
						<Calendar
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
						/>
					</Grid>
					{values?.isMinor && (
						<Grid item md={6}>
							<div style={{ display: "flex" }}>
								<FormControlLabel
									style={{ display: "flex", alignItems: "flex-start" }}
									control={
										<Checkbox
											name="checkedB"
											color="primary"
											disabled
											checked={values?.isMinor ? values?.isMinor : false}
										/>
									}
									label={
										<span style={{ color: "#A6A6A6", textAlign: "left" }}>
											You are a minor. Kindly fill your guardian's details{" "}
										</span>
									}
								/>
							</div>
						</Grid>
					)}

					<Grid item md={6} style={{ display: "grid" }}>
						<Typography className={classes.reqfieldtext}>
							{values?.isMinor ? "Gaurdian's Email Address" : "Email Address"}
						</Typography>
						<TextField
							variant="outlined"
							style={{
								backgroundColor: "#fff",
								borderRadius: "8px",
							}}
							onChange={handleChange("email")}
							value={values?.email}
							error={isError}
							helperText={isError ? errorValues?.email : ""}
						/>
					</Grid>

					<Grid item md={6} style={{ display: "grid" }}>
						<Typography className={classes.reqfieldtext}>
							{values?.isMinor
								? "Gaurdian's Alternate Email Address"
								: " Alternate Email Address"}
							{/* */}
						</Typography>
						<TextField
							variant="outlined"
							style={{
								backgroundColor: "#fff",
								borderRadius: "8px",
							}}
							onChange={handleChange("alternateEmail")}
							value={values?.alternateEmail}
						/>
					</Grid>

					<Grid item md={6}>
						<Typography className={classes.fieldtext}>
							{values?.isMinor ? "Gaurdian's Mobile Number" : "Mobile Number"}
						</Typography>
						<FormControl style={{ display: "flex" }}>
							<PhoneInput
								style={{ width: "100%" }}
								country={"in"}
								inputProps={{
									name: "phone",
								}}
								onChange={(event) => {
									setValues({ ...values, phone: event });
								}}
								value={values?.phone}
							/>
							{isError && errorValues?.phone && (
								<FormHelperText error id="accountId-error">
									{errorValues?.phone}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>

					<Grid item md={6}>
						<Typography className={classes.fieldtext}>
							{values?.isMinor
								? "Gaurdian's Alternate Mobile Number"
								: "Alternate Mobile Number"}
							{/* */}
						</Typography>
						<FormControl style={{ display: "flex" }}>
							<PhoneInput
								style={{ width: "100%" }}
								country={"in"}
								inputProps={{
									name: "phone",
								}}
								onChange={(event) => {
									setValues({ ...values, alternatePhone: event });
								}}
								value={values?.alternatePhone}
							/>
							{/* {isError && errorValues?.phone && (
                  <FormHelperText error id="accountId-error">
                    {errorValues?.phone}
                  </FormHelperText>
                )} */}
						</FormControl>
					</Grid>

					{/* <Grid item md={6} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
              Password
             
            </Typography>
            <FormControl className={classes.margin}>
              <OutlinedInput
                style={{
                  backgroundColor: "#fff",
                  paddingTop: 26,
                  paddingBottom: 26,
                }}
                id="outlined-adornment-password"
                type={values?.showPassword ? "text" : "password"}
                value={values?.password}
                onChange={handleChange("password")}
                // error={isError}
                // helperText={isError ? errorValues?.password : ""}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                //labelWidth={70}
              />
              {isError && errorValues?.password && (
                  <FormHelperText error id="accountId-error1">
                    {errorValues?.password}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
          
          <Grid item md={6} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
              Confirm Password
             
            </Typography>
            <FormControl className={classes.margin}>
              <OutlinedInput
                style={{
                  backgroundColor: "#fff",
                  paddingTop: 25,
                  paddingBottom: 25,
                }}
                id="outlined-adornment-password"
                type={values.showConfirmPassword ? "text" : "password"}
                value={values.confirmPassword}
                onChange={handleChange("confirmPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                //labelWidth={70}
              />
              {isError && errorValues?.confirmPassword && (
                  <FormHelperText error id="accountId-error1">
                    {errorValues?.confirmPassword}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid> */}

					{/* {values?.isMinor && 
          <>
          <Grid item md={6}>
            <Divider />
          </Grid>
          
          <Grid item md={6} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.fieldtext}>Guardian's Name
           
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={handleChange("guardianName")}
              value={values?.guardianName}
              error={isError}
              helperText={isError ? errorValues?.guardianName : ""}
            />
          </Grid>
          <Grid item md={6} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.fieldtext}>Guardian's Relation
           
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={handleChange("guardianRelation")}
              value={values?.guardianRelation}
              error={isError}
              helperText={isError ? errorValues?.guardianRelation : ""}
            />
          </Grid>
          
          <Grid item md={6}>
            <Typography className={classes.fieldtext}>
            Guardian's Mobile Number
             
            </Typography>
            <FormControl  style={{ display: "flex" }}>
            <PhoneInput
              style={{ width: "100%" }}
              country={"in"}
              inputProps={{
                name: "guardianMobileNo",
              }}
              onChange={(event) => {
                setValues({ ...values, guardianMobileNo: event });
              }}
              value={values?.guardianMobileNo}
            />
            {isError && errorValues?.guardianMobileNo && (
                  <FormHelperText error id="accountId-error">
                    {errorValues?.guardianMobileNo}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.fieldtext}>Guardian's Email Address
           
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={handleChange("guardianEmail")}
              value={values?.guardianEmail}
              error={isError}
              helperText={isError ? errorValues?.guardianEmail : ""}
            />
          </Grid>
          <Grid item md={6} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
              Guardian's Address
             
            </Typography>
  
            <TextField
              variant="outlined"
              multiline
              rows={6}
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={handleChange("guardianAddress")}
              value={values?.guardianAddress}
              error={isError}
              helperText={isError ? errorValues?.guardianAddress : ""}
            />
          </Grid>
  
          <Grid item md={6} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.fieldtext}>Guardian's Profession
           
            </Typography>
            <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={OccupationData}
              getOptionLabel={(option) => option.value}
              value={getLabelSelected(OccupationData, values?.guardianProfession)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "State",
                  }}
                />
              )}
              onChange={(e, value) => {
                setValues({...values, guardianProfession: value.name });
              }}
            />
          </Grid>
  
          <Grid item md={6} xs={12} style={{ display: "grid" }}>
            <Divider />
          </Grid>
          </>
  } */}
					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							{values?.isMinor ? "Gaurdian's Gender" : "Gender"}
							{/* */}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<Autocomplete
							id="country-select-demo"
							style={{ backgroundColor: "#fff" }}
							classes={{
								option: classes.option,
							}}
							autoHighlight
							options={gender}
							getOptionLabel={(option) => option.value}
							// getOptionSelected={(option) => option.value === 1994}
							onChange={(e, item) => {
								setValues({ ...values, gender: item.name });
								setErrorValues({ ...errorValues, gender: "" });
							}}
							value={getLabelSelected(gender, values?.gender)}
							renderInput={(params) => (
								<TextField
									{...params}
									size="small"
									variant="outlined"
									inputProps={{
										...params.inputProps,
										autoComplete: "district",
									}}
									error={isError}
									helperText={isError ? errorValues?.legalForm : ""}
								/>
							)}
						/>
					</Grid>

					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<Typography
							// className={classes.reqfieldtext}
							style={{ fontSize: "17", fontWeight: "800" }}
						>
							{values?.isMinor ? "Gaurdian's Address" : "Address"}
						</Typography>
						<Typography
							className={classes.reqfieldtext}
							style={{ marginTop: "1rem" }}
						>
							{values?.isMinor ? "Line 1" : " Line 1"}
							{/* */}
						</Typography>

						<TextField
							variant="outlined"
							// multiline
							// rows={6}
							style={{
								backgroundColor: "#fff",
								borderRadius: "8px",
							}}
							onChange={handleChange("address")}
							value={values?.address}
							error={isError}
							helperText={isError ? errorValues?.address : ""}
						/>

						<Typography
							className={classes.reqfieldtext}
							style={{ marginTop: "1rem" }}
						>
							{values?.isMinor ? "Line 2" : "Line 2"}
							{/* */}
						</Typography>
						<TextField
							variant="outlined"
							// multiline
							// rows={6}
							style={{
								backgroundColor: "#fff",
								borderRadius: "8px",
							}}
							onChange={handleChange("address")}
							value={values?.address}
							error={isError}
							helperText={isError ? errorValues?.address : ""}
						/>
					</Grid>
					<Grid>
						<Grid item md={6} xs={12}>
							<Typography className={classes.fieldtext}>
								{values?.isMinor ? "Gaurdian's State" : "State"}
								{/* */}
							</Typography>
							<Autocomplete
								id="country-select-demo"
								style={{ backgroundColor: "#fff" }}
								classes={{
									option: classes.option,
								}}
								autoHighlight
								options={states}
								getOptionLabel={(option) => option.name}
								value={getLabelSelected(states, values?.state)}
								renderInput={(params) => (
									<TextField
										{...params}
										size="small"
										variant="outlined"
										inputProps={{
											...params.inputProps,
											autoComplete: "district",
										}}
									/>
								)}
								onChange={(e, value) => {
									if (value) {
										setValues({ ...values, state: value?.name, city: "" });
										setCity(value?.city);
									} else {
										setValues({ ...values, city: "", state: "" });
										setCity([]);
									}
								}}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<Typography className={classes.fieldtext}>
								{values?.isMinor ? "Gaurdian's City" : "City"}
								{/* */}
							</Typography>
							<Autocomplete
								id="country-select-demo"
								style={{ backgroundColor: "#fff" }}
								classes={{
									option: classes.option,
								}}
								autoHighlight
								options={city}
								getOptionLabel={(option) => option.city_name}
								value={city ? getSelectedCity(city, values?.city) : {}}
								disabled={city && city?.length > 0 ? false : true}
								renderInput={(params) => (
									<TextField
										{...params}
										size="small"
										variant="outlined"
										inputProps={{
											...params.inputProps,
											autoComplete: "district",
										}}
									/>
								)}
								onChange={(e, value) => {
									setValues({ ...values, city: value?.city_name });
								}}
							/>
						</Grid>
						<Grid item md={6} xs={12} style={{ display: "grid" }}>
							<Typography
								className={classes.fieldtext}
								style={{ marginTop: "1rem" }}
							>
								Pin Code
							</Typography>
							<TextField
								variant="outlined"
								// multiline
								// rows={6}
								style={{
									backgroundColor: "#fff",
									borderRadius: "8px",
								}}
								onChange={handleChange("pinCode")}
								value={values?.pinCode}
								error={isError}
								helperText={isError ? errorValues?.pinCode : ""}
							/>
						</Grid>
					</Grid>
					<Grid item md={6} xs={12}>
						<Typography className={classes.fieldtext}>
							{values?.isMinor ? "Gaurdian's Nationality" : "Nationality"}
						</Typography>
						<Autocomplete
							id="country-select-demo"
							style={{ backgroundColor: "#fff" }}
							classes={{
								option: classes.option,
							}}
							autoHighlight
							options={CountriesData}
							getOptionLabel={(option) => option.name}
							value={getLabelSelected(CountriesData, values?.country)}
							onChange={(e, value) => {
								setValues({ ...values, country: value?.name });
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									size="small"
									variant="outlined"
									inputProps={{
										...params.inputProps,
										autoComplete: "State",
									}}
									error={isError}
									helperText={isError ? errorValues?.country : ""}
								/>
							)}
						/>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<Typography className={classes.fieldtext}>
							{values?.isMinor ? "Gaurdian's Education" : "Education"}
							{/* */}
						</Typography>
						<Autocomplete
							id="country-select-demo"
							style={{ backgroundColor: "#fff" }}
							classes={{
								option: classes.option,
							}}
							autoHighlight
							options={EducationData}
							getOptionLabel={(option) => option.value}
							value={
								EducationData
									? getLabelSelected(EducationData, values?.education)
									: ""
							}
							renderInput={(params) => (
								<TextField
									{...params}
									size="small"
									variant="outlined"
									inputProps={{
										...params.inputProps,
										autoComplete: "State",
									}}
								/>
							)}
							onChange={(e, value) => {
								setValues({ ...values, education: value?.name });
							}}
						/>
						{/* <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={handleChange("profession")}
              error={isError}
              value={values?.education}
              helperText={isError ? errorValues?.education : ""}
            /> */}
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<Typography className={classes.fieldtext}>
							{values?.isMinor ? "Gaurdian's Profession" : "Profession"}
							{/* */}
						</Typography>
						<Autocomplete
							id="country-select-demo"
							style={{ backgroundColor: "#fff" }}
							classes={{
								option: classes.option,
							}}
							autoHighlight
							options={OccupationData}
							getOptionLabel={(option) => option.value}
							value={getLabelSelected(OccupationData, values?.profession)}
							renderInput={(params) => (
								<TextField
									{...params}
									size="small"
									variant="outlined"
									inputProps={{
										...params.inputProps,
										autoComplete: "State",
									}}
								/>
							)}
							onChange={(event, value) => {
								setValues({ ...values, profession: value?.name });
							}}
						/>
						{/* <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={handleChange("profession")}
              value={values?.profession}
              error={isError}
              helperText={isError ? errorValues?.profession : ""}
            /> */}
					</Grid>
					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							{values?.isMinor ? "Gaurdian's Marital Status" : "Marital Status"}
							{/* */}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<Autocomplete
							id="country-select-demo"
							style={{ backgroundColor: "#fff" }}
							classes={{
								option: classes.option,
							}}
							autoHighlight
							options={maritialStatus}
							getOptionLabel={(option) => option.value}
							value={getLabelSelected(maritialStatus, values?.maritialStatus)}
							// getOptionSelected={(option) => option.value === 1994}
							onChange={(e, item) => {
								setValues({ ...values, maritialStatus: item.name });
								setErrorValues({ ...errorValues, maritialStatus: "" });
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									size="small"
									variant="outlined"
									inputProps={{
										...params.inputProps,
										autoComplete: "district",
									}}
									error={isError}
									helperText={isError ? errorValues?.maritialStatus : ""}
								/>
							)}
						/>
					</Grid>

					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							{values?.isMinor ? "Gaurdian's Income" : "Income"}
							{/* */}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<Autocomplete
							id="country-select-demo"
							style={{ backgroundColor: "#fff" }}
							classes={{
								option: classes.option,
							}}
							autoHighlight
							options={IncomeData}
							getOptionLabel={(option) => option.value}
							value={getLabelSelected(IncomeData, values?.incomeRange)}
							// getOptionSelected={(option) => option.value === 1994}
							onChange={(e, item) => {
								setValues({ ...values, incomeRange: item.name });
								setErrorValues({ ...errorValues, incomeRange: "" });
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									size="small"
									variant="outlined"
									inputProps={{
										...params.inputProps,
										autoComplete: "district",
									}}
									error={isError}
									helperText={isError ? errorValues?.legalForm : ""}
								/>
							)}
						/>
					</Grid>

					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							Causes Preference
							{/* */}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<ChipAutocomplete
							style={{ backgroundColor: "#fff", display: "grid" }}
							multiple
							id="tags-outlined"
							options={causes ? causes : []}
							getOptionLabel={(option) => option.category_name}
							// defaultValue={[demo[1]]}
							value={causes ? getCausesList() : []}
							filterSelectedOptions
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										color="primary"
										variant="outlined"
										label={option.category_name}
										{...getTagProps({ index })}
									/>
								))
							}
							renderInput={(params) => (
								<TextField {...params} variant="outlined" />
							)}
							onChange={(e, item) => {
								if (item) {
									const loc1 = item.map((item1) => {
										return item1?.id;
									});
									setValues({ ...values, causes: loc1 });
								} else {
									setValues({ ...values, causes: [] });
								}
							}}
						/>
					</Grid>

					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							Sub Causes Preference
							{/* */}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<ChipAutocomplete
							style={{ backgroundColor: "#fff", display: "grid" }}
							multiple
							id="tags-outlined"
							options={subCauses ? subCauses : []}
							getOptionLabel={(option) => option.sub_category_name}
							// defaultValue={[demo[1]]}
							value={subCauses ? getSubCausesList() : []}
							filterSelectedOptions
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										color="primary"
										variant="outlined"
										label={option.sub_category_name}
										{...getTagProps({ index })}
									/>
								))
							}
							renderInput={(params) => (
								<TextField {...params} variant="outlined" />
							)}
							onChange={(e, item) => {
								if (item) {
									const loc1 = item.map((item1) => {
										return item1?.id;
									});
									setValues({ ...values, subCauses: loc1 });
								} else {
									setValues({ ...values, subCauses: [] });
								}
							}}
						/>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<Divider />
					</Grid>
					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography>NGO Preference</Typography>
					</Grid>
					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							Special Interest
							{/* */}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<ChipAutocomplete
							style={{ backgroundColor: "#fff", display: "grid" }}
							multiple
							id="tags-outlined"
							options={specialInterestData}
							getOptionLabel={(option) => option.value}
							// defaultValue={[demo[1]]}
							value={
								specialInterestData
									? getChipsData(
											specialInterestData,
											values?.ngoPreference?.specialInterest
									  )
									: []
							}
							filterSelectedOptions
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										color="primary"
										variant="outlined"
										label={option.value}
										{...getTagProps({ index })}
									/>
								))
							}
							renderInput={(params) => (
								<TextField {...params} variant="outlined" />
							)}
							onChange={(e, item) => {
								if (item) {
									const loc1 = item.map((item1) => {
										return item1?.name;
									});
									setValues({
										...values,
										ngoPreference: {
											...values?.ngoPreference,
											specialInterest: loc1,
										},
									});
								} else {
									setValues({
										...values,
										ngoPreference: {
											...values?.ngoPreference,
											specialInterest: [],
										},
									});
								}
							}}
						/>
					</Grid>

					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							By Size
							{/* */}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<ChipAutocomplete
							style={{ backgroundColor: "#fff", display: "grid" }}
							multiple
							id="tags-outlined"
							options={sizeData}
							getOptionLabel={(option) => option.value}
							// defaultValue={[demo[1]]}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										color="primary"
										variant="outlined"
										label={option.value}
										{...getTagProps({ index })}
									/>
								))
							}
							value={
								sizeData
									? getChipsData(sizeData, values?.ngoPreference?.size)
									: []
							}
							filterSelectedOptions
							renderInput={(params) => (
								<TextField {...params} variant="outlined" />
							)}
							onChange={(e, item) => {
								if (item) {
									const loc1 = item.map((item1) => {
										return item1?.name;
									});
									setValues({
										...values,
										ngoPreference: { ...values?.ngoPreference, size: loc1 },
									});
								} else {
									setValues({
										...values,
										ngoPreference: { ...values?.ngoPreference, size: [] },
									});
								}
							}}
						/>
					</Grid>

					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>By Age</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<ChipAutocomplete
							style={{ backgroundColor: "#fff" }}
							multiple
							id="tags-outlined"
							options={ageData}
							getOptionLabel={(option) => option.value}
							// defaultValue={[demo[1]]}
							value={
								ageData ? getChipsData(ageData, values?.ngoPreference?.age) : []
							}
							filterSelectedOptions
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										color="primary"
										variant="outlined"
										label={option.value}
										{...getTagProps({ index })}
									/>
								))
							}
							renderInput={(params) => (
								<TextField {...params} variant="outlined" />
							)}
							onChange={(e, item) => {
								if (item) {
									const loc1 = item.map((item1) => {
										return item1?.name;
									});
									setValues({
										...values,
										ngoPreference: { ...values?.ngoPreference, age: loc1 },
									});
								} else {
									setValues({
										...values,
										ngoPreference: { ...values?.ngoPreference, age: [] },
									});
								}
							}}
						/>
					</Grid>

					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<Divider />
					</Grid>

					<Grid item md={6}>
						<div style={{ display: "flex" }}>
							<FormControlLabel
								style={{ display: "flex", alignItems: "flex-start" }}
								control={
									<Checkbox
										name="checkedB"
										color="primary"
										checked={values?.wantReceiveMail}
										onChange={(event, value) => {
											setValues({ ...values, wantReceiveMail: value });
										}}
									/>
								}
								label={
									<span style={{ color: "#A6A6A6", textAlign: "left" }}>
										Would you like to receive communication from our side
										(newletter, development sector and philanthrophy news,
										information, etc.){" "}
									</span>
								}
							/>
						</div>
						<div style={{ display: "flex" }}>
							<FormControlLabel
								style={{ display: "flex", alignItems: "flex-start" }}
								control={
									<Checkbox
										name="checkedB"
										color="primary"
										checked={values?.eventInfo}
										onChange={(event, value) => {
											setValues({ ...values, eventInfo: value });
										}}
									/>
								}
								label={
									<span style={{ color: "#A6A6A6", textAlign: "left" }}>
										Would you like to be informed about and participate in our
										offline and online events etc.{" "}
									</span>
								}
							/>
						</div>
						<div style={{ display: "flex" }}>
							<FormControlLabel
								style={{ display: "flex", alignItems: "flex-start" }}
								control={
									<Checkbox
										name="checkedB"
										color="primary"
										checked={values?.wantVolunteer}
										onChange={(event, value) => {
											setValues({ ...values, wantVolunteer: value });
										}}
									/>
								}
								label={
									<span style={{ color: "#A6A6A6", textAlign: "left" }}>
										Would you like to volunteer your services to us or our
										partner NGO's.{" "}
									</span>
								}
							/>
						</div>
					</Grid>
				</Grid>
			) : (
				<Grid
					container
					spacing={2}
					// style={{ display: "flex", flexDirection: "column" }}
				>
					<Grid item md={6} style={{ display: "grid" }}>
						<Typography className={classes.reqfieldtext}>Name: </Typography>
					</Grid>
					<Grid item md={6} style={{ display: "grid" }}>
						{values?.firstName} {""} {values?.lastName}
					</Grid>

					<Grid item md={6} xs={12}>
						<Typography className={classes.fieldtext}>Date of Birth</Typography>
					</Grid>
					<Grid item md={6} xs={12}>
						{values?.dateOfBirth}
					</Grid>

					<Grid item md={6} style={{ display: "grid" }}>
						<Typography className={classes.reqfieldtext}>
							{values?.isMinor ? "Gaurdian's Email Address" : "Email Address"}
						</Typography>
					</Grid>
					<Grid item md={6} style={{ display: "grid" }}>
						{values?.email}
					</Grid>

					<Grid item md={6} style={{ display: "grid" }}>
						<Typography className={classes.reqfieldtext}>
							{values?.isMinor
								? "Gaurdian's Alternate Email Address"
								: " Alternate Email Address"}
						</Typography>
					</Grid>
					<Grid item md={6} style={{ display: "grid" }}>
						{values?.alternateEmail}
					</Grid>

					<Grid item md={6}>
						<Typography className={classes.fieldtext}>
							{values?.isMinor ? "Gaurdian's Mobile Number" : "Mobile Number"}
						</Typography>
					</Grid>
					<Grid item md={6}>
						{values?.phone}
					</Grid>

					<Grid item md={6}>
						<Typography className={classes.fieldtext}>
							{values?.isMinor
								? "Gaurdian's Alternate Mobile Number"
								: "Alternate Mobile Number"}
						</Typography>
					</Grid>
					<Grid item md={6}>
						{values?.alternatePhone}
					</Grid>

					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							{values?.isMinor ? "Gaurdian's Gender" : "Gender"}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						{values?.gender}
					</Grid>

					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<Typography className={classes.reqfieldtext}>
							{values?.isMinor ? "Gaurdian's Address" : "Address"}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						{values?.address}
					</Grid>

					<Grid item md={6} xs={12}>
						<Typography className={classes.fieldtext}>
							{values?.isMinor ? "Gaurdian's State" : "State"}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12}>
						{values?.state}
					</Grid>
					<Grid item md={6} xs={12}>
						<Typography className={classes.fieldtext}>
							{values?.isMinor ? "Gaurdian's City" : "City"}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12}>
						{values?.city}
					</Grid>

					<Grid item md={6} xs={12}>
						<Typography className={classes.fieldtext}>
							{values?.isMinor ? "Gaurdian's Nationality" : "Nationality"}
						</Typography>
					</Grid>
					<Grid item md={6}>
						{values?.country}
					</Grid>

					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<Typography className={classes.fieldtext}>
							{values?.isMinor ? "Gaurdian's Education" : "Education"}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						{values?.education}
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<Typography className={classes.fieldtext}>
							{values?.isMinor ? "Gaurdian's Profession" : "Profession"}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						{values?.profession}
					</Grid>
					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							{values?.isMinor ? "Gaurdian's Marital Status" : "Marital Status"}
							{/* */}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						{values?.maritialStatus}
					</Grid>

					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							{values?.isMinor ? "Gaurdian's Income" : "Income"}
							{/* */}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						{values?.incomeRange}
					</Grid>

					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							Causes Preference
							{/* */}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<ChipAutocomplete
							style={{ backgroundColor: "", display: "grid" }}
							multiple
							id="tags-outlined"
							options={causes ? causes : []}
							disabled
							getOptionLabel={(option) => option.category_name}
							// defaultValue={[demo[1]]}
							value={causes ? getCausesList() : []}
							filterSelectedOptions
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										color="primary"
										variant="outlined"
										label={option.category_name}
										{...getTagProps({ index })}
									/>
								))
							}
							renderInput={(params) => (
								<TextField {...params} variant="standard" />
							)}
						/>
					</Grid>

					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							Sub Causes Preference
							{/* */}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<ChipAutocomplete
							style={{ backgroundColor: "", display: "grid" }}
							multiple
							disabled
							id="tags-outlined"
							options={subCauses ? subCauses : []}
							getOptionLabel={(option) => option.sub_category_name}
							// defaultValue={[demo[1]]}
							value={subCauses ? getSubCausesList() : []}
							filterSelectedOptions
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										color="primary"
										variant="outlined"
										label={option.sub_category_name}
										{...getTagProps({ index })}
									/>
								))
							}
							renderInput={(params) => (
								<TextField {...params} variant="standard" />
							)}
							onChange={(e, item) => {
								if (item) {
									const loc1 = item.map((item1) => {
										return item1?.id;
									});
									setValues({ ...values, subCauses: loc1 });
								} else {
									setValues({ ...values, subCauses: [] });
								}
							}}
						/>
					</Grid>

					<Grid item md={12} xs={12} className={classes.gridtext}>
						<Typography>NGO Preference</Typography>
					</Grid>
					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							Special Interest
							{/* */}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<ChipAutocomplete
							style={{ display: "grid" }}
							multiple
							id="tags-outlined"
							disabled
							options={specialInterestData}
							getOptionLabel={(option) => option.value}
							// defaultValue={[demo[1]]}
							value={
								specialInterestData
									? getChipsData(
											specialInterestData,
											values?.ngoPreference?.specialInterest
									  )
									: []
							}
							filterSelectedOptions
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										color="primary"
										variant="outlined"
										label={option.value}
										{...getTagProps({ index })}
									/>
								))
							}
							renderInput={(params) => (
								<TextField {...params} variant="standard" />
							)}
							onChange={(e, item) => {
								if (item) {
									const loc1 = item.map((item1) => {
										return item1?.name;
									});
									setValues({
										...values,
										ngoPreference: {
											...values?.ngoPreference,
											specialInterest: loc1,
										},
									});
								} else {
									setValues({
										...values,
										ngoPreference: {
											...values?.ngoPreference,
											specialInterest: [],
										},
									});
								}
							}}
						/>
					</Grid>

					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>
							By Size
							{/* */}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<ChipAutocomplete
							style={{ display: "grid" }}
							multiple
							id="tags-outlined"
							disabled
							options={sizeData}
							getOptionLabel={(option) => option.value}
							// defaultValue={[demo[1]]}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										color="primary"
										variant="outlined"
										label={option.value}
										{...getTagProps({ index })}
									/>
								))
							}
							value={
								sizeData
									? getChipsData(sizeData, values?.ngoPreference?.size)
									: []
							}
							filterSelectedOptions
							renderInput={(params) => (
								<TextField {...params} variant="standard" />
							)}
							onChange={(e, item) => {
								if (item) {
									const loc1 = item.map((item1) => {
										return item1?.name;
									});
									setValues({
										...values,
										ngoPreference: { ...values?.ngoPreference, size: loc1 },
									});
								} else {
									setValues({
										...values,
										ngoPreference: { ...values?.ngoPreference, size: [] },
									});
								}
							}}
						/>
					</Grid>

					<Grid item md={6} xs={12} className={classes.gridtext}>
						<Typography className={classes.reqfieldtext}>By Age</Typography>
					</Grid>
					<Grid item md={6} xs={12} style={{ display: "grid" }}>
						<ChipAutocomplete
							multiple
							id="tags-outlined"
							options={ageData}
							disabled
							getOptionLabel={(option) => option.value}
							// defaultValue={[demo[1]]}
							value={
								ageData ? getChipsData(ageData, values?.ngoPreference?.age) : []
							}
							filterSelectedOptions
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										color="primary"
										variant="outlined"
										label={option.value}
										{...getTagProps({ index })}
									/>
								))
							}
							renderInput={(params) => (
								<TextField {...params} variant="standard" />
							)}
							onChange={(e, item) => {
								if (item) {
									const loc1 = item.map((item1) => {
										return item1?.name;
									});
									setValues({
										...values,
										ngoPreference: { ...values?.ngoPreference, age: loc1 },
									});
								} else {
									setValues({
										...values,
										ngoPreference: { ...values?.ngoPreference, age: [] },
									});
								}
							}}
						/>
					</Grid>

					<Grid item md={6} xs={12} style={{ display: "grid" }}></Grid>
				</Grid>
			)}
			<div style={{ display: "flex", justifyContent: "center" }}>
				{edit ? (
					<>
						<div>
							<Button
								className={classes.btn}
								color="primary"
								variant="contained"
								onClick={() => onProfileUpate()}
							>
								{isLoading ? <CustomButtonCircularProgress /> : "Save"}
							</Button>
						</div>
					</>
				) : (
					""
				)}
				<div>
					<Button
						className={classes.btn}
						color="primary"
						variant="contained"
						onClick={handleEdit}
						style={{ marginLeft: "20px" }}
					>
						{isLoading ? <CustomButtonCircularProgress /> : "Edit"}
					</Button>
				</div>
			</div>
		</>
	);
}

export default UserProfileForm;
