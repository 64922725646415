import React, { Component } from "react";
import Charst from "react-apexcharts";
import { Paper, Typography } from "@material-ui/core";

export default class PieChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        labels: ["No Donation Made"],
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },

        chart: {
          id: "apexchart-example",
          toolbar: {
            show: false,
          },
        },

        colors: ["#C88FEF", "#C88FEF80"],
        stroke: {
          show: true,
          curve: "smooth",
          width: 1,
        },

        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "11px",
          fontFamily: "Raleway",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
        },

        dataLabels: {
          //position: "top", // top, center, bottom
          enabled: false,
          formatter: function (val) {
            return "Q" + val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#94939F"],
          },
          //enabled: true,
        },
      },

      series: [100],
      
    };
  }
  render() {
    return (
      <Paper
        style={{
          // boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
          boxShadow: "none",
          borderRadius: "20px",
          padding: "30px",
          backgroundColor: "#FCF9FE",
        }}
      >
        <div style={{display: "flex", flexDirection: "row"}}>
          <Typography variant="h6">
            NGO
          </Typography>
            <Charst
              options={this.state.options}
              series={this.state.series}
              type="pie"
              height={300}
            />
            <Typography variant="h6">
            Cause
          </Typography>
            <Charst
              options={this.state.options}
              series={this.state.series}
              type="pie"
              height={300}
            />
            <Typography variant="h6">
            Mode
          </Typography>
            <Charst
              options={this.state.options}
              series={this.state.series}
              type="pie"
              height={300}
            />
        </div>

        <div style={{display: "flex", flexDirection: "row", marginTop: "80px"}}>
          <Typography variant="h6">
            Utilised vs Unutilised
          </Typography>
            <Charst
              options={this.state.options}
              series={this.state.series}
              type="pie"
              height={300}
            />
            {/* <Typography variant="h6">
            Agriculture and Farmer Sub-Cause
          </Typography>
            <Charst
              options={this.state.options}
              series={this.state.series}
              type="pie"
              height={300}
            />
            <Typography variant="h6">
            Water and Sanitation Sub-Cause
          </Typography>
            <Charst
              options={this.state.options}
              series={this.state.series}
              type="pie"
              height={300}
            /> */}
        </div>
      </Paper>
    );
  }
}
