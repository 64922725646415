import React from "react";
import RatingBanner from "../../components/Section/NgoRatingsection/RatingBanner";
import RatingMethodology from "../../components/Section/NgoRatingsection/RatingMethodology";
import RatingTiersSection from "../../components/Section/NgoRatingsection/RatingTiersSection";
import TopRatedNGO from "../../components/Section/NgoRatingsection/TopRatedNGO";
import vactor from "../../components/assets/png/resource.png";
import PublicLayout from "../../components/Layout/PublicLayout";

function NgoRating() {
  return (
    <PublicLayout keyss={"resource"}>
    <>
      {data.map((item) => (
        <RatingBanner
          title={item.heading}
          info={item.info}
          image={item.image}
        />
      ))}
      <RatingMethodology />
      <RatingTiersSection />
      <TopRatedNGO />
    </>
    </PublicLayout>
  );
}

export default NgoRating;

const data = [
  {
    heading: "NGO Rating",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum, ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo in tristique feugiat faucibus arcu tempor. Vestibulum, congue vitae eleifend nec maecenas tristique bibendum egestas fermentum. Cursus nunc vulputate cras felis suspendisse convallis nisl.",
    image: vactor,
  },
];
