import Heading from "../../Common/Heading/Heading";
import React, { Fragment, useEffect } from "react";
import {
  Typography,
  Box,
  Hidden,
  makeStyles,
  Container,
  createStyles,
  Grid,
} from "@material-ui/core";
import demo from "../../assets/png/browser-stats/rafiki.png";
import {Divider} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    cardimg: {
      [theme.breakpoints.only("sm")]: {
        width: "auto",
        height: "200px",
      },
      [theme.breakpoints.only("xs")]: {
        width: "auto",
        height: "300px",
      },
    },
    title: {
      paddingTop: "20px",
    },

    title2: {
      //  fontSize: "35px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
    },
    title3: {
      //fontSize: "22px",
      //fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      marginTop: "3%",
    },
    details: {
      //fontSize: "13px",
      marginBottom: "8px",
      //fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      textAlign: "justify",
      color: "#333",
    },
    details2: {
      //  fontSize: "13px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      textAlign: "justify",
      color: "#333",
    },
    gridimg: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

function Need() {
  const classes = useStyles();
  return (
    <>
    <Grid md={12} xs={12} sm={12} style={{marginBottom: "20px"}}>
        <Divider />
      </Grid>
    <div>
    
        <Typography
          variant="h2"
          component="h1"
          color="primary"
          style={{ fontFamily: "Marcellus", textAlign: "center" }}
        >
          What We Offer
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Ikigai Portfolio’s services are primarily focussed on the donors who wish to contribute to social causes through not for profits in India. While we also provide certain services to our partner not for profits as per their needs, the key objective of such services is to strengthen their capacities and processes so that the donors have robust institutions to donate to and their contributions can be optimised for the desired impact. We manage the philanthropy portfolio of our members when they donate through our platform and this is what they get when they donate through our platform:
        </Typography>
      </div>
      
      <div style={{ marginTop: "3%" }}>
        {data.map((item, index) => (
          <Box paddingTop="2%" key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {/* <Hidden only={["sm", "xs"]}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "40px",
                    }}
                  >
                    <Typography
                      variant="h2"
                      style={{ fontFamily: "Marcellus" }}
                    >
                      {item.title}
                    </Typography>
                    <div
                      style={{
                        backgroundColor: "#C4C4C4",
                        height: "1px",
                        width: "40%",
                        marginLeft: "50px",
                      }}
                    />
                  </div>
                </Hidden>
                <Hidden only={["md", "lg", "xl"]}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      style={{ fontFamily: "Marcellus" }}
                    >
                      {item.title}
                    </Typography>
                    <div
                      style={{
                        backgroundColor: "#C4C4C4",
                        height: "1px",
                        width: "40%",
                        marginLeft: "50px",
                      }}
                    />
                  </div>
                </Hidden> */}
                <ul style={{ paddingInlineStart: "15px" }}>
                  {item.detail.map((item2, index2) => (
                    <Fragment key={index2}>
                      <li>
                        <div key={index2} className={classes.details}>
                          <Typography variant="body2">{item2.text}</Typography>
                        </div>
                      </li>
                    </Fragment>
                  ))}
                </ul>
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridimg}>
                <img
                  src={item.image}
                  height="400px"
                  className={classes.cardimg}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
      </div>
    </>
  );
}

export default Need;


const data = [
  {
    title: "The Need",
    image: demo,
    detail: [
      {
        text: `A simple, convenient and transparent platform to donate to social causes.`,
      },
      {
        text: `Choice and flexibility in choosing the cause and not for profit to donate to`,
      },

      {
        text: `Partner NGOs rated through a rigorous rating tool and the rating made publicly available to allow donors to make an informed choice. NGOs that score below a threshold score in the rating are not included in the Partner NGO Pool of Ikigai Portfolio.`,
      },
      {
        text: `Tax exemptions on donations wherever applicable. `,
      },
      {
        text: `A customised dashboard for all donors to view their philanthropy portfolio and its performance and the ability to download relevant donation receipts and tax exemption certificates. `,
      },

      {
        text: `Regular reporting to the donors on how their donation was utilised and what impact it has created. The reports are generated through a comprehensive due diligence process that all partner NGOs go through mandatorily`,
      },
      {
        text: `NGOs in the Ikigai Portfolio Partner NGO Pool have to mandatorily follow the guidelines on how they may utilise the donation mobilised from this platform. These guidelines mostly relate to direct and indirect project spends, what are the kinds of expenses that are allowed and not allowed, restriction on spending the donation for the purpose for which it was received etc. The partner NGOs need to report on this periodically and independent due diligence through audits and assessments are done regularly.  `,
      },
      {
        text: `Focus on maximising impact created through the donors’ philanthropy portfolio`,
      },

      {
        text: `Wherever feasible, Ikigai Portfolio tries to establish beneficiary/project connect with donors through online connects or physical visits to the project locations`,
      },
      {
        text: `Invites to online and physical philanthropy events with speaker opportunities `,
      },
      {
        text: `Recognition to donors through felicitation in online events, certificates of contribution and impact, featuring in Ikigai Portfolio’s website and social media pages, social media kits with beneficiary testimonials, online certificates etc. that they can post on their social media profiles`,
      },

      {
        text: `Ikigai Portfolio also helps partner NGOs in strengthening their capacities, meeting regulations and compliances, improving their systems and processes etc. as per their requirements and a per the NGO Partner membership tier chosen by them.`,
      },
    ],
  },
];
