import { Paper, Typography, Grid } from "@material-ui/core";
import React from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import BarChart from "../../Common/Graph/BarChart";
import LineChart from "../../Common/Graph/LineChart";
import PieChart from "../../Common/Graph/PieChart";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
    paddingBottom: "50px",
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
}));

export default function Portfolio({news}){
    const classes = useStyles();
    return (
        <>
        <div style={{marginBottom: "15px"}}>
        {/* <Typography
          variant="h5"
          color="primary"
          style={{ marginTop: "20px", textAlign: "left" }}
        >Never miss out on Important news and updates regarding your NGO or cause
        </Typography> */}
        </div>
        <Paper className={classes.paper}>
            <Typography
          variant="h5"
        //   color="primary"
          style={{ marginTop: "20px", textAlign: "left" }}
        >Quarterly Amount Raised for a particular cause
        </Typography>
        <Typography
          variant="h9"
        //   color="primary"
          style={{ marginTop: "20px", textAlign: "left" }}
        >Your Philanthrophy portfolio is worth <span>Rs. 0.</span>
        
        {/* , you have Rs.22,400 invested in Water and Sanitation through Jal Jeevan NGO and Rs. 30,000 in Agriculture and Farmers through Swades FoundationNGO. */}
        </Typography>
            <BarChart />
            <br />
            <PieChart />
            <br />
            {/* <LineChart /> */}
            <br />
        </Paper>
        </>
    )
}