import { Typography, Grid, Button, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  getRequest,
  postRequest,
  deleteRequest,
} from "../../../../apis/baseApi";
import { useAlert } from "react-alert";
import { fundingData } from "../../../../constant";
import { Autocomplete } from "@material-ui/lab";
import Calendar from "../../../Common/Calendar/Calendar";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  upload: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",

    borderRadius: 8,
    height: 46,
  },

  fieldtext: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "10px",
    display: "flex",
  },
  reqfieldtext: {
    fontSize: "14px",
    textAlign: "left",
  },
  option: {
    fontSize: 13,
    "& > span": {
      marginRight: 10,
      fontSize: 12,
    },
  },
  gridtext: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textBox: {
    backgroundColor: "#fff",
    "& $div": {
      justifyContent: "center",
      "& $input": {
        // width: "30%",
      },
    },
    "& $p": {
      alignSelf: "center",
    },
  },
}));

export default function Reg({ fromProfile, edit, handleEdit, setEdit }) {
  const classes = useStyles();
  const [listCount, setListCount] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const alert = useAlert();
  const initialState = {
    name: "",
    description: "",
    fundingType: "",
    location: "",
    startDate: "",
    endDate: "",
    id: "",
  };

  const [ongoingProject, setOngoingProject] = useState([initialState]);
  const [ongoingProject2, setOngoingProject2] = useState([]);

  function submitProjects(index) {
    const tempState = ongoingProject[index];
    if (validateData(tempState)) {
      const postData = {
        name: tempState?.name,
        description: tempState?.description,
        funding_type: tempState?.fundingType,
        location: tempState?.location,
        start_date: tempState?.startDate,
        end_date: tempState?.endDate,
        id: tempState?.id,
      };
      postRequest(
        `ngo/on_going_project/${localStorage.getItem("ngo_slug")}/`,
        postData
      )
        .then((res) => {
          if (res?.data?.data) {
            alert.success("Project added successfully!!");
          } else {
            // alert.error("Something went Wrong!");
          }
        })
        .catch((err) => {
          // alert.error("Something went Wrong!");
        });
    }
  }

  const handleDelete = (id) => {
    console.log("id", id);

    deleteRequest(`ngo/delete_on_going_project/${id}/`).then((res) => {
      if (res?.status === 200) {
        setRefresh(!refresh);
        alert.success(res?.data?.msg);
      }
    });
  };

  function validateData(data) {
    if (!data?.name) {
      alert.error("Please fill all the details");
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (fromProfile) {
      getRequest(`ngo/on_going_project/${localStorage.getItem("ngo_slug")}/`)
        .then((res) => {
          if (res?.data?.results) {
            const tempPro = [...ongoingProject];
            setOngoingProject(res?.data?.results);
            res?.data?.results.forEach((item) => {
              tempPro.unshift({
                name: item?.name,
                description: item?.description,
                fundingType: item?.funding_type,
                location: item?.location,
                startDate: item?.start_date,
                endDate: item?.end_date,
                isAdded: true,
                id: item?.id,
              });
            });
            if (edit === false) {
              setOngoingProject(tempPro);
            }
          }
        })
        .catch((err) => {});
    }
  }, [refresh]);

  useEffect(() => {
    if (fromProfile) {
      getRequest(`ngo/on_going_project/${localStorage.getItem("ngo_slug")}/`)
        .then((res) => {
          if (res?.data?.results) {
            setOngoingProject2(res?.data?.results);
          }
        })
        .catch((err) => {});
    }
  }, []);

  return (
    <>
      {!edit ? (
        <Grid container spacing={2}>
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              List of ongoing projects with brief description
            </Typography>
          </Grid>
          <Grid item md={10} xs={6} style={{ display: "grid" }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.upload}
              onClick={() => {
                setOngoingProject([...ongoingProject, initialState]);
              }}
            >
              Add New
            </Button>
          </Grid>
          {ongoingProject?.map((item, index) => {
            console.log("items", item);
            return (
              <Grid item md={12}>
                <Grid container spacing={2}>
                  <Grid item md={2}>
                    <TextField
                      className={classes.textBox}
                      variant="outlined"
                      color="primary"
                      placeholder="Name"
                      onChange={(event) => {
                        const tempData = [...ongoingProject];
                        tempData[index].name = event.target.value;
                        setOngoingProject(tempData);
                      }}
                      value={item?.name}
                      disabled={item?.isAdded}
                    />
                  </Grid>
                  <Grid item md={4} style={{ display: "grid" }}>
                    <TextField
                      className={classes.textBox}
                      multiline
                      rows={5}
                      variant="outlined"
                      color="primary"
                      placeholder="Description"
                      disabled={item?.isAdded}
                      value={item?.description}
                      onChange={(event) => {
                        const tempData = [...ongoingProject];
                        tempData[index].description = event.target.value;
                        setOngoingProject(tempData);
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <TextField
                          className={classes.textBox}
                          variant="outlined"
                          color="primary"
                          placeholder="Funding Type"
                          disabled={item?.isAdded}
                          value={item?.fundingType}
                          onChange={(event) => {
                            const tempData = [...ongoingProject];
                            tempData[index].fundingType = event.target.value;
                            setOngoingProject(tempData);
                          }}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          className={classes.textBox}
                          variant="outlined"
                          color="primary"
                          placeholder="Location"
                          disabled={item?.isAdded}
                          value={item?.location}
                          onChange={(event) => {
                            const tempData = [...ongoingProject];
                            tempData[index].location = event.target.value;
                            setOngoingProject(tempData);
                          }}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Calendar
                          title="Start Date"
                          selectedDate={ongoingProject[index]?.startDate}
                          setSelectedDate={(dates) => {
                            const tempData = [...ongoingProject];
                            tempData[index].startDate =
                              moment(dates).format("YYYY-MM-DD");
                            setOngoingProject(tempData);
                          }}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Calendar
                          title="End Date"
                          selectedDate={ongoingProject[index]?.endDate}
                          setSelectedDate={(dates) => {
                            const tempData = [...ongoingProject];
                            tempData[index].endDate =
                              moment(dates).format("YYYY-MM-DD");
                            setOngoingProject(tempData);
                          }}
                        />
                      </Grid>
                      {item?.id ? (
                        <Grid item md={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.upload}
                            onClick={() => {
                              handleDelete(item?.id);
                            }}
                          >
                            Delete
                          </Button>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid item md={2} xs={6} style={{ display: "grid" }}>
                    {!item?.isAdded && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.upload}
                        onClick={() => {
                          submitProjects(index);
                        }}
                      >
                        Submit
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item md={2} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              List of ongoing projects with brief description
            </Typography>
          </Grid>

          {ongoingProject2?.map((item, index) => {
            console.log("item", item);
            return (
              <Grid item md={12}>
                <Grid container spacing={2}>
                  <Grid item md={2} style={{ textTransform: "capitalize" }}>
                    Name: {item?.name}
                  </Grid>
                  <Grid
                    item
                    md={4}
                    style={{ display: "grid", textTransform: "capitalize" }}
                  >
                    Description:{item?.description}
                  </Grid>
                  <Grid item md={6}>
                    <Grid container spacing={2}>
                      <Grid item md={6} style={{ textTransform: "capitalize" }}>
                        Funding Type: {item?.funding_type}
                      </Grid>
                      <Grid item md={6} style={{ textTransform: "capitalize" }}>
                        Location:{item?.location}
                      </Grid>
                      <Grid item md={6} style={{ textTransform: "capitalize" }}>
                        Start Date: {item?.start_date}
                      </Grid>
                      <Grid item md={6} style={{ textTransform: "capitalize" }}>
                        End Date: {item?.end_date}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
}
