import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { getRequest } from "../../../../apis/baseApi";
import ReportCard from "../../../Cards/DashBoadCards/ReportCard/ReportCard";
import Table from "../../../Common/Table/Table";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function UserReportGrid() {
  const classes = useStyles();

  const [urlData, setUrlData] = useState({
    url: "dashboard/donor/dashboard/donation_reciept/",
    type: "DONATION_RECEIPT",
  });
  const alert = useAlert();
  const [colData, setColData] = useState([]);

  useEffect(() => {
    if (urlData?.url) {
      getRequest(urlData?.url)
        .then((res) => {
          let tempData = [];
          if (urlData?.type === "DONATION_RECEIPT") {
            if (res?.data?.results) {
              res?.data?.results?.forEach((item) => {
                tempData.push({
                  id: item?.id,
                  col1: item?.donation?.ngo?.ngo_name,
                  col2: item?.created_at,
                  col3: item?.donation?.pre_donation?.ammount,
                  col4: item?.reciept,
                });
              });
            } else {
              // alert.error("Something went wrong");
            }
          } else if (urlData?.type === "IPMC_PG") {
            if (res?.data?.results) {
              res?.data?.results?.forEach((item) => {
                tempData.push({
                  id: item?.id,
                  col1: item?.ngo,
                  col2: item?.created_at,
                  col3: item?.ammount,
                  col4: item?.reciept,
                });
              });
            } else {
              // alert.error("Something went wrong");
            }
          } else if (urlData?.type === "UTILISATION_REPORT") {
            if (res?.data?.results) {
              res?.data?.results?.forEach((item) => {
                tempData.push({
                  id: item?.id,
                  col1: item?.ngo?.ngo_name,
                  col2: item?.total_ammount_used,
                  col3: item?.total_amount_recieved,
                  col4: item?.utilization_report,
                });
              });
            } else {
              // alert.error("Something went wrong");
            }
          } else if (urlData?.type === "IMPACT_RECORD") {
            if (res?.data?.results) {
              res?.data?.results?.forEach((item) => {
                tempData.push({
                  id: item?.id,
                  col1: item?.ngo,
                  col2: item?.utilization_report?.total_ammount_used,
                  col3: item?.utilization_report?.total_amount_recieved,
                  col4: item?.utilization_report?.utilization_report,
                });
              });
            } else {
              // alert.error("Something went wrong");
            }
          }
          setColData(tempData);
        })
        .catch((err) => {
          // alert.error("Something went wrong");
        });
    }
  }, [urlData]);

  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          style={{ padding: "20px", paddingTop: "0px" }}
        >
          {data.map((item) => (
            <Grid item md={4}>
              <div style={{ height: "130px", display: "grid" }}>
                <ReportCard
                  title={item.title}
                  onClick={() => {
                    setUrlData({
                      url: item.path,
                      type: item.type,
                    });
                  }}
                />
              </div>
            </Grid>
          ))}
          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={DONATION_RECEIPT_COL} list={colData} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

const data = [
  {
    title: `Donation Receipts`,
    path: `dashboard/donor/dashboard/donation_reciept/`,
    type: "DONATION_RECEIPT",
  },
  {
    title: `IPMC + PG Charges Receipt`,
    path: `dashboard/donor/dashboard/impcpg_certificate/`,
    type: "IPMC_PG",
  },
  {
    title: `Tax Exemption Certificates`,
    path: `dashboard/donor/dashboard/tax-excemption_certificate/`,
    type: "TAX_EXEMPTION",
  },
  {
    title: `Certificates Of Donations`,
    path: `dashboard/donor/dashboard/certificate_of_donation/`,
    type: "DONATION_CERTIFICATE",
  },
  {
    title: `Utilisation Report`,
    path: `dashboard/donor/dashboard/donation_utilization_reports/${localStorage.getItem(
      "slug"
    )}/`,
    type: "UTILISATION_REPORT",
  },
  {
    title: `Impact Records`,
    path: `dashboard/donor/dashboard/impact_report/`,
    type: "IMPACT_RECORD",
  },
  {
    title: `My NGO Reports`,
    path: `dashboard/donor/dashboard/my_ngo_report/${localStorage.getItem(
      "slug"
    )}/`,
    type: "NGO_REPORT",
  },
];

export const columns = [
  {
    field: "col1",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.col1}
      </span>
    ),
  },
  {
    field: "col2",
    type: "text",
    width: 350,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "120px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.col2}
      </span>
    ),
  },
  {
    field: "col3",
    type: "text",
    sortable: false,
    width: 350,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "150px" }}>Date</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.col3}
      </span>
    ),
  },
  {
    field: "col4",
    type: "text",
    sortable: false,
    width: 330,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "120px" }}>Amount</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.col4}</Typography>
      </span>
    ),
  },
];

const DONATION_RECEIPT_COL = [
  {
    field: "col1",
    headerName: "Ngo Name",
    type: "text",
    width: 330,
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.col1}
      </span>
    ),
  },
  {
    field: "col2",
    headerName: "Date",
    type: "text",
    width: 250,
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params?.row?.col2}
      </span>
    ),
  },
  {
    field: "col3",
    type: "text",
    sortable: false,
    headerName: "Amount",
    width: 200,
    // renderHeader: (params) => (
    //   <strong style={{ color: "#5a6268", marginLeft: "150px" }}>Amount</strong>
    // ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.col3 ? `₹ ${params.row.col3}` : ""}
      </span>
    ),
  },
  {
    field: "col4",
    type: "text",
    sortable: false,
    width: 330,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "120px" }}>Receipt</strong>
    ),
    renderCell: (params) => (
      <Button
        color="primary"
        variant="contained"
        style={{
          height: "30px",
          disply: "flex",
          color: "#fff",
          width: "50%",
          boxShadow: "none",
          borderRadius: "8px",
        }}
        onClick={() => {
          window.open(params.row.col4, "_blank");
        }}
      >
        Receipt
      </Button>
      //   <span
      //     style={{
      //       textAlign: "center",
      //       display: "flex",
      //       margin: "auto",
      //       fontSize: "14px",
      //       color: "#94939F",
      //     }}
      //   >
      //     <Typography color="primary">₹ {params.row.col4}</Typography>
      //   </span>
    ),
  },
];

export const row = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns2 = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "100px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },
  {
    field: "date",
    type: "text",
    sortable: false,
    width: 250,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "90px" }}>Date</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.date}
      </span>
    ),
  },
  {
    field: "amount_received",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>
        Amount Received
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount_received}</Typography>
      </span>
    ),
  },
  {
    field: "amount_utilised",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "50px" }}>
        Amount Utilised
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount_utilised}</Typography>
      </span>
    ),
  },
];

export const row2 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns3 = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "100px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },
  {
    field: "date",
    type: "text",
    sortable: false,
    width: 250,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "90px" }}>Date</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.date}
      </span>
    ),
  },

  {
    field: "amount_utilised",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "50px" }}>
        Amount Utilised
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount_utilised}</Typography>
      </span>
    ),
  },
  {
    field: "percentage",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>
        Percentage Utilised
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary"> {params.row.percentage} % </Typography>
      </span>
    ),
  },
];

export const row3 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns4 = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 530,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "210px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },
  {
    field: "certificates",
    type: "text",
    sortable: false,
    width: 500,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "170px" }}>
        Utilisation Certificates
      </strong>
    ),
    renderCell: (params) => (
      <>
        <div
          style={{
            display: "flex",
            margin: "auto",
          }}
        >
          <Button color="primary" variant="text">
            Click Here
          </Button>
        </div>
      </>
    ),
  },
];

export const row4 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];
