import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: "#fff",
		padding: "1.5rem",
		borderRadius: "1rem",
	},
	image: {
		height: "60%",
		width: "25rem",
		objectFit: "cover",
		borderRadius: "1rem",
	},

	box: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const RewardCard = ({ reward }) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<img alt="image" src={reward?.image} className={classes.image} />
			<div>
				<p>{reward?.title}</p>
				<p>{reward?.description}</p>
			</div>
		</div>
	);
};

export default RewardCard;
