import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useAlert } from "react-alert";
import { postRequest } from "../../../../apis/baseApi";
import CustomButtonCircularProgress from "../../../CircularProgress";
import FileUploaderColumn from "../../../FileInput/FileUploadColumn";

const useStyles = makeStyles((theme) => ({
  download_main_grid: {
    display: "flex",
    alignItems: "center",
  },
  download: {
    boxShadow: "none",
    borderRadius: "8px",
    color: "#fff",
    height: "40px",
    display: "flex",
  },
}));

export default function ReportUtilisation() {
  const classes = useStyles();

  const alert = useAlert();
  const initialState = {
    amountUsed: "",
    amountReceived: "",
    actualAmountReceived: "",
    document: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [errorForm, setErrorForm] = useState(initialState);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function addNewsUpates() {
    if (validateForm()) {
      const postData = new FormData();
      postData.set("total_ammount_used", formData?.amountUsed);
      postData.set("total_amount_recieved", formData?.amountReceived);
      postData.set("actual_ammount_recieved", formData?.actualAmountReceived);
      postData.set("utilization_report", formData?.document);
      setIsLoading(true);
      postRequest(
        `dashboard/ngo/dashboard/create_get_utilization_report_list/${localStorage.getItem(
          "ngo_slug"
        )}/`,
        postData
      )
        .then((res) => {
          if (res?.data?.data) {
            alert.success("Utilization Report added successfully");
            setFormData(initialState);
          } else {
            // alert.error("Something went wrong!")
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          // alert.error("Something went wrong!")
        });
    }
  }

  function validateForm() {
    let error = false;
    let amountUsedError = "";
    let amountReceivedError = "";
    let documentError = "";
    let actualAmountReceivedError = "";

    if (!formData?.amountUsed) {
      error = true;
      setIsError(true);
      amountUsedError = "Please enter amount used";
    }
    if (!formData?.amountReceived) {
      error = true;
      setIsError(true);
      amountReceivedError = "Please enter amount received";
    }

    if (!formData?.actualAmountReceived) {
      error = true;
      setIsError(true);
      actualAmountReceivedError = "Please enter actual amount received";
    }
    if (!formData?.document) {
      error = true;
      setIsError(true);
      documentError = "Please add document";
      alert.error("Please upload a file!");
    }

    setErrorForm({
      amountUsed: amountUsedError,
      amountReceived: amountReceivedError,
      actualAmountReceived: actualAmountReceivedError,
      document: documentError,
    });
    return error ? false : true;
  }

  return (
    <>
      <div>
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item md={2} style={{ display: "grid", height: "100%" }}>
            <TextField
              rows={4}
              type="number"
              //   multiline
              variant="outlined"
              placeholder="Total Amount Received"
              color="primary"
              style={{ borderRadius: "8px" }}
              error={isError}
              helperText={isError ? errorForm?.amountReceived : ""}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  amountReceived: event.target.value,
                });
                setErrorForm({ ...errorForm, amountReceived: "" });
              }}
              value={formData?.amountReceived}
            />
          </Grid>
          <Grid item md={2} style={{ display: "grid" }}>
            <TextField
              rows={4}
              //   multiline
              variant="outlined"
              type="number"
              placeholder="Amount Received"
              color="primary"
              style={{ borderRadius: "8px" }}
              error={isError}
              helperText={isError ? errorForm?.actualAmountReceived : ""}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  actualAmountReceived: event.target.value,
                });
                setErrorForm({ ...errorForm, actualAmountReceived: "" });
              }}
              value={formData?.actualAmountReceived}
            />
          </Grid>

          <Grid item md={2} style={{ display: "grid" }}>
            <TextField
              rows={4}
              //   multiline
              variant="outlined"
              type="number"
              placeholder="Amount Utilised"
              color="primary"
              style={{ borderRadius: "8px" }}
              error={isError}
              helperText={isError ? errorForm?.amountUsed : ""}
              onChange={(event) => {
                setFormData({ ...formData, amountUsed: event.target.value });
                setErrorForm({ ...errorForm, amountUsed: "" });
              }}
              value={formData?.amountUsed}
            />
          </Grid>

          <Grid item md={6} className={classes.download_main_grid}>
            <Grid container spacing={2}>
              <Grid
                item
                md={6}
                style={{ display: "grid", justifyContent: "right" }}
              >
                {/* <Button
                  color="primary"
                  variant="contained"
                  className={classes.download}
                >
                  <img
                    src={upload}
                    height="15px"
                    alt="icon"
                    style={{ marginRight: "5px" }}
                  /> */}
                <FileUploaderColumn
                  setFile={(file) => {
                    setFormData({ ...formData, document: file });
                  }}
                />
                {/* </Button> */}
              </Grid>
              <Grid item md={6}>
                <div
                  style={{
                    border: "2px solid #C88FEF",
                    borderRadius: "30px",
                    height: "30px",
                    display: "flex",
                    padding: "2px",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      borderRadius: "30px",
                      boxShadow: "none",
                      padding: "none",
                      width: "100%",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      //height: "24.5px",
                    }}
                    onClick={() => {
                      addNewsUpates();
                    }}
                  >
                    <Typography
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                      }}
                    >
                      {isLoading ? <CustomButtonCircularProgress /> : "Add"}
                    </Typography>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
