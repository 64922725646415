import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      border: "1px solid",
      borderRadius: "8px",
    },
  },
}));

export default function Total(props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.card}>
        <Typography
          variant="h2"
          color="primary"
          noWrap
          style={{ fontFamily: "Marcellus" }}
        >
          {props.name}
        </Typography>
        <Typography variant="body2" style={{ color: "#626262" }}>
          {props.member}
        </Typography>
      </div>
    </>
  );
}
