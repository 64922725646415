import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import ReportCard from "../../../Cards/DashBoadCards/ReportCard/ReportCard";
import Table from "../../../Common/Table/Table";
import { useEffect, useState } from "react";
import { getRequest } from "../../../../apis/baseApi";
import { useAlert } from "react-alert";
import Services from "../MembershipData/Services";
import BenefitsAvailable from "../MembershipData/BenefitsAvailable";
import BenefitsAvailed from "../MembershipData/BenefitsAvailed";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function MemberGrid() {
  const classes = useStyles();
  const [data, setData] = useState({});

  const [currentCard, setCurrentCard] = useState("servicesBought");

  const alert = useAlert();

  function getDataComponent() {
    switch (currentCard) {
      case "servicesBought":
        return <Services servicesData={data?.servicesBoughtData} />;
      case "benefitsAvailable":
        return <BenefitsAvailable benefits={data?.benefitsAvailableData} />;
      case "benefitsAvailed":
        return <BenefitsAvailed benefits={data?.benefitsAvailedData} />;
      default:
        return <></>;
    }
  }

  useEffect(() => {
    getRequest(
      `dashboard/ngo/dashboard/get_ngo_membership_details/${localStorage.getItem(
        "ngo_slug"
      )}/`
    )
      .then((res) => {
        if (res?.data?.data) {
          const data1 = res?.data?.data[0];
          setData({
            membershipTier: data1?.tier,
            membershipCost: data1?.membership_cost,
            servicesBought: data1?.other_data?.services,
            benefitsAvailable: data1?.other_data?.benefits_avilable,
            benefitsAvailed: data1?.other_data?.benefits_availed,
            benefitsRemaining: data1?.other_data?.benefits_remaining,
            numberDonations: res?.data?.total_donations,
            uniqueDonors: res?.data?.total_unique_donations,
            servicesBoughtData: data1?.services,
            benefitsAvailableData: data1?.benefits_avilable,
            benefitsAvailedData: data1?.benefits_availed,
            amountMobilised: "",
            costOfFund: "",
            ratingScore: "",
            costAnalysis: "",
          });
        } else {
          // alert.error("Something went wrong!");
        }
      })
      .catch((err) => {
        // alert.error("Something went wrong!");
      });
  }, []);

  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          style={{ padding: "20px", paddingTop: "0px" }}
        >
          {data2.map((item) => (
            <Grid item md={4}>
              <div style={{ height: "130px", display: "grid" }}>
                <ReportCard
                  title={item.title}
                  num={data[item?.type] ? data[item?.type] : item.number}
                  onClick={() => {
                    setCurrentCard(item?.type);
                  }}
                />
              </div>
            </Grid>
          ))}

          {getDataComponent()}

          {/* <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns4} list={row4} />
          </Grid>
          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns6} list={row6} />
          </Grid>
          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns5} list={row5} />
          </Grid>

          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns2} list={row2} />
          </Grid>

          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns} list={row} />
          </Grid> */}
        </Grid>
      </Paper>
    </>
  );
}

const data2 = [
  { title: `Membership Tier`, type: "membershipTier" },
  {
    title: `Total Membership Cost `,
    number: "Rs. 5,000",
    type: "membershipCost",
  },

  { title: `Services Bought`, number: "21", type: "servicesBought" },

  {
    title: `Membership Benefits Available`,
    number: "2",
    type: "benefitsAvailable",
  },

  {
    title: `Membership Benefits Availed`,
    number: "2",
    type: "benefitsAvailed",
  },

  {
    title: `Membership Benefits Remaining`,
    number: "2",
    type: "benefitsRemaining",
  },

  { title: `Total Number of Donations`, number: "2", type: "numberDonations" },

  { title: `Number of Unique Donors`, number: "2", type: "uniqueDonors" },

  {
    title: `Total Donation Amount Mobilised`,
    number: "Rs. 20,000",
    type: "amountMobilised",
  },

  {
    title: `Cost of Funds/Donations Mobilised`,
    number: "Rs. 4,000",
    type: "costOfFund",
  },

  {
    title: `Improvement in Rating Score`,
    number: "14% Increase",
    type: "ratingScore",
  },

  {
    title: `Membership Cost Benefit Analysis`,
    number: "Rs. 20,000",
    type: "costAnalysis",
  },
];

export const columns = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donor_id",
    type: "text",
    width: 350,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "130px" }}>
        Donor ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        848566 {params.row.donor_id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 350,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "120px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },

  {
    field: "amount",
    type: "text",
    sortable: false,
    width: 330,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "90px" }}>
        Total Amount Donated
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount}</Typography>
      </span>
    ),
  },
];

export const row = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns2 = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donor_id",
    type: "text",
    width: 250,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "80px" }}>Donor ID</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        848566 {params.row.donor_id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "100px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },

  {
    field: "amount_received",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>
        Amount Received
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount_received}</Typography>
      </span>
    ),
  },
  {
    field: "amount_utilised",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "50px" }}>
        Amount Utilised
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount_utilised}</Typography>
      </span>
    ),
  },
];

export const row2 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns4 = [
  {
    field: "amount",
    headerName: "S. No",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "90px" }}>
        Total Amount
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.amount}
      </span>
    ),
  },
  {
    field: "discount",
    type: "text",
    width: 430,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "160px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        Rs. 1,400 {params.row.discount}
      </span>
    ),
  },
  {
    field: "amount_after_discount",
    type: "text",
    sortable: false,
    width: 400,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "120px" }}>
        Amount after Discount
      </strong>
    ),
    renderCell: (params) => (
      <>
        <span
          style={{
            textAlign: "left",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
            color: "#94939F",
          }}
        >
          <Typography color="primary">
            Rs {params.row.amount_after_discount}
          </Typography>
        </span>
      </>
    ),
  },
];

export const row4 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns6 = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 200,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "70px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "services_bought",
    type: "text",
    width: 450,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "160px" }}>
        Services Bought
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        Rs. 1,400 {params.row.service_bought}
      </span>
    ),
  },
  {
    field: "amount",
    type: "text",
    sortable: false,
    width: 400,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "160px" }}>Amount</strong>
    ),
    renderCell: (params) => (
      <>
        <span
          style={{
            textAlign: "left",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
            color: "#94939F",
          }}
        >
          <Typography color="primary">Rs {params.row.amount}</Typography>
        </span>
      </>
    ),
  },
];

export const row6 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns5 = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 350,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "120px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },
  {
    field: "date",
    type: "text",
    sortable: false,
    width: 350,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "150px" }}>Date</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.date}
      </span>
    ),
  },
  {
    field: "amount",
    type: "text",
    sortable: false,
    width: 330,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "120px" }}>Amount</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount}</Typography>
      </span>
    ),
  },
];

export const row5 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];
