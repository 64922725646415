import {
  alpha,
  Button,
  Chip,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BarChart from "../../../Common/Graph/BarChart";
import sideimg from "../../../assets/png/Group39.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    //backgroundColor: alpha(theme.palette.primary.main, 0.1),
    padding: "25px",
    borderColor: theme.palette.primary.main,
    padding: "7px",
    borderRadius: "15px",
    marginTop: "20px",
  },
  btn: {
    borderRadius: "30px",
    boxShadow: "none",
    padding: "none",
    width: "100%",
  },
  mainbtn: {
    border: "2px solid #C88FEF",
    borderRadius: "30px",
    height: "30px",
    display: "flex",
    padding: "2px",
    //width: "100%",
    alignItems: "center",
  },
  firstgrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    flexDirection: "column",
  },
  lastgrid: {
    backgroundColor: "#F9F4FD",
    //borderRadius: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Farmers() {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={7}>
            {data.map((item) => (
              <Grid container spacing={2}>
                <Grid
                  item
                  md={6}
                  className={classes.firstgrid}
                  style={{ paddingBottom: "40px", paddingTop: "40px" }}
                >
                  <Typography style={{ color: "#94939F", fontSize: "14px" }}>
                    {item.title}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={6}
                  className={classes.lastgrid}
                  style={{ paddingBottom: "40px", paddingTop: "40px" }}
                >
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {item.number}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            md={5}
            // style={{
            //   display: "flex",
            //   flexDirection: "column",
            //   justifyContent: "flex-end",
            // }}
          >
            <div>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                List of Sub-causes
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                You haven't made any donation.
              </Typography>
              {/* <Typography
                variant="body2"
                color="primary"
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                Increase Production and Income of Farmers
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                style={{ marginBottom: "20px" }}
              >
                Technology, Infrastructure and Innovation
              </Typography> */}
              <img src={sideimg} height="250px" />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

const data = [
  {
    title: "Number of NGO’s",
    number: "0",
  },
  {
    title: "Total Amount Raised",
    number: "Rs. 0",
  },
  {
    title: "NGO Reported Utilised Amount",
    number: "Rs. 0",
  },
  {
    title: "Verified Utilised Amount",
    number: "Rs. 0",
  },
];
