import React from "react";
import Heading from "../../Common/Heading/Heading";
import { Grid, makeStyles, Typography, Paper } from "@material-ui/core";
import d3 from "../../assets/png/icon/Lock.png";
import d2 from "../../assets/png/icon/Dashboard.png";
import d1 from "../../assets/png/icon/Security.png";
import CardDrivers from "../../Cards/CardDrivers/CardDrivers";

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: "10px",
    boxShadow: "-22px 25px 0px 0px",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)",
    borderRadius: "10px",
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(12),
      //paddingRight: 0,
      borderRadius: "10px",
    },
  },
  overflow: {
    boxShadow: "0px 0px 30px 0px rgb(0 0 0 / 15%)",
    padding: "103px 9px 5px 49px",
    borderRadius: "10px",
    background: "#fff",
    margin: "-305px 0px 0px -22px",
    fontSize: "14px",
    height: "190px",
  },
}));

export default function KeyDriverSection() {
  const classes = useStyles();
  return (
    <>
      <div style={{ marginTop: "50px", marginBottom: "50px" }}>
        <Heading title="Key Drivers" />
        <Grid
          container
          spacing={2}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {total.map((item) => (
            <Grid item md={3}>
              <CardDrivers name={item.title} number={item.num} img={item.img} />
            </Grid>
          ))}
          <Grid item md={5}></Grid>
        </Grid>
      </div>
    </>
  );
}

const total = [
  {
    title: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et etiam mauris vitae pulvinar urna adipiscing gravida. Morbi arcu sed est ut aliquam. Duis sociis cursus urna, nunc integer ut non. Nec a lectus vel, proin dapibus. `,
    num: "01",
    img: d1,
  },
  {
    title: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et etiam mauris vitae pulvinar urna adipiscing gravida. Morbi arcu sed est ut aliquam. Duis sociis cursus urna, nunc integer ut non. Nec a lectus vel, proin dapibus. `,
    num: "02",
    img: d2,
  },
  {
    title: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et etiam mauris vitae pulvinar urna adipiscing gravida. Morbi arcu sed est ut aliquam. Duis sociis cursus urna, nunc integer ut non. Nec a lectus vel, proin dapibus. `,
    num: "03",
    img: d3,
  },
];
