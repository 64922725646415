import React from "react";
import { v4 as uuidv4 } from "uuid";
import Carroussel from "../../Common/Extra/Carroussel";
import Demo from "../../assets/png/team-work/ngo.jpg";
import { useEffect, useState } from "react";

function ImageSlider({image}) {

 
  // const [cards, setCards] = useState([]);

  // useEffect(() => {

  //   if(image){
  //     setCards([
  //       {
  //         key: uuidv4(),
  //         content: (
  //           <img
  //             // src={image? image : Demo}
  //             src={Demo}
  //             height="300px"
  //             alt="demo"
  //             style={{ borderRadius: "10px" }}
  //           />
  //         ),
  //       },
  //       {
  //         key: uuidv4(),
  //         content: (
  //           <img
  //             src={image? image : Demo}
  //             height="300px"
  //             alt="demo"
  //             style={{ borderRadius: "10px" }}
  //           />
  //         ),
  //       },
  //       {
  //         key: uuidv4(),
  //         content: (
  //           <img
  //             // src={image? image : Demo}
  //             src={Demo}
  //             height="300px"
  //             alt="demo"
  //             style={{ borderRadius: "10px" }}
  //           />
  //         ),
  //       },
  //       {
  //         key: uuidv4(),
  //         content: (
  //           <img
  //             // src={image? image : Demo}
  //             src={Demo}
  //             height="300px"
  //             alt="demo"
  //             style={{ borderRadius: "10px" }}
  //           />
  //         ),
  //       },
  //       {
  //         key: uuidv4(),
  //         content: (
  //           <img
  //             // src={image? image : Demo}
  //             src={Demo}
  //             height="300px"
  //             alt="demo"
  //             style={{ borderRadius: "10px" }}
  //           />
  //         ),
  //       },
  //       {
  //         key: uuidv4(),
  //         content: (
  //           <img
  //             // src={image? image : Demo}
  //             src={Demo}
  //             height="300px"
  //             alt="demo"
  //             style={{ borderRadius: "10px" }}
  //           />
  //         ),
  //       },
  //       {
  //         key: uuidv4(),
  //         content: (
  //           <img
  //             // src={image? image : Demo}
  //             src={Demo}
  //             height="300px"
  //             alt="demo"
  //             style={{ borderRadius: "10px" }}
  //           />
  //         ),
  //       },
  //     ])
  //   }

  // }, [image])

//   https://ikigaportfolio.s3.amazonaws.com/static_image/10/What_We_Do_NEW-3.png
// https://ikigaportfolio.s3.amazonaws.com/static_image/9/What_We_Do_NEW-2.png
// https://ikigaportfolio.s3.amazonaws.com/static_image/10/What_We_Do_NEW-1.png
// https://ikigaportfolio.s3.amazonaws.com/static_image/10/What_We_Do_NEW-4.png
// https://ikigaportfolio.s3.amazonaws.com/static_image/10/What_We_Do_NEW-5.png
// https://ikigaportfolio.s3.amazonaws.com/static_image/10/What_We_Do_NEW-6.png

  const cards = [
    // {
    //   key: uuidv4(),
    //   content: (
    //     <img
    //       src={"https://ikigaportfolio.s3.amazonaws.com/static_image/10/What_We_Do_NEW-3.png"}
    //       // src={Demo}
    //       height="300px"
    //       alt="demo"
    //       style={{ borderRadius: "10px" }}
    //     />
    //   ),
    // },
    {
      key: uuidv4(),
      content: (
        <img
          src={"https://ikigaportfolio.s3.amazonaws.com/static_image/9/What_We_Do_NEW-2.png"}
          height="300px"
          alt="demo"
          style={{ borderRadius: "10px" }}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <img
          src={"https://ikigaportfolio.s3.amazonaws.com/static_image/8/What_We_Do_NEW-1.png"}
          // src={Demo}
          height="300px"
          alt="demo"
          style={{ borderRadius: "10px" }}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <img
          src={"https://ikigaportfolio.s3.amazonaws.com/static_image/5/What_We_Do_NEW-_4.png"}
          // src={Demo}
          height="300px"
          alt="demo"
          style={{ borderRadius: "10px" }}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <img
          // src={image? image : Demo}
          src={"https://ikigaportfolio.s3.amazonaws.com/static_image/6/What_We_Do_NEW-5.png"}
          height="300px"
          alt="demo"
          style={{ borderRadius: "10px" }}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <img
          // src={image? image : Demo}
          src={"https://ikigaportfolio.s3.amazonaws.com/static_image/7/What_We_Do_NEW-6.png"}
          height="300px"
          alt="demo"
          style={{ borderRadius: "10px" }}
        />
      ),
    },
    // {
    //   key: uuidv4(),
    //   content: (
    //     <img
    //       // src={image? image : Demo}
    //       src={Demo}
    //       height="300px"
    //       alt="demo"
    //       style={{ borderRadius: "10px" }}
    //     />
    //   ),
    // },
  ];


  return (
    <>
      <Carroussel
        cards={cards}
        height="370px"
        width="100%"
        margin="0 auto"
        offset={2}
        showArrows={false}
      />
    </>
  );
}

export default ImageSlider;
