import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    boxShadow: "0px 10px 30px -15px rgba(0, 0, 0, 0.3)",
    borderRadius: "8px",
    height: "250px",
    width: "300px",
  },
  btn: {
    boxShadow: "none",
    borderRadius: "8px",
    color: "#fff",
    height: "50px",
  },
}));

export default function ChooseOption(props) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <div className={classes.card}>
        <Typography
          variant="body2"
          style={{
            marginBottom: "40px",
            textAlign: "justify",
          }}
        >
          {props.name}
        </Typography>
        <Button variant="contained" color="primary" className={classes.btn} onClick={() => {
          if(props?.buttonId === 2)
            {
              history.push("our-ngo-partner")
            }
            else{
              props.setRecommendMe(true);
            }
        }}>
          {props.btn}
        </Button>
      </div>
    </>
  );
}
