import {
    Paper,
    Typography
} from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      boxShadow: "none",
      textAlign: "center",
      padding: 20,
      borderRadius: 22,
    },
    btn: {
      color: "#fff",
      paddingLeft: "60px",
      paddingRight: "60px",
      boxShadow: "none",
      marginTop: "50px",
      marginBottom: "50px",
      borderRadius: 8,
      height: 46,
    },
    reqfieldtext: {
      fontSize: "12px",
      textAlign: "left",
      display: "flex",
    },
  }));
  
  function NotFound() {
    const classes = useStyles();
  
    return (
    //   <Layout>
        <Paper className={classes.paper}>
          <Typography variant="h3" color="primary" style={{ marginTop: "20px" }}>
            Page Not Found
          </Typography>
        </Paper>
    //   </Layout>
    );
  }
  
  export default NotFound;
  