import axios from "axios";
const BASE_API = process.env.REACT_APP_BACKEND_ENDPOINT;
const PUBLIC_API = process.env.REACT_APP_BACKEND_ENDPOINT_STATIC;

const getRequest = function (
  url,
  headers1 = {
    // withCredentials: true
  }
) {
  if (localStorage.getItem("user_token")) {
    headers1.Authorization = `Bearer ${localStorage.getItem("user_token")}`;
  }
  return axios.get(`${BASE_API}${url}`, { headers: headers1 });
};

const postRequest = function (
  url,
  data,
  headers1 = {
    // withCredentials: true,
  }
) {
  if (localStorage.getItem("user_token")) {
    headers1.Authorization = `Bearer ${localStorage.getItem("user_token")}`;
  }
  return axios.post(`${BASE_API}${url}`, data, { headers: headers1 });
};

const patchRequest = function (
  url,
  data,
  headers1 = {
    // withCredentials: true
  }
) {
  if (localStorage.getItem("user_token")) {
    headers1.Authorization = `Bearer ${localStorage.getItem("user_token")}`;
  }
  return axios.patch(`${BASE_API}${url}`, data, { headers: headers1 });
};

const putRequest = function (
  url,
  data,
  headers1 = {
    // withCredentials: true
  }
) {
  if (localStorage.getItem("user_token")) {
    headers1.Authorization = `Bearer ${localStorage.getItem("user_token")}`;
  }
  return axios.put(`${BASE_API}${url}`, data, { headers: headers1 });
};

const deleteRequest = function (
  url,
  headers1 = {
    // withCredentials: true,
  }
) {
  if (localStorage.getItem("user_token")) {
    headers1.Authorization = `Bearer ${localStorage.getItem("user_token")}`;
  }

  return axios.delete(`${BASE_API}${url}`, { headers: headers1 });
};

const getStaticPageRequest = function (
  url,
  headers1 = {
    // withCredentials: true
  }
) {
  if (localStorage.getItem("user_token")) {
    headers1.Authorization = `Bearer ${localStorage.getItem("user_token")}`;
  }
  return axios.get(`${PUBLIC_API}${url}`, { headers: headers1 });
};

export {
  getRequest,
  postRequest,
  patchRequest,
  putRequest,
  deleteRequest,
  getStaticPageRequest,
};
