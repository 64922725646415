import { InputAdornment, TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Sicon from "../../../assets/png/icon/search.png";
import { useState, useEffect } from "react";
import { useCallback } from "react";
import { getRequest } from "../../../../apis/baseApi";
import debounce from "lodash.debounce";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 300,
    borderRadius: "8px",
    boxShadow: "none",
    // border: "1.5px solid #D0D0D0",
    marginRight: "20px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function Search() {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [data,setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (searchTerm) {
      // debouncedSave(searchTerm);
      getSearchNgo()
    }
  }, [searchTerm]);

  function getSearchNgo(){
    if(searchTerm){
      getRequest(`ngo/ngo_list_filter/?ngo_name=${searchTerm}`).then(res => {
        setData(res?.data?.results);
      }).catch(err => {
     
      })
    }
  }

  return (
    <>
      <Paper className={classes.root}>
        <Autocomplete
          freeSolo
          id="multiple-limit-tags"
          size="small"
          style={{ width: "100%" }}
          classes={{
            root: classes.inputRoot,  
            input: classes.inputInput,
          }}
          options={data}
          getOptionLabel={(option) => option.ngo_name}
          onChange={(e, newValue) => {
              if(newValue){
              history.push(`/ngo-details/?ngo_slug=${newValue?.slug}&ngo_id=${newValue?.id}`);
              }
            }
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search NGO"
              InputProps={{
                ...params.InputProps,
                type: "search",
                startAdornment: (
                  <InputAdornment position="start">
                    <div className={classes.searchIcon}>
                      <img src={Sicon} height="20px" alt="search" />
                    </div>
                  </InputAdornment>
                ),
                // endAdornment: (
                //   <InputAdornment position="end">
                //     <div className={classes.searchIcon}>
                //       {/* <img src={Sicon} height="20px" /> */}
                //       {`<-`}
                //     </div>
                //   </InputAdornment>
                // ),
              }}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          )}
        />
      </Paper>
    </>
  );
}
