import { Typography } from "@material-ui/core";
import React from "react";

function Heading({ title }) {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h2" style={{ fontFamily: "Marcellus" }}>
          {title}
        </Typography>
        <div
          style={{
            backgroundColor: "#C4C4C4",
            height: "1px",
            width: "40%",
            marginLeft: "50px",
          }}
        />
      </div>
    </>
  );
}

export default Heading;
