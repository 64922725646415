import {
  NORMAL_USER_DETAIL
} from "./type";

export const normal = (state = {}, action) => {
  switch (action.type) {
    case NORMAL_USER_DETAIL:
      return { ...state, detail: action.payload };
    default:
      return state;
  }
};
