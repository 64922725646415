import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { postRequest } from "../../apis/baseApi";
import CustomButtonCircularProgress from "../../components/CircularProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
    maxWidth: "250px",
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
  googlebtn: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "30px",
    paddingLeft: "10px",
    paddingRight: "10px",
    minWidth: "150px",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      border: "1px solid",
    },
  },
}));

function ForgotPassword() {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    email: "",
  });

  const [errorValues, setErrorValues] = React.useState({
    email: "",
  });

  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: "" });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateForm = () => {
    let error = false;
    let emailError = "";
    setIsError(false);
    if (!values?.email) {
      emailError = "*Please Enter Email Id";
      setIsError(true);
      error = true;
    }
    setErrorValues({
      email: emailError,
    });
    return error ? false : true;
  };

  const resetPassword = () => {
    if (validateForm()) {
      sendResetPasswordLink();
    }
  };

  const sendResetPasswordLink = () => {
    const postData = {
      email: values?.email,
    };
    setIsLoading(true);
    postRequest("authentication/forget_password_request/", postData)
      .then((res) => {
        if (res?.data) {
          alert.success(
            "Reset password link sent successfully. Please check your mail."
          );
        } else {
          // alert.error("Something went wrong!")
        }
        setIsLoading(false);
      })
      .catch((err) => {
        alert.error(err?.response?.data);
        setIsLoading(false);
      });
  };

  //     const loginUser = () => {
  //       const postData = {
  //         user:{
  //           email:  values?.email,
  //           "password": values?.password
  //       }
  //   }
  //       const url = isNgo ? "authentication/ngo/login/" : "authentication/users/login/";
  //         postRequest(url, postData).then((res) => {
  //           if(res?.data){
  //           dispatch(loggedinUser(true));
  //           dispatch(userDetails({
  //             name: res?.data,
  //             username: res?.data?.username,
  //             token: res?.data?.token,
  //             slug: res?.data?.slug,
  //             isHasNgo: res?.data?.is_has_ngo,
  //             email: res?.data?.email,
  //             profileImage: res?.data?.user_profile?.image
  //           }))
  //           localStorage.setItem('user_token', res?.data?.token);
  //           localStorage.setItem('slug', res?.data?.slug);
  //           localStorage.setItem('user_type', isNgo ? 'NGO' : 'DONOR');
  //           localStorage.setItem('name', `${res?.data?.first_name} ${res?.data?.last_name}`);
  //           localStorage.setItem('profile_image', res?.data?.user_profile?.image);
  //           localStorage.setItem('has_ngo', res?.data?.is_has_ngo);
  //           localStorage.setItem('email', res?.data?.email);
  //           alert.success("Login Successful!!");
  //           isNgo ? history.push("/profile") : history.push('/user/profile');
  //         }else{
  //           alert.error("Email & Password doesn't match");
  //         }
  //       }).catch(err => {
  //         alert.error("Email & Password doesn't match");
  //         // history.push("/profile");
  //       })
  //     }

  return (
    // <Layout>
    <Paper className={classes.paper}>
      <Typography variant="h3" color="primary" style={{ marginTop: "20px" }}>
        Reset Password
      </Typography>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "20px", display: "flex", flexDirection: "col" }}
      >
        <Grid item md={4}></Grid>
        <Grid item md={4} xs={12} style={{ display: "grid" }}>
          <div style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
              Email
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={handleChange("email")}
              error={isError}
              helperText={
                isError && errorValues?.email ? errorValues?.email : ""
              }
            />
            <br />
          </div>
        </Grid>

        <Grid item md={4}>
          {" "}
        </Grid>
      </Grid>
      {/* <Grid item md={4}></Grid> */}
      <div style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          className={classes.btn}
          color="primary"
          onClick={() => {
            resetPassword();
          }}
        >
          {isLoading ? <CustomButtonCircularProgress /> : "Reset Password"}
        </Button>
      </div>
    </Paper>
    // </Layout>
  );
}

export default ForgotPassword;
