import React from "react";
import RatingBanner from "../../components/Section/NgoRatingsection/RatingBanner";
import vactor from "../../components/assets/png/resource.png";
import {
  Grid,
  Paper,
  makeStyles,
  alpha,
  Button,
  Typography,
} from "@material-ui/core";
import Heading from "../../components/Common/Heading/Heading";
import JobApplication from "../../components/Form/Reg/JobApplication";
import PublicLayout from "../../components/Layout/PublicLayout";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 40,
    borderRadius: 22,
    marginBottom: "70px",
    marginTop: "3%",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  btn: {
    color: "#fff",
    boxShadow: "none",
    height: "60px",
    borderRadius: "8px",
  },
}));

export default function Job() {
  const classes = useStyles();
  return (
    <PublicLayout keyss={"involved"}>
    <>
      {/* {data.map((item) => (
        <RatingBanner
          title={item.heading}
          info={item.info}
          image={item.image}
        />
      ))} */}
      <div>
        <Typography
          variant="h2"
          component="h1"
          color="primary"
          style={{ fontFamily: "Marcellus", textAlign: "center" }}
        >
          Jobs
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Ikigai Portfolio is expanding and looking to grow its team. We need a diverse set of talented professionals who are motivated and aligned to the mission and vision of Ikigai Portfolio. As a startup, we try our best to match our compensation to industry standards but what we can promise is an excellent work environment and culture, learning, exposure and opportunities to grow. 
<br />We also have a number of NGO Partners who are looking for team members from time to time. <br />
We will start posting the specific job openings soon. In the meanwhile, if you are interested in exploring job opportunities with Ikigai Portfolio and/or its NGO Partners, please feel free to write to us with your CV at <a
                  href="mailto:support@ikigai.com"
                >hr@ikigaiportfolio.com</a> or you may fill up the form below. 
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={vactor} height="400px" className={classes.img} />
      </div>


      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Heading title="Job Application Form" />
      </div>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography
              variant="h4"
              color="primary"
              style={{ textAlign: "center" }}
            >
              Job Application
            </Typography>
          </Grid>
          <Grid item md={4} />
          <Grid item md={4} xs={12}>
            <JobApplication />
          </Grid>
          <Grid item md={4} />
        </Grid>
      </Paper>
    </>
    </PublicLayout>
  );
}

const data = [
  {
    heading: "Jobs",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum, ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo in tristique feugiat faucibus arcu tempor. Vestibulum, congue vitae eleifend nec maecenas tristique bibendum egestas fermentum. Cursus nunc vulputate cras felis suspendisse convallis nisl.",
    image: vactor,
  },
];
