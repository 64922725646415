import { Typography, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ngoimg from "../../assets/png/team-work/ngo_2.png";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import LanguageIcon from "@mui/icons-material/Language";
import Details from "./Details";
import Rating from "./Rating";
import NgoDocuments from "./NGODocuments";
import { getRequest } from "../../../apis/baseApi";
import { colors } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: "35px",
    paddingRight: "35px",
  },
  basicdetails: {
    marginTop: "20px",
    marginBottom: "20px",
    border: "1px solid #C4C4C4",
    padding: "20px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  basic: {
    marginTop: "20px",
    marginBottom: "20px",
    border: "1px solid #C4C4C4",
    padding: "20px",
    borderRadius: "10px",
  },
}));

function NgoDetailsSection({
  ngoDetail,
  ongoingProject,
  affiliations,
  awards,
}) {
  const classes = useStyles();
  console.log("ngoDetail", ongoingProject);
  localStorage.setItem("ngoSlug", ngoDetail?.slug);

  return (
    <>
      <img
        src={ngoDetail?.ngo_image === null ? ngoimg : ngoDetail?.ngo_image}
        width="100%"
        alt="img"
        height="400px"
      />
      <div style={{ marginTop: "10px" }}>
        <Typography variant="h2">{ngoDetail?.ngo_name}</Typography>
        <div style={{ display: "flex" }}>
          <LocationOnOutlinedIcon color="primary" />
          <Typography
            variant="body2"
            style={{ marginLeft: "10px", color: "#626262" }}
          >
            {ngoDetail?.reg_address_1}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <PhoneOutlinedIcon color="primary" style={{ color: "#C88FEF" }} />
          <Typography
            variant="body2"
            style={{ marginLeft: "10px", color: "#626262" }}
          >
            {ngoDetail?.chief_mobile_number}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <MailOutlineIcon color="primary" style={{ color: "#C88FEF" }} />
          <Typography
            variant="body2"
            style={{ marginLeft: "10px", color: "#626262" }}
          >
            {ngoDetail?.ngo_email}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <LanguageIcon color="primary" style={{ color: "#C88FEF" }} />
          <Typography
            variant="body2"
            style={{ marginLeft: "10px", color: "#626262" }}
          >
            {ngoDetail?.website_link}
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Details />
      </div>
      <div className={classes.basicdetails}>
        <div>
          <Typography
            variant="body1"
            style={{ marginBottom: "10px", fontWeight: "600" }}
          >
            Basic Information about the NGO
          </Typography>
          <Typography variant="body2">
            Legal Form: {""}
            <span style={{ color: "#C88FEF", textTransform: "capitalize" }}>
              {ngoDetail?.legal_Form}
            </span>
          </Typography>
          <br />
          <Typography variant="body2">
            Accepts Foreign Funds as Donation :
            <span style={{ color: "#C88FEF" }}>
              {ngoDetail?.is_eligible_for_foreign_fund ? "Yes" : "No"}
            </span>
          </Typography>
          <br />
          <Typography variant="body2">
            Causes: {""}
            {ngoDetail?.categories_name_2?.map((value) => {
              return (
                <span style={{ color: "#C88FEF", textTransform: "capitalize" }}>
                  {value}
                  <br />
                </span>
              );
            })}
          </Typography>
          <br />
          <Typography variant="body2">
            Project Location: {""}
            {ngoDetail?.categories_name_2?.map((value) => {
              return (
                <span style={{ color: "#C88FEF", textTransform: "capitalize" }}>
                  {value}
                  <br />
                </span>
              );
            })}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            Year of Registration :{" "}
            <span style={{ color: "#C88FEF" }}>
              {ngoDetail?.year_of_registration}
            </span>
          </Typography>
          <br />
          <Typography variant="body2">
            Donation eligible for tax benefit in India :{" "}
            <span style={{ color: "#C88FEF" }}>Yes</span>
          </Typography>
          <br />
          <Typography variant="body2">
            Sub Causes:{" "}
            {ngoDetail?.subcategories_name?.map((value) => {
              return (
                <span style={{ color: "#C88FEF" }}>
                  {value} <br />
                </span>
              );
            })}
          </Typography>
        </div>
      </div>
      <div className={classes.basic}>
        <Typography
          variant="body1"
          style={{ marginBottom: "10px", fontWeight: "600" }}
        >
          Scale
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Typography variant="body2">
              No. of Benefeciaries Served (cumulative) :{""}
              <span style={{ color: "#C88FEF", textTransform: "capitalize" }}>
                {ngoDetail?.number_of_beneficiaries_served}
              </span>
            </Typography>
            <br />
            <Typography variant="body2">
              No. of Full Time Staff :{""}
              <span style={{ color: "#C88FEF", textTransform: "capitalize" }}>
                {ngoDetail?.number_of_full_time_employees}
              </span>
            </Typography>
            <br />
            <Typography variant="body2">
              Size :{""}
              <span style={{ color: "#C88FEF", textTransform: "capitalize" }}>
                {ngoDetail?.bySize}
              </span>
            </Typography>
            <br />
            <Typography variant="body2">
              Geographical Scale :{""}
              <span style={{ color: "#C88FEF", textTransform: "capitalize" }}>
                {ngoDetail?.byGeographicalScale}
              </span>
            </Typography>

            <br />
            <Typography variant="body2">
              Funding:{""}
              <span style={{ color: "#C88FEF", textTransform: "capitalize" }}>
                {ngoDetail?.byFunding}
              </span>
            </Typography>
            <br />
          </div>
          <div>
            <Typography variant="body2">
              No. of Benefeciaries Served (last 1 year) :{""}
              <span style={{ color: "#C88FEF", textTransform: "capitalize" }}>
                100
              </span>
            </Typography>
            <br />
            <Typography variant="body2">
              Average Annual Turnover of last 3 years(in INR Lakh) :{""}
              <span style={{ color: "#C88FEF", textTransform: "capitalize" }}>
                {ngoDetail?.average_annual_turnover_of_last_3_year}
              </span>
            </Typography>

            <br />
            <Typography variant="body2">
              Age Group :{""}
              <span style={{ color: "#C88FEF", textTransform: "capitalize" }}>
                {ngoDetail?.byAge}
              </span>
            </Typography>
            <br />
            <Typography variant="body2">
              Special Interest :{""}
              {ngoDetail?.bySpecialInterest2?.map((value) => {
                return (
                  <span
                    style={{ color: "#C88FEF", textTransform: "capitalize" }}
                  >
                    {value?.name}
                    <br />
                  </span>
                );
              })}
            </Typography>
          </div>
        </div>
      </div>
      <Rating
        data={ngoDetail?.ngo_background}
        ngoSlug={ngoDetail}
        ongoingProject={ongoingProject}
        affiliations={affiliations}
        awards={awards}
      />
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <NgoDocuments ngoSlug={ngoDetail?.slug} />
      </div>
    </>
  );
}

export default NgoDetailsSection;
