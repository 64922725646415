import {
  Button,
  Dialog,
  Divider,
  InputAdornment,
  Slide,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Sicon from "../assets/png/icon/search.png";
import { getRequest } from "../../apis/baseApi";

const useStyles = makeStyles((theme) => ({
  search_btn: {
    display: "flex",
    padding: "20px",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogSearch() {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (searchTerm) {
      // debouncedSave(searchTerm);
      getSearchNgo();
    }
  }, [searchTerm]);

  function getSearchNgo() {
    if (searchTerm) {
      getRequest(`ngo/ngo_list_filter/?ngo_name=${searchTerm}`)
        .then((res) => {
          setData(res?.data?.results);
        })
        .catch((err) => {});
    }
  }

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        color="inherit"
        onClick={handleClickOpen}
        style={{ padding: "0px" }}
      >
        <img src={Sicon} height="20px" />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className={classes.search_btn}>
          <Autocomplete
            freeSolo
            id="multiple-limit-tags"
            size="small"
            style={{ width: "100%" }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            options={data}
            getOptionLabel={(option) => option.ngo_name}
            onChange={(e, newValue) => {
              if (newValue) {
                history.push(
                  `/ngo-details/?ngo_slug=${newValue?.slug}&ngo_id=${newValue?.id}`
                );
                handleClose();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Search"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  startAdornment: (
                    <InputAdornment position="start">
                      <div className={classes.searchIcon}>
                        <img src={Sicon} height="20px" />
                      </div>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            )}
          />
        </div>

        <Divider />
      </Dialog>
    </div>
  );
}

const data = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
];
