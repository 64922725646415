import { alpha, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import vactor from "../../components/assets/png/resource.png";
import Heading from "../../components/Common/Heading/Heading";
import JobApplication from "../../components/Form/Reg/JobApplication";
import PublicLayout from "../../components/Layout/PublicLayout";
import ReferNGO from "../../components/Form/Reg/ReferNGO";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 40,
    borderRadius: 22,
    marginBottom: "70px",
    marginTop: "3%",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  btn: {
    color: "#fff",
    boxShadow: "none",
    height: "60px",
    borderRadius: "8px",
  },
  img: {
    borderRadius: "30%",
    [theme.breakpoints.only("sm")]: {
      width: "auto",
      height: "200px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "auto",
      height: "100px",
    },
  },
}));

export default function ReferAnNGO() {
  const classes = useStyles();
  return (
    <PublicLayout keyss={"involved"}>
      <>
        {/* {data.map((item) => (
          <RatingBanner
            title={item.heading}
            info={item.info}
            image={item.image}
          />
        ))} */}

        <div>
          <Typography
            variant="h2"
            component="h1"
            color="primary"
            style={{ fontFamily: "Marcellus", textAlign: "center" }}
          >
            Refer an NGO
          </Typography>
          <Typography
            variant="body2"
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            We are constantly looking to expand our Partner NGO Pool so that we
            can provide our members with a wider choice of NGOs to donate to
            from diverse causes and sub-causes, from every part of the country,
            of various sizes etc. If there are any NGOs that you would like to
            see on our platform and those that you would like to donate to from
            our platform but if they are not part of our Partner NGO Pool,
            please refer such NGOs to us and we will be happy to reach out to
            them. Please fill out the form below to refer an NGO. Please note
            that you will have to fill a separate form for each NGO that you may
            want to refer.
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={vactor} height="400px" className={classes.img} />
        </div>

        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Heading title="NGO Reference Form" />
        </div>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography
                variant="h4"
                color="primary"
                style={{ textAlign: "center" }}
              >
                NGO Details
              </Typography>
            </Grid>
            <Grid item md={4} />
            <Grid item md={4} xs={12}>
              <ReferNGO />
            </Grid>
            <Grid item md={4} />
          </Grid>
        </Paper>
      </>
    </PublicLayout>
  );
}

const data = [
  {
    heading: "Volunteer",
    info: "Are you passionate about making this world a better place, helping those who need it the most or you want to give back to society by being a responsible citizen? Do you believe that merely money cannot make all the difference but one must get more actively involved, understand and participate in the interventions to bring about real change? You have a few hours, a few days, weeks, or months and want to engage productively and meaningfully? Do you have skills you want to be put to use for the betterment of society or you are a student and want to gain some invaluable real life experience before starting your career? Tell us how you would like to contribute and we will match you to the perfect volunteering opportunities, with Ikigai Portfolio or with one of our NGO Partners. You may write to us with your CV at hr@ikigaiportfolio.com or you may fill up the form below. ",
    image: vactor,
  },
];
