import { Divider, Grid, makeStyles, Paper } from "@material-ui/core";
import ReportCard from "../../../Cards/DashBoadCards/ReportCard/ReportCard";
import { getRequest } from "../../../../apis/baseApi";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function RatingGrid() {
  const classes = useStyles();
  const alert = useAlert();

  const [ratingData, setRatingData] = useState({});

  useEffect(() => {
    getRequest(`ngo/ngo_ratings/${localStorage.getItem("ngo_slug")}/`)
      .then((res) => {
        if (res?.data?.data) {
          setRatingData(res?.data?.data);
        } else {
          // alert.error("Something went wrong!!")
        }
      })
      .catch((err) => {
        // alert.error("Something went wrong!!");
      });
  }, []);

  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          style={{ padding: "20px", paddingTop: "20px" }}
        >
          <Grid item md={6}>
            <div style={{ height: "130px", display: "grid" }}>
              <ReportCard title={"Report Any Discrepancies"} />
            </div>
          </Grid>
          <Grid item md={6}>
            <div style={{ height: "130px", display: "grid" }}>
              <ReportCard title={"Upload Rating Action Taken Report"} />
            </div>
          </Grid>
        </Grid>
        <Grid>
          <Divider />
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ padding: "20px", paddingTop: "20px" }}
        >
          {data.map((item) => (
            <Grid item md={4}>
              <div style={{ height: "130px", display: "grid" }}>
                <ReportCard
                  title={item.title}
                  num={
                    ratingData && ratingData[item?.type]
                      ? ratingData[item?.type]
                      : ""
                  }
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </>
  );
}

const data = [
  {
    title: `Governance and Transparency`,
    number: `75`,
    type: "governance_and_transparency",
  },
  {
    title: `Strategic Planning and Decision Making Process`,
    number: "95",
    type: "strategic_planing_and_dec_making",
  },

  {
    title: `Operational Excellence, Stakeholder Management & Impact`,
    number: "84 ",
    type: "opernl_exclnt_stkhldr_and_impact",
  },

  {
    title: `Risk Management, Assessments, M&E and Due Diligence`,
    number: "75",
    type: "risk_mngmt_and_due_diligence",
  },

  {
    title: `Organisational Sustainability, Fund Diversity and Dependence`,
    number: "82",
    type: "orgsnl_sustanbility_fund_diversity_and_depdnc",
  },

  {
    title: `REcognition and Thought Leadership`,
    number: "90",
    type: "recg_and_through_ldrshp",
  },

  {
    title: `Functional Area EXpertise`,
    number: "20",
    type: "functional_area_expertise",
  },

  { title: `Average Rating`, number: "2", type: "average_rating" },
];
