import Heading from "../../Common/Heading/Heading";
import React, { Fragment, useEffect } from "react";
import {
  Typography,
  Box,
  Hidden,
  makeStyles,
  Container,
  createStyles,
  Grid,
} from "@material-ui/core";
import demo from "../../assets/png/ratingmethod.png";
import Method from "../../Cards/Total/Method";

const useStyles = makeStyles((theme) =>
  createStyles({
    cardimg: {
      [theme.breakpoints.only("sm")]: {
        width: "auto",
        height: "200px",
      },
      [theme.breakpoints.only("xs")]: {
        width: "auto",
        height: "300px",
      },
    },
    title: {
      paddingTop: "20px",
    },

    title2: {
      //  fontSize: "35px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
    },
    title3: {
      //fontSize: "22px",
      //fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      marginTop: "3%",
    },
    details: {
      //fontSize: "13px",
      marginBottom: "8px",
      //fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      textAlign: "justify",
      color: "#333",
    },
    details2: {
      //  fontSize: "13px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      textAlign: "justify",
      color: "#333",
    },
    gridimg: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

export default function RatingMethodology() {
  const classes = useStyles();
  return (
    <>
      <div style={{ marginTop: "3%" }}>
        {data.map((item, index) => (
          <Box paddingTop="2%" key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Hidden only={["sm", "xs"]}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "40px",
                    }}
                  >
                    <Typography
                      variant="h2"
                      style={{ fontFamily: "Marcellus", wordWrap: "break-word" }}
                      
                    >
                      {item.title}
                    </Typography>
                    <div
                      style={{
                        backgroundColor: "#C4C4C4",
                        height: "1px",
                        width: "40%",
                        marginLeft: "50px",
                      }}
                    />
                  </div>
                </Hidden>
                <Hidden only={["md", "lg", "xl"]}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      style={{ fontFamily: "Marcellus", wordWrap: "break-word" }}
                    >
                      {item.title}
                    </Typography>
                    <div
                      style={{
                        backgroundColor: "#C4C4C4",
                        height: "1px",
                        width: "40%",
                        marginLeft: "50px",
                      }}
                    />
                  </div>
                </Hidden>
                <ul style={{ paddingInlineStart: "15px" }}>
                  {/* {item.detail.map((item2, index2) => (
                    <Fragment key={index2}>
                      <li>
                        <div key={index2} className={classes.details}>
                          <Typography variant="body2">{item2.text}</Typography>
                        </div>
                      </li>
                    </Fragment>
                  ))} */}
                  <li>
                  <Typography variant="h6"
          noWrap
          style={{
            marginBottom: "30px",
            width:"100%",
            wordWrap: "break-word"
          }}>
                  The Rating Tool - 
                  </Typography>
                  <Typography variant="body2">
                  A robust rating tool has been developed to assess the NGOs. This rating tool is very specific and relevant to not for profits. It is also designed keeping the individual retail donors in mind - what would be their concerns about NGOs, what would they like to see assessed, what indicators would be more important to them etc. 
                  </Typography>
                  <Typography variant="body2">
                  <ul>
  <li>
  The tool is divided into 4 components 
<ul>
  <li>
  Governance & Transparency</li><li>
Operational Efficiency & Excellence</li><li>
Risk Management & Sustainability</li><li>
Depth & Sustainability of Impact </li>
</ul>
  </li>
  <li>Each component is further divided into indicators and sub-indicators </li>
<li>To keep the scores relevant, some of the indicators and sub-indicators are changed as per the type of organisation (implementing, policy and advocacy, research, institutional donor etc.) or as per the thematic area (education, health, environment etc.). </li>
<li>Each indicator is assigned a weightage that determines the component scores out of 100. </li>
<li>Each component is assigned a weightage that determines the overall score of the NGO out of 100.
</li>
</ul>
</Typography>
</li>

                </ul>
                
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridimg}>
                <img
                  src={item.image}
                  height="400px"
                  className={classes.cardimg}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
        <Grid
          container
          spacing={5}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {total.map((item) => (
            <Grid item md={3}>
              <Method name={item.title} info={item.info} />
            </Grid>
          ))}
        </Grid>
        

        <ul style={{ paddingInlineStart: "15px" }}>
                  {/* {item.detail.map((item2, index2) => (
                    <Fragment key={index2}>
                      <li>
                        <div key={index2} className={classes.details}>
                          <Typography variant="body2">{item2.text}</Typography>
                        </div>
                      </li>
                    </Fragment>
                  ))} */}
                  <li>
                  <Typography variant="h6"
          noWrap
          style={{
            marginBottom: "30px",
            width:"100%",
            wordWrap: "break-word"
          }}>
                  The Rating Process  - 
                  </Typography>
                  <Typography variant="body2">
                  Ikigai Portfolio follows a rigorous rating process and ensures that it is repeated for each NGO that is rated for inclusion into the Partner NGO Pool.
                  </Typography>
                  <Typography variant="body2">
                  <ul>
  <li>
  The NGO makes an online application to be onboarded into the platform
  </li>
  <li>
  Ikigai Portfolio requests the NGO to submit the required rating documents that can be sent online
  </li>
  <li>
  Ikigai Portfolio rating team visits the NGO physically to verify the documents submitted by the NGO and other documents that could not be sent online

  </li>
  <li>
  The Ikigai Portfolio rating team makes a physical visit to the project locations of the NGO

  </li>

  <li>
  The Ikigai Portfolio rating team meets and interviews all relevant stakeholders of the NGO
  </li>
  <li>
  Once all the indicators and sub-indicators in the rating tool are completely filled, the rating team scores the NGOs and submits its report with score, findings and analyses to the rating verification team
  </li>
  <li>
  The rating verification team cross-checks and validates the report and may further reach out to the NGO or its stakeholders if required


  </li>
  <li>
  The rating scores and summary of findings and analyses are published on the platform 
  </li>
  <li>
  Once rated, the NGO will need to renew the rating between a period of 18-24 months.
  </li>


</ul>
In addition to the rating and rating renewal, there is a periodic due diligence of all NGOs through audits, monitoring and evaluation and impact assessments. The summary of these reports are shared with the donors who have donated to these NGOs. The Efficiency and Effectiveness scores of the NGO are based on these due diligence exercises.
</Typography>
</li>

                </ul>
                
              






      </div>
    </>
  );
}

const data = [
  {
    title: "Rating Process and Methodology",
    image: demo,
    detail: [
      {
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum, ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo in tristique feugiat faucibus arcu tempor. Vestibulum, congue vitae eleifend nec maecenas tristique bibendum egestas fermentum. Cursus nunc vulputate cras felis suspendisse convallis nisl.`,
      },
      {
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum, ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo in tristique feugiat faucibus arcu tempor.`,
      },

      {
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum, ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo in tristique feugiat faucibus arcu tempor.`,
      },
    ],
  },
];

const total = [
  {
    title: ` Governance & Transparency `,
    info: "Key indicators like the composition and engagement of the organisation’s board of directors, chief functionaries and founders are assessed under this component. Regulatory compliances, strategic decision making processes, transparency, adherence to governance best practices and benchmarks etc. are also assessed. This component is the foundation for any organisation and a very basic indicator of its intent, strategic ability and regulatory compliance. ",
  },
  {
    title: `Operational Efficiency & Excellence`,
    info: "Under this component, the not for profit’s organisational systems and processes like operations, HR, MIS, Finance and Accounts etc. are assessed. The organisation is also assessed whether it is able to optimally utilise its funds. How the organisation manages its projects, whether it follows best practices and benchmarks, at what average costs and quality it does so is also assessed. How well the organisation functions in its own thematic area is also assessed in this component. ",
  },
  {
    title: `Risk Management & Sustainability`,
    info: "This component assesses the organisation’s ability to manage its risks and whether it can operate for a long time in the future. Identifying risks and then managing those risks are crucial to the survival of any organisation. This is not just for the resources of the organisation but also its reputation, diversity of its fund sources and the assurance of long term funds.",
  },
  {
    title: ` Depth and Sustainability of Impact`,
    info: "The most important reason that the donors donate their money is to create an impact and make a positive change in the world. This component assesses an organisation’s ability to do just that. Scores are assigned based on indicators that assess the impact the organisation creates through its work, the depth and the sustainability of such impact. One of the objectives of this platform is to not just ensure that help reaches those who need it the most, but more importantly to create a system that works on eradicating the problem itself, or atleast make a significant reduction in the problem, or at the very least ensure that the help is available for a long time in the future till it is required or problem is resolved.",
  },
];
