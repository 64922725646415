import React from "react";
import DashboardLayout from "../../../components/Layout/DashBoardLayout";
import MemberGrid from "../../../components/Section/DashBoard/Report/MemberGrid";

export default function Membership() {
  return (
    <>
      <DashboardLayout>
        <MemberGrid />
      </DashboardLayout>
    </>
  );
}
