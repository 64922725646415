import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState, useEffect } from "react";
import { postRequest } from "../../apis/baseApi";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loggedinUser, userDetails } from "../../redux/commonReducer/action";
import Google from "../../components/assets/png/icon/google.png";
import Facebook from "../../components/assets/png/facebook.jpeg";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useLocation } from "react-router-dom";
import CustomButtonCircularProgress from "../../components/CircularProgress";
import { useAlert } from "react-alert";
import { isEmail, checkPassword } from "../../utility";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
    maxWidth: "150px",
  },
  btnGoBack: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
    maxWidth: "200px",
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
  googlebtn: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "30px",
    paddingLeft: "10px",
    paddingRight: "10px",
    minWidth: "150px",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      border: "1px solid",
    },
  },
}));

function Login({ isNgo = false }) {
  const classes = useStyles();
  const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const redirectUrl = params.get("redirectUrl");

  const [signupData, setSignupData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const [errorValues, setErrorValues] = React.useState({
    email: "",
    password: "",
  });

  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const [googleAccessToken, setGoogleAccessToken] = useState();

  const [isError, setIsError] = React.useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: "" });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateForm = () => {
    let error = false;
    let emailError = "";
    let passwordError = "";
    setIsError(false);
    if (!values?.email) {
      emailError = "*Please Enter Email Id/Username";
      setIsError(true);
      error = true;
    }
    if (!values?.password) {
      passwordError = "*Please Enter Password";
      setIsError(true);
      error = true;
    }

    setErrorValues({
      email: emailError,
      password: passwordError,
    });
    return error ? false : true;
  };

  const onLogin = () => {
    if (validateForm()) {
      loginUser();
    }
  };

  const loginUser = () => {
    var isEmailLogin = true;
    if (!isEmail(values?.email)) {
      isEmailLogin = false;
    }
    const postData = {
      user: {
        ...(isEmailLogin && { email: values?.email }),
        ...(!isEmailLogin && { username: values?.email }),
        is_using_email: isEmailLogin ? true : false,
        password: values?.password,
      },
    };
    const url = isNgo
      ? "authentication/ngo/login/"
      : "authentication/users/login/";
    setIsLoading(true);
    postRequest(url, postData)
      .then((res) => {
        if (res?.data) {
          if (isNgo) dispatch(loggedinUser(true));
          dispatch(
            userDetails({
              name: res?.data,
              username: res?.data?.username,
              token: res?.data?.token,
              slug: res?.data?.slug,
              isHasNgo: res?.data?.is_has_ngo,
              email: res?.data?.email,
              profileImage: res?.data?.user_profile?.image,
            })
          );
          if (!isNgo && res?.data?.is_has_ngo) {
            setIsLoading(false);
            alert.error(
              "You are not registered as a member. Please login as NGO"
            );
            return;
          }
          if (isNgo && !res?.data?.is_has_ngo) {
            setIsLoading(false);
            alert.error(
              "You are not registered as a NGO. Please login as Member"
            );
            return;
          }

          localStorage.setItem("user_token", res?.data?.token);
          localStorage.setItem("slug", res?.data?.slug);
          localStorage.setItem("user_type", isNgo ? "NGO" : "DONOR");
          localStorage.setItem(
            "name",
            `${res?.data?.first_name} ${res?.data?.last_name}`
          );
          localStorage.setItem("profile_image", res?.data?.image);
          localStorage.setItem("has_ngo", res?.data?.is_has_ngo);
          localStorage.setItem("email", res?.data?.email);
          localStorage.setItem("username", res?.data?.username);
          alert.success("Login Successful!!");
          if (redirectUrl) {
            history.push(redirectUrl);
          } else
            isNgo
              ? history.push("/ngo/profile")
              : history.push("/user/profile");
        } else {
          alert.error("Email & Password doesn't match");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        alert.error(err?.response?.data?.msg);
        // history.push("/profile");
      });
  };

  const responseGoogleSuccess = (response) => {
    // setSignupData({...signupData,
    // email: respo
    // })
    setGoogleAccessToken(response?.accessToken);
  };

  const responseGoogleFailure = (response) => {
    // alert.error("Error in Google SignUp/Login!!");
  };

  const responseFacebook = (response) => {
    const postData = {
      token: response.accessToken,
      provider: "facebook",
    };
    postRequest("authentication/users/google_fb_auth/", postData)
      .then((res) => {
        if (res?.data?.status === false) {
          // setSignupData()
          history.push({
            pathname: "/user-registration",
            state: {
              firstName: response?.name,
              email: response?.email,
            },
          });
        } else if (res?.data) {
          if (isNgo) dispatch(loggedinUser(true));
          dispatch(
            userDetails({
              name: res?.data,
              username: res?.data?.username,
              token: res?.data?.token,
              slug: res?.data?.slug,
              isHasNgo: res?.data?.is_has_ngo,
              email: res?.data?.email,
              profileImage: res?.data?.user_profile?.image,
            })
          );
          if (!isNgo && res?.data?.is_has_ngo) {
            setIsLoading(false);
            alert.error(
              "You are not registered as a member. Please login as NGO"
            );
            return;
          }
          if (isNgo && !res?.data?.is_has_ngo) {
            setIsLoading(false);
            alert.error(
              "You are not registered as a NGO. Please login as Member"
            );
            return;
          }

          localStorage.setItem("user_token", res?.data?.token);
          localStorage.setItem("slug", res?.data?.slug);
          localStorage.setItem("user_type", isNgo ? "NGO" : "DONOR");
          localStorage.setItem(
            "name",
            `${res?.data?.first_name} ${res?.data?.last_name}`
          );
          localStorage.setItem("profile_image", res?.data?.image);
          localStorage.setItem("has_ngo", res?.data?.is_has_ngo);
          localStorage.setItem("email", res?.data?.email);
          localStorage.setItem("username", res?.data?.username);
          alert.success("Login Successful!!");
          if (redirectUrl) {
            history.push(redirectUrl);
          } else
            isNgo
              ? history.push("/ngo/profile")
              : history.push("/user/profile");
        } else {
          // alert.error("Something went wrong!!");
        }
      })
      .catch((err) => {
        // alert("Something went wrong!!");
      });
  };

  useEffect(() => {
    if (googleAccessToken) {
      const postData = {
        token: googleAccessToken,
        provider: "google",
      };
      postRequest("authentication/users/google_fb_auth/", postData)
        .then((res) => {
          if (res?.data?.status === false) {
            // setSignupData()
            history.push({
              pathname: "/user-registration",
              state: {
                firstName: res?.data?.name,
                email: res?.data?.email,
              },
            });
          } else if (res?.data) {
            if (isNgo) dispatch(loggedinUser(true));
            dispatch(
              userDetails({
                name: res?.data,
                username: res?.data?.username,
                token: res?.data?.token,
                slug: res?.data?.slug,
                isHasNgo: res?.data?.is_has_ngo,
                email: res?.data?.email,
                profileImage: res?.data?.user_profile?.image,
              })
            );
            if (!isNgo && res?.data?.is_has_ngo) {
              setIsLoading(false);
              alert.error(
                "You are not registered as a member. Please login as NGO"
              );
              return;
            }
            if (isNgo && !res?.data?.is_has_ngo) {
              setIsLoading(false);
              alert.error(
                "You are not registered as a NGO. Please login as Member"
              );
              return;
            }

            localStorage.setItem("user_token", res?.data?.token);
            localStorage.setItem("slug", res?.data?.slug);
            localStorage.setItem("user_type", isNgo ? "NGO" : "DONOR");
            localStorage.setItem(
              "name",
              `${res?.data?.first_name} ${res?.data?.last_name}`
            );
            localStorage.setItem("profile_image", res?.data?.image);
            localStorage.setItem("has_ngo", res?.data?.is_has_ngo);
            localStorage.setItem("email", res?.data?.email);
            localStorage.setItem("username", res?.data?.username);
            alert.success("Login Successful!!");
            if (redirectUrl) {
              history.push(redirectUrl);
            } else
              isNgo
                ? history.push("/ngo/profile")
                : history.push("/user/profile");
          } else if (!res?.status) {
          }
          setIsLoading(false);
        })
        .catch((err) => {
          // alert("Something went wrong!!");
        });
    }
  }, [googleAccessToken]);

  return (
    // <Layout>
    <Paper className={classes.paper}>
      {!localStorage.getItem("user_token") ? (
        <>
          <Typography
            variant="h3"
            color="primary"
            style={{ marginTop: "20px" }}
          >
            {isNgo ? "NGO" : "Member"} Login
          </Typography>
          <Grid
            container
            spacing={2}
            style={{ marginTop: "20px", display: "flex", flexDirection: "col" }}
          >
            <Grid item md={4}></Grid>
            <Grid item md={4} xs={12} style={{ display: "grid" }}>
              <div style={{ display: "grid" }}>
                <Typography className={classes.reqfieldtext}>
                  Email/Username
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </Typography>
                <TextField
                  variant="outlined"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                  onChange={handleChange("email")}
                  error={isError}
                  helperText={
                    isError && errorValues?.email ? errorValues?.email : ""
                  }
                />
                <br />
                <Typography className={classes.reqfieldtext}>
                  Password
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </Typography>
                <FormControl className={classes.margin}>
                  <OutlinedInput
                    style={{
                      backgroundColor: "#fff",
                      paddingTop: 26,
                      paddingBottom: 26,
                    }}
                    id="outlined-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    //labelWidth={70}
                  />
                  {isError && errorValues?.password && (
                    <FormHelperText error id="accountId-error">
                      {errorValues?.password}
                    </FormHelperText>
                  )}
                </FormControl>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push("/reset-password");
                  }}
                >
                  <span style={{ color: "#A6A6A6" }}>Forgot Password? </span>
                </div>
              </div>
            </Grid>
            <Grid item md={4}>
              {" "}
            </Grid>{" "}
          </Grid>
          <div style={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              className={classes.btn}
              color="primary"
              onClick={() => {
                onLogin();
              }}
            >
              {isLoading ? <CustomButtonCircularProgress /> : "Login"}
            </Button>
          </div>

          {!isNgo ? (
            <>
              <div style={{ marginTop: "20px" }}>
                <Typography variant="body2">OR Login with</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  <GoogleLogin
                    clientId={
                      "303020332087-p91ke2ehr7klb0cae9446fuoncush23m.apps.googleusercontent.com"
                    }
                    render={(renderProps) => (
                      <Button
                        className={classes.googlebtn}
                        onClick={renderProps.onClick}
                      >
                        <img
                          src={Google}
                          height="25px"
                          alt="google-icon"
                          style={{ marginRight: "10px" }}
                        />
                        <span style={{ fontSize: "14px" }}> Google </span>
                      </Button>
                    )}
                    fields="name,email,profile"
                    onSuccess={responseGoogleSuccess}
                    onFailure={responseGoogleFailure}
                  />
                </div>

                <FacebookLogin
                  appId="479517474027882"
                  callback={responseFacebook}
                  fields="name,email,picture"
                  render={(renderProps) => (
                    // <button onClick={renderProps.onClick}>This is my custom FB button</button>
                    <Button
                      className={classes.googlebtn}
                      onClick={renderProps.onClick}
                    >
                      <img
                        src={Facebook}
                        height="25px"
                        alt="twitter-icon"
                        style={{ marginRight: "10px" }}
                      />
                      <span style={{ fontSize: "14px" }}> Facebook </span>
                    </Button>
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <Typography>
                  Not Registered Yet?
                  <div
                    onClick={() => {
                      history.push("user-registration-social");
                    }}
                  >
                    <span
                      style={{
                        color: "blue",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      Sign Up Now{" "}
                    </span>
                  </div>
                </Typography>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              <Typography>
                Not Registered Yet?
                <div
                  onClick={() => {
                    history.push("ngo-registration-1");
                  }}
                >
                  <span
                    style={{
                      color: "blue",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                  >
                    Sign Up Now{" "}
                  </span>
                </div>
              </Typography>
            </div>
          )}
          <div>
            <form className={classes.root} noValidate></form>
          </div>
        </>
      ) : (
        <>
          <Typography
            variant="h3"
            color="primary"
            style={{ marginTop: "20px" }}
          >
            You are already logged in.
          </Typography>
          <Button
            variant="contained"
            className={classes.btnGoBack}
            color="primary"
            onClick={() => {
              redirectUrl ? history.push(redirectUrl) : history.push("/home");
            }}
          >
            Go Back
          </Button>
        </>
      )}
    </Paper>
    // </Layout>
  );
}

export default Login;
