import {
	Box,
	Button,
	Container,
	Grid,
	IconButton,
	Paper,
	Typography,
	makeStyles,
	alpha,
	Accordian,
	Divider,
} from "@material-ui/core";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Facebook, Globe, Instagram } from "lucide-react";
import ngo from "../../assets/images/esteban-benites-_bZzytRTSvo-unsplash.jpg";
import avatar from "../../assets/images/9440461.jpg";
import NgoDetialsCard from "../../components/Cards/NgoDetailsCard/NgoDetialsCard";
import AvatarComponent from "../../components/Cards/NgoDetailsCard/AvatarComponent";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CollectionsSharp } from "@material-ui/icons";
import RewardCard from "../../components/Cards/NgoDetailsCard/RewardCard";
import reward from "../../assets/images/larm-rmah-AEaTUnvneik-unsplash.jpg";
import CarouselComponent from "../../components/Cards/NgoDetailsCard/CarouselComponent";

const NgoDetails = [
	{
		ngoName: "The Gurgaon Project",
		description: `Lorem ipsum dolor sit amet,
    consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore
    magna aliqua.`,
		image: ngo,
		achivements: ["no poverty", "zero hunger"],
	},
	{
		ngoName: "The Gurgaon Project",
		description: `Lorem ipsum dolor sit amet,
    consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore
    magna aliqua.`,
		image: ngo,
		achivements: ["no poverty", "zero hunger", "Income Enhancement"],
	},
	{
		ngoName: "The Gurgaon Project",
		description: `Lorem ipsum dolor sit amet,
    consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore
    magna aliqua.`,
		image: ngo,
		achivements: ["no poverty", "zero hunger", "Water", "Social Insurance"],
	},
];

const rewardsArray = [
	{
		image: reward,
		description: "description",
		title: "Appriciation from government",
	},
	{
		image: reward,
		description: "description",
		title: "Appriciation from government",
	},
	{
		image: reward,
		description: "description",
		title: "Appriciation from government",
	},
];

const ngoTeams = [avatar, avatar, avatar, avatar, avatar, avatar, avatar];

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		textAlign: "center",
		width: "100%",
	},
	title: {
		marginBottom: theme.spacing(3),
		textAlign: "center",
	},
	donateButton: {
		marginTop: theme.spacing(3),
		[theme.breakpoints.down("sm")]: {
			marginTop: theme.spacing(2),
		},
	},
	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
	},
	image: {
		width: "100%",
		aspectRatio: "16:9",
		borderRadius: "2rem",
		margin: "2rem",
	},
	titleButton: {
		height: "2rem",
		width: "8rem",
		backgroundColor: "#f5effd",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "10px",
	},
	buttonText: {
		color: "#b892fb",
		textTransform: "uppercase",
		fontSize: "12px",
		fontWeight: "600",
	},
	paragraph: {
		fontSize: "14px",
		textAlign: "justify",
		marginTop: "2rem",
		fontWeight: "800",
	},
	paper: {
		boxShadow: "none",
		backgroundColor: alpha(theme.palette.primary.main, 0.1),
		textAlign: "left",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		padding: "1rem",
	},
	cards: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-evenly",
	},
	avatarsView: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: "1rem",
	},
	teamsView: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		marginTop: "3rem",
		width: "80%",
		margin: "auto",
	},
	container: {
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-start",
		width: "100%",
		marginTop: "5rem",
		marginBottom: "5rem",
	},

	leftContainer: {
		width: "50%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},
	rightContainer: {
		width: "50%",
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
	},

	discriptionBox: {
		width: "100%",
		border: "1px solid #e6d6fb",
		backgroundColor: "#f8f4fe",
		borderRadius: "10px",
		padding: "0.6rem",
	},

	accordianStyle: {
		width: "64%",
		margin: "0.5rem 0",
		borderRadius: "0.5rem",
	},

	fileDownloadBox: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "0.5rem 0",
	},

	downloadButton: {
		color: "#b189f6",
	},

	dividerStyle: {
		backgroundColor: "#b189f6",
	},

	rewardBox: {
		display: "flex",
		flexDirection: "row",
		gap: "2rem",
		marginTop: "2rem",
	},
}));

const NgoPartners = () => {
	const classes = useStyles();
	const [isBasicDetails, setIsBasicDetails] = useState(true);
	return (
		<>
			<Container className={classes.root}>
				<Grid className={classes.header}>
					<Box style={{ display: "flex", flexDirection: "row" }}>
						<IconButton>
							<PlaceOutlinedIcon />
						</IconButton>
						<Typography>New Delhi</Typography>
					</Box>
					<Typography variant="h4" className={classes.title}>
						Partnering Hope into Action Foundation
					</Typography>
					<Box>
						<IconButton>
							<Instagram />
						</IconButton>
						<IconButton>
							<Facebook />
						</IconButton>
						<IconButton>
							<Globe />
						</IconButton>
					</Box>
				</Grid>
				<Typography variant="body1">
					Your generous donation will help us make a difference in the lives of
					those in need.
				</Typography>
				<Button
					variant="contained"
					color="primary"
					size="large"
					className={classes.donateButton}
				>
					DONATE TO THIS NGO
				</Button>

				<img src={ngo} alt="NGO" className={classes.image} />

				<Grid>
					<Box className={classes.titleButton}>
						<Typography className={classes.buttonText}>Background</Typography>
					</Box>
					<Typography className={classes.paragraph}>
						<span>Partnering Hope Into Action Foundation (PHIA) </span>
						is a Charitable Trust registered in India in 2005. PHIA has a vision
						for a society free from poverty, exclusion and discrimination where
						all people live with justice, peace and dignity. PHIA works for the
						eradication of poverty among the poor, vulnerable, and marginalized
						communities in India and empowers them to be in charge of their own
						development. Phia Foundation works on the themes of – Access to
						Essential Services, Reducing Inequality and Bridging Gender Gap,
						Ensuring Sustainable Livelihoods and Humanitarian Response and
						Mitigating Climate Change responding to the calling of the
						Sustainable development Goals (SDGs). Gender equality crosscuts all
						of PHIA’s work.
					</Typography>
				</Grid>
			</Container>
			<Paper className={classes.paper}>
				<Box className={classes.titleButton}>
					<Typography className={classes.buttonText}>Projects</Typography>
				</Box>
				<Typography>Project Details</Typography>

				<Grid className={classes.cards}>
					{NgoDetails.map((el, index) => {
						return <NgoDetialsCard details={el} />;
					})}
				</Grid>
				<Box className={classes.titleButton}>
					<Typography className={classes.buttonText}>Background</Typography>
				</Box>
				<Typography>More about the NGO</Typography>
				<Accordion className={classes.accordianStyle}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1-content"
						id="panel1-header"
					>
						Basic Details
					</AccordionSummary>
					<AccordionDetails>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
						malesuada lacus ex, sit amet blandit leo lobortis eget.
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.accordianStyle}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
					>
						Scale
					</AccordionSummary>
					<AccordionDetails>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
						malesuada lacus ex, sit amet blandit leo lobortis eget.
					</AccordionDetails>
				</Accordion>
				<Accordion defaultExpanded className={classes.accordianStyle}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3-content"
						id="panel3-header"
					>
						Documentation
					</AccordionSummary>
					<AccordionDetails>
						<Divider className={classes.dividerStyle} />
						<Box className={classes.fileDownloadBox}>
							<Typography>NGO Registration Certificate</Typography>
							<IconButton
								aria-label="file-download"
								className={classes.downloadButton}
							>
								<FileDownloadOutlinedIcon />
							</IconButton>
						</Box>
						<Divider className={classes.dividerStyle} />

						<Box className={classes.fileDownloadBox}>
							<Typography>PAN Card Certificate</Typography>
							<IconButton
								aria-label="file-download"
								className={classes.downloadButton}
							>
								<FileDownloadOutlinedIcon />
							</IconButton>
						</Box>
						<Divider className={classes.dividerStyle} />

						<Box className={classes.fileDownloadBox}>
							<Typography>12A Certificate</Typography>
							<IconButton
								aria-label="file-download"
								className={classes.downloadButton}
							>
								<FileDownloadOutlinedIcon />
							</IconButton>
						</Box>
						<Divider className={classes.dividerStyle} />

						<Box className={classes.fileDownloadBox}>
							<Typography>80G Certificate</Typography>
							<IconButton
								aria-label="file-download"
								className={classes.downloadButton}
							>
								<FileDownloadOutlinedIcon />
							</IconButton>
						</Box>
						<Divider className={classes.dividerStyle} />
						<Box className={classes.fileDownloadBox}>
							<Typography>FCRA Certificate</Typography>
							<IconButton
								aria-label="file-download"
								className={classes.downloadButton}
							>
								<FileDownloadOutlinedIcon />
							</IconButton>
						</Box>
						<Divider className={classes.dividerStyle} />

						<Box className={classes.fileDownloadBox}>
							<Typography>GST Certificate</Typography>
							<IconButton
								aria-label="file-download"
								className={classes.downloadButton}
							>
								<FileDownloadOutlinedIcon />
							</IconButton>
						</Box>
						<Divider className={classes.dividerStyle} />

						<Box className={classes.fileDownloadBox}>
							<Typography>Annual Report</Typography>
							<IconButton
								aria-label="file-download"
								className={classes.downloadButton}
							>
								<FileDownloadOutlinedIcon />
							</IconButton>
						</Box>
						<Divider className={classes.dividerStyle} />

						<Box className={classes.fileDownloadBox}>
							<Typography>Audited Financial Statements</Typography>
							<IconButton
								aria-label="file-download"
								className={classes.downloadButton}
							>
								<FileDownloadOutlinedIcon />
							</IconButton>
						</Box>
						<Divider className={classes.dividerStyle} />

						<Box className={classes.fileDownloadBox}>
							<Typography>ITR Certificate</Typography>
							<IconButton
								aria-label="file-download"
								className={classes.downloadButton}
							>
								<FileDownloadOutlinedIcon />
							</IconButton>
						</Box>
						<Divider className={classes.dividerStyle} />
					</AccordionDetails>
					{/* <AccordionActions>
						<Button>Cancel</Button>
						<Button>Agree</Button>
					</AccordionActions> */}
				</Accordion>
			</Paper>
			<Grid className={classes.teamsView}>
				<Box className={classes.titleButton}>
					<Typography className={classes.buttonText}>THE TEAM</Typography>
				</Box>
				<Typography>Meet the hearts behind the cause</Typography>
				<Grid className={classes.avatarsView}>
					{ngoTeams.map((el, index) => {
						return <AvatarComponent image={el} />;
					})}
				</Grid>
				<Typography>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
					minim veniam, quis nostrud exercitation ullamco laboris nisi ut
					aliquip ex ea commodo consequat. Duis aute irure dolor in
					reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
					pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
					culpa qui officia deserunt mollit anim id est laborum.
				</Typography>
				<Typography>Name Surname</Typography>
				<Typography>Founder of PHIA</Typography>

				<Grid className={classes.container}>
					<Box className={classes.leftContainer}>
						<Box className={classes.titleButton}>
							<Typography className={classes.buttonText}>NETWORK</Typography>
						</Box>
						<Typography>Membership and Affiliations</Typography>
					</Box>

					<Box className={classes.rightContainer}>
						<Box className={classes.discriptionBox}>
							<Typography>Lorem Ipsum</Typography>
							<Typography>Description</Typography>
						</Box>
						<Box className={classes.discriptionBox}>
							<Typography>Lorem Ipsum</Typography>
							<Typography>Description</Typography>
						</Box>
						<Box className={classes.discriptionBox}>
							<Typography>Lorem Ipsum</Typography>
							<Typography>Description</Typography>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Paper className={classes.paper}>
				<Box className={classes.titleButton}>
					<Typography className={classes.buttonText}>APPRECIATION</Typography>
				</Box>
				<Typography>Rewards and Recognition</Typography>
				{/* <Box style={classes.rewardBox}>
					{rewardsArray.map((reward, index) => (
						<RewardCard reward={reward} key={index} />
					))}
				</Box> */}

				<Box className={classes.rewardBox}>
					{rewardsArray.map((reward, index) => (
						<RewardCard reward={reward} key={index} />
					))}
					{/* <CarouselComponent /> */}
				</Box>
			</Paper>
		</>
	);
};

export default NgoPartners;
