import { combineReducers } from "redux";
import { common } from './commonReducer/reducer';
import {normal} from './normalUserReducer/reducer';
import {ngo} from  './ngoReducer/reducer';

const rootReducer = combineReducers({
    common,
    normal,
    ngo
})

export default rootReducer;