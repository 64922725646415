import {
  Paper,
  makeStyles,
  Grid,
  Divider,
  Typography,
  Button,
} from "@material-ui/core";
import React from "react";
import ReportCard from "../../../Cards/DashBoadCards/ReportCard/ReportCard";
import Table from "../../../Common/Table/Table";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function DonationDetailsGrid() {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          style={{ padding: "20px", paddingTop: "0px" }}
        >
          {data.map((item) => (
            <Grid item md={4}>
              <div style={{ height: "130px", display: "grid" }}>
                <ReportCard title={item.title} num={item.number} />
              </div>
            </Grid>
          ))}
          {/* <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns} list={row} />
          </Grid>

          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns2} list={row2} />
          </Grid>

          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns3} list={row3} />
          </Grid>
          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns4} list={row4} />
          </Grid>
          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns6} list={row5} />
          </Grid>
          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns5} list={row5} />
          </Grid> */}
        </Grid>
      </Paper>
    </>
  );
}

const data = [
  { title: `Total No. of Donations`, number: `14`, path: `/` },
  { title: `No. of Unique Donors`, number: "Rs. 5,000", path: `/` },

  {
    title: `Total  Donation Amount Mobilised`,
    number: "Rs. 20,000 ",
    path: `/`,
  },

  { title: `Documents Uploaded`, number: "", path: `/` },

  { title: `Pending Activities`, number: "02", path: `/` },

  { title: `Ikigai Portfolio Fund Management Charges`, number: "", path: `/` },

  { title: `IP FMC Paid`, number: "Rs. 20,000", path: `/` },

  { title: `IP FMC Pending`, number: "2", path: `/` },
];

export const columns = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 350,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "120px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },
  {
    field: "date",
    type: "text",
    sortable: false,
    width: 350,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "150px" }}>Date</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.date}
      </span>
    ),
  },
  {
    field: "amount",
    type: "text",
    sortable: false,
    width: 330,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "120px" }}>Amount</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount}</Typography>
      </span>
    ),
  },
];

export const row = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns2 = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donor_id",
    type: "text",
    width: 250,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "80px" }}>Donor ID</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        848566 {params.row.donor_id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "100px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },

  {
    field: "amount_donated",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>
        Amount Donated
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount_donated}</Typography>
      </span>
    ),
  },
  {
    field: "total_amount_donated",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "50px" }}>
        Total Amount Donated
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">
          ₹ {params.row.total_amount_donated}
        </Typography>
      </span>
    ),
  },
];

export const row2 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns3 = [
  {
    field: "id",

    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "60px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },
  {
    field: "receipt",
    type: "text",
    width: 200,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "30px" }}>
        Donation Receipt
      </strong>
    ),
    renderCell: (params) => (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",

          color: "#94939F",
        }}
      >
        <Typography color="primary" style={{ fontSize: "14px" }}>
          Clich here to view {params.row.receipt}
        </Typography>
      </div>
    ),
  },
  {
    field: "certificate",
    type: "text",
    width: 250,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "30px" }}>
        Tax Exemption Certificate
      </strong>
    ),
    renderCell: (params) => (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",

          color: "#94939F",
        }}
      >
        <Typography color="primary" style={{ fontSize: "14px" }}>
          Clich here to view {params.row.certificate}
        </Typography>
      </div>
    ),
  },
  {
    field: "report",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "80px" }}>
        Utilisation Report
      </strong>
    ),
    renderCell: (params) => (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",

          color: "#94939F",
        }}
      >
        <Typography color="primary" style={{ fontSize: "14px" }}>
          Clich here to view {params.row.report}
        </Typography>
      </div>
    ),
  },
];

export const row3 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns4 = [
  {
    field: "id",

    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "60px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },
  {
    field: "receipt",
    type: "text",
    width: 200,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "30px" }}>
        Donation Receipt
      </strong>
    ),
    renderCell: (params) => (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",

          color: "#94939F",
        }}
      >
        <div
          style={{
            border: "2px solid #C88FEF",
            borderRadius: "30px",
            height: "40px",
            display: "flex",
            padding: "10px",
            paddingLeft: "2px",
            paddingRight: "2px",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: "30px",
              boxShadow: "none",
              padding: "none",
              paddingLeft: "30px",
              paddingRight: "30px",
              height: "34px",
            }}
          >
            <Typography
              style={{
                color: "#fff",
                fontSize: "12px",
              }}
            >
              Upload
            </Typography>
          </Button>
        </div>
      </div>
    ),
  },
  {
    field: "certificate",
    type: "text",
    width: 250,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "30px" }}>
        Tax Exemption Certificate
      </strong>
    ),
    renderCell: (params) => (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",

          color: "#94939F",
        }}
      >
        <div
          style={{
            border: "2px solid #C88FEF",
            borderRadius: "30px",
            height: "40px",
            display: "flex",
            padding: "10px",
            paddingLeft: "2px",
            paddingRight: "2px",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: "30px",
              boxShadow: "none",
              padding: "none",
              paddingLeft: "30px",
              paddingRight: "30px",
              height: "34px",
            }}
          >
            <Typography
              style={{
                color: "#fff",
                fontSize: "12px",
              }}
            >
              Upload
            </Typography>
          </Button>
        </div>
      </div>
    ),
  },
  {
    field: "report",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "80px" }}>
        Utilisation Report
      </strong>
    ),
    renderCell: (params) => (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",

          color: "#94939F",
        }}
      >
        <div
          style={{
            border: "2px solid #C88FEF",
            borderRadius: "30px",
            height: "40px",
            display: "flex",
            padding: "10px",
            paddingLeft: "2px",
            paddingRight: "2px",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: "30px",
              boxShadow: "none",
              padding: "none",
              paddingLeft: "30px",
              paddingRight: "30px",
              height: "34px",
            }}
          >
            <Typography
              style={{
                color: "#fff",
                fontSize: "12px",
              }}
            >
              Upload
            </Typography>
          </Button>
        </div>
      </div>
    ),
  },
];

export const row4 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns5 = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },

  {
    field: "donation_id",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "100px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },

  {
    field: "fund",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "80px" }}>Fund</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
        }}
      >
        <Typography color="primary" style={{ fontSize: "14px" }}>
          Rs. 10,000 {params.row.fund}
        </Typography>
      </span>
    ),
  },
  {
    field: "pending_fmc",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "50px" }}>
        Pending FMC
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
        }}
      >
        <Typography color="primary" style={{ fontSize: "14px" }}>
          Rs. 10,000 {params.row.pending_fmc}
        </Typography>
      </span>
    ),
  },
  {
    field: "action",
    type: "text",
    width: 250,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "80px" }}></strong>
    ),
    renderCell: (params) => (
      <div
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <div
          style={{
            border: "2px solid #C88FEF",
            borderRadius: "30px",
            height: "40px",
            display: "flex",
            padding: "10px",
            paddingLeft: "2px",
            paddingRight: "2px",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: "30px",
              boxShadow: "none",
              padding: "none",
              paddingLeft: "30px",
              paddingRight: "30px",
              height: "34px",
            }}
          >
            <Typography
              style={{
                color: "#fff",
                fontSize: "12px",
              }}
            >
              Pay
            </Typography>
          </Button>
        </div>
      </div>
    ),
  },
];

export const columns6 = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },

  {
    field: "donation_id",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "100px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },

  {
    field: "fund",
    type: "text",
    sortable: false,
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "110px" }}>Fund</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
        }}
      >
        <Typography color="primary" style={{ fontSize: "14px" }}>
          Rs. 10,000 {params.row.fund}
        </Typography>
      </span>
    ),
  },
  {
    field: "pending_fmc",
    type: "text",
    sortable: false,
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "90px" }}>
        Pending FMC
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
        }}
      >
        <Typography color="primary" style={{ fontSize: "14px" }}>
          Rs. 10,000 {params.row.pending_fmc}
        </Typography>
      </span>
    ),
  },
];

export const row5 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];
