import {
  Button,
  Grid,
  makeStyles,
  Paper,
  alpha,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ChooseOption from "../../Cards/Total/ChooseOption";
import Heading from "../../Common/Heading/Heading";
import SelectNgoFilter from "../../Common/InputField/Filter/SelectNgoFilter";
import PartnerList from "../../Cards/Partner/PartnerList";
import { getRequest } from "../../../apis/baseApi";
import NGOCard from "./NgoCard";

const useStyles = makeStyles((theme) => ({
  btn: {
    color: "#fff",
    boxShadow: "none",
    height: "50px",
    borderRadius: "8px",
  },
  maingrid: {
    marginTop: "50px",
    marginBottom: "50px",
    // display: "flex",
    // justifyContent: "center",
    flexDirection: "row",
  },
}));

export default function SelectNgo({ recommendMe }) {
  const classes = useStyles();
  const [ngoPartner, setNgoPartner] = useState([]);
  useEffect(() => {
    if (recommendMe)
      getRequest("ngo/user_ngo_preferences/?page=2&page_size=5")
        .then((res) => {
          if (res?.data?.results && res?.data?.results.length > 0) {
            setNgoPartner(res?.data?.results);
          }
        })
        .catch((err) => {});
  }, [recommendMe]);
  return (
    <>
      {ngoPartner?.length > 0 && (
        <div style={{ marginTop: "3%" }}>
          <Heading title="Select your NGO from below" />
          <Grid container spacing={2} className={classes.maingrid}>
            {/* <Grid item md={12}>
            <SelectNgoFilter />
          </Grid> */}
            <Grid item md={12}>
              <div>
                <Typography
                  variant="h6"
                  color="primary"
                  style={{ fontFamily: "Marcellus" }}
                >
                  <span style={{ color: "#2B2D3F" }}> Number of NGOs:</span>{" "}
                  {ngoPartner?.length}
                </Typography>
              </div>
            </Grid>

            {ngoPartner?.map((item) => {
              return (
                <Grid item md={4}>
                  <NGOCard item={item} />
                </Grid>
              );
            })}
          </Grid>
        </div>
      )}
    </>
  );
}
