import React from "react";
import UserDashboardLayout from "../../components/Layout/UserDashboardLayout";

import UserReportGrid from "../../components/Section/DashBoard/Report/UserReportGrid";

export default function UserDownloadReports() {
  return (
    <>
      <UserDashboardLayout>
        <UserReportGrid />
      </UserDashboardLayout>
    </>
  );
}
