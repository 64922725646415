import {
  alpha,
  Button,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import ImageSlider from "../../Cards/ImageSlider/ImageSlider";
import MobileImageSlider from "../../Cards/ImageSlider/MobileImageSlider";
import Heading from "../../Common/Heading/Heading";
import { getRequest } from "../../../apis/baseApi";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  learn: {
    paddingLeft: "40px",
    paddingRight: "40px",
    color: "#fff",
    height: "45px",
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
  },
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",

    borderRadius: 22,
    marginBottom: "70px",
    width: "100%",
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",

    marginTop: "20px",
    marginBottom: "30px",
    borderRadius: 8,
    height: 46,
    boxShadow: "0px 10px 40px 0px #C88FEF73",
  },
}));

function WhatWeDo() {
  const classes = useStyles();
  const [cardsData, setCardsData] = useState();
  const history = useHistory();

  useEffect(() => {
    getRequest("home_page/get_what_we_do/")
      .then((res) => {
        if (res?.data?.status) {
          setCardsData(res?.data?.data[0]);
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <div style={{ marginTop: "40px", marginBottom: "40px" }}>
        <Heading title={cardsData?.title} />
        <br />
        <br />
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item md={12} style={{ marginTop: "20px" }}>
              <Typography
                variant="body2"
                // color="primary"
                style={{ paddingLeft: "10%", paddingRight: "10%" }}
              >
                Make the world a better place by inspiring its citizens to
                become catalysts of social change
                <br /> <br /> <br />
                We believe that it needs collective effort, channelled
                strategically, to bring about positive change in the world. We
                believe in the goodness of people, who, given the right
                platform, would like to be part of this change and contribute to
                it, however large or small, as per their capacity. We work
                towards connecting these people who have a passion to do good
                with credible institutions with impeccable intent in
                implementing social change. The idea is to generate sustainable
                and deep social impact.
                <br />
                <br />
                <br />
                We manage the philanthropy portfolio (starting from INR 500) of
                individual donors and help them donate to the social causes that
                they are passionate about. With our portfolio management
                approach to philanthropy, while our donors have immense
                flexibility, choice and complete control over which NGOs they
                want to donate to, we help them make the right choices by:
                {/* <ul style={{marginLeft: "30px"}}> */}
                <ul style={{ textAlign: "initial" }}>
                  <li style={{ paddingLeft: "10px" }}>
                    Provide information on NGOs through research and rigorous
                    rating
                  </li>
                  <li style={{ paddingLeft: "10px" }}>
                    Regularly monitor NGOs through independent financial and
                    process audits and impact assessments
                  </li>
                  <li style={{ paddingLeft: "10px" }}>
                    Focus on optimising impact (return on the portfolio)
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item md={2}></Grid>
            <Hidden only={["xs"]}>
              <Grid item md={8}>
                <ImageSlider image={cardsData?.image} />
              </Grid>
            </Hidden>
            <Hidden only={["sm", "md", "lg", "xl"]}>
              <Grid item xs={12}>
                <MobileImageSlider image={cardsData?.image} />
              </Grid>
            </Hidden>

            <Grid item md={2}></Grid>
          </Grid>
          <Button
            className={classes.btn}
            color="primary"
            variant="contained"
            onClick={() => {
              window.open(cardsData?.url, "_blank");
            }}
          >
            {cardsData?.button_text}
          </Button>
        </Paper>
      </div>
    </>
  );
}

export default WhatWeDo;
