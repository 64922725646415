import React, { Component } from "react";
import Charst from "react-apexcharts";
import { Paper } from "@material-ui/core";

export default class LineChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },

        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 10,
        },
        colors: ["#C88FEF"],

        xaxis: {
          categories: [1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003],
        },
      },

      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
      ],
    };
  }
  render() {
    return (
      <Paper
        style={{
          boxShadow: "none",
          borderRadius: "20px",
          padding: "30px",
          backgroundColor: "#FCF9FE",
        }}
      >
        <Charst
          options={this.state.options}
          series={this.state.series}
          type="line"
          height={420}
        />
      </Paper>
    );
  }
}
