import { Button, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { ngoDocDetailsSelectorFn } from "../../redux/ngoReducer/selector";
import { ngoDocDetails } from "../../redux/ngoReducer/action";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getRequest } from "../../apis/baseApi";
import UpgradeIcon from "@mui/icons-material/Upgrade";
const useStyles = makeStyles((theme) => ({
  btn: {
    color: "#fff",
    paddingLeft: "10px",
    paddingRight: "10px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  upload: {
    color: "#fff",
    paddingLeft: "10px",
    paddingRight: "10px",
    boxShadow: "none",
    borderRadius: 8,
    height: 46,
    // minWidth: "50px",
  },

  fieldtext: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "10px",
    display: "flex",
  },
  reqfieldtext: {
    fontSize: "14px",
    textAlign: "left",
  },
  option: {
    fontSize: 13,
    "& > span": {
      marginRight: 10,
      fontSize: 12,
    },
  },
  gridtext: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "flex-end",
    alignItems: "center",
  },
}));

function FileUploader({
  setFile = () => {},
  removeFileName = false,
  variant = "contained",
}) {
  const [values, setValues] = React.useState({
    ngoName: "",
    ngoImage: "",
    ngoBackgroud: "",
    legalForm: "",
    registrationNumber: "",
    panNumber: "",
    ngoTheme: "",
    registrationYear: "",
    registeredAddress: "",
    registeredCity: "",
    registeredPincode: "",
    registeredArea: "",
    communicationAddress: "",
    communicationCity: "",
    communicationArea: "",
    communicationPincide: "",
    isCommunicationSame: "",
    primaryEmail: "",
    alternateEmail: "",
    chiefName: "",
    chiefDesignation: "",
    chiefPhone: "",
    chiefAlternatePhone: "",
    chiefEmail: "",
    chiefAlternateEmail: "",
    nodalName: "",
    nodalDesignation: "",
    nodalPhone: "",
    nodalAlternatePhone: "",
    nodalEmail: "",
    nodalAlternateEmail: "",
    sameAsChief: true,
    beneficiariesNo: "",
    annualTurnover: "",
    staffNo: "",
    projectLocation: "",
    internationalPayment: false,
    mobaliseInternationPayment: false,
    accountNo: "",
    bankName: "",
    accountName: "",
    ifscCode: "",
    branch: "",
    intlAccountNo: "",
    intlBankName: "",
    intlAccountName: "",
    intlIfscCode: "",
    intlBranch: "",
    sameAsDomestic: false,
    geographicalScale: null,
    specialInterest: null,
    funding: null,
    size: null,
    age: null,
    categories: [],
    subcategories: [],
  });
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const [fileName, setFileName] = useState("");
  const classes = useStyles();

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setFileName(fileUploaded?.name);
    setFile(fileUploaded);
  };
  const ngoDocs = useSelector(ngoDocDetailsSelectorFn);
  const dispatch = useDispatch();

  useEffect(() => {
    getRequest("ngo/ngo_user_ngo_list/")
      .then((res) => {
        if (res?.data?.data?.length !== 0) {
          localStorage.setItem("ngo_slug", res?.data?.data[0].slug);
          const ngoData = res?.data?.data[0];

          localStorage.setItem("ngo_name", ngoData?.ngo_name);
          setValues({
            ngoName: ngoData?.ngo_name,
            ngoImage: ngoData?.ngo_image,
            image:
              ngoData?.ngo_images && ngoData?.ngo_images.length > 0
                ? ngoData?.ngo_images.at(-1)
                : null,
            ngoBackgroud: ngoData?.ngo_background,
            legalForm: ngoData?.legal_Form,
            registrationNumber: ngoData?.ngo_registration_id,
            panNumber: ngoData?.pan_card_number,
            ngoTheme: ngoData?.byThematicFocus,
            registrationYear: ngoData?.year_of_registration,
            registeredAddress: ngoData?.reg_address_1,
            registeredCity: ngoData?.reg_city,
            registeredPincode: ngoData?.reg_pincode,
            registeredArea: ngoData?.reg_area,
            communicationAddress: ngoData?.comm_address_1,
            communicationCity: ngoData?.comm_city,
            communicationArea: ngoData?.comm_area,
            communicationPincide: ngoData?.comm_pincode,
            isCommunicationSame: ngoData?.ngo_name,
            primaryEmail: ngoData?.ngo_email,
            alternateEmail: ngoData?.alternative_email,
            chiefName: ngoData?.chief_name,
            chiefDesignation: ngoData?.chief_destination,
            chiefPhone: ngoData?.chief_mobile_number,
            chiefAlternatePhone: ngoData?.chief_alternative_mobile_number,
            chiefEmail: ngoData?.chief_email,
            chiefAlternateEmail: ngoData?.alternative_email,
            nodalName: ngoData?.nodal_name,
            nodalDesignation: ngoData?.nodal_destination,
            nodalPhone: ngoData?.nodal_mobile_number,
            nodalAlternatePhone: ngoData?.nodal_alternative_mobile_number,
            nodalEmail: ngoData?.nodal_email,
            nodalAlternateEmail: ngoData?.nodal_email,
            sameAsChief: ngoData?.ngo_name,
            beneficiariesNo: ngoData?.number_of_beneficiaries_served,
            annualTurnover: ngoData?.average_annual_turnover_of_last_3_year,
            staffNo: ngoData?.number_of_full_time_employees,
            projectLocation: ngoData?.project_locations,
            internationalPayment: ngoData?.is_eligible_for_foreign_fund,
            mobaliseInternationPayment: ngoData?.want_to_mob_foreign_fund_here,
            accountNo: ngoData?.domestic_ngo_bank_account_numer,
            bankName: ngoData?.domestic_bank_name,
            accountName: ngoData?.dom_account_holder_name,
            ifscCode: ngoData?.domestic_ifsc_code,
            branch: ngoData?.domestic_branch,
            intlAccountNo: ngoData?.international_ngo_bank_account_numer,
            intlBankName: ngoData?.international_bank_name,
            intlAccountName: ngoData?.international_account_holder_name,
            intlIfscCode: ngoData?.international_ifsc_code,
            intlBranch: ngoData?.international_branch,
            sameAsDomestic: ngoData?.ngo_name,
            geographicalScale: ngoData?.byGeographicalScale,
            specialInterest: ngoData?.bySpecialInterest,
            specialInterest2: ngoData?.bySpecialInterest2,
            funding: ngoData?.byFunding,
            size: ngoData?.bySize,
            age: ngoData?.byAge,
            categories: ngoData?.categories,
            subcategories: ngoData?.subcategories,
          });
          dispatch(
            ngoDocDetails({
              itrCopy: ngoData?.ngo_docs_images?._ITR_certificate,
              itrCopy2: ngoData?.ngo_docs_images?._ITR_2_certificate,
              itrCopy3: ngoData?.ngo_docs_images?._ITR_3_certificate,
              cancelledChequeDom: ngoData?.ngo_docs_images?.dom_cancel_chaque,
              cancelledChequeDom2:
                ngoData?.ngo_docs_images?.dom_cancel_chaque_2,

              cancelledChequeIntl:
                ngoData?.ngo_docs_images?.internation_cancel_chaque,
              cancelledChequeIntl2:
                ngoData?.ngo_docs_images?.internation_cancel_chaque_2,
              annualReport: ngoData?.ngo_docs_images?.annual_report,
              annualReport2: ngoData?.ngo_docs_images?.annual_report_2,
              annualReport3: ngoData?.ngo_docs_images?.annual_report_3,
              gstRegistrationNo: ngoData?.ngo_docs_images?.gst_certificate,
              auditedFinancial:
                ngoData?.ngo_docs_images?.audited_financial_statement,
              auditedFinancial2:
                ngoData?.ngo_docs_images?.audited_financial_statement_2,
              auditedFinancial3:
                ngoData?.ngo_docs_images?.audited_financial_statement_3,
              registrationNo: ngoData?.ngo_docs_images?.ngo_registration_id,
              panCardCert: ngoData?.ngo_docs_images?.pan_card_certificate,
              certificate12a: ngoData?.ngo_docs_images?._12_a_certificate,
              certificateFcra: ngoData?.ngo_docs_images?._FCRA_certificate,
              certificate80G: ngoData?.ngo_docs_images?._80_g_certificate,
              internalAuditReport:
                ngoData?.ngo_docs_images?.internal_audit_reports,
              projectCompletionReport:
                ngoData?.ngo_docs_images?.project_completion,
              impactAssessmentReport:
                ngoData?.ngo_docs_images?.impact_assessment_reports,
              caseStudy: ngoData?.ngo_docs_images?.case_study_and_testimonials,
              ratingReport: ngoData?.ngo_docs_images?.rating_report,
              relevantPic: ngoData?.ngo_docs_images?.relevant_photo_1,
              projectStatusReport: ngoData?.ngo_docs_images?.project_status,
              otherDoc: null,
            })
          );
        } else {
          // alert.error("Something went wrong");
        }
      })
      .catch((err) => {
        // alert.error("Something went wrong");
      });
  }, []);
  return (
    <Grid item md={1} xs={6} className={classes.gridtext}>
      {/* <div onClick={() => {handleClick()}} style={{cursor: "pointer"}}> */}
      <Typography variant="body2">{removeFileName ? "" : fileName}</Typography>
      {ngoDocs?.panCardCert === undefined ? (
        <Button
          variant={variant}
          color="primary"
          className={classes.upload}
          onClick={() => {
            handleClick();
          }}
        >
          Add
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
            // accept="image/*"
          />
        </Button>
      ) : (
        <Button
          variant={variant}
          color="primary"
          className={classes.upload}
          onClick={() => {
            handleClick();
          }}
        >
          Update
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
            // accept="image/*"
          />
        </Button>
      )}

      {/* </div> */}
    </Grid>
  );
}
export default FileUploader;
