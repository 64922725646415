import {
  alpha,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useAlert } from "react-alert";
import { postRequest } from "../../../apis/baseApi";
import CustomButtonCircularProgress from "../../CircularProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    borderRadius: "20px",
    backgroundColor: "transparent",
    //backgroundColor: alpha(theme.palette.primary.main, 0.1),
    textAlign: "left",
    //padding: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    // borderColor: theme.palette.primary.main,
    // border: "2px solid",
  },
  para: {
    color: "#94939F",
    marginTop: "10px",
  },
  para: {
    color: "#94939F",
    marginTop: "5px",
    marginBottom: "20px",
  },
  radio: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  btn: {
    height: "40px",
    disply: "flex",
    color: "#fff",
    fontWeight: "700",
    width: "100%",
    boxShadow: "0px 10px 40px 0px #C88FEF73",
    borderRadius: "8px",
  },
}));

export default function ChooseYourMode({ ngoId, ngoName }) {
  const classes = useStyles();
  const alert = useAlert();
  const [mode, setMode] = useState("lumpsum");
  const [occur, setOccur] = useState("weekly");
  const [donationAmount, setDonationAmount] = useState();
  const [convenienceFee, setConvenienceFee] = useState();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const isAnonymous = localStorage.getItem("user_token") ? false : true;

  const handleModeChange = (event) => {
    setMode(event.target.value);
  };

  const handleOccurChange = (event) => {
    setOccur(event.target.value);
  };

  const getTotal = () => {
    let total = 0;
    if (donationAmount) total += parseInt(donationAmount);
    if (convenienceFee) total += parseInt(convenienceFee);
    return total;
  };

  const handleBookingPaymentResponse = (res) => {
    let paymetStatus = {
      status: true,
      razorpay_order_id: res?.razorpay_order_id,
      razorpay_payment_id: res?.razorpay_payment_id,
      razorpay_signature: res?.razorpay_signature,
      donation: parseInt(donationAmount),
    };

    handlePaymentProcessing(paymetStatus);
  };

  const createOrder = () => {
    setLoading(true);
    postRequest("payment_gateway/create_pre_payment/", {
      amount: donationAmount,
      ngo_id: ngoId,
    })
      .then((res) => {
        postRequest("payment_gateway/create_payment/", {
          is_international: false,
          pre_donation_id: res?.data?.data?.id,
          currency: "INR",
        })
          .then((res) => {
            var options = {
              key: "rzp_live_gEldzuAs4625Uw", // Enter the Key ID generated from the Dashboard
              amount: res?.data?.data?.amounr, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: "INR",
              name: "Ikigai Portfolio",
              description: ngoName,
              image:
                "https://ikigaportfolio.s3.amazonaws.com/static_image/01/favicon.png",
              order_id: res?.data?.data?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
              handler: handleBookingPaymentResponse,
              prefill: {
                name: name ? name : localStorage.getItem("name"),
                email: email ? email : localStorage.getItem("email"),
                contact: "9999999999",
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.on("payment.failed", function (response) {
              alert(response.error.code);
              alert(response.error.description);
              alert(response.error.source);
              alert(response.error.step);
              alert(response.error.reason);
              alert(response.error.metadata.order_id);
              alert(response.error.metadata.payment_id);

              let paymetStatus = {
                status: "FAILED",
                description: response.error.description,
                code: response.error.code,
                source: response.error.source,
                reason: response.error.reason,
                order_id: response.error.metadata.order_id,
                payment_id: response.error.metadata.payment_id,
              };
              handlePaymentProcessing(paymetStatus);
            });
            rzp1.open();
            setLoading(false);
          })
          .catch((err) => {
            alert.error(err?.response?.data?.msg + ".Please activate this NGO");
            setLoading(false);
          });
      })
      .catch((err) => {
        alert.error(err?.response?.data?.msg);
        setLoading(false);
      });
  };

  const createAnonymousOrder = () => {
    setLoading(true);
    postRequest("payment_gateway/anonymus_pre_payment/", {
      amount: donationAmount,
      ngo_id: ngoId,
      donor_name: name,
      email: email,
    })
      .then((res) => {
        postRequest("payment_gateway/anonymus_create_payment/", {
          // is_international: false,
          pre_donation_id: res?.data?.data?.id,
          currency: "inr",
        })
          .then((res) => {
            if (res?.data?.status) {
              var options = {
                key: "rzp_live_gEldzuAs4625Uw", // Enter the Key ID generated from the Dashboard
                amount: res?.data?.data?.amounr, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                currency: "INR",
                name: "Ikigai Portfolio",
                description: ngoName,
                image:
                  "https://ikigaportfolio.s3.amazonaws.com/static_image/01/favicon.png",
                order_id: res?.data?.data?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                handler: handleBookingPaymentResponse,
                prefill: {
                  name: name ? name : localStorage.getItem("name"),
                  email: email ? email : localStorage.getItem("email"),
                  contact: "9999999999",
                },
                notes: {
                  address: "Razorpay Corporate Office",
                },
                theme: {
                  color: "#3399cc",
                },
              };
              var rzp1 = new window.Razorpay(options);
              rzp1.on("payment.failed", function (response) {
                alert(response.error.code);
                alert(response.error.description);
                alert(response.error.source);
                alert(response.error.step);
                alert(response.error.reason);
                alert(response.error.metadata.order_id);
                alert(response.error.metadata.payment_id);

                let paymetStatus = {
                  status: "FAILED",
                  description: response.error.description,
                  code: response.error.code,
                  source: response.error.source,
                  reason: response.error.reason,
                  order_id: response.error.metadata.order_id,
                  payment_id: response.error.metadata.payment_id,
                };
                handlePaymentProcessing(paymetStatus);
              });
              rzp1.open();
            } else {
              alert.error(res?.data?.msg);
            }
            setLoading(false);
          })
          .catch((err) => {
            alert.error(err?.response?.data?.msg + ".Please activate this NGO");
            setLoading(false);
          });
      })
      .catch((err) => {
        // alert.error("Something went wrong");
        setLoading(false);
      });
  };

  const createSubscription = () => {
    postRequest("payment_gateway/create_plan/", {
      ammount: donationAmount,
      ngo: ngoId,
      period: occur,
      name: localStorage.getItem("name"),
    })
      .then((res) => {
        postRequest("payment_gateway/create_subscription/", {
          plan_id: res?.data?.data?.plan_id,
          start_at: 1877314600,
        })
          .then((res) => {
            var options = {
              key: "rzp_live_gEldzuAs4625Uw", // Enter the Key ID generated from the Dashboard
              amount: res?.data?.data?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: "INR",
              name: "Ikigai Portfolio",
              description: "Test Transaction",
              image: "https://example.com/your_logo",
              order_id: res?.data?.data?.subscription_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
              handler: handleBookingPaymentResponse,
              prefill: {
                name: localStorage.getItem("name"),
                email: localStorage.getItem("email"),
                contact: "9999999999",
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.on("payment.failed", function (response) {
              alert(response.error.code);
              alert(response.error.description);
              alert(response.error.source);
              alert(response.error.step);
              alert(response.error.reason);
              alert(response.error.metadata.order_id);
              alert(response.error.metadata.payment_id);

              let paymetStatus = {
                status: "FAILED",
                description: response.error.description,
                code: response.error.code,
                source: response.error.source,
                reason: response.error.reason,
                order_id: response.error.metadata.order_id,
                payment_id: response.error.metadata.payment_id,
              };
              handlePaymentProcessing(paymetStatus);
            });
            rzp1.open();
          })
          .catch((err) => {
            // alert.error("Something went wrong");
          });
      })
      .catch((err) => {
        // alert.error("Something went wrong");
      });
  };

  const handlePaymentProcessing = (data) => {
    postRequest("payment_gateway/get_payment_status/", data)
      .then((res) => {
        if (data.status) {
          alert.success("Donation successful");
          setDonationAmount(0);
        } else alert.error("Error in donation");
      })
      .catch((err) => {
        // alert.error("Something went wrong");
      });
  };

  const validateData = () => {
    if (isAnonymous && (!name || !email)) {
      alert.error("Please enter both email and name");
      return false;
    }
    if (!donationAmount || parseInt(donationAmount < 0)) {
      alert.error("Please enter valid donation amount");
      return false;
    }
    return true;
  };

  return (
    <>
      <Paper className={classes.paper}>
        {isAnonymous && (
          <>
            <Grid container spacing={2} style={{ width: "100%" }}>
              <Grid item md={12} style={{ display: "grid" }}>
                <Typography
                  variant="subtitle2"
                  style={{ fontWeight: "500", marginBottom: "10px" }}
                >
                  Donor Email
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  color="primary"
                  placeholder="Email"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12} style={{ display: "grid" }}>
                <Typography
                  variant="subtitle2"
                  style={{ fontWeight: "500", marginBottom: "10px" }}
                >
                  Donor Name
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  color="primary"
                  placeholder="Name"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Grid>

              <Typography variant="body2" className={classes.para}>
                These details will not be shared publicly.
              </Typography>
            </Grid>
          </>
        )}
        {!isAnonymous && (
          <>
            <Typography
              variant="body1"
              color="primary"
              style={{ fontWeight: "500" }}
            >
              Choose your Mode of Donation
            </Typography>
            <Typography variant="body2" className={classes.para}>
              1. Lumpsum - Make a one-time donation
            </Typography>
            <Typography variant="body2" className={classes.para}>
              2. Periodic - Make a fixed donation monthly or yearly
            </Typography>
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <RadioGroup
                className={classes.radio}
                value={mode}
                onChange={handleModeChange}
              >
                <FormControlLabel
                  value="periodic"
                  control={<Radio color="primary" size="small" name="mode" />}
                  label={
                    <span style={{ fontSize: "14px", color: "#2B2D3F" }}>
                      Periodic
                    </span>
                  }
                />
                <FormControlLabel
                  value="lumpsum"
                  control={<Radio color="primary" size="small" name="mode" />}
                  label={
                    <span style={{ fontSize: "14px", color: "#2B2D3F" }}>
                      Lumpsum
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>
          </>
        )}
        {mode === "periodic" && (
          <FormControl
            component="fieldset"
            style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
          >
            <RadioGroup
              className={classes.radio}
              value={occur}
              onChange={(event) => {
                handleOccurChange(event);
              }}
            >
              {/* <FormControlLabel
                value="weekly"
                control={<Radio color="primary" size="small" name="occur" />}
                label={
                  <span style={{ fontSize: "14px", color: "#2B2D3F" }}>
                    Weekly
                  </span>
                }
              /> */}
              <FormControlLabel
                value="monthly"
                control={<Radio color="primary" size="small" name="occur" />}
                label={
                  <span style={{ fontSize: "14px", color: "#2B2D3F" }}>
                    Monthly
                  </span>
                }
              />
              <FormControlLabel
                value="yearly"
                control={<Radio color="primary" size="small" name="occur" />}
                label={
                  <span style={{ fontSize: "14px", color: "#2B2D3F" }}>
                    Yearly
                  </span>
                }
              />
            </RadioGroup>
          </FormControl>
        )}
        <Grid container spacing={2}>
          <Grid item md={12} style={{ display: "grid" }}>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "500", marginBottom: "15px" }}
            >
              Donation Amount
            </Typography>
            <TextField
              type="number"
              variant="outlined"
              color="primary"
              placeholder="Rs. 1,000"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={(e) => {
                setDonationAmount(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <Typography variant="h6" style={{ color: "#626262" }}>
            Total
          </Typography>

          <Typography variant="h6" color="primary">
            Rs. {getTotal()}
          </Typography>
        </div>

        <div style={{ width: "100%", marginTop: "20px" }}>
          <Button
            color="primary"
            variant="contained"
            className={classes.btn}
            onClick={() => {
              if (validateData()) {
                mode === "periodic"
                  ? createSubscription()
                  : isAnonymous
                  ? createAnonymousOrder()
                  : createOrder();
              }
            }}
          >
            {loading ? <CustomButtonCircularProgress /> : "DONATE"}
          </Button>
        </div>
      </Paper>
    </>
  );
}
