import React, { useState, useEffect } from "react";
import UserDashboardLayout from "../../components/Layout/UserDashboardLayout";
import NewsAndUpdates from "../../components/Section/UserDashboard/NewsAndUpdates";
import { getRequest } from "../../apis/baseApi";
import { useAlert } from "react-alert";

export default function UserNewsUpdates() {
  const [news, setNews] = useState([]);
  const alert = useAlert();
  useEffect(() => {
    getRequest("dashboard/donor/dashboard/get_news_and_event/")
      .then((res) => {
        if (res?.data?.results) {
          setNews(res?.data);
        } else {
          alert.error("Something went wrong!");
        }
      })
      .catch((err) => {
        // alert.error("Something went wrong!");
      });
  }, []);
  return (
    <>
      <UserDashboardLayout>
        <NewsAndUpdates news={news} />
      </UserDashboardLayout>
    </>
  );
}
