import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import ChooseOption from "../../Cards/Total/ChooseOption";
import Heading from "../../Common/Heading/Heading";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  // btn: {
  //   color: "#fff",
  //   boxShadow: "none",
  //   height: "50px",
  //   borderRadius: "8px",
  //   minWidth: "200px"
  // },
  maingrid: {
    marginTop: "50px",
    marginBottom: "50px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    boxShadow: "0px 10px 30px -15px rgba(0, 0, 0, 0.3)",
    borderRadius: "8px",
    height: "250px",
    width: "300px",
  },
  btn: {
    boxShadow: "none",
    borderRadius: "8px",
    color: "#fff",
    height: "50px",
  },
}));

function LikeDonate({setRecommendMe}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <div style={{ marginTop: "3%" }} id="donate">
        <Heading title="How would you like to donate?" />
        <Grid container spacing={2} className={classes.maingrid} >
          <Grid item md={6} style={{ textAlign: "center", display: "flex", justifyContent: "center"}}>
            {/* <Button variant="contained" color="primary" className={classes.btn} onClick={() => {
              history.push("/member-login?redirectUrl=donate-now")
            }}>
              Login to Donate
            </Button> */}
            <div className={classes.card}>
        <Typography
          variant="body2"
          style={{
            marginBottom: "10px",
            textAlign: "justify",
          }}
        >
          If you donate while being logged in, you can view your donation history, usage and impact reports and download donation receipts and tax exemption certificates from your dashboard. If you wish to choose this option and are already logged in, please move on to the next section. Click this button if you are not already logged in but wish to keep track of your donations through your member dashboard.

        </Typography>
        <Button variant="contained" color="primary" className={classes.btn} onClick={() => {
          history.push("/member-login?redirectUrl=donate-now")
        }}>
          Login to Donate
        </Button>
      </div>
          </Grid>
          <Grid item md={6} style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
            {/* <Button variant="contained" color="primary" className={classes.btn} onClick={() => {
              if(localStorage.getItem('user_token'))
              {
                localStorage.clear();
                window.location.reload();
              }
              else{
                
              }
            }}>
              Donate Anonymously
            </Button> */}
            <div className={classes.card}>
        <Typography
          variant="body2"
          style={{
            marginBottom: "10px",
            textAlign: "justify",
          }}
        >
          When you donate anonymously, donations you make are not linked to you and you cannot track their usage and impact status nor can you download the receipts and tax exemption certificates at a later date. If you are currently not logged in and want to donate anonymously, please move directly to the next section. If you are logged in, click on the button below and you will be logged out. You may then continue to donate anonymously by moving to the next section. 

        </Typography>
        <Button variant="contained" color="primary" className={classes.btn} onClick={() => {
         if(localStorage.getItem('user_token'))
         {
           localStorage.clear();
           window.location.reload();
         }
         else{
           
         }
        }}>
          Donate Anonymously
        </Button>
      </div>
          </Grid>
        </Grid>
        <div id="option">
          <Heading title="Choose your option below" />
          <Grid
            container
            spacing={5}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
            }}
            
          >
            {data.map((item) => (
              <Grid
                item
                md={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ChooseOption name={item.title} btn={item.btn} buttonId={item.id} setRecommendMe={setRecommendMe}/>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </>
  );
}

export default LikeDonate;

const data = [
  {
    title: `You choose this option when you do not want to choose an NGO from all our NGOs but want us to recommend 3-5 NGOs for you to choose from and donate. If you are not logged in, the system will randomly recommend 3-5 NGOs for you to choose from and donate. If you are logged in and have completed your profile, the system will recommend 3-5 NGOs based on the preferences marked in your profile.`,
    btn: "Recommend Me",
    id: 1
  },
  {
    title: `Choosing this option will take you to the list of all our NGO partners and you will get multiple options to filter and shortlist NGOs to donate to, according to your preference. You can select the NGO from your shortlist and donate.`,
    btn: "I want to Choose",
    id: 2
  },
];
