import React from "react";
import {
  Typography,
  Grid,
  TextField,
  makeStyles,
  Button,
} from "@material-ui/core";
import Calendar from "../../Calendar/Calendar";

const useStyles = makeStyles((theme) => ({
  download_main_grid: {
    display: "flex",
    alignItems: "center",
  },
  download: {
    boxShadow: "none",
    borderRadius: "8px",
    color: "#fff",
    height: "40px",
    display: "flex",
  },
}));

function ReportDownloadGrid({item}) {
  const classes = useStyles();
  return (
    <>
      <div>
        <Grid container spacing={2}>
          <Grid item md={6} style={{ display: "grid" }}>
            <TextField
              rows={3}
              multiline
              variant="outlined"
              placeholder="Description"
              color="primary"
              style={{ borderRadius: "8px" }}
              value={item?.description}
              disabled
            />
          </Grid>

          <Grid item md={6} className={classes.download_main_grid}>
            <Grid container spacing={2}>
              <Grid item md={4} >
                <Calendar placeholder="11/02/2022" selectedDate={item?.from_date} disabled={true}/>
              </Grid>
              <Grid item md={4} >
                <Calendar placeholder="11/02/2022" disabled={true} selectedDate={item?.to_date}/>
              </Grid>
              <Grid
                item
                md={4}
                style={{ display: "grid", alignItems: "center" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.download}
                  disabled={item?.certificate ? false : true}
                  onClick={() => {
                    window.open(item?.certificate, "_blank")
                  }}
                >
                  {item?.certificate ? "Download" : "Not Available"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ReportDownloadGrid;
