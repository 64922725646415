import React from "react";
import NgoRegThirdSection from "../../../components/Section/DashBoard/Profile/NgoRegThirdSection";
import DashboardLayout from "../../../components/Layout/DashBoardLayout";
import { useEffect, useState } from "react";
import { getRequest } from "../../../apis/baseApi";

export default function NgoRegFinal() {
  const [onGoingProjects, setOngoingProjects] = useState([]);
  const [keyTeamMembers, setKeyTeamMembers] = useState([]);

  return (
    <>
      <DashboardLayout>
        <NgoRegThirdSection fromProfile={true} />
      </DashboardLayout>
    </>
  );
}
//Ram
