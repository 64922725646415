import { Button } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useHistory } from "react-router-dom";

const StyledMenu = withStyles({
  paper: {
    border: ".5px solid #d3d4d5",
    borderRadius: "10px",
  },
})((props) => (
  <Menu
    style={{ top: "14px" }}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function Login() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        color="primary"
        style={{
          paddingLeft: "30px",
          paddingRight: "30px",
          padding: "10px",
          // marginLeft: "20px",
          borderRadius: "8px",
          boxShadow: "none",
          minWidth: "175px"
        }}
        endIcon={<ExpandMoreIcon />}
      >
        Login
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={() => {
              history.push("/member-login");
              handleClose();
          }}>
          <ListItemText primary="As Member" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => {
              history.push("/ngo-login");
              handleClose();
          }}>
          <ListItemText primary="As NGO" onClick={() => {
              history.push("/ngo-login");
              handleClose();
          }}/>
        </StyledMenuItem>{" "}
      </StyledMenu>
    </>
  );
}
