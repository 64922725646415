import Heading from "../../Common/Heading/Heading";
import React, { Fragment, useEffect } from "react";
import {
  Typography,
  Box,
  Hidden,
  makeStyles,
  Container,
  createStyles,
  Grid,
} from "@material-ui/core";
import demo from "../../assets/png/browser-stats/rafiki.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    cardimg: {
      [theme.breakpoints.only("sm")]: {
        width: "auto",
        height: "200px",
      },
      [theme.breakpoints.only("xs")]: {
        width: "auto",
        height: "300px",
      },
    },
    title: {
      paddingTop: "20px",
    },

    title2: {
      //  fontSize: "35px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
    },
    title3: {
      //fontSize: "22px",
      //fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      marginTop: "3%",
    },
    details: {
      //fontSize: "13px",
      marginBottom: "8px",
      //fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      textAlign: "justify",
      color: "#333",
    },
    details2: {
      //  fontSize: "13px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      textAlign: "justify",
      color: "#333",
    },
    gridimg: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

function Values() {
  const classes = useStyles();
  return (
    <>
      <div style={{ marginTop: "10%" }}>
        {data.map((item, index) => (
          <Box paddingTop="2%" key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Hidden only={["sm", "xs"]}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "40px",
                    }}
                  >
                    <Typography
                      variant="h2"
                      style={{ fontFamily: "Marcellus" }}
                    >
                      {item.title}
                    </Typography>
                    <div
                      style={{
                        backgroundColor: "#C4C4C4",
                        height: "1px",
                        width: "35%",
                        // marginLeft: "50px",
                      }}
                    />
                  </div>
                </Hidden>
                <Hidden only={["md", "lg", "xl"]}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      style={{ fontFamily: "Marcellus" }}
                    >
                      {item.title}
                    </Typography>
                    <div
                      style={{
                        backgroundColor: "#C4C4C4",
                        height: "1px",
                        width: "35%",
                        // marginLeft: "50px",
                      }}
                    />
                  </div>
                </Hidden>
                <Grid md={12} xs={12} style={{display: "flex"}}>
                <Grid md={6} xs={6}>
                <Typography
                      variant="h6"
                      
                    >
                      Values
                    </Typography>
                <ul style={{ paddingInlineStart: "15px" }}>
                  {item.detail.map((item2, index2) => (
                    <Fragment key={index2}>
                      <li>
                        <div key={index2} className={classes.details}>
                          <Typography variant="body2">{item2.text}</Typography>
                        </div>
                      </li>
                    </Fragment>
                  ))}
                </ul>
                {/* </Grid> */}
                </Grid>
                <Grid md={6} xs={6}>
                
                <Typography
                      variant="h6"
                    >
                      Key Drivers
                    </Typography>
                    <ul style={{ paddingInlineStart: "15px" }}>
                  {item.keyDrivers.map((item2, index2) => (
                    <Fragment key={index2}>
                      <li>
                        <div key={index2} className={classes.details}>
                          <Typography variant="body2">{item2.text}</Typography>
                        </div>
                      </li>
                    </Fragment>
                  ))}
                </ul>
                </Grid>
              </Grid>
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridimg}>
                <img
                  src={item.image}
                  height="400px"
                  className={classes.cardimg}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
      </div>
    </>
  );
}

export default Values;


const data = [
  {
    title: "Values and Key Drivers",
    image: demo,
    detail: [
      {
        text: `Sensitivity`,
      },
      {
        text: `Professionalism`,
      },
      {
        text: `Integrity`,
      },
      {
        text: `Customer Centricity`,
      },
      {
        text: `Efficiency`,
      },
      {
        text: `Transparency`,
      },
    ],
    keyDrivers: [
      {
        text: `Passion`,
      },
      {
        text: `Technology & Innovation`,
      },
      {
        text: `Compliance`,
      },
      {
        text: `Solution Oriented Approach`,
      },
      {
        text: `Communication`,
      },
      {
        text: `Respect`,
      },
      {
        text: `Caring`,
      },
      {
        text: `Joy`,
      }
    ],
  },
];
