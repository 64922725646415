import {
  alpha, makeStyles
} from "@material-ui/core";
import Layout from "../../components/Layout/Layout";
import Mission from "../../components/Section/AboutSection/Mission";
import Philosophy from "../../components/Section/AboutSection/Philosophy";
import Values from "../../components/Section/AboutSection/Values";
import Vision from "../../components/Section/AboutSection/Vision";
import WhoWeAre from "../../components/Section/AboutSection/WhoWeAre";
import PublicLayout from "../../components/Layout/PublicLayout";

const useStyles = makeStyles((theme) => ({
  learn: {
    paddingLeft: "40px",
    paddingRight: "40px",
    color: "#fff",
    height: "45px",
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
  },
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
    marginBottom: "70px",
  },
}));

export default function Support() {
  const classes = useStyles();
  return (
    <>
      <PublicLayout keyss={"about"}>
      <>
        <Philosophy />
        <WhoWeAre />
        <Mission />
        {/* <Vision /> */}
        <Values />
      </>
      </PublicLayout>
    </>
  );
}

const donator = [
  { title: `Mr. Ankur Mishra` },
  { title: `Mr. Nirmal Thakur` },
  { title: `Mrs. Mrinali Thakral` },
  { title: `Mr. Kishore Kumar` },
  { title: `Mrs. Mrinali Thakral` },
  { title: `Mr. Kishore Kumar` },
];
