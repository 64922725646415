import { Grid, Hidden } from "@material-ui/core";
import DashBoardSidebar from "../Navbar/DashBoardSidebar";
import PublicSidebar from "../Navbar/PublicSidebar";

export default function PublicLayout(props) {
  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        <Hidden only={["xs"]}>
          <Grid item md={2} sm={2}>
            <PublicSidebar keyss={props?.keyss} />
          </Grid>
        </Hidden>
        <Grid item md={10} sm={10} xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </>
  );
}
