import React from "react";
import CenterHeading from "../../Common/Heading/CenterHeading";
import Grid from "@material-ui/core/Grid";
import Radhika from "../../assets/png/radhika.png";
import Rahul from "../../assets/png/rahul.jpg";
import Venkatesh from "../../assets/png/venkatesh.jpg";
import Anish from "../../assets/png/anish.jpeg";
import Advisors from "../../Cards/DonationPerson/Advisors";

function AdvisorSection() {
  return (
    <>
      <CenterHeading title="Board of Advisors" />
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        {donator.map((item) => (
          <Grid
            item
            md={3}
            sm={4}
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Advisors popUp={item.popUp} image={item.image} name={item.title} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default AdvisorSection;

const donator = [
  {
    image: Anish,
    title: `Mr. Anish Kumar`,
    popUp: `Anish is Managing Director at Transforming Rural India Foundation 
  (www.trif.in). Its work is focused on transforming life opportunities in poorest 
  villages of India. The TRI Action Platform with 19 leading NGOs, socialenterprises and partnership with flagship programmes of Government of India 
  like Mission Antyodaya, Transformation of Aspirational Districts; is the largest 
  formal multi-stakeholder engagement in transforming villages.
  Anish was part of the senior management team at PRADAN, India’s leading 
  rural development NGO. His areas of expertise include creating business 
  organizations run by poor communities and facilitating participation of smallholder farmers in modern value chains. He has been involved in designing 
  programmes on producer collectives and is founder of National Association for 
  Farmer Producer Organisation (https://www.nafpo.in/)
  Anish was involved in developing small-holder poultry model, run by poor 
  women this has emerged as the largest family poultry network in India with 
  turnover of US $ 83 million, he Chairs National Smallholder Poultry 
  Development Trust (www.nspdt.org), supporting more than 13,000 women.`,
  },
  {
    image: Radhika,
    title: `Ms. Radhika Agashe Mathur`,
    popUp: `Radhika specialises in the thematic areas of financial and business education, microfinance operations, Self Help Group model, and social performance management through over two decades of experience in program implementation, development of content for training and communication, convening forums and policy dialogues and conducting social research. At ACCESS, she has led the organisation’s work in three broad areas — conceiving and leading field-based projects in financial and business capability building, providing technical assistance and consulting and convening the flagship Inclusive Finance India platform, for over a decade. She has coordinated various policy discussions and state of practice studies for promoting an enabling institutional and regulatory framework for financial inclusion in India. She has actively represented ACCESS on the India Responsible Finance Forum led by the IFC. In her current role in ACCESS, she provides leads on donor engagement and provides support and oversight across the programmatic areas of livelihoods and financial inclusion.
  Radhika has in the past been associated with many organisations, such as EDA Rural Systems, BASIX, Intellecap, Action for Social Advancement, Sambodhi Research & Communications and Microfinance Institutions Network. She holds a postgraduate degree from the Indian Institute of Forest Management (IIFM).`,
  },
  {
    image: Rahul,
    title: `Mr. Rahul Bist`,
    popUp: `Rahul is a management professional with close to two decades of experience. He is a graduate in Computer Application and an MBA from Indian Institute of Forest Management. Rahul is a co-founder of M2i Consulting and has worked in 18 countries in Asia, Africa and Europe. Rahul specialises in the domain of financial inclusion, development finance and microenterprise financing. Within these domains, he has been involved in advisory and consulting services, training, program evaluations, risk management, audits, due diligence and ratings. Rahul has worked extensively in developing models and products for formal financial linkages of Saving Groups in several countries in Africa. As part of M2i, Rahul has been involved in co-developing, marketing and implementation of technology based solutions for training, Monitoring & Evaluation and Internal Audit.`,
  },
  {
    image: Venkatesh,
    title: `Mr. Venkatesh Roddawar`,
    popUp: `Venkatesh is a Public Health Specialist with over two decades of experience in the field of health systems strengthening, managing public health programs and closely working with Central and State governments.  He contributed in building capacities of state level institutions for better planning & implementation of public health programs. He played a catalytic role in supporting National TB Elimination Program efforts towards private sector engagement, strategic contracting and complement Joint Efforts for Elimination of TB project. His work on the revised guidance on partnership enabled to accelerate output-based contracting in the country. He was part of various sub-committees for developing National Health Policy 2017 and expert committee on tribal health. 
  Before joining JSI India, he was associated with PATH, WHO, The Union, The World Bank, MoHFW and NHSRC.  He is a recipient of Erasmus+ fellowship in European Public Health from European Union.`,
  },
];
