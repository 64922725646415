import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { postRequest } from "../../../../apis/baseApi";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    // backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderRadius: "20px",
    padding: "25px",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    color: "#fff",
    marginTop: "50px",
  },
}));

export default function FeedBackSection({ isNGO = false }) {
  const classes = useStyles();
  const alert = useAlert();

  const [data, setData] = useState({
    category: "",
    subject: "",
    messageBody: "",
  });

  function validateForm() {
    if (!data?.category) {
      alert.error("Please select category!");
      return false;
    }
    if (!data?.subject) {
      alert.error("Please enter subject!");
      return false;
    }
    if (!data?.messageBody) {
      alert.error("Please enter message!");
      return false;
    }
    return true;
  }

  function onSubmit() {
    if (validateForm()) {
      const postData = {
        category: data?.category,
        subject: data?.subject,
        message_body: data?.messageBody,
      };
      postRequest(
        isNGO
          ? `dashboard/ngo/dashboard/ngo_query/${localStorage.getItem(
              "ngo_slug"
            )}/`
          : "dashboard/donor/dashboard/donor_query_create/",
        postData
      )
        .then((res) => {
          if (res?.data?.data) {
            alert.success("Query submitted successfully!!");
            setData({
              category: "",
              subject: "",
              messageBody: "",
            });
          } else {
            // alert.error("Something went wrong!!");
          }
        })
        .catch((err) => {
          // alert.error("Something went wrong!!");
        });
    }
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h5" color="primary">
          We at Ikigai Portfolio are always happy to hear from you.
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Typography variant="body2">
            Email us with your query, feedback or complaints and we will reach
            back
          </Typography>

          <Typography
            variant="body2"
            // color="primary"
            style={{ fontWeight: "700" }}
          >
            Email us at{" "}
            {isNGO
              ? "partner@ikigaiportfolio.com"
              : "member@ikigaiportfolio.com"}{" "}
            with your query.
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{ backgroundColor: "#000", height: "1px", width: "40%" }}
          />{" "}
          or{" "}
          <div
            style={{ backgroundColor: "#000", height: "1px", width: "40%" }}
          />
        </div>
        <Typography variant="body2" style={{ color: "#94939F" }}>
          Fill out the form regarding your query, feedback or complaint
        </Typography>

        <div style={{ marginTop: "20px" }}>
          <Grid container spacing={5}>
            <Grid
              item
              md={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2">Select your category</Typography>
            </Grid>
            <Grid item md={6}>
              <Autocomplete
                id="country-select-demo"
                style={{ backgroundColor: "#fff" }}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                options={category}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "district",
                    }}
                  />
                )}
                onChange={(e, item) => {
                  setData({ ...data, category: item?.name });
                }}
                value={{ name: data?.category }}
              />
            </Grid>
            <Grid item md={4} />
            <Grid
              item
              md={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2">Subject</Typography>
            </Grid>
            <Grid item md={6}>
              <TextField
                // multiline
                // rows={5}
                style={{ width: "100%" }}
                size="small"
                variant="outlined"
                onChange={(event) => {
                  setData({ ...data, subject: event.target.value });
                }}
                value={data?.subject}
              />
            </Grid>
            <Grid item md={4} />
            <Grid
              item
              md={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2">Body</Typography>
            </Grid>
            <Grid item md={6}>
              <TextField
                multiline
                rows={10}
                style={{ width: "100%" }}
                size="small"
                variant="outlined"
                onChange={(event) => {
                  setData({ ...data, messageBody: event.target.value });
                }}
                value={data?.messageBody}
              />
            </Grid>
            <Grid item md={4} />
            <Grid item md={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={() => {
                  onSubmit();
                }}
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </>
  );
}

const category = [
  { name: "Query" },
  { name: "Complaint" },
  { name: "Feedback" },
];
