import DashboardLayout from "../../../components/Layout/DashBoardLayout";
import FeedBackSection from "../../../components/Section/DashBoard/FeedBackSection/FeedBackSection";

export default function FeedBack() {
  return (
    <>
      <DashboardLayout>
        <FeedBackSection isNGO={true}/>
      </DashboardLayout>
    </>
  );
}
