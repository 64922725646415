import vactor from "../../components/assets/png/resource.png";
import PublicLayout from "../../components/Layout/PublicLayout";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: "30%",
    [theme.breakpoints.only("sm")]: {
      width: "auto",
      height: "200px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "auto",
      height: "100px",
    },
  },
}));


export default function Measurement() {
  const classes = useStyles();
  return (
    <PublicLayout keyss={"resource"}>
    <>
      {/* {data.map((item) => (
        <RatingBanner
          title={item.heading}
          info={item.info}
          image={item.image}
        />
      ))} */}

<div>
        <Typography
          variant="h2"
          component="h1"
          color="primary"
          style={{ fontFamily: "Marcellus", textAlign: "center" }}
        >
          Impact Measurement
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
         This is a key page in the platform. Here we intend to cover the actual impact created through this platform, the depth and sustainability of the impact, the numbers, coverage etc. We will do an in-depth analysis of the impact created, both quantitative and qualitative. 
<br />
Stay tuned to this page for more. We will start posting here soon as we start getting the impact reports from our Partner NGOs and from our due diligence.  

        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={vactor} height="400px" className={classes.img} />
      </div>



      {/* <Testimonial />
      <DonationsMobilised />
      <InNumbers /> */}
    </>
    </PublicLayout>
  );
}

const data = [
  {
    heading: "Impact Measurement",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum, ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo in tristique feugiat faucibus arcu tempor. Vestibulum, congue vitae eleifend nec maecenas tristique bibendum egestas fermentum. Cursus nunc vulputate cras felis suspendisse convallis nisl.",
    image: vactor,
  },
];
