import { Paper, makeStyles, Grid, Typography, Button } from "@material-ui/core";
import React from "react";
import Table from "../../../Common/Table/Table";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function PaymentGrid() {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          style={{ padding: "20px", paddingTop: "0px" }}
        >
          <Grid item md={12} style={{ textAlign: "center" }}>
            <Typography variant="h6" color="primary">
              Payments
            </Typography>
          </Grid>

          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns} list={row} />
          </Grid>

          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns2} list={row2} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export const columns = [
  {
    field: "services",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "120px" }}>
        Services
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.services}
      </span>
    ),
  },
  {
    field: "total_charges_payable",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "60px" }}>
        Total Charges Payable
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.total_charges_payable}
      </span>
    ),
  },
  {
    field: "payment_made",
    type: "text",
    width: 250,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "60px" }}>
        Payment Made
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.payment_made}
      </span>
    ),
  },

  {
    field: "payment_due",
    type: "text",
    sortable: false,
    width: 250,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "70px" }}>
        Payment Due
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary"> {params.row.payment_due}</Typography>
      </span>
    ),
  },
];

export const row = [
  {
    id: `1`,
    services: `Lorem ipsum dolor sit amet, consectetur adipiscing `,
    total_charges_payable: "Rs. 50,000",
    payment_made: "Rs. 30,000",
    payment_due: "Rs. 30,000",
  },
  {
    id: `2`,
    services: `Lorem ipsum dolor sit amet, consectetur adipiscing `,
    total_charges_payable: "Rs. 50,000",
    payment_made: "Rs. 30,000",
    payment_due: "Rs. 30,000",
  },
  {
    id: `3`,
    services: `Lorem ipsum dolor sit amet, consectetur adipiscing `,
    total_charges_payable: "Rs. 50,000",
    payment_made: "Rs. 30,000",
    payment_due: "Rs. 30,000",
  },
  {
    id: `4`,
    services: `Lorem ipsum dolor sit amet, consectetur adipiscing `,
    total_charges_payable: "Rs. 50,000",
    payment_made: "Rs. 30,000",
    payment_due: "Rs. 30,000",
  },
];

export const columns2 = [
  {
    field: "donation_id",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "100px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },
  {
    field: "refund",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "60px" }}>
        Reason for Refund
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.refund}
      </span>
    ),
  },
  {
    field: "refund_amount",
    type: "text",
    width: 250,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "60px" }}>
        Refund Amount
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.refund_amount}
      </span>
    ),
  },

  {
    field: "refund_payment",
    type: "text",
    sortable: false,
    width: 250,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "50px" }}>
        Refund Payment
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <div
          style={{
            border: "2px solid #C88FEF",
            borderRadius: "30px",
            height: "40px",
            display: "flex",
            padding: "10px",
            paddingLeft: "2px",
            paddingRight: "2px",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: "30px",
              boxShadow: "none",
              padding: "none",
              paddingLeft: "30px",
              paddingRight: "30px",
              height: "34px",
            }}
          >
            <Typography
              style={{
                color: "#fff",
                fontSize: "12px",
              }}
            >
              {" "}
              Refund
            </Typography>
          </Button>
        </div>
      </span>
    ),
  },
];

export const row2 = [
  {
    id: `1`,
    donation_id: `Lorem`,
    refund: `Lorem ipsum dolor sit amet`,
    refund_amount: `Rs. 20,000`,
  },
  {
    id: `2`,
    donation_id: `Lorem`,
    refund: `Lorem ipsum dolor sit amet`,
    refund_amount: `Rs. 20,000`,
  },
  {
    id: `3`,
    donation_id: `Lorem`,
    refund: `Lorem ipsum dolor sit amet`,
    refund_amount: `Rs. 20,000`,
  },
  {
    id: `4`,
    donation_id: `Lorem`,
    refund: `Lorem ipsum dolor sit amet`,
    refund_amount: `Rs. 20,000`,
  },
  {
    id: `5`,
    donation_id: `Lorem`,
    refund: `Lorem ipsum dolor sit amet`,
    refund_amount: `Rs. 20,000`,
  },
];
