import {
  Paper,
  Typography,
  makeStyles,
  Link,
  alpha,
  Hidden,
} from "@material-ui/core";
import React from "react";
import door from "../assets/png/icon/Door.png";
import { useHistory } from "react-router-dom";
import DashBoardSubMenu from "./DashBoardSubMenu";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderColor: theme.palette.primary.main,
    border: "1px solid ",
    boxShadow: "none",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      border: "none",
      backgroundColor: "transparent",
    },
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
}));

function DashBoardSidebar() {
  const history = useHistory();

  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        {navLinks.map((item, index) => {
          return <DashBoardSubMenu items={item} key={index} />;
          // <Link href={item.path}>
          //   <Typography variant="body1" key={item} className={classes.navlink}>
          //     {item.title}
          //   </Typography>
          // </Link>
        })}

        <Hidden only={["sm", "xs"]}>
          <Link href="">
            <div
              style={{ display: "flex", cursor: "pointer" }}
              onClick={() => {
                localStorage.clear();
                history.push("/home");
              }}
            >
              <Typography variant="h6" color="primary">
                LogOut
              </Typography>
              <img
                src={door}
                height="30px"
                alt="logout"
                style={{ marginLeft: "10px" }}
              />
            </div>
          </Link>
        </Hidden>
      </Paper>
    </>
  );
}

export default DashBoardSidebar;

const navLinks = [
  { title: `NGO Profile`, path: `/ngo/profile` },
  { title: `Membership and Cost Benefit Analysis`, path: `/ngo/member` },
  { title: `Rating`, path: `/ngo/rating` },
  { title: `Donation Mobilisation Details`, path: `/ngo/donation_details` },
  { title: `Reporting, Updates, News and Uploads`, path: `/ngo/report` },
  { title: `Payments and Refunds`, path: `/ngo/payment` },
  { title: `Notifications`, path: `/ngo/notifications` },
  { title: `Pending Activities`, path: `/ngo/pending` },
  { title: `Master Admin Section`, path: `/ngo/master_admin` },
  { title: `Queries, Feedback and Complaints`, path: `/ngo/feedback` },
];
