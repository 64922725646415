import {
  Paper,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  TextField,
  makeStyles,
  alpha,
  Button,
  Grid,
} from "@material-ui/core";
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { fundingData, geographicalScaleData, sizeData, specialInterestData } from "../../../../constant";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    padding: "30px",
    borderRadius: "8px",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderRadius: "30px",
  },

  fieldtext: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "10px",
    display: "flex",
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
  option: {
    fontSize: 13,
    "& > span": {
      marginRight: 10,
      fontSize: 12,
    },
  },
  root: {
    padding: `0 ${theme.spacing(1)}px`,
  },
}));

export default function SelectNgoFilter() {
  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <div>
              <Typography variant="h6" color="initial">
                By Geographical Scale
              </Typography>

              <Autocomplete
                id="country-select-demo"
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                options={geographicalScaleData}
                getOptionLabel={(option) => option.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="select"
                    style={{ height: "30px" }}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "district",
                    }}
                  />
                )}
              />
            </div>
            
          </Grid>
          <Grid item md={3}>
            <div>
              <Typography variant="h6" color="initial">
                By Special Interest
              </Typography>

              <Autocomplete
                id="country-select-demo"
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                options={specialInterestData}
                getOptionLabel={(option) => option.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="select"
                    style={{ height: "30px" }}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "district",
                    }}
                  />
                )}
              />
            </div>
            
          </Grid>
          <Grid item md={3}>
            <div>
              <Typography variant="h6" color="initial">
                By Funding
              </Typography>

              <Autocomplete
                id="country-select-demo"
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                options={fundingData}
                getOptionLabel={(option) => option.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="select"
                    style={{ height: "30px" }}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "district",
                    }}
                  />
                )}
              />
            </div>
            
          </Grid>
          <Grid item md={3}>
            <div>
              <Typography variant="h6" color="initial">
                By Size
              </Typography>

              <Autocomplete
                id="country-select-demo"
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                options={sizeData}
                getOptionLabel={(option) => option.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="select"
                    style={{ height: "30px" }}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "district",
                    }}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}