import {
  alpha,
  Avatar,
  Badge,
  IconButton,
  makeStyles,
  Paper,
  Button,
  Typography,
} from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import PersonIcon from "@material-ui/icons/Person";
import { useEffect, useRef, useState } from "react";
import { putRequest, postRequest } from "../../../../apis/baseApi";
import CustomButtonCircularProgress from "../../../CircularProgress";
import ImageCropperDialog from "../../../Common/ImageCropper";
import NgoReg from "../../../Form/Reg/Ngoreg";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "5px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  paper: {
    boxShadow: "none",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderRadius: "20px",
    padding: "25px",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  percentage: {
    color: theme.palette.primary.main,
  },
  person: {
    display: "flex",
    alignItems: "center",
  },
  fileInput: {
    display: "none",
  },
}));

export default function MemberGrid({ data, fromProfile = false }) {
  const classes = useStyles();
  const [profilePic, setProfilePic] = useState();
  const [open, setOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [fileName, setFileName] = useState();
  const [edit, setEdit] = useState(false);
  const fileInputRefNew = useRef();
  const alert = useAlert();

  const handleEdit = () => {
    setEdit(!edit);
  };
  console.log("editghuiuhgh", edit);
  const fileInputClickedNew = (e) => {
    fileInputRefNew.current.click();
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setProfilePic(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setFileName(e.target.files[0].name);
      handleClickOpen();
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const addDetailsImage = (prevImg) => {
    const fd = new FormData();
    if (prevImg) {
      fd.set("image", dataURLtoFile(prevImg, fileName));
    } else {
      alert.error("Image Error!!");
      return;
    }
    setSaveLoaderImage(true);
    postRequest(`ngo/add_ngo_images/${localStorage.getItem("ngo_slug")}/`, fd)
      .then((res) => {
        setSaveLoaderImage(false);
        alert.success(res?.data?.msg);
      })
      .catch((err) => {
        alert.error();
        setSaveLoaderImage(false);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [saveLoaderImage, setSaveLoaderImage] = useState(false);
  const [profilePicChanged, setProfilePicChanged] = useState(false);
  const [profilePercent, setProfilePercent] = useState(60);

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.person}>
          <ImageCropperDialog
            open={open}
            setOpen={setOpen}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            profilePic={profilePic}
            setProfilePic={setProfilePic}
            setPreviewImage={setPreviewImage}
            setProfilePicChanged={setProfilePicChanged}
            addDetailsImage={addDetailsImage}
          />
          <div>
            <input
              ref={fileInputRefNew}
              className={classes.fileInput}
              type="file"
              id="file"
              onChange={(e) => {
                onSelectFile(e);
              }}
              accept="image/*"
            />
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              style={{ marginBottom: 40 }}
              badgeContent={
                <IconButton
                  style={{
                    background: "#fff",
                    padding: 6,
                    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                    border: "solid 1px #f1f1f1",
                  }}
                  onClick={(e) => fileInputClickedNew(e)}
                >
                  {saveLoaderImage ? (
                    <CustomButtonCircularProgress isWhite={false} />
                  ) : (
                    <PhotoCamera style={{ fontSize: 20, color: "#C88FEF" }} />
                  )}
                </IconButton>
              }
            >
              {/* <Avatar alt="Travis Howard" style={{ height: "100px", width: "100px" }} src={saveLoaderImage ? circular_loader : previewImage} /> */}
              {previewImage ? (
                <Avatar
                  alt="Travis Howard"
                  style={{ height: "100px", width: "100px" }}
                  src={previewImage}
                />
              ) : data?.image ? (
                <Avatar
                  alt="Travis Howard"
                  style={{ height: "100px", width: "100px" }}
                  src={data?.image}
                />
              ) : (
                <Avatar alt="Remy Sharp" className={classes.large}>
                  <PersonIcon style={{ fontSize: "70px" }} />
                </Avatar>
              )}
            </Badge>
          </div>

          {/* <Avatar alt="Remy Sharp" className={classes.large}>
            <PersonIcon style={{ fontSize: "70px" }} />
          </Avatar> */}
          <div className="NgoProfileSection">
            <Typography variant="h6" style={{ textAlign: "center" }}>
              {localStorage.getItem("ngo_name")}
            </Typography>
            {edit ? (
              <Button
                className={classes.btn}
                color="primary"
                variant="contained"
                onClick={handleEdit}
              >
                <Typography
                  variant="h6"
                  style={{ color: "#fff", cursor: "pointer" }}
                >
                  Profile
                </Typography>
              </Button>
            ) : (
              <Button
                className={classes.btn}
                color="primary"
                variant="contained"
                onClick={handleEdit}
              >
                <Typography
                  variant="h6"
                  style={{ color: "#fff", cursor: "pointer" }}
                >
                  Edit
                </Typography>
              </Button>
            )}

            {/* <Typography variant="h6" style={{ color: "#94939F" }}>
              username: {localStorage.getItem("ngo_slug")}
            </Typography> */}
            <br />
            {/* <Typography variant="body2" style={{ color: "#94939F" }}>

              Your profile is <span className={classes.percentage}>{profilePercent}% </span>{" "}
              complete
            </Typography> */}
          </div>
        </div>
        <div>
          <Typography
            variant="h3"
            color="primary"
            style={{ marginBottom: "20px", marginTop: "20px" }}
          >
            {fromProfile ? "NGO Profile 1/3" : "NGO Registration 1/3"}
          </Typography>
          <NgoReg
            data={data}
            fromProfile={true}
            handleEdit={handleEdit}
            edit={edit}
          />
        </div>
      </Paper>
    </>
  );
}
