import React from "react";
import Layout from "../../components/Layout/Layout";
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  alpha,
  Paper,
} from "@material-ui/core";
import OurTeamSection from "../../components/Section/AboutSection/OurTeamSection";
import BoardDirectors from "../../components/Section/AboutSection/BoardDirectors";
import AdvisorSection from "../../components/Section/AboutSection/AdvisorSection";
import TeamSection from "../../components/Section/AboutSection/TeamSection";
import WhatWeDoSection from "../../components/Section/AboutSection/WhatWeDoSection";
import Problem from "../../components/Section/AboutSection/Problem";
import Need from "../../components/Section/AboutSection/Need";
import Solution from "../../components/Section/AboutSection/Solution";
import ValueProposition from "../../components/Section/AboutSection/ValueProposition";
import KeyDifferentiators from "../../components/Section/AboutSection/KeyDifferentiators";
import KeyDriverSection from "../../components/Section/AboutSection/KeyDriverSection";
import PublicLayout from "../../components/Layout/PublicLayout";

const useStyles = makeStyles((theme) => ({
  learn: {
    paddingLeft: "40px",
    paddingRight: "40px",
    color: "#fff",
    height: "45px",
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
  },
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
    marginBottom: "70px",
  },
}));

export default function WhatWeDo() {
  const classes = useStyles();
  return (
    <>
      <PublicLayout keyss={"about"}>
        <WhatWeDoSection />
        {/* <Problem /> */}
        <Need />
        {/* <Solution />
        <KeyDriverSection />
        <KeyDifferentiators />
        <ValueProposition /> */}
      </PublicLayout>
    </>
  );
}
