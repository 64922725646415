import React from "react";
import UserDashboardLayout from "../../components/Layout/UserDashboardLayout";
import FeedBackSection from "../../components/Section/DashBoard/FeedBackSection/FeedBackSection";

export default function FeedBack() {
  return (
    <>
      <UserDashboardLayout>
        <FeedBackSection />
      </UserDashboardLayout>
    </>
  );
}
