import { Typography } from "@material-ui/core";
import React from "react";

function CenterHeading({ title }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#C4C4C4",
            height: "1px",
            width: "20%",
            marginRight: "50px",
          }}
        />
        <Typography variant="h2" style={{ fontFamily: "Marcellus" }}>
          {title}
        </Typography>
        <div
          style={{
            backgroundColor: "#C4C4C4",
            height: "1px",
            width: "20%",
            marginLeft: "50px",
          }}
        />
      </div>
    </>
  );
}

export default CenterHeading;
