import {
  alpha,
  Avatar,
  makeStyles,
  Paper,
  Typography,
  Badge,
  IconButton,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import UserProfileForm from "../../../Form//UserProfileForm";
import { useEffect, useState } from "react";
import { useRef } from "react";
import ImageCropperDialog from "../../../Common/ImageCropper";
import { PhotoCamera } from "@material-ui/icons";
import CustomButtonCircularProgress from "../../../CircularProgress";
import { putRequest } from "../../../../apis/baseApi";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderRadius: "20px",
    padding: "25px",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  percentage: {
    color: theme.palette.primary.main,
  },
  person: {
    display: "flex",
    alignItems: "center",
  },
  fileInput: {
    display: "none",
  },
}));

export default function UserProfile({ profileData }) {
  const classes = useStyles();
  const [profilePercent, setProfilePercent] = useState(0);
  const completeProfileFields = [
    "address",
    "city",
    "country",
    "dob",
    "education",
    "gender",
    "image",
    "income_range",
    "maritial_status",
    "phone",
    "prefer_cat",
    "prefer_ngos",
    "prefer_subcategories",
  ];
  const alert = useAlert();

  useEffect(() => {
    if (profileData) {
      let count = 0;
      Object.keys(profileData)?.forEach((key) => {
        if (completeProfileFields.includes(key) && profileData[key]) {
          count += 1;
        }
      });
      if (count > 0) {
        setProfilePercent(
          Math.ceil(((count * 1.0) / completeProfileFields.length) * 100)
        );
      }
    }
  }, [profileData]);

  const [profilePic, setProfilePic] = useState();
  const [open, setOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [fileName, setFileName] = useState();
  const fileInputRefNew = useRef();

  const fileInputClickedNew = (e) => {
    fileInputRefNew.current.click();
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setProfilePic(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setFileName(e.target.files[0].name);
      handleClickOpen();
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const addDetailsImage = (prevImg) => {
    const fd = new FormData();
    if (prevImg) {
      fd.append("image", dataURLtoFile(prevImg, fileName));
    } else {
      alert.error("Image Error!!");
      return;
    }
    setSaveLoaderImage(true);
    putRequest(
      `authentication/user_profile/${localStorage.getItem("slug")}/`,
      fd
    )
      .then((res) => {
        if (res?.data?.data) {
          alert.success("Profile Updated successfully!!");
          localStorage.setItem("profile_image", res.data.data?.image);
        } else {
          // alert.error("Something went wrong!");
        }
        // setIsLoading(false);
        setSaveLoaderImage(false);
      })
      .catch((err) => {
        // alert.error("Something went wrong!");
        setSaveLoaderImage(false);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [saveLoaderImage, setSaveLoaderImage] = useState(false);
  const [profilePicChanged, setProfilePicChanged] = useState(false);

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.person}>
          <ImageCropperDialog
            open={open}
            setOpen={setOpen}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            profilePic={profilePic}
            setProfilePic={setProfilePic}
            setPreviewImage={setPreviewImage}
            setProfilePicChanged={setProfilePicChanged}
            addDetailsImage={addDetailsImage}
          />
          <div>
            <input
              ref={fileInputRefNew}
              className={classes.fileInput}
              type="file"
              id="file"
              onChange={(e) => {
                onSelectFile(e);
              }}
              accept="image/*"
            />
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              style={{ marginBottom: 40 }}
              badgeContent={
                <IconButton
                  style={{
                    background: "#fff",
                    padding: 6,
                    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                    border: "solid 1px #f1f1f1",
                  }}
                  onClick={(e) => fileInputClickedNew(e)}
                >
                  {saveLoaderImage ? (
                    <CustomButtonCircularProgress isWhite={false} />
                  ) : (
                    <PhotoCamera style={{ fontSize: 20, color: "#C88FEF" }} />
                  )}
                </IconButton>
              }
            >
              {/* <Avatar alt="Travis Howard" style={{ height: "100px", width: "100px" }} src={saveLoaderImage ? circular_loader : previewImage} /> */}
              {previewImage ? (
                <Avatar
                  alt="Travis Howard"
                  style={{ height: "100px", width: "100px" }}
                  src={previewImage}
                />
              ) : profileData?.image ? (
                <Avatar
                  alt="Travis Howard"
                  style={{ height: "100px", width: "100px" }}
                  src={profileData?.image}
                />
              ) : (
                <Avatar alt="Remy Sharp" className={classes.large}>
                  <PersonIcon style={{ fontSize: "70px" }} />
                </Avatar>
              )}
            </Badge>
          </div>
          <div style={{ marginLeft: "25px" }}>
            <Typography variant="h6">
              {profileData?.user?.first_name} {profileData?.user?.last_name}
            </Typography>
            <Typography variant="h6" style={{ color: "#94939F" }}>
              username: {localStorage.getItem("username")}
            </Typography>

            <br />
            {/* <Typography variant="body2" style={{ color: "#94939F" }}>
                Your profile is <span className={classes.percentage}>{profilePercent}%</span>{" "}
                complete. Complete Your Profile Now.
              </Typography> */}
          </div>
        </div>
        <div>
          <UserProfileForm profileData={profileData} />
        </div>
      </Paper>
    </>
  );
}
