import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "15px",
    boxShadow: "0px 4px 40px 0px #C88FEF33",
    borderRadius: "8px",
  },
}));

export default function Method(props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.card}>
        <Typography
          variant="body3"
          noWrap
          style={{
            fontFamily: "Marcellus",
            marginBottom: "30px",
            width:"100%",
            wordWrap: "break-word"
          }}
        >
          {props.name}
        </Typography>
        <Typography variant="body2" style={{ color: "#626262",textAlign:"justify" }}>
          {props.info}
        </Typography>
      </div>
    </>
  );
}
