import UserDashboardLayout from "../../components/Layout/UserDashboardLayout";
import DonationHistory from "../../components/Section/DashBoard/MyPhilanthrophyPortfolio/DonationHistory";
import Farmers from "../../components/Section/DashBoard/MyPhilanthrophyPortfolio/Farmers";
import PiaPortfolio from "../../components/Section/DashBoard/MyPhilanthrophyPortfolio/PiaPortfolio";
import SecondPortfolio from "../../components/Section/DashBoard/MyPhilanthrophyPortfolio/SecondPortfolio";
import StartPortfolio from "../../components/Section/DashBoard/MyPhilanthrophyPortfolio/StartPortfolio";
import Portfolio from "../../components/Section/UserDashboard/Portfolio";

export default function MyPortfolio() {
	return (
		<>
			<UserDashboardLayout>
				{/* <StartPortfolio /> */}
				<br />
				{/* <SecondPortfolio /> */}
				<br />
				{/* <PiaPortfolio /> */}
				<DonationHistory />
				<br />
				{/* <Farmers /> */}
				{/* <NgoProfileSection /> */}
				{/* <Portfolio /> */}
			</UserDashboardLayout>
		</>
	);
}
