import React from "react";
import NgoRegThirdSection from "../../../components/Section/DashBoard/Profile/NgoRegThirdSection";

export default function NgoRegFinal() {
  return (
    <>
      {/* <DashboardLayout> */}
        <NgoRegThirdSection />
      {/* </DashboardLayout> */}
    </>
  );
}
