import { Paper, Typography, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { useHistory } from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";
import MemberReg from "../../components/Form/Reg/MemberReg";
import { loggedInSelectorFn } from "../../redux/commonReducer/selector";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
    paddingBottom: "50px",
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
}));

export default function MemberRegistration() {
  const classes = useStyles();
  const history = useHistory();
  const loggedIn = useSelector(loggedInSelectorFn);

  // useEffect(() => {
  //   if(!loggedIn)
  //     history.push("/page-not-found");
  // }, [loggedIn])

  return (
    // <Layout>
    <Paper className={classes.paper}>
      <Typography variant="h3" color="primary" style={{ marginTop: "20px" }}>
        Member Registration
      </Typography>
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item md={4}></Grid>
        <Grid item md={4} xs={12} style={{ display: "grid" }}>
          <MemberReg />
        </Grid>
        <Grid item md={4}></Grid>
      </Grid>
    </Paper>
    // </Layout>
  );
}
