import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const UserTooltip = withStyles((theme) => ({
  tooltip: {
    borderRadius: "20px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#fff",
    // maxWidth: 320,
    // height: 320,
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    // alignItems: "center",
    // alignContent: "center",
    borderColor: theme.palette.primary.main,
    border: "1px solid",
  },
}))(Tooltip);

export default function TeamToolTip({ children, text }) {
  const classes = useStyles();
  return (
    <div>
      <UserTooltip
        placement="right"
        arrow
        title={
          <>
            <div className={classes.root}>
              <Typography style={{ fontSize: "12px" }}>
                {text}
              </Typography>
            </div>
          </>
        }
      >
        {children}
      </UserTooltip>
    </div>
  );
}
