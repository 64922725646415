import {
  alpha,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getRequest } from "../../apis/baseApi";
import ChooseYourMode from "../../components/Cards/Details/ChooseYourMode";
import NgoDetailsSection from "../../components/Section/NgoDetailsSection/NgoDetailsSection";
import DonationDialog from "../../components/Dialogs/DonationDialog";

function handleClick(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  learn: {
    paddingLeft: "40px",
    paddingRight: "40px",
    color: "#fff",
    height: "45px",
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
  },
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
    marginBottom: "70px",
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "280px",
      height: "auto",
    },
  },
  btn: {
    width: "100%",
    color: "#fff",
    boxShadow: "0px 10px 40px 0px #C88FEF33",
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
    height: "75px",
  },
  donatation: {
    boxShadow: "none",
    borderRadius: "20px",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    textAlign: "left",
    padding: "15px",
    borderColor: theme.palette.primary.main,
    border: "2px solid",
  },
}));

function NgoDetails() {
  const classes = useStyles();
  const initialState = {
    name: "",
    description: "",
    fundingType: "",
    location: "",
    startDate: "",
    endDate: "",
  };
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const slug = params.get("ngo_slug");
  const ngoId = params.get("ngo_id");
  const history = useHistory();
  const [ngoDetail, setNgoDetail] = useState({});
  const [showDonate, setShowDonate] = useState(false);
  const [open, setOpen] = useState(false);
  const [ongoingProject, setOngoingProject] = useState([initialState]);
  const [affiliations, setAffiliations] = useState([]);
  const [awards, setAwards] = useState([]);
  const [slugs, setSlugs] = useState({});

  useEffect(() => {
    if (slug) {
      getRequest(`ngo/ngo_details/${slug}/`)
        .then((res) => {
          if (res?.data?.data) {
            setNgoDetail(res?.data?.data);
          } else {
            // alert.error("Something went wrong!!");
          }
        })
        .catch((err) => {
          // alert.error("Something went wrong!!");
        });
    }
  }, [slug]);
  useEffect(() => {
    if (slug) {
      getRequest(`ngo/on_going_project/${slug}/`).then((res) => {
        if (res?.data?.results) {
          console.log("kjhgvfghj", res);
          const tempPro = [...ongoingProject];
          res?.data?.results?.forEach((item) => {
            tempPro?.unshift({
              name: item?.name,
              description: item?.description,
              fundingType: item?.funding_type,
              location: item?.location,
              startDate: item?.start_date,
              endDate: item?.end_date,
              isAdded: true,
            });
          });
          setOngoingProject(tempPro);
        }
      });
    }
  }, [slug]);
  useEffect(() => {
    if (slug) {
      getRequest(`ngo/membership_list/${slug}/`)?.then((res) => {
        console.log("hfghuh", res);
        setAffiliations(res?.data?.results);
      });
    }
  }, []);
  useEffect(() => {
    if (slug) {
      getRequest(`ngo/recorgnition_and_awards_list/${slug}/`)?.then((res) => {
        console.log("yghjikji", res);
        setAwards(res?.data?.results);
      });
    }
  }, []);

  return (
    <>
      {/* <Layout> */}
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item md={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="/"
              onClick={() => {
                history.push("/home");
              }}
            >
              Home
            </Link>
            <Link
              color="inherit"
              href="/our-ngo-partner"
              onClick={() => {
                history.push("/our-ngo-partner");
              }}
            >
              Our NGO Partners
            </Link>
            <Typography color="textPrimary">{ngoDetail?.ngo_name}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item md={9}>
          <NgoDetailsSection
            ngoDetail={ngoDetail}
            ongoingProject={ongoingProject}
            affiliations={affiliations}
            awards={awards}
          />
        </Grid>

        <Grid item md={3}>
          {/* {(localStorage.getItem("user_token") ||
            localStorage.getItem("user_type") === "DONOR") && ( */}
          <Button
            className={classes.btn}
            color="primary"
            variant="contained"
            onClick={() => {
              if (localStorage.getItem("user_token")) {
                // history.push("/donate-now#donate");
                setOpen(true);
              } else {
                setShowDonate(true);
              }
            }}
          >
            <Typography style={{ fontSize: "22px", color: "#fff" }}>
              Donate To This NGO
            </Typography>
          </Button>
          {/* )} */}
          <br />
          <br />
          {localStorage.getItem("user_token") &&
            localStorage.getItem("user_type") === "DONOR" &&
            showDonate && (
              <Paper className={classes.donatation}>
                <ChooseYourMode ngoId={ngoId} />
              </Paper>
            )}

          <br />
          <DonationDialog
            ngoId={ngoId}
            open={open}
            setOpen={setOpen}
            ngoName={ngoDetail?.ngo_name}
          />
        </Grid>
      </Grid>
      {/* </Layout> */}
    </>
  );
}

export default NgoDetails;

const donator = [
  { title: `Mr. Ankur Mishra` },
  { title: `Mr. Nirmal Thakur` },
  { title: `Mrs. Mrinali Thakral` },
  { title: `Mr. Kishore Kumar` },
  { title: `Mrs. Mrinali Thakral` },
  { title: `Mr. Kishore Kumar` },
];
