import { Grid, makeStyles, Paper, Typography, Button } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { getRequest } from "../../../../apis/baseApi";
import Table from "../../../Common/Table/Table";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    width: "100%",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  download_main_grid: {
    display: "flex",
    alignItems: "center",
  },
  download: {
    boxShadow: "none",
    borderRadius: "8px",
    color: "#fff",
    height: "40px",
    display: "flex",
  },
  download_main_grid: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function ImpactUpdates() {
  const classes = useStyles();
  const [data, setData] = useState();
  const alert = useAlert();

  useEffect(() => {
    getRequest(
      `dashboard/ngo/dashboard/get_create_impact_report_list/${localStorage.getItem(
        "ngo_slug"
      )}/`
    )
      .then((res) => {
        if (res?.data?.data) {
          let tempData = [];
          let count = 0;
          res?.data?.data?.forEach((item) => {
            tempData.push({
              id: count + 1,
              sNo: count + 1,
              date: item?.created_at
                ? moment(item?.created_at).format("YYYY-MM-DD")
                : "",
              utilisationReport: item?.utilization_report?.utilization_report
                ? item?.utilization_report?.utilization_report
                : null,
              actualAmount: item?.actual_ammount_recieved,
              achivement: item?.achivement,
              evidenceAchievement: item?.evidence_of_achivement_doc,
            });
            count = count + 1;
          });
          setData(tempData);
        } else {
          // alert.error("Something went wrong!!")
        }
      })
      .catch((err) => {
        // alert.error("Something went wrong!!");
      });
  }, []);

  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          style={{ padding: "20px", paddingTop: "0px" }}
        >
          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns} list={data} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export const columns = [
  {
    field: "sNo",
    headerName: "S. No",
    type: "text",
    // width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.sNo}
      </span>
    ),
  },
  {
    field: "date",
    type: "text",
    headerName: "Date",
    // width: 100,
    // renderHeader: (params) => (
    //   <strong style={{ color: "#5a6268", marginLeft: "120px" }}>
    //     Date
    //   </strong>
    // ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.date}
      </span>
    ),
  },
  {
    field: "utilisationReport",
    type: "text",
    sortable: false,
    headerName: "NGO Utilisation Report",
    width: 200,
    // renderHeader: (params) => (
    //   <strong style={{ color: "#5a6268", marginLeft: "150px" }}>NGO Utilisation Report</strong>
    // ),
    renderCell: (params) => (
      <Button
        color="primary"
        variant="text"
        style={{
          boxShadow: "none",
          borderRadius: "8px",
          //   color: "#fff",
          height: "40px",
          display: "flex",
        }}
        onClick={() => {
          window.open(params?.row?.utilisationReport, "_blank");
        }}
        disabled={params?.row?.utilisationReport ? false : true}
      >
        {params?.row?.utilisationReport ? "Click Here" : "N/A"}
      </Button>
      //   <span
      //     style={{
      //       textAlign: "center",
      //       display: "flex",
      //       margin: "auto",
      //       fontSize: "14px",
      //       color: "#94939F",
      //     }}
      //   >
      //     {params.row.utilisationReport}
      //   </span>
    ),
  },
  {
    field: "actualAmount",
    type: "text",
    sortable: false,
    headerName: "Actual Amount Received",
    width: 250,
    // renderHeader: (params) => (
    //   <strong style={{ color: "#5a6268", marginLeft: "120px" }}>Actual Amount Received</strong>
    // ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.actualAmount}</Typography>
      </span>
    ),
  },
  {
    field: "achivement",
    type: "text",
    sortable: false,
    headerName: "Acheivements",
    width: 200,
    // renderHeader: (params) => (
    //   <strong style={{ color: "#5a6268", marginLeft: "120px" }}>Acheivements</strong>
    // ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">{params.row.achivement}</Typography>
      </span>
    ),
  },
  {
    field: "evidenceAchievement",
    type: "text",
    sortable: false,
    headerName: "Evidence of Acheivements",
    width: 250,
    // renderHeader: (params) => (
    //   <strong style={{ color: "#5a6268", marginLeft: "120px" }}>Evidence of Acheivements</strong>
    // ),
    renderCell: (params) => (
      <Button
        color="primary"
        variant="text"
        style={{
          boxShadow: "none",
          borderRadius: "8px",
          //   color: "#fff",
          height: "40px",
          display: "flex",
        }}
        onClick={() => {
          window.open(params?.row?.evidenceAchievement, "_blank");
        }}
        disabled={params?.row?.evidenceAchievement ? false : true}
      >
        {params?.row?.evidenceAchievement ? "Download" : "N/A"}
      </Button>
      //   <span
      //     style={{
      //       textAlign: "center",
      //       display: "flex",
      //       margin: "auto",
      //       fontSize: "14px",
      //       color: "#94939F",
      //     }}
      //   >
      //     <Typography color="primary">{params.row.evidenceAchievement}</Typography>
      //   </span>
    ),
  },
];

export const row = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];
