import { makeStyles, alpha } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  lay: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderRadius: "20px",
    width: "100%",
    height: "80vh",
  },

  root: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Table({ columns, list }) {
  const classes = useStyles();
  const [pageSize, setPageSize] = React.useState(5);

  return (
    <div className={classes.lay}>
      <DataGrid
        style={{
          borderRadius: "20px",
          border: "none",
          textAlign: "center",
        }}
        rows={list?.length ? list : []}
        columns={columns}
        // pageSize={pageSize}
        // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // rowsPerPageOptions={[5, 10, 15, 20]}
        // pagination
        {...list}
        // disableSelectionOnClick
        // rowCount={list?.length}
        componentsProps={{
          pagination: {
            style: {
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            },
          },
        }}
      />
    </div>
  );
}
