import {
  Button, Divider, Grid, makeStyles, Paper, Typography
} from "@material-ui/core";
import { useState } from "react";
import ReportCard from "../../../Cards/DashBoadCards/ReportCard/ReportCard";
import AnnouncementUpdates from "../ReportData/AnnouncementUpdates";
import ImpactUpdates from "../ReportData/ImpactUpdates";
import PeriodicReportig from "../ReportData/PeriodicReporting";
import UtilisationUpdates from "../ReportData/UtilisationUpdates";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  download_main_grid: {
    display: "flex",
    alignItems: "center",
  },
  download: {
    boxShadow: "none",
    borderRadius: "8px",
    color: "#fff",
    height: "40px",
    display: "flex",
  },
}));

export default function ReportGrid() {
  const classes = useStyles();
  const [currentCard, setCurrentCard] = useState("utilisation_updates");

  function getDataComponent(){
    switch(currentCard){
      case "periodic_reporting":
        return <PeriodicReportig />;
      case "announcement_updates":
        return <AnnouncementUpdates />;
      case "impact_updates":
        return <ImpactUpdates />;
      case "utilisation_updates":
        return <UtilisationUpdates />;
      default:
        return <> </>;
    }

  }

  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          style={{ padding: "20px", paddingTop: "0px" }}
        >
          {data.map((item) => (
            <Grid item md={4}>
              <div style={{ height: "130px", display: "grid" }}>
                <ReportCard title={item.title} onClick={(event) => {
                  setCurrentCard(item?.type);
                }}/>
              </div>
            </Grid>
          ))}

          <Grid md={12} style={{marginBottom: "20px"}}>
            <Divider />
          </Grid>

                {
                  getDataComponent()
                }

          {/* <Grid
            item
            md={12}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Divider className={classes.divider} />
            <Typography
              variant="body2"
              style={{
                marginTop: "20px",
                color: "#9896A3",
                fontStyle: "italic",
                textAlign: "center",
              }}
            >
              Self Reported
            </Typography>
          </Grid>
          {secondData.map((item) => (
            <Grid item md={3}>
              <div style={{ height: "66px", display: "grid" }}>
                <ReportCard title={item.title} />
              </div>
            </Grid>
          ))} */}
          {/* <Grid
            item
            md={12}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Divider className={classes.divider} />
            <Typography
              variant="body2"
              style={{
                marginTop: "20px",
                color: "#9896A3",
                fontStyle: "italic",
                textAlign: "center",
              }}
            >
              Ikigai Portolio/Third Party Assessed
            </Typography>
          </Grid>
          {secondData.map((item) => (
            <Grid item md={3}>
              <div style={{ height: "66px", display: "grid" }}>
                <ReportCard title={item.title} />
              </div>
            </Grid>
          ))}
          <Grid item md={12} style={{ display: "grid" }}>
            <Divider />
          </Grid>

          <Grid
            item
            md={12}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Grid container spacing={2}>
              <Grid item md={6} style={{ display: "grid" }}>
                <Typography variant="body2" style={{ textAlign: "center" }}>
                  Description
                </Typography>
              </Grid>

              <Grid item md={6} className={classes.download_main_grid}>
                <Grid container spacing={2}>
                  <Grid item md={4} style={{ display: "grid" }}>
                    <Typography variant="body2" style={{ textAlign: "center" }}>
                      From Date
                    </Typography>
                  </Grid>
                  <Grid item md={4} style={{ display: "grid" }}>
                    <Typography variant="body2" style={{ textAlign: "center" }}>
                      End Date
                    </Typography>
                  </Grid>
                  <Grid item md={4} />
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}

          {/* <Grid item md={12}>
            <ReportDownloadGrid />
          </Grid>
          <Grid item md={12}>
            <ReportDownloadGrid />
          </Grid>
          <Grid item md={12}>
            <ReportDownloadGrid />
          </Grid>
          <Grid item md={12}>
            <ReportDownloadGrid />
          </Grid> */}

          {/* <Grid item md={12} style={{ display: "grid" }}>
            <Divider />
          </Grid>

          <Grid
            item
            md={12}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Grid container spacing={2}>
              <Grid item md={3} style={{ display: "grid" }}>
                <Typography variant="body2" style={{ textAlign: "center" }}>
                  Headline
                </Typography>
              </Grid>
              <Grid item md={6} style={{ display: "grid" }}>
                <Typography variant="body2" style={{ textAlign: "center" }}>
                  Description
                </Typography>
              </Grid>

              <Grid item md={3} className={classes.download_main_grid}>
                <Grid container spacing={2}>
                  <Grid item md={6} style={{ display: "grid" }}>
                    <Typography variant="body2" style={{ textAlign: "center" }}>
                      Document
                    </Typography>
                  </Grid>
                  <Grid item md={6} style={{ display: "grid" }}>
                    <Typography variant="body2" style={{ textAlign: "center" }}>
                      Approval Status
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}

          {/* <Grid item md={12}>
            <ReportUpoadGrid />
          </Grid>

          <Grid item md={12}>
            <ReportUploadedFieldGrid />
          </Grid>
          <Grid item md={12}>
            <ReportUploadedFieldGrid />
          </Grid>
          <Grid item md={12}>
            <ReportUploadedFieldGrid />
          </Grid> */}

          {/* <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns} list={row} />
          </Grid>
          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns2} list={row2} />
          </Grid>
          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns3} list={row3} />
          </Grid>
          <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns4} list={row4} />
          </Grid> */}
        </Grid>
      </Paper>
    </>
  );
}

const data = [
  { title: `Utilisation Updates`, type: "utilisation_updates" },
  { title: `Impact Updates`, type: "impact_updates" },
  { title: `Periodic Reporting`, type: "periodic_reporting" },
  {
    title: `News, Announcements, 
  Events and other Updates`,
  type: "announcement_updates",
  },
  { title: `Other Uploads`, type: "other_uploads" },
];

const secondData = [
  { title: `Impact Metrics`, path: `/` },
  { title: `Acheivement`, path: `/about-us` },
  { title: `Evidence of Acheivement`, path: `/list` },
  {
    title: `Monitoring/Impact Assessment Report`,
    path: `/`,
  },
];

export const columns = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 350,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "120px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },
  {
    field: "date",
    type: "text",
    sortable: false,
    width: 350,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "150px" }}>Date</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.date}
      </span>
    ),
  },
  {
    field: "amount",
    type: "text",
    sortable: false,
    width: 330,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "120px" }}>Amount</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount}</Typography>
      </span>
    ),
  },
];

export const row = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns2 = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "100px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },
  {
    field: "date",
    type: "text",
    sortable: false,
    width: 250,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "90px" }}>Date</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.date}
      </span>
    ),
  },
  {
    field: "amount_received",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>
        Amount Received
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount_received}</Typography>
      </span>
    ),
  },
  {
    field: "amount_utilised",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "50px" }}>
        Amount Utilised
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount_utilised}</Typography>
      </span>
    ),
  },
];

export const row2 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns3 = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 300,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "100px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },
  {
    field: "date",
    type: "text",
    sortable: false,
    width: 250,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "90px" }}>Date</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.date}
      </span>
    ),
  },

  {
    field: "amount_utilised",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "50px" }}>
        Amount Utilised
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount_utilised}</Typography>
      </span>
    ),
  },
  {
    field: "percentage",
    type: "text",
    sortable: false,
    width: 230,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>
        Percentage Utilised
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary"> {params.row.percentage} % </Typography>
      </span>
    ),
  },
];

export const row3 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];

export const columns4 = [
  {
    field: "id",
    headerName: "S. No",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "donation_id",
    type: "text",
    width: 530,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "210px" }}>
        Donation ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.donation_id}
      </span>
    ),
  },
  {
    field: "certificates",
    type: "text",
    sortable: false,
    width: 500,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "170px" }}>
        Utilisation Certificates
      </strong>
    ),
    renderCell: (params) => (
      <>
        <div
          style={{
            display: "flex",
            margin: "auto",
          }}
        >
          <Button color="primary" variant="text">
            Click Here
          </Button>
        </div>
      </>
    ),
  },
];

export const row4 = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];
