import {
  alpha,
  Button,
  Chip,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRequest } from "../../../../apis/baseApi";
import BarChart from "../../../Common/Graph/BarChart";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    //backgroundColor: alpha(theme.palette.primary.main, 0.1),
    padding: "25px",
    borderColor: theme.palette.primary.main,
    padding: "7px",
    borderRadius: "15px",
    marginTop: "20px",
  },
  btn: {
    borderRadius: "30px",
    boxShadow: "none",
    padding: "none",
    width: "100%",
  },
  mainbtn: {
    border: "2px solid #C88FEF",
    borderRadius: "30px",
    height: "30px",
    display: "flex",
    padding: "2px",
    //width: "100%",
    alignItems: "center",
  },
  firstgrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    flexDirection: "column",
  },
  lastgrid: {
    backgroundColor: "#F9F4FD",
    //borderRadius: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function SecondPortfolio() {
  const classes = useStyles();
  // const [data, setData] = useState([]);
  const slug = localStorage.getItem("slug");
  const [portfolioData, setPortfolioData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getRequest(`dashboard/donor/dashboard/donation_history_Page/${slug}/`)
      .then((res) => {
        setPortfolioData(res);
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid
            item
            md={3}
            className={classes.firstgrid}
            style={{ paddingBottom: "50px" }}
          >
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              NGO
            </Typography>
          </Grid>
          <Grid item md={3} style={{ paddingBottom: "50px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" style={{ marginRight: "10px" }}>
                {/* NGO */}
              </Typography>
              <div className={classes.mainbtn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  onClick={() => {
                    history.push("/donate-now");
                  }}
                >
                  <Typography
                    style={{
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Start Donation
                  </Typography>
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item md={3} style={{ paddingBottom: "50px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" style={{ marginRight: "10px" }}>
                {/* Swades Foundation */}
              </Typography>
              <div className={classes.mainbtn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  onClick={() => {
                    history.push("/donate-now");
                  }}
                >
                  <Typography
                    noWrap
                    style={{
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Start Donation
                  </Typography>
                </Button>
              </div>
            </div>
          </Grid>
          <Grid
            item
            md={3}
            className={classes.lastgrid}
            style={{ paddingBottom: "50px" }}
          >
            {" "}
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              NGO
            </Typography>
          </Grid>
        </Grid>

        {data.map((item) => (
          <Grid container spacing={2}>
            <Grid
              item
              md={3}
              className={classes.firstgrid}
              style={{ paddingBottom: "50px" }}
            >
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                {item.title}
              </Typography>
            </Grid>
            <Grid item md={3} style={{ paddingBottom: "50px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2">{item.second}</Typography>
              </div>
            </Grid>
            <Grid item md={3} style={{ paddingBottom: "50px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2">{item.third}</Typography>
              </div>
            </Grid>
            <Grid
              item
              md={3}
              className={classes.lastgrid}
              style={{ paddingBottom: "50px" }}
            >
              <Typography
                variant="body2"
                color="primary"
                style={{ fontWeight: "bold" }}
              >
                {item.number}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Paper>
    </>
  );
}

const data = [
  {
    title: "Cause",
    second: "No Donation Made",
    third: "No Donation Made",
    number: "0",
  },
  {
    title: "Sub-Cause",
    second: "No Donation Made",
    third: "No Donation Made",
    number: "0",
  },
  {
    title: "Donation Mode",
    second: "NA",
    third: "NA",
    number: "-",
  },
  // {
  //   title: "Cause",
  //   second: "Water and Sanitation",
  //   third: "Agricultre and Farmers",
  //   number: "0",
  // },
  // {
  //   title: "Sub-Cause",
  //   second: "Providing affordable drinking water",
  //   third: "Increase production and income of farmers",
  //   number: "0",
  // },
  // {
  //   title: "Donation Mode",
  //   second: "Lumpsum",
  //   third: "Lumpsum",
  //   number: "-",
  // },
  // {
  //   title: "Total Amount Donated",
  //   second: "Click Here to see Total Amount Donated ",
  //   third: "Click Here to see Total Amount Donated ",
  //   number: "Rs. 52,400 ",
  // },
  // {
  //   title: "NGO Reported Utilised Amount",
  //   second: "Rs. 15,000",
  //   third: "Rs. 15,000 ",
  //   number: "Rs. 32,000 ",
  // },
  // {
  //   title: "Verified Utilised Amount",
  //   second: "Click Here to see Utilization Reports",
  //   third: "Click Here to see Utilization Reports ",
  //   number: "Rs. 32,000 ",
  // },
];
