import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    //  flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    //borderColor: theme.palette.primary.main,
    //border: "1px solid",
    borderRadius: "8px",
    boxShadow: "0px 4px 40px 0px #2B2D3F14",

    //height: "100px",
  },
}));

export default function Differentiators(props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.card}>
        <img src={props.img} height="50px" />
        <Typography variant="body2" style={{ marginLeft: "20px" }}>
          {props.name}
        </Typography>
      </div>
    </>
  );
}
