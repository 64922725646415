import {
  alpha,
  Grid,
  Hidden,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getRequest } from "../../apis/baseApi";
import PartnerList from "../../components/Cards/Partner/PartnerList";
import Filter from "../../components/Common/InputField/Filter/Filter";
import Sort from "../../components/Common/InputField/Filter/Sort";
import Pagination from "@material-ui/lab/Pagination";
import MobileListing from "../../components/Common/Extra/MobileListing";

const useStyles = makeStyles((theme) => ({
  learn: {
    paddingLeft: "40px",
    paddingRight: "40px",
    color: "#fff",
    height: "45px",
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
  },
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
    marginBottom: "70px",
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "280px",
      height: "auto",
    },
  },
  banner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  page: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  become: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "8px",
    marginTop: "30px",
    paddingBottom: "8px",
    color: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 10px 40px 0px #C88FEF73",
    marginLeft: "20px",
    minWidth: "175px",
  },
}));

function NgoListing() {
  const classes = useStyles();
  const history = useHistory();
  const initialState = {
    byGeographicalScale: "",
    bySpecialInterest: "",
    byFunding: "",
    byThematicFocus: "",
    bySize: "",
    byAge: "",
    tier: "",
    categories: "",
    subcategories: "",
    sort: "",
    page: 1,
  };
  const [appliedFilter, setAppliedFilter] = useState(initialState);

  const [applyFilter, setApplyFilter] = useState(false);

  const [ngoPartner, setNgoPartner] = useState([]);
  const [count, setCount] = useState(0);
  useEffect(() => {
    getRequest(createUrl())
      .then((res) => {
        if (res?.data?.results) {
          setNgoPartner(res?.data?.results);
          setCount(res?.data?.count);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (appliedFilter) {
      getRequest(createUrl())
        .then((res) => {
          // if(res?.data?.results){
          setNgoPartner(res?.data?.results);
          setCount(res?.data?.count);
          setApplyFilter(false);
          // }
        })
        .catch((err) => {});
    }
  }, [appliedFilter]);

  const createUrl = () => {
    let url = "ngo/ngo_list_filter/?";
    Object.keys(appliedFilter).forEach(function (key) {
      if (appliedFilter[key]) url = url + `${key}=${appliedFilter[key]}&`;
    });
    return url;
  };

  const handleChange = (event, value) => {
    setAppliedFilter({ ...appliedFilter, page: value });
  };

  return (
    <>
      {/* <Layout> */}
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Hidden only={["sm", "xs"]}>
          <Grid item md={3}>
            <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <Filter
                appliedFilter={appliedFilter}
                setAppliedFilter={setAppliedFilter}
                setApplyFilter={setApplyFilter}
              />
            </div>
          </Grid>
        </Hidden>

        <Grid item xs={12} md={9}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body2"
                onClick={() => {
                  history.push("home");
                }}
                style={{ cursor: "pointer" }}
              >
                Home
              </Typography>
              <Typography variant="body2">/ Our NGO Partners</Typography>
            </div>
            <Sort
              appliedFilter={appliedFilter}
              setAppliedFilter={setAppliedFilter}
            />
          </div>
          <Grid
            container
            spacing={2}
            style={{ marginTop: "20px", justifyContent: "center" }}
          >
            {ngoPartner && ngoPartner?.length > 0 ? (
              ngoPartner?.map((item) => {
                return (
                  <Grid item md={4}>
                    <PartnerList item={item} />
                  </Grid>
                );
              })
            ) : (
              <div>
                <Typography>
                  We are sorry we currently do not have any partners or
                  campaigns matching the filters/criteria that you searched for.
                  We are in the process of adding many more partners to our
                  platform. You should be able to find a partner/campaign as per
                  your requirement very soon. In the meanwhile, may we suggest
                  that you try finding some of our other wonderful partners by
                  slightly modifying your search filters/criteria and start your
                  journey of investing in impact right away. We appreciate your
                  patience and understanding.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.become}
                  onClick={() => setAppliedFilter(initialState)}
                >
                  Clear Filter
                </Button>
              </div>
            )}
            <div className={classes.page}>
              {count > 0 && (
                <Pagination
                  color="primary"
                  count={Math.ceil(count / 9)}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              )}
            </div>
          </Grid>
        </Grid>

        <Hidden only={["md", "lg", "xl"]}>
          <MobileListing
            appliedFilter={appliedFilter}
            setAppliedFilter={setAppliedFilter}
            setApplyFilter={setApplyFilter}
          />
        </Hidden>
      </Grid>

      {/* </Layout> */}
    </>
  );
}

export default NgoListing;
