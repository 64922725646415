import {
  alpha, makeStyles
} from "@material-ui/core";
import DashboardLayout from "../../components/Layout/DashBoardLayout";
import Layout from "../../components/Layout/Layout";
import Alignment from "../../components/Section/AboutSection/Alignment";
import Causes from "../../components/Section/AboutSection/Causes";
import CausesWeSupport from "../../components/Section/AboutSection/CausesWeSupport";
import Introduction from "../../components/Section/AboutSection/Introduction";
import PublicLayout from "../../components/Layout/PublicLayout";

const useStyles = makeStyles((theme) => ({
  learn: {
    paddingLeft: "40px",
    paddingRight: "40px",
    color: "#fff",
    height: "45px",
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
  },
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
    marginBottom: "70px",
  },
}));

export default function Philosophy() {
  const classes = useStyles();
  return (
    <>
        <PublicLayout keyss={"about"}>
          <>
        <CausesWeSupport />
        {/* <Introduction /> */}
        {/* <Alignment /> */}
        <Causes />
        </>
        </PublicLayout>
    </>
  );
}
