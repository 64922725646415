import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link, useHistory } from "react-router-dom";
import facebook from "../assets/png/facebook.jpeg";
import insta from "../assets/png/instagram.jpeg";
import twi from "../assets/png/twitter_1.jpeg";
import Linkedin from "../assets/png/whatsapp.jpeg";
import Logo from "../assets/svg/logo.svg";
import india from "../assets/svg/logo_right.svg";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#F6F6F6",
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  footerdiv: {
    backgroundColor: "#F6F6F6",
  },
  logos: {
    display: "flex",
  },
  navlink: {
    margin: "20px",
    cursor: "pointer",
  },
  socialmedia: {
    marginRight: "20px",
    cursor: "pointer",
  },
}));

// const footerLinks = [
//   { title: `Home`, path: `/students` },
//   { title: `All NGOs`, path: `/full-time-jobs` },
//   { title: `Blog`, path: `/work-from-home-jobs` },
// ];

export default function Footer() {
  const classes = useStyles();

  const history = useHistory();

  return (
    <>
      <div className={classes.footerdiv}>
        <Container maxWidth="xl" component="footer" className={classes.footer}>
          <Grid container spacing={4} justifyContent="space-evenly">
            <Grid item md={3} sm={6} xs={12}>
              <div className={classes.logos}>
                <Link href="/">
                  <img src={india} height="150px" width="150px" alt="logo" />
                  <img src={Logo} height="150px" width="150px" alt="logo" />
                </Link>
              </div>
            </Grid>

            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "flex-start",
              }}
            >
              {/* {navLinks.map((item) => (
                <Typography
                  variant="body1"
                  key={item}
                  className={classes.navlink}
                >
                  {item.title}
                </Typography>
              ))} */}
              <div>
                <div
                  onClick={() => {
                    history.push("/about-us");
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="body1" className={classes.navlink}>
                    About us
                  </Typography>
                </div>
                <div
                  onClick={() => {
                    history.push("/privacy-policy");
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="body1" className={classes.navlink}>
                    Privacy Policy
                  </Typography>
                </div>
                <div
                  onClick={() => {
                    history.push("/terms-and-conditions");
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="body1" className={classes.navlink}>
                    Terms & Conditions
                  </Typography>
                </div>
                <div
                  onClick={() => {
                    history.push("/legal-disclaimer");
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="body1" className={classes.navlink}>
                    Legal Disclaimer
                  </Typography>
                </div>
                <div
                  onClick={() => {
                    history.push("/anti-money-laundering-aml-policy");
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="body1" className={classes.navlink}>
                    Anti-Money Laundering AML Policy
                  </Typography>
                </div>
              </div>
              <div>
                <div
                  onClick={() => {
                    history.push("/blog");
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="body1" className={classes.navlink}>
                    Blog
                  </Typography>
                </div>
                <div
                  onClick={() => {
                    history.push("/contact");
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="body1" className={classes.navlink}>
                    Contact Us
                  </Typography>
                </div>
                <div
                  onClick={() => {
                    history.push("/sitemap");
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="body1" className={classes.navlink}>
                    SiteMap
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid
              item
              md={3}
              sm={6}
              xs={8}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" className={classes.navlink}>
                Social Handles
              </Typography>
              <div>
                <img
                  src={facebook}
                  height="30px"
                  alt="facebook"
                  className={classes.socialmedia}
                  onClick={() => {
                    window.open('https://www.facebook.com/ikigaiportfoliopvtltd', '_blank')
                  }}
                  
                />

                <img
                  src={insta}
                  height="30px"
                  alt="insta"
                  className={classes.socialmedia}
                  onClick={() => {
                    window.open('https://www.instagram.com/ikigaiportfolio/', '_blank')
                  }}
                />
                <img
                  src={twi}
                  height="30px"
                  alt="twi"
                  className={classes.socialmedia}
                  onClick={() => {
                    window.open('https://twitter.com/IkigaiPortfolio', '_blank')
                  }}
                />
                <img
                  src={Linkedin}
                  height="30px"
                  alt="linkedin"
                  className={classes.socialmedia}
                  onClick={() => {
                    window.open('https://www.linkedin.com/company/ikigai-portfolio/about/', '_blank')
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
