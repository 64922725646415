import React from "react";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";

export default function MuiCustomTheme({ children }) {
  const Theme2 = createTheme({
    palette: {
      type: "light",
      common: {
        black: "#000000",
        white: "#ffffff",
      },
      primary: {
        main: "#C88FEF",
      },
      secondary: {
        main: "#f50057",
      },
    },
    typography: {
      fontFamily: "Raleway",
      h1: {
        fontSize: "3em",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h2: {
        fontSize: "34px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h3: {
        fontSize: "24px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h4: {
        fontSize: "20px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h5: {
        fontSize: "18px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h6: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body1: {
        color: "#000000",
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body2: {
        color: "#000000",
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body3: {
        fontSize: "12px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      subtitle1: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      subtitle2: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      caption: {
        fontSize: "12px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      overline: {
        fontSize: "10px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      button: {
        fontFamily: "Raleway",
        fontSize: "12px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      buttonmob: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
    },
    overrides: {
      MuiButton: {
        label: {
          textTransform: "capitalize",
          fontSize: "16px",
        },
        contained: {
          "&$disabled": {
            color: "#FFF",
            backgroundColor: "#EE7D66",
          },
        },
        // text: {
        //   color: "#EE7D66",
        // },
      },
      MuiOutlinedInput: {
        root: {
          // height: "40px",
          borderRadius: 8,
          height: 48,

          //border: "1px solid #C88FEF",
          // boxShadow: "0px 0px 3px 0px rgb(206 212 218)",
        },
        focused: {
          border: "1px solid #f50057",
        },
        notchedOutline: {
          border: "1px solid #f50057",
        },

        multiline: {
          height: "auto",
        },
      },

      MuiFab: {
        root: {
          backgroundColor: "#EE7D66",
          color: "#FFF",
        },
      },
      MuiTab: {
        wrapper: {
          color: "#6c757d",
        },
        textColorInherit: {
          color: "#FFF",
        },
      },
    },
  });

  return <ThemeProvider theme={Theme2}>{children}</ThemeProvider>;
}
