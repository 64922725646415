import {
  Paper,
  makeStyles,
  Grid,
  Divider,
  Typography,
  Button,
  Avatar,
  alpha,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import PersonIcon from "@material-ui/icons/Person";
import NgoRegTwo from "../../../Form/Reg/NgoRegTwo/NgoRegTwo";
import NgoRegSecondCard from "../../../Form/Reg/NgoRegTwo/NgoRegSecondCard";
import NgoRegThirdCard from "../../../Form/Reg/NgoRegTwo/NgoRegThirdCard";
import Reg from "../../../Form/Reg/NgoRegThree/Reg";
import RegSecondCard from "../../../Form/Reg/NgoRegThree/RegSecondCard";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderRadius: "20px",
    padding: "25px",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  percentage: {
    color: theme.palette.primary.main,
  },
  person: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function NgoRegThirdSection({ fromProfile = false }) {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const handleEdit = () => {
    setEdit(!edit);
  };
  return (
    <>
      <Paper className={classes.paper}>
        <div>
          <Typography
            variant="h3"
            color="primary"
            style={{ marginBottom: "20px", marginTop: "20px" }}
          >
            {fromProfile ? "NGO Profile 3/3" : "NGO Registration Form Part 3/3"}
          </Typography>
          <Typography
            style={{
              color: "#A6A6A6",
              fontStyle: "italic",
              textAlign: "left",
              marginTop: "10px",
            }}
            variant="body2"
          >
            Other Fields
          </Typography>
          <Reg
            fromProfile={fromProfile}
            edit={edit}
            handleEdit={handleEdit}
            setEdit={setEdit}
          />
        </div>
      </Paper>
      <br />
      <Paper className={classes.paper}>
        <RegSecondCard
          fromProfile={fromProfile}
          edit={edit}
          handleEdit={handleEdit}
          setEdit={setEdit}
        />
      </Paper>
    </>
  );
}
