import { NGO_DOCS } from "./type";

export const ngoDocDetails = (payload) => {
  return async (dispatch) => {
      dispatch({
        type: NGO_DOCS,
        payload: payload,
      });
    }
  };
  