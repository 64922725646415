import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const NgoToolTip = withStyles((theme) => ({
  tooltip: {
    borderRadius: "20px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#fff",
    // maxWidth: 320,
    // height: 320,
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    // alignItems: "center",
    // alignContent: "center",
    borderColor: theme.palette.primary.main,
    border: "1px solid",
  },
}))(Tooltip);

export default function NgoToolTipComponent({ children }) {
  const classes = useStyles();
  return (
    <div>
      <NgoToolTip
        placement="right"
        arrow
        title={
          <>
            <div className={classes.root}>
              <Typography style={{ fontSize: "12px" }}>
                Please write a brief description about your NGO in this section.
                What you write here will appear as the description of your NGO
                on the Ikigai Portfolio website, specifically on your NGO page,
                so we request you to please fill this section carefully. You can
                type the description in the text box here or you may also copy
                and paste content in this box.
              </Typography>
            </div>
          </>
        }
      >
        {children}
      </NgoToolTip>
    </div>
  );
}
