import React, { useEffect, useState } from "react";
import UserDashboardLayout from "../../components/Layout/UserDashboardLayout";
import UserProfile from "../../components/Section/DashBoard/Profile/UserProfile";
import { getRequest } from "../../apis/baseApi";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

export default function MyProfile() {
  const [profileData, setProfileData] = useState({});
  const slug = localStorage.getItem("slug");
  const alert = useAlert();
  const history = useHistory();

  useEffect(() => {
    if (slug) {
      getRequest(`authentication/user_profile/${slug}/`)
        .then((res) => {
          if (res?.data?.data) {
            setProfileData(res.data.data);
          } else {
            // alert.error("Something went wrong");
          }
        })
        .catch((err) => {
          // alert.error("Something went wrong");
        });
    } else {
      alert.error("Something went wrong. Please login again!!");
      localStorage.clear();
      history.push("/home");
    }
  }, []);

  return (
    <>
      <UserDashboardLayout>
        <UserProfile profileData={profileData} />
      </UserDashboardLayout>
    </>
  );
}
