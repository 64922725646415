import {
  alpha,
  Button,
  Chip,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { subCategoriesListSelectorFn } from "../../../../redux/commonReducer/selector";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    // borderRadius: "20px",
    padding: "25px",
    borderColor: theme.palette.primary.main,
    padding: "7px",
    border: "1px solid ",
    borderRadius: "0px",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    color: "#fff",
    marginTop: "50px",
  },
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
  cat: {
    // borderColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    borderRight: "1px solid #C88FEF ",
    borderBottom: "1px solid #C88FEF",
  },
  catfield: {
    // borderColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid #C88FEF ",
    borderBottom: "1px solid #C88FEF",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  sub: {
    display: "flex",
    justifyContent: "center",
    // borderLeft: "1px solid #C88FEF ",
    borderBottom: "1px solid #C88FEF",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  approve_main: {
    borderColor: theme.palette.primary.main,
    border: "2px solid",
    borderRadius: "30px",
    height: "30px",
    display: "flex",
    padding: "2px",
    alignItems: "center",
    width: "70%%",
  },
  approve: {
    // width: "100%",
    color: "#fff",
    borderRadius: "30px",
    boxShadow: "none",
    padding: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      boxShadow: "none",
      color: theme.palette.primary.main,
    },
  },
  chiproot: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    backgroundColor: "transparent",
    //backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  chiptext: {
    maxWidth: "500px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "200px",
    },
  },
}));

export default function CategorySection() {
  const classes = useStyles();
  // const categoriesList = useSelector(categoriesListSelectorFn);
  const subCategoriesList = useSelector(subCategoriesListSelectorFn);
  const [causeData, setCauseData] = useState({});
  const history = useHistory();

  useEffect(() => {
    // if(subCategoriesList){
    //   let temp = {};
    //   let tempId = [];
    //   subCategoriesList?.forEach(item => {
    //     if(!tempId.includes(item?.category?.id)){
    //       tempId.push(item?.category?.id);
    //       temp[item?.category?.category_name] = [item]
    //     }else{
    //       temp[item?.category?.category_name].push(item)
    //     }
    //   })
    //   setCauseData(temp);
    // }
    setCauseData(subCategoriesList);
  }, [subCategoriesList]);

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Hidden only={["xs", "sm"]}>
            <Grid item md={6} className={classes.cat}>
              <Typography variant="h5" style={{ padding: "15px" }}>
                Causes
              </Typography>
            </Grid>
          </Hidden>
          <Hidden only={["xs", "sm"]}>
            <Grid item md={6} className={classes.sub}>
              <Typography variant="h5" style={{ padding: "15px" }}>
                Subcauses{" "}
              </Typography>
            </Grid>
          </Hidden>
          {causeData &&
            Object.keys(causeData).map((key) => {
              return (
                <>
                  <Grid item md={6} xs={12} className={classes.catfield}>
                    <Hidden only={["md", "lg", "xl"]}>
                      <Typography variant="h5" style={{ padding: "15px" }}>
                        Causes
                      </Typography>
                    </Hidden>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="contained"
                          className={classes.approve}
                          onClick={() => {
                            let url = "ngo-cause?causeName=" + key;
                            if (causeData[key].length > 0)
                              url =
                                url +
                                "&causeId=" +
                                causeData[key][0]?.category?.id;
                            history.push(url);
                          }}
                        >
                          <Typography
                            noWrap
                            style={{
                              fontSize: "18px",
                            }}
                            className={classes.chiptext}
                            color="inherit"
                          >
                            {key}
                          </Typography>
                        </Button>
                      </div>

                      <div style={{ marginTop: "30px", display: "flex" }}>
                        <div>
                          <div
                            style={{
                              border: "2px solid #C88FEF",
                              borderRadius: "50%",
                              height: "100px",
                              width: "100px",
                              display: "flex",
                              padding: "2px",
                              alignItems: "center",
                              marginRight: "20px",
                              justifyContent: "center",
                            }}
                          >
                            0
                          </div>
                          <div
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "14px",
                              paddingRight: "15px",
                              paddingTop: "10px",
                            }}
                          >
                            NGOs
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              border: "2px solid #C88FEF",
                              borderRadius: "50%",
                              height: "100px",
                              width: "100px",
                              display: "flex",
                              padding: "2px",
                              marginRight: "20px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            Rs. 0
                          </div>
                          <div
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "14px",
                              paddingRight: "15px",
                              paddingTop: "10px",
                            }}
                          >
                            Funds Mobilised
                          </div>
                        </div>

                        <div>
                          <div
                            style={{
                              border: "2px solid #C88FEF",
                              borderRadius: "50%",
                              height: "100px",
                              width: "100px",
                              display: "flex",
                              padding: "2px",
                              marginRight: "20px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            0
                          </div>
                          <div
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "14px",
                              paddingRight: "15px",
                              paddingTop: "10px",
                            }}
                          >
                            Efficiency Score
                          </div>
                        </div>

                        <div>
                          <div
                            style={{
                              border: "2px solid #C88FEF",
                              borderRadius: "50%",
                              height: "100px",
                              width: "100px",
                              display: "flex",
                              padding: "2px",
                              marginRight: "20px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            0
                          </div>
                          <div
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "14px",
                              paddingTop: "10px",
                            }}
                          >
                            Effectiveness Score
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item md={6} xs={12} className={classes.sub}>
                    <Hidden only={["md", "lg", "xl"]}>
                      <Typography variant="h5" style={{ padding: "15px" }}>
                        Subcauses{" "}
                      </Typography>
                    </Hidden>
                    <Paper component="ul" className={classes.chiproot}>
                      {causeData &&
                        causeData[key].map((item) => {
                          return (
                            <li key={item.id}>
                              <Chip
                                //style={{ maxWidth: "500px" }}
                                label={
                                  <Typography
                                    noWrap
                                    className={classes.chiptext}
                                    style={{
                                      fontSize: "12px",
                                      backgroundColor:
                                        " rgb(247 246 247 / 50%)",
                                    }}
                                    color="#2b2d3f"
                                  >
                                    {item?.sub_category_name} - 40{"NGOs"}
                                  </Typography>
                                }
                                className={classes.chip}
                              />
                            </li>
                          );
                        })}
                    </Paper>
                  </Grid>
                </>
              );
            })}
        </Grid>
      </Paper>
    </>
  );
}
