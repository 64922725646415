import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useAlert } from "react-alert";
import moment from "moment";
import CustomButtonCircularProgress from "../CircularProgress";
import { postRequest } from "../../apis/baseApi";


const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 18px 0px #2B2D3F14",
    padding: 40,
    borderRadius: 22,
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  btn: {
    color: "#fff",
    paddingLeft: "30px",
    paddingRight: "30px",
    boxShadow: "0px 10px 40px 0px #C88FEF73",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
    minWidth: "150px"
  },

  fieldtext: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "10px",
    display: "flex",
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
}));

function ContactForm() {
  const classes = useStyles();

  const demo = [{ title: "Option 1" }, { title: "option 2" }];

  const alert = useAlert();

  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    if (selectedDate) {
      var a = moment(selectedDate);
      var b = moment(new Date());
      var d = b.diff(a, "years");
      var minor = false;
      if (d < 18) {
        minor = true;
      }
      setValues({
        ...values,
        isMinor: minor,
        dateOfBirth: moment(selectedDate).format("YYYY-MM-DD"),
      });
    }
  }, [selectedDate]);

  const initialState = {
    fullName: "",
    phone: "",
    email: "",
    purpose: "",
    msg: "",
  }

  const [values, setValues] = React.useState(initialState);

  const [errorValues, setErrorValues] = React.useState(initialState);

  const [isError, setIsError] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: "" });
  };

  const validateForm = () => {
    let error = false;
    let fullnameErrow = "";

    let phoneError = "";
    let emailError = "";
    let purposeError = "";
    let msgError = "";

    if (!values?.fullName) {
      // setErrorValues({...errorValues, firstName: "Please Enter First Name"});
      fullnameErrow = "*Please Enter Full Name";
      setIsError(true);
      error = true;
    }

    if (!values?.phone) {
      // setErrorValues({...errorValues, phone: "Please Enter Phone No."});
      phoneError = "*Please Enter Phone No.";
      setIsError(true);
      error = true;
    }
    if (!values?.email) {
      // setErrorValues({...errorValues, email: "Please Enter Email Id"});
      emailError = "*Please Enter Email Id";
      setIsError(true);
      error = true;
    }
    if (!values?.purpose) {
      // setErrorValues({...errorValues, password: "Please Enter Password"});
      purposeError = "*Please Enter Subject";
      setIsError(true);
      error = true;
    }
    if (!values?.msg) {
      // setErrorValues({...errorValues, password: "Please Enter Password"});
      msgError = "*Please Enter Message";
      setIsError(true);
      error = true;
    }

    setErrorValues({
      fullName: fullnameErrow,
      phone: phoneError,
      email: emailError,
      purpose: purposeError,
      msg: msgError,
    });
    return error ? false : true;
  };

  const sendContactForm = () => {
    if (validateForm()) {
      setIsLoading(true);
      const body = {
        name: values?.fullName,
        email: values?.email,
        mobile_number: values?.phone,
        subject: values?.purpose,
        message: values?.msg
      }
      postRequest("contactus/contact_us_form/", body).then(res => {
        if(res?.status === 200){
          alert.success("Query submitted successfully. We will get back to you shortly.");
          setValues(initialState);
          setErrorValues(initialState);
        }
        setIsLoading(false);
        setValues()
      }).catch(err => {
        alert.error(err?.message);
      })
    }
  };

  return (
    <>
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={8} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
              Name
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={handleChange("fullName")}
              value={values?.fullName ? values?.fullName : ""}
              error={isError}
              helperText={isError ? errorValues?.fullName : ""}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography className={classes.fieldtext}>
              Mobile Number
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <FormControl style={{ display: "flex" }}>
              <PhoneInput
                style={{ width: "100%" }}
                country={"in"}
                inputProps={{
                  name: "phone",
                }}
                onChange={(event) => {
                  setValues({ ...values, phone: event });
                }}
                value={values?.phone ? values?.phone : ""}
              />
              {isError && errorValues?.phone && (
                <FormHelperText error id="accountId-error">
                  {errorValues?.phone}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item md={8} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
              Email Address
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={handleChange("email")}
              value={values?.email ? values?.email : ""}
              error={isError}
              helperText={isError ? errorValues?.email : ""}
            />
          </Grid>
          <Grid item md={8} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
              Subject
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            {/* <Autocomplete
              id="country-select-demo"
              style={{ backgroundColor: "#fff" }}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              options={demo}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "State",
                  }}
                />
              )}
            /> */}
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              onChange={handleChange("purpose")}
              value={values?.purpose ? values?.purpose : ""}
              error={isError}
              helperText={isError ? errorValues?.purpose : ""}
            />
          </Grid>
          <Grid item md={8} xs={12} style={{ display: "grid" }}>
            <Typography className={classes.reqfieldtext}>
              Message
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              multiline
              rows={5}
              value={values?.msg ? values?.msg : ""}
              onChange={handleChange("msg")}
              error={isError}
              helperText={isError ? errorValues?.msg : ""}
            />
          </Grid>
        </Grid>
        <div>
          <Button className={classes.btn} color="primary" variant="contained" onClick={() => {
            sendContactForm();
          }}>
            {isLoading  ? <CustomButtonCircularProgress /> : "Send Now"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
