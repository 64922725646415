import { useEffect, useState } from "react";

import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { getRequest } from "../../../apis/baseApi";
import Heading from "../../Common/Heading/Heading";

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: "10px",
    boxShadow: "-22px 25px 0px 0px",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)",
    borderRadius: "10px",
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(12),
      //paddingRight: 0,
      borderRadius: "10px",
    },
  },
  overflow: {
    boxShadow: "0px 0px 30px 0px rgb(0 0 0 / 15%)",
    padding: "103px 9px 5px 49px",
    borderRadius: "10px",
    background: "#fff",
    margin: "-305px 0px 0px -22px",
    fontSize: "14px",
    height: "190px",
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "20px",
    marginBottom: "30px",
    borderRadius: 8,
    height: 46,
  },
  img: {
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "280px",
      height: "auto",
    },
  },
}));

export default function WhatYouCanDo() {
  const classes = useStyles();

  const [youCanDo, setYouCanDo] = useState({});

  useEffect(() => {
    getRequest("home_page/get_what_you_can_do/")
      .then((res) => {
        if (res?.data?.status) {
          setYouCanDo(res?.data?.data);
        }
      })
      .catch((err) => {});
  }, []);
  return (
    <>
      <Heading title={youCanDo?.title} />
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item md={6}>
          <div>
            {/* <Typography variant="h4" color="primary">
            {youCanDo?.headline}
            </Typography> */}
            <Typography variant="body2" style={{ marginTop: "30px" }}>
              {/* {youCanDo?.content} */}
              When was the last time you knew exactly how your donated money was
              spent? Or what impact it created? Was your donation a
              strategically thought out initiative with a larger picture in
              mind, or was it an emotional response to a tragic image or video
              or plea? When you donate through Ikigai Portfolio, you get:
              <ul>
                <li>Choice and flexibility of social cause and NGO</li>
                <li>Rigorously rated NGOs in the partner NGO pool</li>
                <li>Strategic, impact focussed approach to philanthropy</li>
                <li>
                  Transparency in outcomes, deliverables and impact from donated
                  money
                </li>
                <li>
                  Regular monitoring and reporting on usage and impact of your
                  donated amount
                </li>
                <li>
                  Strict guidelines to NGO partners on how they may use your
                  donation money
                </li>
                <li>Tax exemption under under section 80G</li>
                <li>Connect with beneficiaries of your donation</li>
                <li>
                  Social media kit including certificates, beneficiary
                  testimonials and images related to your donation
                </li>
                <li>
                  Invites to online and physical events on philanthropy and
                  individual giving and speaker opportunities
                </li>
                <li>
                  Opportunities to connect directly with not for profits and
                  beneficiaries
                </li>
                {/* <li>Tax Exemptions on your Donation</li><li>
Know How Your Contribution Made a Difference</li><li>
Share and Spread the Goodness</li><li>
Speaking Opportunities and Recognition</li><li>
Your Customised Philanthropy Dashboard </li><li>
Choice and Flexibility to Donate to a Cause You are Passionate About</li> */}
              </ul>
            </Typography>

            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={() => {
                window.open(youCanDo?.url, "_blank");
              }}
            >
              {youCanDo?.button_text}
            </Button>
          </div>
        </Grid>
        <Grid
          item
          md={6}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <img src={youCanDo?.image} height="300px" className={classes.img} />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
