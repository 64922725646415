import { Grid, makeStyles, Typography } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import facebook from "../../components/assets/png/icon/facebook.png";
import insta from "../../components/assets/png/icon/instagram.png";
import Linkedin from "../../components/assets/png/icon/linkedin.png";
import twi from "../../components/assets/png/icon/twittersecond.png";
import map from "../../components/assets/png/map.png";
import location from "../../components/assets/png/location.jpeg";
import BarChart from "../../components/Common/Graph/BarChart";
import LineChart from "../../components/Common/Graph/LineChart";
import PieChart from "../../components/Common/Graph/PieChart";
import ContactForm from "../../components/Form/ContactForm";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
    borderRadius: "20px",
    [theme.breakpoints.only("sm")]: {
      width: "auto",
      height: "200px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "auto",
      height: "200px",
    },
  },
  socialmedia: {
    marginRight: "20px",
    cursor: "pointer",
  },
}));

function ContactUs() {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} style={{ marginBottom: "10px", width: "100%" }}>
          <Typography
            variant="h2"
            color="primary"
            style={{
              fontFamily: "Marcellus",
              textAlign: "center",
            }}
          >
            Contact Us
          </Typography>
        </Grid>
        <Grid item md={6}>
          <ContactForm />
        </Grid>
        <Grid item md={6}>
          <img src={location} height="400px" className={classes.img} />
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item md={6}>
              <div style={{ display: "flex" }}>
                <a
                  href="tel:+91 98751 57894"
                  style={{ textDecoration: "none", display: "flex" }}
                >
                  <PhoneIcon color="primary" />
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    style={{ marginLeft: "10px", fontWeight: "normal" }}
                  >
                    +91 81304 37139
                  </Typography>
                </a>
              </div>
            </Grid>
            <Grid item md={6}>
              <div style={{ display: "flex" }}>
                <a
                  href="https://www.google.com/maps/place/E-44%2F3,+Pocket+D,+Okhla+Phase+II,+Okhla+Industrial+Estate,+New+Delhi,+Delhi+110020/@28.535955,77.2704722,19.01z/data=!4m13!1m7!3m6!1s0x390ce158b9506a3b:0x8103192eff881730!2sE-44%2F3,+Pocket+D,+Okhla+Phase+II,+Okhla+Industrial+Estate,+New+Delhi,+Delhi+110020!3b1!8m2!3d28.535501!4d77.2702879!3m4!1s0x390ce158b9506a3b:0x8103192eff881730!8m2!3d28.535501!4d77.2702879"
                  style={{ textDecoration: "none", display: "flex" }}
                  target="_blank"
                >
                  <LocationOnIcon color="primary" />
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    style={{ marginLeft: "10px", fontWeight: "normal" }}
                  >
                    Ikigai Portfolio Private Limited, E-44/3, Ground Floor, Okhla Industrial Area Phase II, New Delhi, India - 110020
                  </Typography>
                </a>
              </div>
            </Grid>
            <Grid item md={6}>
              <div >
                <img
                  src={facebook}
                  height="20px"
                  alt="facebook"
                  className={classes.socialmedia}
                  onClick={() => {
                    window.open('https://www.facebook.com/ikigaiportfoliopvtltd', '_blank')
                  }}

                />

                <img
                  src={insta}
                  height="20px"
                  alt="insta"
                  className={classes.socialmedia}
                  onClick={() => {
                    window.open('https://www.instagram.com/ikigaiportfolio/', '_blank')
                  }}
                />
                <img
                  src={twi}
                  height="20px"
                  alt="twi"
                  className={classes.socialmedia}
                  onClick={() => {
                    window.open('https://twitter.com/IkigaiPortfolio', '_blank')
                  }}
                />
                <img
                  src={Linkedin}
                  height="20px"
                  alt="linkedin"
                  className={classes.socialmedia}
                  onClick={() => {
                    window.open('https://www.linkedin.com/company/ikigai-portfolio/about/', '_blank')
                  }}
                />
              </div>
            </Grid>
            <Grid item md={6}>
              <div style={{ display: "flex" }}>
                <a
                  href="mailto:support@ikigaiportfolio.com"
                  style={{ textDecoration: "none", display: "flex" }}
                >
                  <MailIcon color="primary" />
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    style={{ marginLeft: "10px", fontWeight: "normal" }}
                  >
                    support@ikigaiportfolio.com
                  </Typography>
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <br />
      <BarChart />
      <br />
      <PieChart />
      <br />
      <LineChart /> */}
      {/* <CategorySection /> */}
    </>
  );
}
export default ContactUs;
