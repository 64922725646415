import {
  alpha,
  Button,
  Chip,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { subCategoriesListSelectorFn } from "../../../../redux/commonReducer/selector";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    // borderRadius: "20px",
    padding: "25px",
    borderColor: theme.palette.primary.main,
    padding: "7px",
    border: "1px solid ",
    borderRadius: "0px",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    color: "#fff",
    marginTop: "50px",
  },
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
  cat: {
    // borderColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    borderRight: "1px solid #C88FEF ",
    borderBottom: "1px solid #C88FEF",
  },
  catfield: {
    // borderColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid #C88FEF ",
    borderBottom: "1px solid #C88FEF",
  },
  sub: {
    display: "flex",
    justifyContent: "center",
    // borderLeft: "1px solid #C88FEF ",
    borderBottom: "1px solid #C88FEF",
  },
  approve_main: {
    borderColor: theme.palette.primary.main,
    border: "2px solid",
    borderRadius: "30px",
    height: "30px",
    display: "flex",
    padding: "2px",
    alignItems: "center",
    // width: "100%",
  },
  approve: {
    // width: "100%",
    color: "#fff",
    borderRadius: "30px",
    boxShadow: "none",
    padding: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      boxShadow: "none",
      color: theme.palette.primary.main,
    },
  },
  chiproot: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    backgroundColor: "transparent",
    //backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));

export default function SubCategorySection({ subCategoriesList }) {
  const classes = useStyles();
  // const categoriesList = useSelector(categoriesListSelectorFn);
  // const subCategoriesList = useSelector(subCategoriesListSelectorFn);
  // const [causeData, setCauseData] = useState({})

  // useEffect(() => {
  //   // if(subCategoriesList){
  //   //   let temp = {};
  //   //   let tempId = [];
  //   //   subCategoriesList?.forEach(item => {
  //   //     if(!tempId.includes(item?.category?.id)){
  //   //       tempId.push(item?.category?.id);
  //   //       temp[item?.category?.category_name] = [item]
  //   //     }else{
  //   //       temp[item?.category?.category_name].push(item)
  //   //     }
  //   //   })
  //   //   setCauseData(temp);
  //   // }
  //   setCauseData(subCategoriesList);
  // }, [ subCategoriesList])

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={4} className={classes.cat}>
            <Typography variant="h5" style={{ padding: "15px" }}>
              SubCauses
            </Typography>
          </Grid>
          <Grid item md={3} xs={3} className={classes.cat}>
            <Typography variant="h5" style={{ padding: "15px" }}>
              NGOs Registered
            </Typography>
          </Grid>
          <Grid item md={3} xs={3} className={classes.cat}>
            <Typography variant="h5" style={{ padding: "15px" }}>
            Efficiency Score
            </Typography>
          </Grid>
          <Grid item md={2} xs={2} className={classes.cat}>
            <Typography variant="h5" style={{ padding: "15px" }}>
            Effectiveness Score
            </Typography>
          </Grid>

          {/* <Grid item md={6} xs={6} className={classes.sub}>
            <Typography variant="h5" style={{ padding: "15px" }}>
              Explanation{" "}
            </Typography>
          </Grid> */}

          {subCategoriesList &&
            subCategoriesList.map((item) => {
              return (
                <>
                  <Grid item md={4} xs={4} className={classes.catfield}>
                    <div>
                      {/* <Button variant="contained" className={classes.approve}
                  onClick={() => {
                    
                  }}
                  > */}
                      <Typography
                        style={{
                          fontSize: "12px",
                        }}
                        color="inherit"
                      >
                        {item.sub_category_name}
                      </Typography>
                      {/* </Button> */}
                    </div>
                  </Grid>
                  <Grid item md={3} xs={3} className={classes.catfield}>
                    <div>
                      {/* <Button variant="contained" className={classes.approve}
                  onClick={() => {
                    
                  }}
                  > */}
                      <Typography
                        style={{
                          fontSize: "12px",
                        }}
                        color="inherit"
                      >
                        0
                      </Typography>
                      {/* </Button> */}
                    </div>
                  </Grid>
                  <Grid item md={3} xs={3} className={classes.catfield}>
                    <div>
                      {/* <Button variant="contained" className={classes.approve}
                  onClick={() => {
                    
                  }}
                  > */}
                      <Typography
                        style={{
                          fontSize: "12px",
                        }}
                        color="inherit"
                      >
                        0
                      </Typography>
                      {/* </Button> */}
                    </div>
                  </Grid>
                  <Grid item md={2} xs={2} className={classes.catfield}>
                    <div>
                      {/* <Button variant="contained" className={classes.approve}
                  onClick={() => {
                    
                  }}
                  > */}
                      <Typography
                        style={{
                          fontSize: "12px",
                        }}
                        color="inherit"
                      >
                        0
                      </Typography>
                      {/* </Button> */}
                    </div>
                  </Grid>
                  

                  {/* <Grid item md={6} xs={6} className={classes.sub}>
                    <Paper component="ul" className={classes.chiproot}>
                      <Typography
                        style={{
                          fontSize: "12px",
                        }}
                        color="inherit"
                      >
                        {item.sub_category_description}
                      </Typography>
                    </Paper>
                  </Grid> */}
                </>
              );
            })}
        </Grid>
      </Paper>
    </>
  );
}
