import {
  LOGGEDIN_USER,
  USER_DETAILS,
  CITY_STATE_LIST,
  CATEGORIES_LIST,
  SUB_CATEGORIES_LIST,
  NGO_DOCS
} from "./type";

export const common = (state = {}, action) => {
  switch (action.type) {
    case LOGGEDIN_USER:
      return { ...state, loggedIn: action.payload };
    case USER_DETAILS:
    return {...state, userDetails: action.payload};
    case CITY_STATE_LIST:
    return {...state, cityState: action.payload};
    case CATEGORIES_LIST:
    return {...state, categoriesList: action.payload};
    case SUB_CATEGORIES_LIST:
    return {...state, subCategoriesList: action.payload};
    case NGO_DOCS:
    return {...state, ngoDocs: action.payload};
    default:
      return state;
  }
};
