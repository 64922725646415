import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import ReportDownloadGrid from "../../../Common/InputField/Basic/ReportDownloadGrid";
import { getRequest } from "../../../../apis/baseApi";
import { useAlert } from "react-alert";
import ReportUploadedFieldGrid from "../../../Common/InputField/Basic/ReportUploadedFieldGrid";
import ReportUpoadGrid from "../../../Common/InputField/Basic/ReportUploadGrid";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    width: "100%",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  download_main_grid: {
    display: "flex",
    alignItems: "center",
  },
  download: {
    boxShadow: "none",
    borderRadius: "8px",
    color: "#fff",
    height: "40px",
    display: "flex",
  },
  download_main_grid: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function AnnouncementUpdates() {
  const classes = useStyles();
  const [data, setData] = useState();
  const alert = useAlert();

  useEffect(() => {
    getRequest(
      `dashboard/ngo/dashboard/get_create_news_list/${localStorage.getItem(
        "ngo_slug"
      )}/`
    )
      .then((res) => {
        if (res?.data?.data) {
          setData(res?.data?.data);
        } else {
          // alert.error("Something went wrong!!")
        }
      })
      .catch((err) => {
        // alert.error("Something went wrong!!");
      });
  }, []);

  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          style={{ padding: "20px", paddingTop: "0px" }}
        >
          <Grid
            item
            md={12}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Grid container spacing={2}>
              <Grid item md={3} style={{ display: "grid" }}>
                <Typography variant="body2" style={{ textAlign: "center" }}>
                  Headline
                </Typography>
              </Grid>
              <Grid item md={6} style={{ display: "grid" }}>
                <Typography variant="body2" style={{ textAlign: "center" }}>
                  Description
                </Typography>
              </Grid>

              <Grid item md={3} className={classes.download_main_grid}>
                <Grid container spacing={2}>
                  <Grid item md={6} style={{ display: "grid" }}>
                    <Typography variant="body2" style={{ textAlign: "center" }}>
                      Document
                    </Typography>
                  </Grid>
                  <Grid item md={6} style={{ display: "grid" }}>
                    <Typography variant="body2" style={{ textAlign: "center" }}>
                      Approval Status
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <ReportUpoadGrid />
          </Grid>

          {data?.map((item) => {
            return (
              <Grid item md={12}>
                <ReportUploadedFieldGrid item={item} />
              </Grid>
            );
          })}

          {/* <Grid item md={12}>
            <ReportUploadedFieldGrid />
        </Grid>
          <Grid item md={12}>
            <ReportUploadedFieldGrid />
          </Grid> */}

          {/* {data?.map(item => {
            return (

            <Grid item md={12}>
              <ReportDownloadGrid item={item}/>
            </Grid>
            )
        })} */}
        </Grid>
      </Paper>
    </>
  );
}
