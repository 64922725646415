import {
  alpha,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
  Link,
} from "@material-ui/core";
import Partner from "../../Cards/Partner/Partner";
import Heading from "../../Common/Heading/Heading";
import React, { useState, useEffect } from "react";
import { getRequest } from "../../../apis/baseApi";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    padding: 20,
    borderRadius: 22,
    marginBottom: "70px",
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "20px",
    marginBottom: "30px",
    borderRadius: 8,
    height: 46,
  },
  img: {
    borderRadius: "10px",
  },
  iconbtn: {
    padding: "20px",
    borderColor: theme.palette.primary.main,
    border: "1px solid",
  },
  gridmobile: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

export default function OurNGOPartners() {
  const classes = useStyles();
  const [ngoPartner, setNgoPartner] = useState([]);
  useEffect(() => {
    getRequest("home_page/get_our_ngos/")
      .then((res) => {
        if (res?.data?.status) {
          setNgoPartner(res?.data?.data);
        }
      })
      .catch((err) => {});
  }, []);
  //   getRequest("home_page/get_hero_headlines/").then(res => {
  //     if(res?.data?.status){
  //       setHeroHeadline({
  //         title: res?.data?.data?.title,
  //         content: res?.data?.data?.content,
  //         headline: res?.data?.data?.headline,
  //         location: res?.data?.data?.location,
  //         image: res?.data?.data?.image,
  //       })
  //     }
  // }).catch(err => {
  //
  // })
  const data = {
    data: [
      {
        id: 1,
        ngo_data: null,
        image:
          "https://ikigaportfolio.s3.amazonaws.com/HomePageData/ngopartners_image/NGo%202/highlight.png",
        location: "1534, Port Blair (ANDAMAN & NICOBAR ISLANDS)",
        subcategory: "Sports",
        is_active: true,
        modified_at: "2022-07-01T10:35:22.944594Z",
        created_at: "2022-07-01T10:35:22.944643Z",
        ngo: 3,
      },
      {
        id: 2,
        ngo_data: null,
        image:
          "https://ikigaportfolio.s3.amazonaws.com/HomePageData/ngopartners_image/NGo%202/kisan.png",
        location: "1534, Port Blair (ANDAMAN & NICOBAR ISLANDS)",
        subcategory: "Agriculture",
        is_active: true,
        modified_at: "2022-07-01T10:37:15.327348Z",
        created_at: "2022-07-01T10:37:15.327395Z",
        ngo: 4,
      },
      {
        id: 3,
        ngo_data: null,
        image:
          "https://ikigaportfolio.s3.amazonaws.com/HomePageData/ngopartners_image/NGo%202/2.png",
        location: "1534, Port Blair (ANDAMAN & NICOBAR ISLANDS)",
        subcategory: "Other",
        is_active: true,
        modified_at: "2022-07-01T10:40:41.919167Z",
        created_at: "2022-07-01T10:40:41.919215Z",
        ngo: 3,
      },
    ],
    status: true,
  };
  //   setNgoPartner(data?.data);
  // }, []);
  return (
    ngoPartner &&
    ngoPartner.length > 0 && (
      <>
        <Heading title="Our NGO Partners" />
        <br />
        <br />
        <Paper className={classes.paper}>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item md={12}>
              <Grid container spacing={5} className={classes.gridmobile}>
                {ngoPartner?.map((item) => {
                  return (
                    <Grid item md={3}>
                      <Partner data={item} />
                    </Grid>
                  );
                })}
                <Grid
                  item
                  md={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "Center",
                  }}
                >
                  <Link href="/our-ngo-partner">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <IconButton color="primary" className={classes.iconbtn}>
                        <ArrowForwardRoundedIcon />
                      </IconButton>
                      <Typography color="primary" style={{ fontSize: "12px" }}>
                        See All
                      </Typography>
                    </div>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </>
    )
  );
}

const total = [
  { title: `₹ 4,00,000+`, member: "Lakh Total Donations" },
  { title: `50000+`, member: "Members" },
  { title: `10000`, member: "Donations" },
  { title: `19000`, member: "NGO Partners" },
];
