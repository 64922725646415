import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import PartnerList from "../../Cards/Partner/PartnerList";
import Heading from "../../Common/Heading/Heading";
import { getRequest } from "../../../apis/baseApi";

function TopRatedNGO() {
  const [ngoPartner, setNgoPartner] = useState([]);
  useEffect(() => {
    getRequest("home_page/get_our_ngos/")
      .then((res) => {
        if (res?.data?.status) {
          setNgoPartner(res?.data?.data);
        }
      })
      .catch((err) => {});
  }, []);
  return (
    <>
      <div style={{ marginTop: "3%" }}>
        <Heading title="Top 10 Rated NGOs" />

        <Grid container spacing={2} style={{ marginTop: "30px" }}>
          {ngoPartner?.map((item) => {
            return (
              <Grid item md={3}>
                <PartnerList item={item} />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
}

export default TopRatedNGO;
