import React, { Component } from "react";
import Charst from "react-apexcharts";
import { Paper, Typography } from "@material-ui/core";

export default class BarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },

        chart: {
          id: "apexchart-example",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: ["2019", "2020", "2021", "2022"],
        },

        colors: ["#C88FEF", "#C88FEF80"],

        yaxis: {
          categories: ["0", "2020", "2021", "2022"],
          title: {
            // text: "Number of Hired Students",
            style: {
              colors: ["#C88FEF", "#C88FEF80"],
              fontSize: "12px",
              fontFamily: "Raleway",
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "75%",
            borderRadius: 0,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return "Q" + val;
          },
          offsetY: -20,
          style: {
            fontSize: "10px",
            colors: ["#94939F"],
            fontFamily: "Raleway",
          },
          //enabled: true,
        },
      },

      series: [
        {
          name: "No donation made",
          data: [0, 0, 0, 0],
        },
        // {
        //   name: "Fair Health",
        //   data: [0, 0, 0, 0],
        // },
        // {
        //   name: "Good Health",
        //   data: [0, 0, 0, 0],
        // },
        // {
        //   name: "Good Health",
        //   data: [0, 0, 0, 0],
        // },
      ],
    };
  }
  render() {
    return (
      <Paper
        style={{
          boxShadow: "none",
          borderRadius: "20px",
          padding: "30px",
          backgroundColor: "transparent",
        }}
      >
        <Charst
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={420}
        />
      </Paper>
    );
  }
}
