import { Paper, Typography, makeStyles, Link, alpha } from "@material-ui/core";
import React from "react";
import door from "../assets/png/icon/Door.png";
import { useHistory } from "react-router-dom";
import DashBoardSubMenu from "./DashBoardSubMenu";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderColor: theme.palette.primary.main,
    border: "1px solid ",
    boxShadow: "none",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    position: "sticky",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
}));

function PublicSidebar({ keyss }) {
  const history = useHistory();

  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        {navLinks[keyss]?.map((item, index) => {
          return <DashBoardSubMenu items={item} key={index} />;
        })}
      </Paper>
    </>
  );
}

export default PublicSidebar;

const navLinks = {
  about: [
    { title: `Our Philosophy`, path: `/about-us` },
    { title: `What We Do`, path: `/about-us-what` },
    { title: `Our Team`, path: `/about-us-team` },
    { title: `Causes We Support`, path: `/about-us-support` },
  ],
  resource: [
    { title: `NGO Rating`, path: `/ngo-rating` },
    { title: `Impact Measurement`, path: `/ngo-impact` },
    { title: `Key Data Points`, path: `/ngo-points` },
    { title: `Case Studies`, path: `/case-studies` },
    { title: `Financials`, path: `/financials` },
    { title: `Knowledge`, path: `/knowledge` },
    { title: `FAQs`, path: `/faqs` },
  ],
  involved: [
    { title: `Become A Member`, path: `/become-a-member` },
    { title: `Donate Now`, path: `/donate-now` },
    { title: `Jobs`, path: `/jobs` },
    { title: `Volunteer`, path: `/volunteer` },
    { title: `Refer an NGO`, path: `/refer-an-ngo` },
  ],
};
