import React from "react";
import DashboardLayout from "../../../components/Layout/DashBoardLayout";
import PaymentGrid from "../../../components/Section/DashBoard/Report/PaymentGrid";

export default function Payment() {
  return (
    <>
      <DashboardLayout>
        <PaymentGrid />
      </DashboardLayout>
    </>
  );
}
