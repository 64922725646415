import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Ngo from "../../assets/png/team-work/ngo.jpg";
import DonationDialog from "../../Dialogs/DonationDialog";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    boxShadow: "none",
    border: "none",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  btn: {
    color: "#fff",
    boxShadow: "none",
    height: "50px",
    borderRadius: "8px",
    minWidth: "150px"
  },
  btnAlign: {
    display: "flex", justifyContent: "space-between"
  }
});

// const StyledRating = withStyles({
//   iconFilled: {
//     color: "#C88FEF",
//   },
//   iconHover: {
//     color: "#C88FEF",
//   },
// })(Rating);

export default function NGOCard({item}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(4);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  

  return (
    <>
      
        <Card className={classes.root} >
          {/* <CardActionArea> */}
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="240"
            image={item?.image ? item?.image :Ngo}
            style={{ borderRadius: "10px" }}
            title="Contemplative Reptile"
          />
          <CardContent style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Typography gutterBottom variant="h5" component="h2">
              {item?.ngo_name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {item?.reg_area} , {item?.reg_city}
            </Typography>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "bold ",
                }}
                color="primary"
              >
                {item?.subcategory}
              </Typography>
            </div>
            <div className={classes.btnAlign}>
            <Button variant="contained" color="primary" className={classes.btn} onClick={() => {
          history.push(`ngo-details/?ngo_slug=${item?.slug}&ngo_id=${item?.id}`)
        }}>
          View NGO Details
        </Button>
        <Button variant="contained" color="primary" className={classes.btn} onClick={() => {
          setOpen(true);
        }}>
          Donate
        </Button>
            </div>
            {/* <StyledRating
              name="read-only"
              size="small"
              color="primary"
              value={value}
              readOnly
            /> */}
          </CardContent>
          <DonationDialog ngoId={item?.id} open={open} setOpen={setOpen} ngoName={item?.ngo_name}/>
          {/* </CardActionArea> */}
        </Card>
      {/* </Link> */}
    </>
  );
}
