import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { postRequest, putRequest } from "../../../../apis/baseApi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ngoDocDetails } from "../../../../redux/ngoReducer/action";
import { ngoDocDetailsSelectorFn } from "../../../../redux/ngoReducer/selector";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import NgoRegTwoSection from "../../../Section/DashBoard/Profile/NgoRegTwoSection";
import FileUploader from "../../../FileInput";
import DownloadIcon from "@mui/icons-material/Download";
import ImageIcon from "@mui/icons-material/Image";
const useStyles = makeStyles((theme) => ({
  upload: {
    color: "#fff",
    paddingLeft: "25px",
    paddingRight: "25px",
    boxShadow: "none",
    borderRadius: 8,
    height: 46,
    // minWidth: "50px",
  },
  download: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default function DownloadButton({ link }) {
  const history = useHistory();
  const classes = useStyles();
  const ngoDocs = useSelector(ngoDocDetailsSelectorFn);
  const [docs, setDocs] = useState({
    itrCopy: null,
    itrCopy2: null,
    itrCopy3: null,
    cancelledChequeDom: null,
    cancelledChequeDom2: null,
    cancelledChequeIntl: null,
    cancelledChequeIntl2: null,
    annualReport: null,
    annualReport2: null,
    annualReport3: null,
    gstRegistrationNo: null,
    auditedFinancial: null,
    auditedFinancial2: null,
    auditedFinancial3: null,
    registrationNo: null,
    panCardCert: null,
    certificate12a: null,
    certificateFcra: null,
    certificate80G: null,
    internalAuditReport: null,
    projectCompletionReport: null,
    impactAssessmentReport: null,
    caseStudy: null,
    ratingReport: null,
    relevantPic: null,
    projectStatusReport: null,
    otherDoc: null,
  });
  function uploadDocs() {
    const formData = new FormData();
    formData.set("pan_card_certificate", docs?.panCardCert);
    formData.set("ngo_registration_id", docs?.registrationNo);
    formData.set("_12_a_certificate", docs?.certificate12a);
    formData.set("_80_g_certificate", docs?.certificate80G);
    formData.set("_FCRA_certificate", docs?.certificateFcra);
    formData.set("_ITR_certificate", docs?.itrCopy);
    formData.set("_ITR_2_certificate", docs?.itrCopy2);
    formData.set("_ITR_3_certificate", docs?.itrCopy3);
    formData.set("annual_report", docs?.annualReport);
    formData.set("annual_report_2", docs?.annualReport2);
    formData.set("annual_report_3", docs?.annualReport3);
    formData.set("audited_financial_statement", docs?.auditedFinancial);
    formData.set("audited_financial_statement_2", docs?.auditedFinancial2);
    formData.set("audited_financial_statement_3", docs?.auditedFinancial3);
    formData.set("dom_cancel_chaque", docs?.cancelledChequeDom);
    formData.set("dom_cancel_chaque_2", docs?.cancelledChequeDom2);
    formData.set("gst_certificate", docs?.gstRegistrationNo);
    formData.set("internation_cancel_chaque", docs?.cancelledChequeIntl);
    formData.set("internation_cancel_chaque_2", docs?.cancelledChequeIntl2);
    docs?.internalAuditReport &&
      formData.set("internal_audit_reports", docs?.internalAuditReport);
    docs?.impactAssessmentReport &&
      formData.set("impact_assessment_reports", docs?.impactAssessmentReport);
    docs?.ratingReport && formData.set("rating_report", docs?.ratingReport);
    docs?.projectStatusReport &&
      formData.set("project_status", docs?.projectStatusReport);
    docs?.projectCompletionReport &&
      formData.set("project_completion", docs?.projectCompletionReport);
    docs?.caseStudy &&
      formData.set("case_study_and_testimonials", docs?.caseStudy);

    if (ngoDocs?.panCardCert === undefined) {
      postRequest(
        `ngo/ngo_docs_images/${localStorage.getItem("ngo_slug")}/`,
        formData
      )
        .then((res) => {
          if (res?.data) {
            alert.success("Docs uploaded Successfully");
            history.push("ngo-registration-final");
          } else {
            // alert.error("Something Went Wrong");
          }
          // setLoading(false);
        })
        .catch((err) => {
          // setLoading(false);
          // alert.error("Something Went Wrong");
        });
    } else {
      putRequest(
        `ngo/ngo_documents_update/${localStorage.getItem("ngo_slug")}/`,
        formData
      )
        .then((res) => {
          if (res?.data) {
            alert.success("Docs uploaded Successfully");
            history.push("ngo-registration-final");
          } else {
            // alert.error("Something Went Wrong");
          }
          // setLoading(false);
        })
        .catch((err) => {
          // setLoading(false);
          // alert.error("Something Went Wrong");
        });
    }
  }
  return (
    <>
      {typeof link === "string" ? (
        <Grid item md={1} xs={2} className={classes.download}>
          <Button
            variant="contained"
            color="primary"
            className={classes.upload}
            disabled={link ? false : true}
            onClick={() => {
              window.open(link, "_target");
            }}
          >
            <ImageIcon />
          </Button>
        </Grid>
      ) : (
        <Grid item md={1} xs={2} className={classes.download}>
          <Button
            variant="contained"
            color="primary"
            className={classes.upload}
            disabled={link ? false : true}
          >
            {/* <FileUploader
            setFile={(file) => {
              setDocs({ ...docs, internalAuditReport: file });
            }} */}
            N/A
          </Button>
        </Grid>
      )}
    </>
  );
}
