import {
  alpha,
  Button,
  Chip,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BarChart from "../../../Common/Graph/BarChart";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    padding: "25px",
    borderColor: theme.palette.primary.main,
    padding: "7px",
    borderRadius: "15px",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    color: "#fff",
    marginTop: "50px",
  },
}));

export default function StartPortfolio() {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <div style={{ padding: "20px" }}>
              <Typography variant="h5">
                Start building your portfolio now
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#A6A6A6",
                  marginTop: "10px",
                  fontWeight: "500",
                }}
              >
                Your Philanthrophy Portfolio is worth{" "}
                <span style={{ color: "#C88FEF" }}>Rs. 0. </span>
                
                {/* , you have
                <span style={{ color: "#C88FEF" }}> Rs. 22,400 </span>invested
                in Water and Sanitation through Jal Sevan NGO and{" "}
                <span style={{ color: "#C88FEF" }}> Rs. 30,000</span> in
                Agriculture and Farmers through Swades Foundation NGO, */}
              </Typography>
              <br />
              <BarChart />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
