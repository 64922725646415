import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((_) => ({
  root: {
    width: "80%",
    // "& > * + *": {
    //   marginTop: theme.spacing(2),
    // },
  },

  wrong: {
    background: "#f8d7da",
    borderRadius: "7px",
    padding: "10px",
    boxShadow: "none",
    position: "relative",
    top: "25px",
    // right: -150,
    "&:after": {
      content: '" "',
      position: "absolute",
      right: "-10px",
      top: "20px",
      borderTop: "10px solid transparent",
      borderRight: "none",
      //borderColor: theme.palette.primary.main,
      borderLeft: "10px solid #f8d7da",
      borderBottom: "10px solid transparent",
    },
  },

  second: {
    background: "#c3e6cb",
    borderRadius: "7px",
    padding: "10px",
    boxShadow: "none",
    position: "relative",
    // borderColor: theme.palette.primary.main,
    //  border: "2px solid",
    top: "25px",
    // right: -250,

    "&:after": {
      content: '" "',
      position: "absolute",

      right: "-10px",
      top: "20px",
      borderTop: "10px solid transparent",
      borderRight: "none",
      //borderColor: theme.palette.primary.main,
      borderLeft: "10px solid #c3e6cb",
      borderBottom: "10px solid transparent",
    },
  },
  centerAlign: {
    display: "flex",
    alignItems: "center",
  },
  closeIconGreen: {
    marginLeft: "10px",
    cursor: "pointer",
    color: "green",
  },
  closeIconRed: {
    marginLeft: "10px",
    cursor: "pointer",
    color: "red",
  },
}));
const AlertTemplate = ({ style, options, message, close }) => {
  const classes = useStyles();
  return (
    <div style={style} className={classes.root}>
      <>
        <>
          <Alert severity="false" className={options.type === "success" ? classes.second : classes.wrong}>
            <div className={classes.centerAlign}>
              {" "}
              <Typography variant="body2"> {message}</Typography>
              <CloseIcon onClick={close} className={options.type === "success" ? classes.closeIconGreen : classes.closeIconRed} />
            </div>
          </Alert>
        </>
      </>
    </div>
  );
};
export default AlertTemplate;

