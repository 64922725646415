import { Paper, Typography, makeStyles, alpha } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    borderRadius: "20px",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    textAlign: "center",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      border: "2px solid",
      // transform: "scale(1.01)",
      //transition: "all .2s ease-in-out",
    },
  },
  navlink: {
    color: "#000",
  },
  dropdownlink: {
    display: "flex",
    color: "#060b26",
    textDecoration: "none",
    listStyle: "none",
    backgroundColor: "#ffff",
    height: "30px",
    color: "#000",
    alignItems: "center",
    // padding: "0px 16px 0px 55px",
    justifyContent: "flex-start",
    position: "relative",
    height: "40px",
    padding: "15px",
    paddingTop: "0px",
    paddingBottom: "0px",
    "&:hover": {
      backgroundColor: "#fff",
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  blueLink: {
    display: "flex",
    padding: "15px",
    paddingTop: "0px",
    paddingBottom: "0px",
    textDecoration: "none",
    listStyle: "none",
    fontWeight: 600,
    alignItems: "center",
    //padding: "0px 16px 0px 55px",
    justifyContent: "flex-start",
    borderRadius: "4px",
    backgroundColor: "#ffff",
    textDecoration: "none",
    color: theme.palette.primary.main,
    position: "relative",
    height: "40px",
  },
}));

export default function ReportCard(props) {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper} onClick={props.onClick}>
        <Typography variant="body1" className={classes.navlink}>
          {props.title}
        </Typography>
        <Typography variant="h6" color="primary">
          {props.num}
        </Typography>
      </Paper>
    </>
  );
}
