import { createSelector } from "reselect";

const commonState = state => state.common;

export const loggedInSelectorFn = createSelector(commonState, common => common?.loggedIn);

export const cityStateSelectorFn = createSelector(commonState, common => common?.cityState);

export const userDetailSelectorFn = createSelector(commonState, common => common?.userDetails);

export const StateSelectorFn = createSelector(commonState, common => {
  let state = [];
  let stateId = [];
  common?.cityState?.forEach(item => {
      if(!stateId.includes(item?.state?.id)){
        state.push({id: item?.state?.id, name:item?.state?.state_name, value: item?.state?.state_name, city: []});
        stateId.push(item?.state?.id);
      }
      const indx = stateId.indexOf(item?.state?.id);

      state[indx]?.city.push(item);
  })
  return state;
});

export const categoriesListSelectorFn = createSelector(commonState, common => common?.categoriesList);

export const subCategoriesListSelectorFn = createSelector(commonState, common => {
    
    let temp = {};
    let tempId = [];
    common?.subCategoriesList?.forEach(item => {
      if(!tempId.includes(item?.category?.id)){
        tempId.push(item?.category?.id);
        temp[item?.category?.category_name] = [item]
      }else{
        temp[item?.category?.category_name].push(item)
      }
    })
    return temp;
     }
    );

    export const subCategoriesListIdSelectorFn = createSelector(commonState, common => {
    
        let temp = {};
        let tempId = [];
        common?.subCategoriesList?.forEach(item => {
          if(!tempId.includes(item?.category?.id)){
            tempId.push(item?.category?.id);
            temp[item?.category?.id] = [item]
          }else{
            temp[item?.category?.id].push(item)
          }
        })
        return temp;
         }
        );