import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FileUploader from "../../../FileInput";
import { useHistory } from "react-router-dom";
import CustomButtonCircularProgress from "../../../CircularProgress";
import DownloadButton from "./DownloadButton";

const useStyles = makeStyles((theme) => ({
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  upload: {
    color: "#fff",
    paddingLeft: "70px",
    paddingRight: "75px",
    boxShadow: "none",
    borderRadius: 8,
    height: 46,
    minWidth: "100px",
  },
  uploads: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    borderRadius: 8,
    height: 46,
    minWidth: "100px",
    marginBottom: "5%",
  },

  fieldtext: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "10px",
    display: "flex",
  },
  reqfieldtext: {
    fontSize: "14px",
    textAlign: "left",
  },
  option: {
    fontSize: 13,
    "& > span": {
      marginRight: 10,
      fontSize: 12,
    },
  },
  gridtext: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

export default function NgoRegSecondCard({
  docs,
  setDocs,
  uploadDocs,
  fromProfile = false,
  isLoading = false,
  edit,
  handleEdit,
}) {
  const classes = useStyles();
  const history = useHistory();
  const pathname = window?.location.pathname;

  return (
    <>
      {edit ? (
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Typography
              variant="body2"
              style={{
                color: "#A6A6A6",
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              Optional Documents to be Uploaded
            </Typography>
          </Grid>

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Internal Audit Reports
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>
          {typeof docs?.internalAuditReport === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, internalAuditReport: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, internalAuditReport: file });
              }}
            />
          ) : docs?.internalAuditReport ? (
            <DownloadButton link={docs?.internalAuditReport} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, internalAuditReport: file });
              }}
            />
          )}

          <Grid item md={3} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Project Completion Reports
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>
          {typeof docs?.projectCompletionReport === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, projectCompletionReport: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, projectCompletionReport: file });
              }}
            />
          ) : docs?.projectCompletionReport ? (
            <DownloadButton link={docs?.projectCompletionReport} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, projectCompletionReport: file });
              }}
            />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Impact Assessment Reports
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>
          {typeof docs?.impactAssessmentReport === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, impactAssessmentReport: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, impactAssessmentReport: file });
              }}
            />
          ) : docs?.impactAssessmentReport ? (
            <DownloadButton link={docs?.impactAssessmentReport} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, impactAssessmentReport: file });
              }}
            />
          )}

          <Grid item md={3} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Case Studies and Testimonials
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>
          {typeof docs?.caseStudy === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, caseStudy: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, caseStudy: file });
              }}
            />
          ) : docs?.caseStudy ? (
            <DownloadButton link={docs?.caseStudy} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, caseStudy: file });
              }}
            />
          )}
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Rating Reports
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>
          {typeof docs?.ratingReport === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, ratingReport: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, ratingReport: file });
              }}
            />
          ) : docs?.ratingReport ? (
            <DownloadButton link={docs?.ratingReport} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, ratingReport: file });
              }}
            />
          )}

          <Grid item md={3} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Relevant Pictures
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>
          {typeof docs?.relevantPic === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, relevantPic: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, relevantPic: file });
              }}
            />
          ) : docs?.relevantPic ? (
            <DownloadButton link={docs?.relevantPic} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, relevantPic: file });
              }}
            />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Project Status Reports
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>
          {typeof docs?.projectStatusReport === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, projectStatusReport: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, projectStatusReport: file });
              }}
            />
          ) : docs?.projectStatusReport ? (
            <DownloadButton link={docs?.projectStatusReport} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, projectStatusReport: file });
              }}
            />
          )}

          <Grid item md={3} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Any other reports or documents that the NGO Finds Relevant for the
              Platform
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>
          {typeof docs?.otherDoc === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, otherDoc: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, otherDoc: file });
              }}
            />
          ) : docs?.otherDoc ? (
            <DownloadButton link={docs?.otherDoc} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, otherDoc: file });
              }}
            />
          )}
          <br />
          <Grid
            item
            md={12}
            xs={8}
            className="NgoRegSecondContainer"
            style={
              {
                // marginLeft: "25%",
              }
            }
          >
            <Button
              className={classes.upload}
              color="primary"
              variant="contained"
              // style={{ marginRight: "20px" }}
              onClick={() => {
                fromProfile
                  ? history.push("/ngo/profile")
                  : history.push("/ngo-registration");
                localStorage.removeItem("refresh");
              }}
            >
              Back
            </Button>
            {/* {pathname === "/ngo-registration-doc" ? (
              ""
            ) : (
              <Button
                className={classes.upload}
                color="primary"
                variant="contained"
                style={{ marginLeft: "20px" }}
                onClick={handleEdit}
              >
                Edit
              </Button>
            )} */}
            {!fromProfile ? (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.uploads}
                  onClick={() => {
                    fromProfile
                      ? pathname === "/ngo-registration-doc"
                        ? history.push("/ngo-registration-final")
                        : history.push("/ngo/profile-final")
                      : uploadDocs();
                  }}
                >
                  {isLoading ? <CustomButtonCircularProgress /> : "Next"}
                </Button>
              </div>
            ) : (
              <div className="ngoComponent">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.uploads}
                  onClick={() => {
                    !fromProfile
                      ? pathname === "/ngo-registration-doc"
                        ? history.push("/ngo-registration-final")
                        : history.push("/ngo/profile-final")
                      : uploadDocs();
                  }}
                >
                  {isLoading ? <CustomButtonCircularProgress /> : "SAVE & NEXT"}
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Typography
              variant="body2"
              style={{
                color: "#A6A6A6",
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              Optional Documents to be Uploaded
            </Typography>
          </Grid>

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Internal Audit Reports
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, internalAuditReport: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.internalAuditReport} />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Project Completion Reports
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, projectCompletionReport: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.projectCompletionReport} />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Impact Assessment Reports
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, impactAssessmentReport: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.impactAssessmentReport} />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Case Studies and Testimonials
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, caseStudy: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.caseStudy} />
          )}
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Rating Reports
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, ratingReport: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.ratingReport} />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Relevant Pictures
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, relevantPic: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.relevantPic} />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Project Status Reports
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, projectStatusReport: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.projectStatusReport} />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Any other reports or documents that the NGO Finds Relevant for the
              Platform
              {/* <span style={{ color: "red", fontSize: "20px" }}>*</span> */}
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, otherDoc: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.otherDoc} />
          )}
          <br />
          <Grid
            item
            md={12}
            xs={8}
            className="NgoRegSecondContainer"
            style={
              {
                // marginLeft: "25%",
              }
            }
          >
            <Button
              className={classes.upload}
              color="primary"
              variant="contained"
              // style={{ marginRight: "20px" }}
              onClick={() => {
                fromProfile
                  ? history.push("/ngo/profile")
                  : history.push("/ngo-registration");
                localStorage.removeItem("refresh");
              }}
            >
              Back
            </Button>
            {pathname === "/ngo-registration-doc" ? (
              ""
            ) : (
              <Button
                className={classes.upload}
                color="primary"
                variant="contained"
                style={{ marginLeft: "20px" }}
                onClick={handleEdit}
              >
                Edit
              </Button>
            )}

            {!fromProfile ? (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.uploads}
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    fromProfile
                      ? pathname === "/ngo-registration-doc"
                        ? history.push("/ngo-registration-final")
                        : history.push("/ngo/profile-final")
                      : uploadDocs();
                  }}
                >
                  {isLoading ? <CustomButtonCircularProgress /> : "SAVE & NEXT"}
                </Button>
              </div>
            ) : (
              <div className="ngoComponent" style={{ marginLeft: "20px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.uploads}
                  onClick={() => {
                    fromProfile
                      ? pathname === "/ngo-registration-doc"
                        ? history.push("/ngo-registration-final")
                        : history.push("/ngo/profile-final")
                      : uploadDocs();
                  }}
                >
                  {isLoading ? <CustomButtonCircularProgress /> : "NEXT"}
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
