import {
    Grid, makeStyles, Paper, Typography
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import Table from "../../../Common/Table/Table";
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      boxShadow: "none",
      width: "100%"
    },
    navlink: {
      color: "#94939F",
      marginBottom: "20px",
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
    },
    download_main_grid: {
      display: "flex",
      alignItems: "center",
    },
    download: {
      boxShadow: "none",
      borderRadius: "8px",
      color: "#fff",
      height: "40px",
      display: "flex",
    },
    download_main_grid: {
        display: "flex",
        alignItems: "center",
      },
  }));
  
  export default function BenefitsAvailed({benefits}) {
    const classes = useStyles();
    const [data, setData] = useState();
    const alert = useAlert();
    
    useEffect(() => {
        if(benefits){
            let tempData = [];
            let count = 0;
            benefits?.forEach(item => {
                tempData.push({
                    id: count + 1,
                    sNo: count + 1,
                    serviceBought: item?.name,
                    amount: item?.cost
                })  
                count = count + 1;
            })
            setData(tempData);
        }
    }, [benefits])

    return (
      <>
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={2}
            style={{ padding: "20px", paddingTop: "0px" }}
          >
            <Grid item md={12} style={{ marginTop: "20px" }}>
            <Table columns={columns} list={data} />
          </Grid>
          </Grid>
        </Paper>
      </>
    );
  }

  export const columns = [
  {
    field: "sNo",
    headerName: "S. No",
    type: "text",
    width: 350,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.sNo}
      </span>
    ),
  },
  {
    field: "serviceBought",
    type: "text",
    headerName: "Benefits Availed",
    width: 350,
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        {params.row.serviceBought}
      </span>
    ),
  },
  {
    field: "amount",
    type: "text",
    sortable: false,
    headerName: "Amount",
    width: 350,
    // renderHeader: (params) => (
    //   <strong style={{ color: "#5a6268", marginLeft: "150px" }}>NGO Utilisation Report</strong>
    // ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color: "#94939F",
        }}
      >
        <Typography color="primary">₹ {params.row.amount}</Typography>
      </span>
    ),
  }
];

export const row = [
  { id: `1`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `2`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `3`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `4`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `5`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `6`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `7`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `8`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `9`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `10`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `11`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `12`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `13`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `14`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `15`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `16`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `17`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `18`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `19`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
  { id: `20`, donation_id: `Lorem`, date: "02/12/22", amount: "490490" },
];
  