import React from "react";
import user from "../../assets/png/team-work/user.jpg";
// import Abhishek from "../../assets/png/abhishek.jpeg";
// import Ankita from "../../assets/png/Ankita.JPG";
import { makeStyles, Typography } from "@material-ui/core";
import TeamToolTip from "../../Dialogs/TeamToolTip";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    cursor: "pointer",
  },
  user: {
    borderRadius: "50%",
  },
}));

function DirectorsPerson(props) {
  const classes = useStyles();
  return (
    <>
      <TeamToolTip text={props?.data?.popUp}>
        <div className={classes.card}>
          <img
            src={props.image}
            height="200px"
            width="200px"
            alt="user"
            className={classes.user}
          />
          <Typography variant="h6" noWrap style={{ marginTop: "20px" }}>
            {props.name}
          </Typography>
          <Typography variant="body2" style={{ color: "#626262" }}>
            {props?.data?.position}
          </Typography>
        </div>
      </TeamToolTip>
    </>
  );
}

export default DirectorsPerson;
