import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    //  flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    //borderColor: theme.palette.primary.main,
    //border: "1px solid",
    borderRadius: "8px",
    flexDirection: "column",
    // boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
    boxShadow: "0px 4px 40px 0px #C88FEF33",

    //height: "100px",
  },
  second: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "50px",
  },
}));

export default function CardDrivers(props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.card}>
        <div className={classes.second}>
          <img src={props.img} height="50px" />
          <Typography variant="h2" style={{ color: "#E0E0E0" }}>
            {props.number}
          </Typography>
        </div>
        <Typography variant="body2">{props.name}</Typography>
      </div>
    </>
  );
}
