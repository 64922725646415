import { Typography, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { getRequest } from "../../../apis/baseApi";
import { useAlert } from "react-alert";

import { ngoDocDetailsSelectorFn } from "../../../redux/ngoReducer/selector";
import { ngoDocDetails } from "../../../redux/ngoReducer/action";
import { useSelector } from "react-redux";
function NgoDocuments({ ngoSlug }) {
  const alert = useAlert();
  const [value, setValue] = useState();

  // useEffect(() => {
  //   if(ngoSlug)
  //     getRequest(`ngo/ngo_docs_images/${ngoSlug}/`).then(res => {

  //     }).catch(err => {
  //       alert.error("Something went wrong!!")
  //     })

  // }, [ngoSlug])
  const ngoDocs = useSelector(ngoDocDetailsSelectorFn);

  useEffect(() => {
    if (localStorage.getItem("user_token")) {
      getRequest("ngo/ngo_user_ngo_list/").then((res) => {
        if (res?.data?.data?.length !== 0) {
          localStorage.setItem("ngo_slug", res?.data?.data[0].slug);
          const ngoData = res?.data?.data[0]?.ngo_docs_images;
          setValue(ngoData);

          localStorage.setItem("ngo_name", ngoData?.ngo_name);
        }
      });
    }
  }, []);

  return (
    <>
      <div
        style={{
          borderRadius: "10px",
          border: "1px solid #C4C4C4",
          padding: "20px",
        }}
      >
        <Typography variant="h6">
          Transparency, Documentation & Downloads
        </Typography>
        <Grid
          md={12}
          style={{ marginTop: "20px", display: "flex", flexDirection: "row" }}
        >
          {value?.ngo_registration_id === "null" ? (
            <Grid md={4}></Grid>
          ) : (
            <Grid md={4}>
              <Typography>
                <a href={value?.ngo_registration_id} target="_blank">
                  NGO Registration Certificate
                </a>
              </Typography>
            </Grid>
          )}
          {value?.pan_card_certificate === "null" ? (
            <Grid md={4}></Grid>
          ) : (
            <Grid md={4}>
              <a href={value?.pan_card_certificate} target="_blank">
                PAN Card Certificate
              </a>
            </Grid>
          )}
          {value?._12_a_certificate === "null" ? (
            <Grid md={4}></Grid>
          ) : (
            <Grid md={4}>
              <a href={value?._12_a_certificate} target="_blank">
                12A Certificate
              </a>
            </Grid>
          )}
        </Grid>
        <Grid
          md={12}
          style={{ marginTop: "20px", display: "flex", flexDirection: "row" }}
        >
          {value?._80_g_certificate === "null" ? (
            <Grid md={4}></Grid>
          ) : (
            <Grid md={4}>
              <a href={value?._80_g_certificate} target="_blank">
                80G Certificate
              </a>
            </Grid>
          )}
          {value?._FCRA_certificate === "null" ? (
            <Grid md={4}></Grid>
          ) : (
            <Grid md={4}>
              <Typography>
                <a href={value?._FCRA_certificate} target="_blank">
                  FCRA Certificate
                </a>
              </Typography>
            </Grid>
          )}
          {value?.gst_certificate === "null" ? (
            <Grid md={4}></Grid>
          ) : (
            <Grid md={4}>
              <Typography>
                <a href={value?.gst_certificate} target="_blank">
                  GST Certificate
                </a>
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid
          md={12}
          style={{ marginTop: "20px", display: "flex", flexDirection: "row" }}
        >
          {value?.annual_report === "null" ? (
            <Grid md={4}></Grid>
          ) : (
            <Grid md={4}>
              <a href={value?.annual_report} target="_blank">
                Annual Report
              </a>
            </Grid>
          )}
          {value?.audited_financial_statement === "null" ? (
            <Grid md={4}></Grid>
          ) : (
            <Grid md={4}>
              <a href={value?.audited_financial_statement} target="_blank">
                Audited Financial Statements
              </a>
            </Grid>
          )}
          {value?._ITR_certificate === "null" ? (
            <Grid md={4}></Grid>
          ) : (
            <Grid md={4}>
              <a href={value?._ITR_certificate} target="_blank">
                ITR Certificate
              </a>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

export default NgoDocuments;
