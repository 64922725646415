import Heading from "../../Common/Heading/Heading";
import React, { Fragment, useEffect } from "react";
import {
  Typography,
  Box,
  Hidden,
  makeStyles,
  Container,
  createStyles,
  Grid,
  Paper,
} from "@material-ui/core";
import demo from "../../assets/png/ratingmethod.png";
import Method from "../../Cards/Total/Method";

const useStyles = makeStyles((theme) =>
  createStyles({
    cardimg: {
      [theme.breakpoints.only("sm")]: {
        width: "auto",
        height: "200px",
      },
      [theme.breakpoints.only("xs")]: {
        width: "auto",
        height: "300px",
      },
    },
    title: {
      paddingTop: "20px",
    },

    title2: {
      //  fontSize: "35px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
    },
    title3: {
      //fontSize: "22px",
      //fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      marginTop: "3%",
    },
    details: {
      //fontSize: "13px",
      marginBottom: "8px",
      //fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      textAlign: "justify",
      color: "#333",
    },
    details2: {
      //  fontSize: "13px",
      // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      textAlign: "justify",
      color: "#333",
    },
    gridimg: {
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.primary.main,
      width: "80%",
      height: "200px",
      boxShadow: "0px 4px 100px 0px #C88FEF73",
    },
    paper2: {
      backgroundColor: theme.palette.primary.main,
      width: "80%",
      height: "300px",
      boxShadow: "0px 4px 100px 0px #C88FEF73",
    },
    paper3: {
      backgroundColor: theme.palette.primary.main,
      width: "80%",
      height: "400px",
      boxShadow: "0px 4px 100px 0px #C88FEF73",
    },
  })
);

export default function RatingTiersSection() {
  const classes = useStyles();
  return (
    <>
      <div style={{ marginTop: "3%" }}>
        <Heading title="Rating Tiers" />
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <Grid item md={4} xs={4}>
            <Grid container spacing={2}>
              <Grid
                item
                md={8}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Typography variant="h6" style={{ textAlign: "right" }}>
                    Tier 3
                  </Typography>
                  <br />
                  <Typography variant="body2" style={{ textAlign: "right" }}>
                  NGOs that have scored between 30-50 in their rating

                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                md={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", fontSize: "60px" }}
                  color="primary"
                >
                  3
                </Typography>
                <Paper className={classes.paper}></Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} xs={4}>
            <Grid container spacing={2}>
              <Grid
                item
                md={8}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Typography variant="h6" style={{ textAlign: "right" }}>
                    Tier 2
                  </Typography>
                  <br />
                  <Typography variant="body2" style={{ textAlign: "right" }}>
                  NGOs that have scored between 51-75 in their rating
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                md={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", fontSize: "60px" }}
                  color="primary"
                >
                  2
                </Typography>
                <Paper className={classes.paper2} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} xs={4}>
            <Grid container spacing={2}>
              <Grid
                item
                md={8}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Typography variant="h6" style={{ textAlign: "right" }}>
                    Tier 1
                  </Typography>
                  <br />
                  <Typography variant="body2" style={{ textAlign: "right" }}>
                  NGOs that have scored over 75 in their rating

                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                md={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", fontSize: "60px" }}
                  color="primary"
                >
                  1
                </Typography>
                <Paper className={classes.paper3} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
