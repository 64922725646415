import { Grid, Paper, Typography } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { postRequest } from "../../apis/baseApi";
import UserReg from "../../components/Form/Reg/UserReg";
import { loggedinUser, userDetails } from "../../redux/commonReducer/action";
import { useEffect, useState } from "react";
import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
    paddingBottom: "50px",
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
}));

export default function UserRegistration() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [signupData, setSignupData] = useState({});

  useEffect(() => {
    if (location.state) {
      setSignupData(location.state);
    }
  }, [location.state]);

  const registerUser = (data) => {
    const postData = {
      email: data?.email,
      phone: data?.phone,
      password: data?.password,
      first_name: data?.firstName,
      last_name: data?.lastName,
      is_user_minor: data?.isMinor,
      signup_from: "web",
      dob: data?.dateOfBirth,
      ...(data?.city && { city: data?.city }),
      ...(data?.country && { country: data?.country }),
      ...(data?.profession_details && {
        profession_details: data?.profession_details,
      }),
      ...(data?.guardian_name && { guardian_name: data?.guardian_name }),
      ...(data?.relation && { relation: data?.relation }),
      ...(data?.guardian_email && { guardian_email: data?.guardian_email }),
      ...(data?.guardian_phone && { guardian_phone: data?.guardian_phone }),
      ...(data?.gaurdian_profession && {
        gaurdian_profession: data?.gaurdian_profession,
      }),
      ...(data?.gaurdian_address && {
        gaurdian_address: data?.gaurdian_address,
      }),
    };
    setIsLoading(true);
    postRequest("authentication/users/register/", postData)
      .then((res) => {
        if (res?.data?.data) {
          dispatch(loggedinUser(true));
          dispatch(
            userDetails({
              name: res?.data?.data,
              username: res?.data?.data?.username,
              token: res?.data?.data?.token,
              slug: res?.data?.data?.slug,
              isHasNgo: res?.data?.data?.is_has_ngo,
              profileImage: res?.data?.data?.user_profile?.image,
            })
          );
          localStorage.setItem("user_token", res?.data?.data?.token);
          localStorage.setItem("slug", res?.data?.data?.slug);
          localStorage.setItem("user_type", "DONOR");
          localStorage.setItem(
            "name",
            `${data?.firstName} ${data?.lastName ? data?.lastName : ""}`
          );
          localStorage.setItem(
            "profile_image",
            res?.data?.data?.user_profile?.image
          );
          localStorage.setItem("has_ngo", res?.data?.data?.is_has_ngo);
          localStorage.setItem("email", res?.data?.data?.email);
          localStorage.setItem("username", res?.data?.data?.username);
          alert.success("User Registered Successfully!!");
          history.push("/user/profile");
        } else {
          alert.error("Some Error Occurred!");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        alert.error("Some Error Occurred!");
        setIsLoading(false);
      });
  };

  return (
    // <Layout>
    <Paper className={classes.paper}>
      <Typography variant="h3" color="primary" style={{ marginTop: "20px" }}>
        User Registration
      </Typography>
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item md={4}></Grid>
        <Grid item md={4} xs={12} style={{ display: "grid" }}>
          <UserReg
            registerUser={registerUser}
            isLoading={isLoading}
            signupData={signupData}
          />
        </Grid>
        <Grid item md={4}></Grid>
      </Grid>
    </Paper>
    // </Layout>
  );
}
