import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import UserBasicInfo from "../../../Cards/Total/UserBasicInfo";
import BasicInfoDialog from "../../../Dialogs/BasicInfoDialog";
import { getRequest, postRequest } from "../../../../apis/baseApi";
import FileUploader from "../../../FileInput";
import DownloadButton from "../NgoRegTwo/DownloadButton";
import { deleteRequest } from "../../../../apis/baseApi";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderRadius: "20px",
    padding: "25px",
  },
  upload: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginLeft: "20px",
    borderRadius: 8,
    height: 46,
  },
  uploads: {
    // color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginLeft: "20px",
    borderRadius: 8,
    height: 46,
  },

  fieldtext: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "10px",
    display: "flex",
  },
  reqfieldtext: {
    fontSize: "14px",
    textAlign: "left",
  },
  option: {
    fontSize: 13,
    "& > span": {
      marginRight: 10,
      fontSize: 12,
    },
  },
  gridtext: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  person: {
    display: "flex",
    alignItems: "center",
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  textBox: {
    backgroundColor: "#fff",
    "& $div": {
      justifyContent: "center",
      "& $input": {
        // width: "30%",
      },
    },
    "& $p": {
      alignSelf: "center",
    },
  },
}));

export default function RegSecondCard({
  fromProfile,
  edit,
  handleEdit,
  setEdit,
}) {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const initialState = {
    image: "",
    name: "",
    occupation: "",
    email: "",
    qualification: "",
    role: "",
    description: "",
  };

  const [newUser, setNewUser] = useState(initialState);

  const [refreshData, setRefreshData] = useState(false);

  const [members, setMembers] = useState([]);

  const [affiliations, setAffiliations] = useState([]);

  const [awards, setAwards] = useState([]);
  const pathname = window?.location.pathname;

  useEffect(() => {
    if (fromProfile) {
      getKeyMembers();
      getAffiliations();
      getAwards();
    }
  }, [fromProfile]);

  useEffect(() => {
    if (refreshData) {
      getKeyMembers();
      getAffiliations();
      getAwards();
      setRefreshData(false);
    }
  }, [refreshData]);

  const getKeyMembers = () => {
    getRequest(`ngo/key_team_member_list/${localStorage?.getItem("ngo_slug")}/`)
      .then((res) => {
        if (res?.data?.results) {
          setMembers(res?.data?.results);
        } else {
          alert.error("Error in fetch members list!");
        }
      })
      .catch((err) => {
        alert.error("Error in fetch members list!");
      });
  };

  const getAffiliations = () => {
    getRequest(`ngo/membership_list/${localStorage?.getItem("ngo_slug")}/`)
      .then((res) => {
        if (res?.data?.results) {
          setAffiliations(res?.data?.results);
        } else {
          alert.error("Error in fetch Affiliations list!");
        }
      })
      .catch((err) => {
        alert.error("Error in fetch Affiliations list!");
      });
  };

  const getAwards = () => {
    getRequest(
      `ngo/recorgnition_and_awards_list/${localStorage?.getItem("ngo_slug")}/`
    )
      .then((res) => {
        if (res?.data?.results) {
          setAwards(res?.data?.results);
        } else {
          alert.error("Error in fetch members list!");
        }
      })
      .catch((err) => {
        alert.error("Error in fetch members list!");
      });
  };
  useEffect(() => {}, []);

  const handleDelete = (id) => {
    deleteRequest(`ngo/delete_membership/${id}/`).then((res) => {
      if (res?.status === 200) {
        setRefreshData(!refreshData);
        alert.success(res?.data?.msg);
      }
    });
  };
  const handleDelete2 = (id) => {
    deleteRequest(`ngo/delete_recorgnition_and_awards/${id}/`).then((res) => {
      console.log("ghjhgh", res?.data?.msg);
      if (res?.status === 200) {
        setRefreshData(!refreshData);
        alert.success(res?.data?.msg);
      }
    });
  };

  const [newAward, setAward] = useState({
    image: null,
    name: "",
  });

  const [awardRemoveName, setAwardRemoveName] = useState(false);
  const [affiRemoveName, setAffiRemoveName] = useState(false);

  const [newAffiliation, setNewAffiliation] = useState({
    image: null,
    name: "",
  });

  const postAwards = () => {
    if (!newAward?.name) {
      alert.error("Please add name and file both!!");
      return;
    }
    let fd = new FormData();
    fd.set("name", newAward?.name);
    fd.set("image", newAward?.image);
    if (fd.getAll("image")[0] === "null") {
      fd.delete("image");
    }
    postRequest(
      `ngo/recorgnition_and_awards_list/${localStorage?.getItem("ngo_slug")}/`,
      fd
    )
      .then((res) => {
        if (res?.data?.data) {
          alert.success("Recognition and Award added successfully!!");
          setAward({
            image: null,
            name: "",
          });
          setRefreshData(true);
          setAwardRemoveName(true);
        } else {
          // alert.error("Something went wrong!!");
        }
      })
      .catch((err) => {
        // alert.error("Something went wrong!!");
      });
  };

  const postAffiliations = () => {
    if (!newAffiliation?.name) {
      alert.error("Please add name and file both!!");
      return;
    }
    let fd = new FormData();
    fd.set("name", newAffiliation?.name);
    fd.set("image", newAffiliation?.image);
    if (fd.getAll("image")[0] === "null") {
      fd.delete("image");
    }
    postRequest(`ngo/membership_list/${localStorage?.getItem("ngo_slug")}/`, fd)
      .then((res) => {
        if (res?.data?.data) {
          alert.success("Memberships and Affiliations added successfully!!");
          setNewAffiliation({
            image: null,
            name: "",
          });
          setRefreshData(true);
          setAffiRemoveName(true);
        } else {
          // alert.error("Something went wrong!!");
        }
      })
      .catch((err) => {
        // alert.error("Something went wrong!!");
      });
  };

  const handleClose = () => {
    setNewUser(initialState);
    setOpen(false);
  };
  useEffect(() => {
    if (pathname === "/ngo/profile-final") {
      setEdit(true);
    }
  }, []);

  return (
    <>
      {!edit ? (
        <>
          <BasicInfoDialog
            open={open}
            handleClose={handleClose}
            newUser={newUser}
            setNewUser={setNewUser}
            setRefreshData={setRefreshData}
          />
          <Grid container spacing={2}>
            <Grid item md={3} xs={12} style={{ display: "grid" }}>
              <Typography variant="body2">
                List of Board Members/Trustees with communication details and
                brief profile (qualifications, experience,
                profession/occupation)*
              </Typography>
            </Grid>
            <Grid item md={9} style={{ display: "grid" }}>
              <Button
                color="primary"
                variant="contained"
                className={classes.upload}
                onClick={handleClickOpen}
              >
                Add
              </Button>
              <Grid
                container
                spacing={2}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                {members?.map((item) => {
                  return (
                    <Grid item md={6}>
                      <UserBasicInfo data={item} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            {/* <Grid item md={3} xs={12} style={{ display: "grid" }}>
          <Typography variant="body2">
            List of Senior Management Team with communication details and brief
            profile
          </Typography>
        </Grid>
        <Grid item md={9} style={{ display: "grid" }}>
          <Button
            color="primary"
            variant="contained"
            className={classes.upload}
          >
            Add
          </Button>
          <Grid
            container
            spacing={2}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Grid item md={6}>
              <UserBasicInfo />
            </Grid>
            <Grid item md={6}>
              <UserBasicInfo />
            </Grid>
            <Grid item md={6}>
              <UserBasicInfo />
            </Grid>
            <Grid item md={6}>
              <UserBasicInfo />
            </Grid>
          </Grid>
        </Grid> */}
            {/* <Grid item md={3}>
          <Typography variant="body2">
            Details of Other Sources of Funds (List of Donors and Funding
            Details)
          </Typography>
        </Grid>
        <Grid item md={9}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button color="primary" variant="text">
              Download Format
            </Button>

            <Typography variant="body2">+ Add File</Typography>

            <Button
              color="primary"
              variant="contained"
              className={classes.upload}
            >
              Upload
            </Button>
          </div>
        </Grid> */}
            {/* <Grid item md={3} xs={12} style={{ display: "grid" }}>
          <Typography variant="body2">
            List of Offices with addresses and Phone Numbers
          </Typography>
        </Grid>
        <Grid item md={9} style={{ display: "grid" }}>
          <Button
            color="primary"
            variant="contained"
            className={classes.upload}
          >
            Add
          </Button>
          <Grid
            container
            spacing={2}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Grid item md={6}>
              <UserBasicInfo />
            </Grid>
            <Grid item md={6}>
              <UserBasicInfo />
            </Grid>
            <Grid item md={6}>
              <UserBasicInfo />
            </Grid>
            <Grid item md={6}>
              <UserBasicInfo />
            </Grid>
          </Grid>
        </Grid> */}
            <Grid md={12} style={{ marginBottom: "20px" }}>
              <Divider />
            </Grid>
            {affiliations?.map((item) => {
              console.log("ytrtghhgfg", item);
              return (
                <>
                  <Grid md={2}></Grid>
                  <Grid md={4} style={{ display: "grid" }}>
                    {item?.name}
                  </Grid>
                  <Grid
                    md={3}
                    item
                    style={{
                      // display: "contents",
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <DownloadButton link={item?.image} />
                  </Grid>
                  <Grid md={3}>
                    <Button
                      // variant="text"
                      color="primary"
                      variant="contained"
                      className={classes.upload}
                      style={{ color: "#fff" }}
                      onClick={() => {
                        handleDelete(item?.id);
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </>
              );
            })}

            <Grid item md={3}>
              <Typography variant="body2">
                Memberships and Affiliations
              </Typography>
            </Grid>

            <Grid item md={4} style={{ display: "grid" }}>
              <TextField
                className={classes.textBox}
                color="primary"
                variant="outlined"
                placeholder="Name"
                value={newAffiliation?.name}
                onChange={(event) => {
                  setNewAffiliation({
                    ...newAffiliation,
                    name: event.target.value,
                  });
                }}
                required
              />
            </Grid>
            <Grid
              item
              md={5}
              style={{
                // display: "contents",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <FileUploader
                setFile={(file) => {
                  setAffiRemoveName(false);
                  setNewAffiliation({ ...newAffiliation, image: file });
                }}
                removeFileName={affiRemoveName}
                variant={"contained"}
              />
              <Grid item md={2}></Grid>
              <Button
                // variant="text"
                color="primary"
                variant="outlined"
                className={classes.uploads}
                onClick={postAffiliations}
              >
                Upload
              </Button>
            </Grid>
            <Grid md={12} style={{ marginBottom: "20px" }}>
              <Divider />
            </Grid>
            {awards?.map((item) => {
              return (
                <>
                  <Grid md={2}></Grid>
                  <Grid md={4} style={{ display: "grid" }}>
                    {item?.name}
                  </Grid>
                  <Grid
                    md={3}
                    item
                    style={{
                      // display: "contents",
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <DownloadButton link={item?.image} />
                  </Grid>
                  <Grid md={3}>
                    <Button
                      // variant="text"
                      color="primary"
                      variant="contained"
                      className={classes.upload}
                      style={{ color: "#fff" }}
                      onClick={() => {
                        handleDelete2(item?.id);
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </>
              );
            })}

            <Grid item md={3}>
              <Typography variant="body2">Recognitions and Awards</Typography>
            </Grid>
            <Grid item md={4} style={{ display: "grid" }}>
              <TextField
                className={classes.textBox}
                variant="outlined"
                placeholder="Name"
                value={newAward?.name}
                onChange={(event) => {
                  setAward({ ...newAward, name: event.target.value });
                }}
                // rows={5} multiline
              />
            </Grid>
            <Grid
              item
              md={5}
              style={{
                // display: "contents",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <FileUploader
                setFile={(file) => {
                  setAwardRemoveName(false);
                  setAward({ ...newAward, image: file });
                }}
                removeFileName={awardRemoveName}
                variant={"contained"}
              />
              <Grid item md={2}></Grid>
              <Button
                // variant="text"
                color="primary"
                variant="outlined"
                className={classes.uploads}
                onClick={postAwards}
              >
                Upload
              </Button>
            </Grid>

            <Grid item md={12} style={{ display: "grid" }}>
              <Divider />
              {/* <br />
          <Button
            variant="text"
            color="primary"
            style={{ display: "contents" }}
          >
            + Add Another NGO
          </Button>
          <br />
          <Divider /> */}
            </Grid>
            <Grid item md={12}>
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  style={{ display: "flex", alignItems: "flex-start" }}
                  control={<Checkbox name="checkedB" color="primary" />}
                  label={
                    <span style={{ color: "#A6A6A6", textAlign: "left" }}>
                      The information provided in the form is accurate to the
                      best of their knowledge and Ikigai Portfolio will not be
                      responsible for any consequences arising out of ay
                      incorrect information provided by the NGO on this
                      platform. Ikigai Portfolio will also have the right to
                      terminate the registration of the NGO at its sole
                      discretion, if it finds that the information provided by
                      the NGO in the form is incorrect.
                    </span>
                  }
                />
              </div>
            </Grid>
            <Grid item md={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.upload}
                onClick={() => {
                  pathname === "/ngo/profile-final"
                    ? history.push("/ngo/profile-doc")
                    : history.push("/ngo-registration-doc");
                }}
              >
                Back
              </Button>
              {/* {pathname === "/ngo-registration-final" ? (
                ""
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.upload}
                  onClick={handleEdit}
                >
                  EDIT
                </Button>
              )} */}
              <Button
                variant="contained"
                color="primary"
                className={classes.upload}
                onClick={() => {
                  history.push("/ngo/profile");
                }}
              >
                SAVE & SUBMIT
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {" "}
          {/* <BasicInfoDialog
            open={open}
            handleClose={handleClose}
            newUser={newUser}
            setNewUser={setNewUser}
            setRefreshData={setRefreshData}
            edit={edit}
          /> */}
          <Grid container spacing={2}>
            {/* <Grid item md={3} xs={12} style={{ display: "grid" }}>
              <Typography variant="body2">
                List of Board Members/Trustees with communication details and
                brief profile (qualifications, experience,
                profession/occupation)*
              </Typography>
            </Grid>
            <Grid item md={9} style={{ display: "grid" }}>
              <Button
                color="primary"
                variant="contained"
                className={classes.upload}
                onClick={handleClickOpen}
              >
                Add
              </Button>
              <Grid
                container
                spacing={2}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                {members?.map((item) => {
                  return (
                    <Grid item md={6}>
                      <UserBasicInfo data={item} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid> */}

            <Grid md={12} style={{ marginBottom: "20px" }}>
              {/* <Divider /> */}
            </Grid>
            <Grid item md={12}>
              <Typography variant="body2">
                Memberships and Affiliations
              </Typography>
            </Grid>
            {affiliations?.map((item) => {
              return (
                <>
                  <Grid md={3}></Grid>
                  <Grid
                    md={4}
                    style={{ display: "grid", textTransform: "capitalize" }}
                  >
                    {item?.name}
                  </Grid>
                  <Grid
                    md={5}
                    item
                    style={{
                      // display: "contents",
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <DownloadButton link={item?.image} />
                  </Grid>
                </>
              );
            })}

            <Grid md={12} style={{ marginBottom: "20px" }}>
              <Divider />
            </Grid>

            <Grid item md={12}>
              <Typography variant="body2">Recognitions and Awards</Typography>
            </Grid>
            {awards?.map((item) => {
              return (
                <>
                  <Grid md={3}></Grid>
                  <Grid
                    md={4}
                    style={{ display: "grid", textTransform: "capitalize" }}
                  >
                    {item?.name}
                  </Grid>
                  <Grid
                    md={5}
                    item
                    style={{
                      // display: "contents",
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <DownloadButton link={item?.image} />
                  </Grid>
                </>
              );
            })}

            <Grid item md={12} style={{ display: "grid" }}></Grid>

            <Grid item md={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.upload}
                onClick={() => {
                  pathname === "/ngo/profile-final"
                    ? history.push("/ngo/profile-doc")
                    : history.push("/ngo-registration-doc");
                }}
              >
                Back
              </Button>

              {pathname === "ngo-registration-final" ? (
                ""
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.upload}
                  onClick={handleEdit}
                >
                  EDIT
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                className={classes.upload}
                onClick={() => {
                  history.push("/ngo/profile");
                }}
              >
                SAVE & SUBMIT
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
