import React from "react";
import DashboardLayout from "../../../components/Layout/DashBoardLayout";
import RatingGrid from "../../../components/Section/DashBoard/Report/RatingGrid";

export default function NgoNotifications() {
  return (
    <>
      <DashboardLayout>
        Coming Soon
      </DashboardLayout>
    </>
  );
}