import {
  alpha,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { getRequest } from "../../apis/baseApi";
import DonationPerson from "../../components/Cards/DonationPerson/DonationPerson";
import Subscribe from "../../components/Cards/Subscribe/Subscribe";
import Heading from "../../components/Common/Heading/Heading";
import HeroHeadline from "../../components/Section/HomeSection/HeroHeadline";
import HighLight from "../../components/Section/HomeSection/HighLight/HighLight";
import OurNGOPartners from "../../components/Section/HomeSection/OurNGOPartners";
import WhatWeDo from "../../components/Section/HomeSection/WhatWeDo";
import Banner from "../../components/assets/png/banner.png";
import WhatYouCanDo from "../../components/Section/HomeSection/WhatYouCanDo";

const useStyles = makeStyles((theme) => ({
  learn: {
    paddingLeft: "40px",
    paddingRight: "40px",
    color: "#fff",
    height: "45px",
    display: "flex",
    borderRadius: "8px",
    //boxShadow: "0px 4px 40px 0px #C88FEF59",
    boxShadow: "0px 10px 40px 0px #C88FEF73",
  },
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
    marginBottom: "70px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "280px",
      height: "auto",
    },
  },
  banner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
}));

function Home() {
  const classes = useStyles();
  const [heroBanner, setHeroBanner] = useState({});
  const [latestDonation, setLatestDonation] = useState([]);

  useEffect(() => {
    getRequest("home_page/get_hero_banner/")
      .then((res) => {
        if (res?.data?.status) {
          setHeroBanner({
            title: res?.data?.data?.title,
            content: res?.data?.data?.content,
            buttonText: res?.data?.data?.button_text,
            url: res?.data?.data?.url,
            image: res?.data?.data?.image,
          });
        }
      })
      .catch((err) => {});
    getLatestDonations();
  }, []);

  const getLatestDonations = () => {
    getRequest("home_page/get_donar/")
      .then((res) => {
        if (res?.data?.status) {
          setLatestDonation(res?.data?.data);
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      {/* <Layout> */}
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid
            item
            md={7}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className={classes.banner}>
              <Typography
                variant="h1"
                color="primary"
                style={{ fontFamily: "Marcellus", textAlign: "left" }}
              >
                {heroBanner?.title}
              </Typography>
              <br />
              <Typography variant="h6" style={{ textAlign: "left" }}>
                {heroBanner?.content}
              </Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                className={classes.learn}
                onClick={() => {
                  window.open(heroBanner?.url, "_blank");
                }}
              >
                <Typography variant="h5">{heroBanner?.buttonText}</Typography>
              </Button>
            </div>
          </Grid>
          <Grid item md={5}>
            {/* <img
              src={heroBanner?.image}
              height="500px"
              width="500px"
              alt="demo"
            /> */}
            <img
              src={
                "https://ikigaportfolio.s3.amazonaws.com/static_image/15/Home_Hero.gif"
              }
              height="450px"
              width="450px"
              alt="ikigai"
              style={{ borderRadius: "50%" }}
              className={classes.img}
            />
          </Grid>
        </Grid>
      </Paper>
      <HighLight />

      {/* <br /> */}
      <WhatWeDo />
      <WhatYouCanDo />
      <OurNGOPartners />
      <HeroHeadline />
      <Grid container spacing={2}>
        {latestDonation && latestDonation?.length > 0 && (
          <>
            <Grid item md={12}>
              <Heading title="Featured Donors" />
            </Grid>
            <Grid item md={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  {latestDonation.map((item) => {
                    return (
                      <Grid item md={2} sm={4} xs={6}>
                        <DonationPerson data={item} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default Home;
