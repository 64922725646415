import {
  AppBar,
  Avatar,
  Button,
  Container,
  Hidden,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getRequest } from "../../apis/baseApi";
import {
  categoryListDetails,
  cityStateDetails,
  subCategoryListDetails,
} from "../../redux/commonReducer/action";
// import Logo from "../assets/svg/logo.svg";
import Logo from "../assets/svg/logo.svg";
import Search from "../Common/InputField/Search/Search";
import Login from "./Login";
import SideDrawer from "./SideDrawer";
import SubMenu from "./SubMenu";
// import SideDrawer from "./SideDrawer";

const StyledMenu = withStyles({
  paper: {
    border: ".5px solid #d3d4d5",
    borderRadius: "10px",
  },
})((props) => (
  <Menu
    style={{ top: "14px" }}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "white",
    // paddingTop: 20,
    //paddingBottom: 20,
    [theme.breakpoints.down("md")]: {
      borderBottom: "1px solid #e2e6ea",
    },
  },
  navbar: {
    backgroundColor: "white",
    BorderBottom: " 4px solid black",
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  navtab: {
    float: "right",
    paddingRight: "0px",
    padding: "10px",
    marginLeft: "-29px",
    display: "flex",
    alignItems: "baseline",
  },
  navicon: {
    marginRight: "5px",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingLeft: "15px",
    paddingRight: "15px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  menuButton: {
    padding: "0px",
    // justifyContent: "flex-start",
    display: "flex",
    justifyContent: "center",
  },
  sectionDesktop: {
    //display: "none",
    display: "flex",
    alignItems: "center",
  },
  btn: {
    "&:hover": {
      color: "#007bff",
    },
    padding: "10px",
  },
  btn_active: {
    color: "#007bff",
    padding: "10px",
    fontWeight: "600",
  },
  tab: {
    borderRadius: "30px",
  },
  tab_active: {
    borderRadius: "30px",
    // backgroundColor: "#e8f3ff",
  },
  img: {
    borderRadius: "50%",
    marginLeft: "-5px",
  },
  navlink: {
    paddingRight: "30px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  login: {
    paddingLeft: "60px",
    paddingRight: "60px",
    padding: "10px",
    marginLeft: "20px",
    borderRadius: "8px",
    boxShadow: "none",
  },
  become: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "8px",
    paddingBottom: "8px",
    color: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 10px 40px 0px #C88FEF73",
    marginLeft: "20px",
    minWidth: "175px",
  },
  becomeLoggedIn: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "8px",
    paddingBottom: "8px",
    color: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 10px 40px 0px #C88FEF73",
    marginLeft: "20px",
    minWidth: "175px",
    marginRight: "18px",
  },
  firstAppbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  secondAppbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  secondAppbarmobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

function Navbar() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  // const router = useRouter();

  useEffect(() => {
    getRequest("address/citi_state_list")
      .then((res) => {
        if (res?.data?.data) dispatch(cityStateDetails(res?.data?.data));
      })
      .catch((err) => {});
    getCategoryData();
    getSubCategoryData();
  }, []);

  function getCategoryData() {
    getRequest("ngo/ngo_categories_list/")
      .then((res) => {
        if (res?.data?.data) dispatch(categoryListDetails(res?.data?.data));
      })
      .catch((err) => {});
  }

  function getSubCategoryData() {
    getRequest("ngo/ngo_sub_category_list/")
      .then((res) => {
        if (res?.data?.data) dispatch(subCategoryListDetails(res?.data?.data));
      })
      .catch((err) => {});
  }

  const navLinks = [
    { title: `Home`, path: `/` },
    { title: `About Us`, path: `/about-us` },
    { title: `Our NGO Partners`, path: `/our-ngo-partner` },
    { title: `Resources`, path: `/` },
    { title: `Get Involved`, path: `/` },
    { title: `Contact Us`, path: `/` },
    { title: `Pricing`, path: `/` },
  ];

  const loggedIn = localStorage.getItem("user_token") ? false : true;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Hidden smDown>
        <AppBar position="relative" elevation={0} className={classes.appbar}>
          <Toolbar className={classes.navbar}>
            <Container
              maxWidth="xl"
              component="main"
              className={classes.header}
              disableGutters
            >
              <div className={classes.firstAppbar}>
                <Hidden smDown>
                  <a href="/">
                    <Button
                      edge="start"
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="menu"
                      style={{ flexGox: 1 }}
                    >
                      <img src={Logo} alt="logo" width="180px" height="100px" />
                    </Button>
                  </a>

                  <div className={classes.sectionDesktop}>
                    <Search />
                    {/* <Button
                    variant="outlined"
                    color="primary"
                    className={classes.login}
                    onClick = {() => {
                      history.push("user-login")
                    }}
                  >
                    Login
                  </Button> */}
                    {loggedIn ? (
                      <>
                        <Login />
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.become}
                          onClick={() => history.push("/donate-now")}
                        >
                          Donate Now
                        </Button>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {localStorage.getItem("profile_image") ? (
                          <Avatar
                            alt={
                              localStorage?.getItem("ngo_name")
                                ? localStorage?.getItem("ngo_name")
                                : localStorage?.getItem("username")
                            }
                            className={classes.large}
                            src={localStorage?.getItem("profile_image")}
                          ></Avatar>
                        ) : (
                          <Avatar
                            alt={
                              localStorage?.getItem("ngo_name")
                                ? localStorage?.getItem("ngo_name")
                                : localStorage?.getItem("username")
                            }
                            className={classes?.large}
                          ></Avatar>
                        )}
                      </div>
                    )}
                  </div>
                </Hidden>
                <Hidden mdUp>{/* <SideDrawer navLinks={navLinks} /> */}</Hidden>
              </div>
            </Container>
          </Toolbar>
        </AppBar>
      </Hidden>
      <AppBar position="sticky" elevation={0} className={classes.appbar}>
        <Toolbar className={classes.navbar}>
          <Container
            maxWidth="xl"
            component="main"
            className={classes.header}
            disableGutters
          >
            <div className={classes.secondAppbar}>
              <Hidden smDown>
                <div className={classes.navtab}>
                  {sidebar_data &&
                    sidebar_data.map((item, index) => {
                      return <SubMenu items={item} key={index} />;
                    })}
                </div>

                <div className={classes.sectionDesktop}>
                  {loggedIn ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.become}
                        onClick={() =>
                          history.push("/user-registration-social")
                        }
                      >
                        Become a Member
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.become}
                        onClick={() => history.push("/ngo-registration-1")}
                        width="50px"
                      >
                        NGO Sign Up
                      </Button>
                    </>
                  ) : (
                    <>
                      {localStorage.getItem("user_type") === "DONOR" && (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.becomeLoggedIn}
                          onClick={() => history.push("/donate-now")}
                        >
                          Donate Now
                        </Button>
                      )}
                      <Button
                        onClick={handleClick}
                        variant="outlined"
                        color="primary"
                        style={{
                          paddingLeft: "30px",
                          paddingRight: "30px",
                          padding: "10px",
                          // marginLeft: "20px",
                          borderRadius: "8px",
                          boxShadow: "none",
                          minWidth: "175px",
                          borderColor: "primary",
                        }}
                        endIcon={<ExpandMoreIcon />}
                      >
                        {localStorage.getItem("user_type") === "DONOR"
                          ? localStorage.getItem("name")
                          : localStorage.getItem("ngo_slug")
                          ? localStorage.getItem("ngo_slug")
                          : localStorage.getItem("name")}
                      </Button>
                      <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <StyledMenuItem
                          onClick={() => {
                            history.push(
                              localStorage.getItem("user_type") === "DONOR"
                                ? "/user/profile"
                                : "/ngo/profile"
                            );
                            handleClose();
                          }}
                        >
                          <ListItemText primary="Dashboard" />
                        </StyledMenuItem>
                        <StyledMenuItem
                          onClick={() => {
                            localStorage.clear();
                            history.push("/home");
                          }}
                        >
                          <ListItemText primary="LogOut" />
                        </StyledMenuItem>{" "}
                      </StyledMenu>
                    </>
                  )}
                </div>
              </Hidden>
            </div>
            <div className={classes.secondAppbarmobile}>
              <Hidden mdUp>
                <SideDrawer navLinks={sidebar_data} />
              </Hidden>
            </div>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Navbar;

const navLinks = [
  { title: `Home`, path: `/` },
  { title: `About Us`, path: `/about-us` },
  { title: `Our NGO Partners`, path: `/our-ngo-partner` },
  { title: `Resources`, path: `/` },
  { title: `Get Involved`, path: `/` },
  { title: `Contact Us`, path: `/` },
  { title: `Pricing`, path: `/` },
];

const sidebar_data = [
  {
    title: "Home",
    path: "/",
    cName: "nav-text",
    sNum: "1",
  },

  {
    title: "About Us",
    cName: "nav-text",
    sNum: "9",
    subNav: [
      {
        title: "Our Philosophy",
        path: "/about-us",
      },
      {
        title: "What We Do",
        path: "/about-us-what",
      },
      {
        title: "Our Team",
        path: "/about-us-team",
      },
      {
        title: "Causes We Support",
        path: "/about-us-support",
      },
    ],
  },

  {
    title: "Our NGO Partners",
    path: "/our-ngo-partner",
    cName: "nav-text",
    sNum: "4",
  },
  {
    title: "Resources",
    cName: "nav-text",
    sNum: "5",
    subNav: [
      {
        title: "NGO Rating",
        path: "/ngo-rating",
      },
      {
        title: "Impact Measurement",
        path: "/ngo-impact",
      },
      {
        title: "Key Data Points",
        path: "/ngo-points",
      },
      {
        title: "Case Studies",
        path: "/case-studies",
      },
      {
        title: "Financials",
        path: "/financials",
      },
      {
        title: "Knowledge",
        path: "/knowledge",
      },
      {
        title: "FAQs",
        path: "/faqs",
      },
    ],
  },
  {
    title: "Get Involved",
    cName: "nav-text",
    sNum: "6",
    subNav: [
      {
        title: "Become a Member",
        path: "/become-a-member",
      },
      {
        title: "Donate Now",
        path: "/donate-now",
      },
      {
        title: "Jobs",
        path: "/jobs",
      },
      {
        title: "Volunteer",
        path: "/volunteer",
      },
      {
        title: "Refer An NGO",
        path: "/refer-an-ngo",
      },
    ],
  },
  {
    title: "Contact Us",
    path: "/contact",
    cName: "nav-text",
    sNum: "7",
  },
  {
    title: "Product Offerings",
    path: "/product-offerings",
    cName: "nav-text",
    sNum: "8",
  },
];
