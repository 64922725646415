import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import MobileUserDrawer from "./MobileUserDrawer";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    // backgroundColor: "transparent",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
    backgroundColor: "#fff",
  },
}));

export default function BottomAppBar({ children }) {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      {children}
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <Fab aria-label="add" className={classes.fabButton}>
            <MobileUserDrawer />
          </Fab>

          <div className={classes.grow} />
        </Toolbar>
      </AppBar>
    </>
  );
}
