import React, { useState } from "react";
import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  ListItem,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  btn: {
    boxShadow: "none",
    borderRadius: "10px",
    height: "45px",
    color: "#000",
    display: "flex",
    border: "none",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    width: "200px",
    border: "1px solid #626262",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
    },
  },
  root: {
    width: "100%",
    height: 400,
    width: 200,

    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledMenu = withStyles((theme) => ({
  paper: {
    height: "115px",
    width: "200px",
    border: "1px solid #d3d4d5",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
    },
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // height: "300px",
    "&:focus": {
      //   backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        ///  color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function Sort({ appliedFilter, setAppliedFilter }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="outlined"
          className={classes.btn}
          onClick={handleClick}
        >
          <Typography variant="body2" noWrap>
            Sort
          </Typography>
          <ExpandMoreIcon />
        </Button>

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem
            onClick={() => {
              setAppliedFilter({ ...appliedFilter, sort: "a" });
              handleClose();
            }}
          >
            <Typography variant="body2" color="inherit" noWrap>
              A-Z
            </Typography>
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              setAppliedFilter({ ...appliedFilter, sort: "z" });
              handleClose();
            }}
          >
            <Typography variant="body2" color="inherit" noWrap>
              Z-A
            </Typography>
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              setAppliedFilter({ ...appliedFilter, sort: "r" });
              handleClose();
            }}
          >
            <Typography variant="body2" color="inherit" noWrap>
              Recommended
            </Typography>
          </StyledMenuItem>
        </StyledMenu>
      </div>
    </>
  );
}
