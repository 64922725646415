import { Button, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
    btn: {
      color: "#fff",
      paddingLeft: "60px",
      paddingRight: "60px",
      boxShadow: "none",
      marginTop: "50px",
      marginBottom: "50px",
      borderRadius: 8,
      height: 46,
    },
    upload: {
      color: "#fff",
      paddingLeft: "60px",
      paddingRight: "60px",
      boxShadow: "none",
        width: "50%",
      borderRadius: 8,
      height: 46,
    },
  
    fieldtext: {
      fontSize: "12px",
      textAlign: "left",
      marginBottom: "10px",
      display: "flex",
    },
    reqfieldtext: {
      fontSize: "14px",
      textAlign: "left",
    },
    option: {
      fontSize: 13,
      "& > span": {
        marginRight: 10,
        fontSize: 12,
      },
    },
    gridtext: {
      display: "flex",
    //   flexDirection: "row",  
    //   justifyContent: "right",
    //   alignItems: "center",
    },
  }));
  
function FileUploaderColumn({setFile = () => {}, removeFileName=false}) {
    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);
    const [fileName, setFileName] = useState("");
    const classes = useStyles();
    
    
    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = event => {
      hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleChange = event => {
      const fileUploaded = event.target.files[0];
      setFileName(fileUploaded?.name);
      setFile(fileUploaded);
    };
    return (
        <Grid item md={2} xs={12} className={classes.gridtext}>
         {/* <div onClick={() => {handleClick()}} style={{cursor: "pointer"}}> */}
         <div style={{display: "flex", flexDirection: "column"}}>
          <Button
            variant="contained"
            color="primary"
            className={classes.upload}
            onClick={() => {handleClick()}}
          >
            {" "}
            Upload
         
          
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{display: 'none'}} 
          accept="image/*"
        />
         </Button>
         <Typography variant="body2">{removeFileName ? "" : fileName}</Typography>
         </div>
      {/* </div> */}
      </Grid>
    );
}
  export default FileUploaderColumn;