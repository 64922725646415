import { Grid, Hidden } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import UserDashboardSidebar from "../Navbar/UserDashboardSidebar";
import { getRequest } from "../../apis/baseApi";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import BottomAppBar from "../Navbar/BottomAppbar";

export default function UserDashboardLayout({ children }) {
  const [profileData, setProfileData] = useState({});
  const slug = localStorage.getItem("slug");
  const alert = useAlert();
  const history = useHistory();
  useEffect(() => {
    if (slug) {
      getRequest(`authentication/user_profile/${slug}/`)
        .then((res) => {
          if (res?.data?.data) {
            setProfileData(res.data.data);
          } else {
            // alert.error("Something went wrong");
          }
        })
        .catch((err) => {
          // alert.error("Something went wrong");
        });
    } else {
      // alert.error("Something went wrong. Please login again!!");
      localStorage.clear();
      history.push("/home");
    }
  }, []);
  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        <Hidden only={["xs", "sm"]}>
          <Grid item md={2} xs={12}>
            <UserDashboardSidebar />
          </Grid>
        </Hidden>
        <Grid item md={10} xs={12}>
          {children}
        </Grid>
        <Hidden only={["md", "lg", "xl"]}>
          <BottomAppBar />
        </Hidden>
      </Grid>
    </>
  );
}
