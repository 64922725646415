import {
  alpha,
  Button,
  Chip,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PiaChart from "../../../Common/Graph/PieChart";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    padding: "25px",
    borderColor: theme.palette.primary.main,
    padding: "7px",
    borderRadius: "15px",
  },
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    color: "#fff",
    marginTop: "50px",
  },
}));

export default function PiaPortfolio() {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <div style={{ padding: "20px" }}>
              <Typography variant="h5">Amount Donated</Typography>
              <br />
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography variant="body2" style={{ fontWeight: "700" }}>
                    NGO
                  </Typography>
                  <PiaChart />
                </Grid>
                {/* <Grid item md={4}>
                  <Typography variant="body2" style={{ fontWeight: "700" }}>
                    Cause
                  </Typography>
                  <PiaChart />
                </Grid>
                <Grid item md={4}>
                  <Typography variant="body2" style={{ fontWeight: "700" }}>
                    Mode
                  </Typography>
                  <PiaChart />
                </Grid>
                <Grid item md={4}>
                  <Typography variant="body2" style={{ fontWeight: "700" }}>
                    Utilised vs Unutilised
                  </Typography>
                  <PiaChart />
                </Grid>
                <Grid item md={4}>
                  <Typography variant="body2" style={{ fontWeight: "700" }}>
                    Agriculture and Farmers Sub-cause
                  </Typography>
                  <PiaChart />
                </Grid>
                <Grid item md={4}>
                  <Typography variant="body2" style={{ fontWeight: "700" }}>
                    Water and Sanitation Sub-cause
                  </Typography>
                  <PiaChart />
                </Grid> */}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
