import React from "react";
import UserDashboardLayout from "../../components/Layout/UserDashboardLayout";
import NgoProfileSection from "../../components/Section/DashBoard/Profile/NgoProfileSection";

export default function UserNotifications() {
  return (
    <>
      <UserDashboardLayout>
        {/* <NgoProfileSection /> */}
        Coming Soon!!
      </UserDashboardLayout>
    </>
  );
}
