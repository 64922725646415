import React from "react";
import { Grid, Typography } from "@material-ui/core";
import vactor from "../../assets/png/team-work/ngo.jpg";
import team from "../../assets/png/team-work/team.png";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  img: {
    [theme.breakpoints.only("sm")]: {
      width: "auto",
      height: "200px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "auto",
      height: "100px",
    },
  },
}));

function Philosophy() {
  const classes = useStyles();
  return (
    <>
      <div>
        <Typography
          variant="h2"
          component="h1"
          color="primary"
          style={{ fontFamily: "Marcellus", textAlign: "center" }}
        >
          Our Philosophy
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          At Ikigai Portfolio, we strongly believe that as responsible citizens, it is our duty to give back to society a part of what we gain from it. While we do it for those less privileged than us, for a more sustainable environment and for nature, in the long run, we and our future generations benefit from preserving and living in a better world. 
To achieve sustainable positive social change, it is important that we move beyond emotional responses and impulsive one-off donations without considering the outcomes of our donations. We need to treat our philanthropy contributions just like we do our financial investments, with a portfolio approach where:
        </Typography>
        <div style={{marginLeft: "50px"}}>
        <ul style={{ paddingInlineStart: "15px" }}>
          <li>
            <Typography variant="body2">
            we ‘invest’ in the causes we are passionate about through the right not for profits that are credible, efficient and have the right intent 
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
            we monitor the usage and impact of our contribution; and

            </Typography>
          </li>
          <li>
            <Typography variant="body2">
            We aim to maximise the returns on our contributions by way of deep sustainable impact
            </Typography>
          </li>
        </ul>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <img
                  src={"https://ikigaportfolio.s3.amazonaws.com/static_image/12/Home2.png"}
                  style={{
                    width: "100% ",
                    height: "100%",
                    borderRadius: "20px",
                  }}
                  alt="demo"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item md={7}>
                {" "}
                <img src={"https://ikigaportfolio.s3.amazonaws.com/static_image/14/Home5.png"} style={{ width: "100% " }} alt="demo" />
              </Grid>
              <Grid item md={5}>
                {" "}
                <img src={"https://ikigaportfolio.s3.amazonaws.com/static_image/13/Home3.png"} style={{ width: "100% " }} alt="demo" />
              </Grid>
              <Grid item md={12}>
                {" "}
                <img src={"https://ikigaportfolio.s3.amazonaws.com/static_image/7/What_We_Do_NEW-6.png"} style={{ width: "100% " }} alt="demo" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Philosophy;
