import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { getRequest } from "../../../../apis/baseApi";
import ReportCard from "../../../Cards/DashBoadCards/ReportCard/ReportCard";
import Table from "../../../Common/Table/Table";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
  },
  navlink: {
    color: "#94939F",
    marginBottom: "20px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  download_main_grid: {
    display: "flex",
    alignItems: "center",
  },
  download: {
    boxShadow: "none",
    borderRadius: "8px",
    color: "#fff",
    height: "40px",
    display: "flex",
  },
  approve_main: {
    border: "2px solid #1DDD48",
    borderRadius: "30px",
    height: "30px",
    display: "flex",
    padding: "2px",
    alignItems: "center",
    width: "100%",
  },
  approve: {
    width: "100%",
    borderRadius: "30px",
    boxShadow: "none",
    padding: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "#1DDD48",
    "&:hover": {
      backgroundColor: "rgb(7, 177, 77, 0.42)",
      boxShadow: "none",
    },
  },

  reject_main: {
    border: "2px solid #FF3737",
    borderRadius: "30px",
    height: "30px",
    display: "flex",
    padding: "2px",
    alignItems: "center",
    width: "100%",
  },
  reject: {
    width: "100%",
    borderRadius: "30px",
    boxShadow: "none",
    padding: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "#FF3737",
    "&:hover": {
      backgroundColor: "rgb(7, 177, 77, 0.42)",
      boxShadow: "none",
    },
  },
}));

export default function PendingActivityGrid() {
  const classes = useStyles();
  const [totalActivity, setTotalActivity] = useState();
  const [data, setData] = useState([]);
  const alert = useAlert();

  const columns = [
    {
      field: "sNo",
      headerName: "S. No",
      type: "text",
      // width: 100,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "20px" }}>S. No</strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
            color: "#94939F",
          }}
        >
          {params.row.sNo}
        </span>
      ),
    },
    {
      field: "donationId",
      type: "text",
      headerName: "Donation Id",
      width: 200,
      // renderHeader: (params) => (
      //   <strong style={{ color: "#5a6268", marginLeft: "120px" }}>
      //     Date
      //   </strong>
      // ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "left",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
            color: "#94939F",
          }}
        >
          {params.row.donationId}
        </span>
      ),
    },
    {
      field: "donationReceipt",
      type: "text",
      sortable: false,
      headerName: "Donation Receipt",
      width: 200,
      // renderHeader: (params) => (
      //   <strong style={{ color: "#5a6268", marginLeft: "150px" }}>NGO Utilisation Report</strong>
      // ),
      renderCell: (params) => (
        <div
          className={
            !params.row.donationReceipt
              ? classes.reject_main
              : classes.approve_main
          }
        >
          <Button
            variant="contained"
            className={
              !params.row.donationReceipt ? classes.reject : classes.approve
            }
          >
            <Typography
              style={{
                color: "#fff",
                fontSize: "12px",
              }}
            >
              {params.row.donationReceipt ? "Uploaded" : "Pending"}
            </Typography>
          </Button>
        </div>
      ),
    },
    {
      field: "taxExemptionCertificate",
      type: "text",
      sortable: false,
      headerName: "Tax Excemption Certificate",
      width: 250,
      // renderHeader: (params) => (
      //   <strong style={{ color: "#5a6268", marginLeft: "120px" }}>Actual Amount Received</strong>
      // ),
      renderCell: (params) => (
        <div
          className={
            !params.row.taxExemptionCertificate
              ? classes.reject_main
              : classes.approve_main
          }
        >
          <Button
            variant="contained"
            className={
              !params.row.taxExemptionCertificate
                ? classes.reject
                : classes.approve
            }
          >
            <Typography
              style={{
                color: "#fff",
                fontSize: "12px",
              }}
            >
              {params.row.taxExemptionCertificate ? "Uploaded" : "Pending"}
            </Typography>
          </Button>
        </div>
      ),
    },
    {
      field: "utilisationReport",
      type: "text",
      sortable: false,
      headerName: "Utilisation Report",
      width: 200,
      // renderHeader: (params) => (
      //   <strong style={{ color: "#5a6268", marginLeft: "120px" }}>Acheivements</strong>
      // ),
      renderCell: (params) => (
        <div
          className={
            !params.row.utilisationReport
              ? classes.reject_main
              : classes.approve_main
          }
        >
          <Button
            variant="contained"
            className={
              !params.row.utilisationReport ? classes.reject : classes.approve
            }
          >
            <Typography
              style={{
                color: "#fff",
                fontSize: "12px",
              }}
            >
              {params.row.utilisationReport ? "Uploaded" : "Pending"}
            </Typography>
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getRequest(
      `dashboard/ngo/dashboard/get_pending_activity/anti-alchohol-ban/`
    )
      .then((res) => {
        if (res?.data?.results?.data) {
          let tempData = [];
          let count = 0;
          res?.data?.results?.data.forEach((item) => {
            tempData.push({
              id: count + 1,
              sNo: count + 1,
              donationId: item?.unique_id,
              donationReceipt: item?.is_has_reciept,
              taxExemptionCertificate: item?.is_has_tax_excemption,
              utilisationReport: item?.is_has_utilization_report,
            });
            count = count + 1;
          });
          setTotalActivity(res?.data?.results?.pendig_activity);
          setData(tempData);
        } else {
          // alert.error("Something went wrong");
        }
      })
      .catch((err) => {
        // alert.error("Something went wrong");
      });
  }, []);

  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          style={{ padding: "20px", paddingTop: "0px" }}
        >
          <Grid item md={4}>
            <div style={{ height: "130px", display: "grid" }}>
              <ReportCard title={"Pending Activities"} num={totalActivity} />
            </div>
          </Grid>

          <Grid md={12} style={{ marginBottom: "20px" }}>
            <Divider />
          </Grid>
          {/* <Paper className={classes.paper}> */}
          <Grid
            container
            spacing={2}
            style={{ padding: "20px", paddingTop: "0px" }}
          >
            <Grid item md={12} style={{ marginTop: "20px" }}>
              <Table columns={columns} list={data} />
            </Grid>
          </Grid>
          {/* </Paper> */}
        </Grid>
      </Paper>
    </>
  );
}
