import {
  alpha, makeStyles, Typography
} from "@material-ui/core";
import vactor from "../../components/assets/png/resource.png";
import LikeDonate from "../../components/Section/DonateNowsection/LikeDonate";
import SelectNgo from "../../components/Section/DonateNowsection/SelectNgo";
import RatingBanner from "../../components/Section/NgoRatingsection/RatingBanner";
import { useState } from "react";
import PublicLayout from "../../components/Layout/PublicLayout";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 40,
    borderRadius: 22,
    marginBottom: "70px",
    marginTop: "3%",
  },
  btn: {
    color: "#fff",
    boxShadow: "none",
    height: "60px",
    borderRadius: "8px",
  },
}));

export default function DonateNow() {
  const classes = useStyles();
  const [recommendMe, setRecommendMe] = useState(false);
  return (
    <PublicLayout keyss={"involved"}>
    <>
      {/* {data.map((item) => (
        <RatingBanner
          title={item.heading}
          info={item.info}
          image={item.image}
        />
      ))} */}
      <div>
        <Typography
          variant="h2"
          component="h1"
          color="primary"
          style={{ fontFamily: "Marcellus", textAlign: "center" }}
        >
          Donate Now
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Welcome! We are glad to see that you are here and are considering making philanthropy a part of your life. We are committed to support you through this process and make this decision worthwhile for you. We understand that you are donating money that you have worked hard to earn, and we highly appreciate this noble thought. 
We will try our best to ensure that you have enough choice and flexibility in:
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
      <Typography
          variant="body2"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <ul>
            <li>
            Choosing the cause and subcause that you are passionate about
            </li>
            <li>
            Choosing the right not for profit to donate to based on a rigorous rating score and multiple variables to filter and shortlist the right not for profits
            </li>
            <li>
            Choice to donate monthly or in lumpsum
            </li>
            <li>
            Choice to donate logged in or anonymously
            </li>
          </ul>
          Our engagement does not end after you have donated the money. This is what makes us different from other platforms. Once you have donated, we ensure the following;
          <ul>
            <li>
            That you receive proper receipts and tax exemption certificates (available for download from member dashboards)

            </li>
            <li>
            You receive periodic updates on how your donation was spent and the impact it created/is creating
            </li>
            <li>
            Timely red flags, if any, in the NGOs you donated to

            </li>
            <li>
            Regular due diligence of the NGOs you donated to through periodic self-reporting by NGO, third party M&E, audits and impact assessments

            </li>
            <li>
            Regular news, updates and analyses on the NGOs and causes you have donated to or are interested in

            </li>
          </ul>
        </Typography>
        <img src={vactor} height="400px" className={classes.img} />
      </div>


      <div>
        <Typography
          variant="h2"
          component="h1"
          color="primary"
          style={{ fontFamily: "Marcellus", textAlign: "center" }}
        >
          Charges
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          When you make a donation from the Ikigai Portfolio platform, following are the associated charges:
        </Typography>

        <ul>
          <li>
          <Typography
          variant="h5"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
        Ikigai Portfolio Management Charges (IPMC) - 
        <Typography
          variant="body2"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
        This is a nominal charge to manage your philanthropy portfolio and to cover the costs of reporting, due diligence and other services provided to you to make your donating process simple, convenient, transparent, credible and effective. This amount is dependent on the amount of donation made and whether it is a one-time or lumpsum donation. For more information, please see the Product Offerings page on our website http://www.ikigaiportfolio.com/product-offerings 
        </Typography>
        </Typography>
        </li>
        <li>
        <Typography
          variant="h5"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
Payment Gateway Charges (PG Charges) - 
<Typography
          variant="body2"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
These are the charges taken by the Payment Gateway provider (RazorPay) to provide their Payment Gateway Platform to ensure smooth and secure online payments. These are generally a nominal percentage of the donation amount plus IPMC charges, depending upon the mode of online payment chosen like UPI, Internet Banking, Credit or Debit Cards etc.
</Typography>
        </Typography>
</li>
<li>
<Typography
          variant="h5"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
GST as applicable on IPMC and PG Charges - 
<Typography
          variant="body2"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
These are mandatory taxes levied by the government and need to be paid on all payments, except on the donation amount. Hence while the donation amount is exempt from GST, GST needs to be paid at the prevailing applicable rate on the IPMC and PG Charges.
</Typography>
        </Typography>
</li>
        </ul>
        <Typography
          variant="body2"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Therefore, total amount to be charged to donors - Donation Amount + (IPMC + applicable GST*) + (PG Charges + applicable GST)
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          *Please note that for a brief period of time, till Ikigai Portfolio Pvt. Ltd.’s revenues are below the mandatory threshold level of GST registration, no GST is being charged on IPMC amount. Once revenues cross the threshold and Ikigai Portfolio Pvt. Ltd. is registered under GST, IPMC will also attract GST at applicable rates. 

        </Typography>

      </div>




      <LikeDonate setRecommendMe={setRecommendMe} />
      <SelectNgo recommendMe={recommendMe} />
    </>
    </PublicLayout>
  );
}

const data = [
  {
    heading: "Donate Now",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum, ullamcorper sed dui varius amet tempus feugiat dignissim euismod. Leo in tristique feugiat faucibus arcu tempor. Vestibulum, congue vitae eleifend nec maecenas tristique bibendum egestas fermentum. ",
    image: vactor,
  },
];
