import { Paper, Typography, Grid } from "@material-ui/core";
import React from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
    paddingBottom: "50px",
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
}));

export default function NewsAndUpdates({news}){
    const classes = useStyles();
    return (
        <>
        <div style={{marginBottom: "15px"}}>
        <Typography
          variant="h5"
          color="primary"
          style={{ marginTop: "20px", textAlign: "left" }}
        >Never miss out on Important news and updates regarding your NGO or cause
        </Typography>
        </div>
        <Paper className={classes.paper}>
            {news?.results?.length > 0 ? news?.results?.map(item => {
                return (
                <Grid md={12} style={{display: "flex"}}>
                    <Grid md={9}>
                        <Typography>
                            {item?.name}
                        </Typography>
                    </Grid>
                    <Grid md={3}>
                        <Typography>
                        {moment(item?.created_at).format("YYYY-MM-DD HH:MM")}
                        </Typography>
                    </Grid>
                </Grid>
                )
            }) : <Typography>
                No News and Updates
            </Typography>}
        {/* <Typography
          variant="h2"
          color="primary"
          style={{ marginTop: "20px", textAlign: "left" }}
        >Coming Soon
        </Typography> */}
        </Paper>
        </>
    )
}