import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FileUploader from "../../../FileInput";
import DownloadButton from "./DownloadButton";
import DownloadIcon from "@mui/icons-material/Download";

const useStyles = makeStyles((theme) => ({
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  upload: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    borderRadius: 8,
    height: 46,
  },

  fieldtext: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "10px",
    display: "flex",
  },
  reqfieldtext: {
    fontSize: "14px",
    textAlign: "left",
  },
  option: {
    fontSize: 13,
    "& > span": {
      marginRight: 10,
      fontSize: 12,
    },
  },
  gridtext: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

export default function NgoRegTwo({
  docs,
  setDocs,
  fromProfile,
  edit,
  handleEdit,
}) {
  const classes = useStyles();

  return (
    <>
      {edit ? (
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Typography
              variant="body2"
              style={{
                color: "#A6A6A6",
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              Mandatory Documents to be Uploaded
            </Typography>
          </Grid>

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Registration/Incorporation Certificate
            </Typography>
          </Grid>
          {typeof docs?.registrationNo === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, registrationNo: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, registrationNo: file });
              }}
            />
          ) : docs?.registrationNo ? (
            <DownloadButton link={docs?.registrationNo} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, registrationNo: file });
              }}
            />
          )}

          <Grid item md={3} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>PAN Card</Typography>
          </Grid>
          {typeof docs?.panCardCert === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, panCardCert: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, panCardCert: file });
              }}
            />
          ) : docs?.panCardCert ? (
            <DownloadButton link={docs?.panCardCert} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, panCardCert: file });
              }}
            />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              12A Certificate
            </Typography>
          </Grid>
          {typeof docs?.certificate12a === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, certificate12a: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, certificate12a: file });
              }}
            />
          ) : docs?.certificate12a ? (
            <DownloadButton link={docs?.certificate12a} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, certificate12a: file });
              }}
            />
          )}

          <Grid item md={3} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              80 G Certificate
            </Typography>
          </Grid>
          {typeof docs?.certificate80G === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, certificate80G: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, certificate80G: file });
              }}
            />
          ) : docs?.certificate80G ? (
            <DownloadButton link={docs?.certificate80G} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, certificate80G: file });
              }}
            />
          )}
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              GST Registration
            </Typography>
          </Grid>
          {typeof docs?.gstRegistrationNo === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, gstRegistrationNo: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, gstRegistrationNo: file });
              }}
            />
          ) : docs?.gstRegistrationNo ? (
            <DownloadButton link={docs?.gstRegistrationNo} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, gstRegistrationNo: file });
              }}
            />
          )}
          <Grid item md={3} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              FCRA Certificate
            </Typography>
          </Grid>
          {typeof docs?.certificateFcra === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, certificateFcra: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, certificateFcra: file });
              }}
            />
          ) : docs?.certificateFcra ? (
            <DownloadButton link={docs?.certificateFcra} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, certificateFcra: file });
              }}
            />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Cancelled Cheque of account for receiving domestic donations
            </Typography>
          </Grid>
          {typeof docs?.cancelledChequeDom === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, cancelledChequeDom: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, cancelledChequeDom: file });
              }}
            />
          ) : docs?.cancelledChequeDom ? (
            <DownloadButton link={docs?.cancelledChequeDom} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, cancelledChequeDom: file });
              }}
            />
          )}

          <Grid item md={3} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Cancelled Cheque of account for receiving international donations
            </Typography>
          </Grid>
          {typeof docs?.cancelledChequeIntl === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, cancelledChequeIntl: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, cancelledChequeIntl: file });
              }}
            />
          ) : docs?.cancelledChequeIntl ? (
            <DownloadButton link={docs?.cancelledChequeIntl} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, cancelledChequeIntl: file });
              }}
            />
          )}
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Audited Financial Statements (Y1, Y2, Y3)
            </Typography>
          </Grid>
          {typeof docs?.auditedFinancial === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, auditedFinancial: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, auditedFinancial: file });
              }}
            />
          ) : docs?.auditedFinancial ? (
            <DownloadButton link={docs?.auditedFinancial} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, auditedFinancial: file });
              }}
            />
          )}
          <Grid item md={1} xs={12}></Grid>
          {typeof docs?.auditedFinancial2 === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, auditedFinancial2: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}
          <Grid item md={1} xs={12}>
            {!fromProfile ? (
              <FileUploader
                setFile={(file) => {
                  setDocs({ ...docs, auditedFinancial2: file });
                }}
              />
            ) : docs?.auditedFinancial2 ? (
              <DownloadButton link={docs?.auditedFinancial2} />
            ) : (
              <FileUploader
                setFile={(file) => {
                  setDocs({ ...docs, auditedFinancial2: file });
                }}
              />
            )}
          </Grid>
          {typeof docs?.auditedFinancial3 === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, auditedFinancial3: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, auditedFinancial3: file });
              }}
            />
          ) : docs?.auditedFinancial3 ? (
            <DownloadButton link={docs?.auditedFinancial3} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, auditedFinancial3: file });
              }}
            />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              ITR Copy (Y1, Y2, Y3)
            </Typography>
          </Grid>
          {typeof docs?.itrCopy === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, itrCopy: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, itrCopy: file });
              }}
            />
          ) : docs?.itrCopy ? (
            <DownloadButton link={docs?.itrCopy} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, itrCopy: file });
              }}
            />
          )}
          <Grid item md={1} xs={12}></Grid>
          {typeof docs?.itrCopy2 === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, itrCopy2: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}
          <Grid item md={1} xs={12}>
            {!fromProfile ? (
              <FileUploader
                setFile={(file) => {
                  setDocs({ ...docs, itrCopy2: file });
                }}
              />
            ) : docs?.itrCopy2 ? (
              <DownloadButton link={docs?.itrCopy2} />
            ) : (
              <FileUploader
                setFile={(file) => {
                  setDocs({ ...docs, itrCopy2: file });
                }}
              />
            )}
          </Grid>

          {typeof docs?.itrCopy3 === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, itrCopy3: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}
          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, itrCopy3: file });
              }}
            />
          ) : docs?.itrCopy3 ? (
            <DownloadButton link={docs?.itrCopy3} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, itrCopy3: file });
              }}
            />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Annual Report (Y1, Y2, Y3)
            </Typography>
          </Grid>
          {typeof docs?.annualReport === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, annualReport: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, annualReport: file });
              }}
            />
          ) : docs?.annualReport ? (
            <DownloadButton link={docs?.annualReport} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, annualReport: file });
              }}
            />
          )}
          <Grid item md={1} xs={12}></Grid>
          {typeof docs?.annualReport2 === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, annualReport2: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}
          <Grid item md={1} xs={12}>
            {!fromProfile ? (
              <FileUploader
                setFile={(file) => {
                  setDocs({ ...docs, annualReport2: file });
                }}
              />
            ) : docs?.annualReport2 ? (
              <DownloadButton link={docs?.annualReport2} />
            ) : (
              <FileUploader
                setFile={(file) => {
                  setDocs({ ...docs, annualReport2: file });
                }}
              />
            )}
          </Grid>
          {typeof docs?.annualReport3 === "string" ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, annualReport3: file });
              }}
            />
          ) : (
            <Grid item md={1} xs={12}></Grid>
          )}

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, annualReport3: file });
              }}
            />
          ) : docs?.annualReport3 ? (
            <DownloadButton link={docs?.annualReport3} />
          ) : (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, annualReport3: file });
              }}
            />
          )}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} style={{ display: "grid" }}>
            <Typography
              variant="body2"
              style={{
                color: "#A6A6A6",
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              Mandatory Documents to be Uploaded
            </Typography>
          </Grid>

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Registration/Incorporation Certificate
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, registrationNo: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.registrationNo} />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>PAN Card</Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, panCardCert: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.panCardCert} />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              12A Certificate
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, certificate12a: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.certificate12a} />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              80 G Certificate
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, certificate80G: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.certificate80G} />
          )}
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              GST Registration
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, gstRegistrationNo: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.gstRegistrationNo} />
          )}
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              FCRA Certificate
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, certificateFcra: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.certificateFcra} />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Cancelled Cheque of account for receiving domestic donations
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, cancelledChequeDom: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.cancelledChequeDom} />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Cancelled Cheque of account for receiving international donations
            </Typography>
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, cancelledChequeIntl: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.cancelledChequeIntl} />
          )}
          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Audited Financial Statements (Y1, Y2, Y3)
            </Typography>
          </Grid>

          <Grid item md={3} xs={12}>
            {!fromProfile ? (
              <FileUploader
                setFile={(file) => {
                  setDocs({ ...docs, auditedFinancial: file });
                }}
              />
            ) : (
              <DownloadButton link={docs?.auditedFinancial} />
            )}
          </Grid>

          <Grid item md={2} xs={12}>
            {!fromProfile ? (
              <FileUploader
                setFile={(file) => {
                  setDocs({ ...docs, auditedFinancial2: file });
                }}
              />
            ) : (
              <DownloadButton link={docs?.auditedFinancial2} />
            )}
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, auditedFinancial3: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.auditedFinancial3} />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              ITR Copy (Y1, Y2, Y3)
            </Typography>
          </Grid>

          <Grid item md={3} xs={12}>
            {!fromProfile ? (
              <FileUploader
                setFile={(file) => {
                  setDocs({ ...docs, itrCopy: file });
                }}
              />
            ) : (
              <DownloadButton link={docs?.itrCopy} />
            )}
          </Grid>

          <Grid item md={2} xs={12}>
            {!fromProfile ? (
              <FileUploader
                setFile={(file) => {
                  setDocs({ ...docs, itrCopy2: file });
                }}
              />
            ) : (
              <DownloadButton link={docs?.itrCopy2} />
            )}
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, itrCopy3: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.itrCopy3} />
          )}

          <Grid item md={4} xs={12} className={classes.gridtext}>
            <Typography className={classes.reqfieldtext}>
              Annual Report (Y1, Y2, Y3)
            </Typography>
          </Grid>

          <Grid item md={3} xs={12}>
            {!fromProfile ? (
              <FileUploader
                setFile={(file) => {
                  setDocs({ ...docs, annualReport: file });
                }}
              />
            ) : (
              <DownloadButton link={docs?.annualReport} />
            )}
          </Grid>

          <Grid item md={2} xs={12}>
            {!fromProfile ? (
              <FileUploader
                setFile={(file) => {
                  setDocs({ ...docs, annualReport2: file });
                }}
              />
            ) : (
              <DownloadButton link={docs?.annualReport2} />
            )}
          </Grid>

          {!fromProfile ? (
            <FileUploader
              setFile={(file) => {
                setDocs({ ...docs, annualReport3: file });
              }}
            />
          ) : (
            <DownloadButton link={docs?.annualReport3} />
          )}
        </Grid>
      )}
    </>
  );
}
