import { Typography, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { getRequest } from "../../../apis/baseApi";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: "35px",
    paddingRight: "35px",
  },
  basicdetails: {
    marginTop: "20px",
    marginBottom: "20px",
    border: "1px solid #C4C4C4",
    padding: "20px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  basic: {
    marginTop: "20px",
    marginBottom: "20px",
    border: "1px solid #C4C4C4",
    padding: "20px",
    borderRadius: "10px",
  },
}));
function Rating({ data, ongoingProject, affiliations, awards }) {
  const classes = useStyles();
  const initialState = {
    name: "",
    description: "",
    fundingType: "",
    location: "",
    startDate: "",
    endDate: "",
  };
  console.log("affiliations", affiliations);
  console.log("awards", awards);
  return (
    <>
      <div
        style={{
          borderRadius: "10px",
          border: "1px solid #C4C4C4",
          padding: "20px",
        }}
      >
        <Typography variant="h6">NGO and Project Details</Typography>
        <div style={{ marginTop: "20px" }}>
          <Typography variant="body2">NGO Background & Description</Typography>
          <br />
          <Typography variant="body2" style={{ color: "#626262" }}>
            {data}
          </Typography>
        </div>
      </div>
      <br />
      <div
        style={{
          borderRadius: "10px",
          border: "1px solid #C4C4C4",
          padding: "20px",
        }}
      >
        <Typography variant="h6">Project Details</Typography>
        {ongoingProject?.map((value) => {
          console.log("value", value);
          return (
            <div
              style={{ marginTop: "20px", borderBottom: "1px solid #f9f9f9" }}
            >
              <Typography variant="body2">
                Project Name: {value?.name}
              </Typography>
              <br />
              <Typography variant="body2">
                Funding Type: {value?.fundingType}
              </Typography>
              <br />
              <Typography variant="body2">
                Location: {value?.location}
              </Typography>
              <br />
              <Typography variant="body2" style={{ color: "#626262" }}>
                Description: {value?.description}
              </Typography>
            </div>
          );
        })}
      </div>
      <br />
      <div
        style={{
          borderRadius: "10px",
          border: "1px solid #C4C4C4",
          padding: "20px",
        }}
      >
        <Typography variant="h6">Membership and Affiliations </Typography>
        <br />
        {affiliations?.map((value) => {
          return (
            <>
              <div>
                <Typography variant="div">
                  Name:{" "}
                  <span
                    style={{ color: "#C88FEF", textTransform: "capitalize" }}
                  >
                    {value?.name}
                  </span>
                </Typography>
                {value?.image === null ? (
                  ""
                ) : (
                  <Typography
                    variant="div"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={value?.image}
                      alt="Membership image"
                      style={{ width: "100%", height: "200px" }}
                    />
                  </Typography>
                )}
              </div>

              <br />
            </>
          );
        })}
      </div>

      <br />
      <div
        style={{
          borderRadius: "10px",
          border: "1px solid #C4C4C4",
          padding: "20px",
        }}
      >
        <Typography variant="h6">Recognitions & Awards</Typography>
        <br />
        {awards?.map((value) => {
          return (
            <>
              <div>
                <Typography variant="div">
                  Name:{" "}
                  <span
                    style={{ color: "#C88FEF", textTransform: "capitalize" }}
                  >
                    {value?.name}
                  </span>
                </Typography>
                {value?.image === null ? (
                  ""
                ) : (
                  <Typography
                    variant="div"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={value?.image}
                      alt="Membership image"
                      style={{ width: "100%", height: "200px" }}
                    />
                  </Typography>
                )}
              </div>
              <br />
            </>
          );
        })}
      </div>
    </>
  );
}

export default Rating;
