import {
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useSelector } from "react-redux";
import { postRequest } from "../../../apis/baseApi";
import {
  StateSelectorFn,
  cityStateSelectorFn,
} from "../../../redux/commonReducer/selector";
import CustomButtonCircularProgress from "../../CircularProgress";
import Calendar from "../../Common/Calendar/Calendar";
import FileUploader from "../../FileInput";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "0px 10px 40px 0px #C88FEF73",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  googlebtn: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "30px",
    paddingLeft: "15px",
    paddingRight: "15px",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      border: "1px solid",
    },
  },
  fieldtext: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "10px",
    display: "flex",
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
  option: {
    fontSize: 13,
    "& > span": {
      marginRight: 10,
      fontSize: 12,
    },
  },
  upload: {
    boxShadow: "none",
    borderRadius: "8px",
    color: "#fff",
    height: "40px",
    display: "flex",
  },
}));

export default function JobApplication({ isVolunteer }) {
  const classes = useStyles();

  const states = useSelector(StateSelectorFn);
  const allCities = useSelector(cityStateSelectorFn);

  const [cities, setCities] = useState([]);
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);

  const initialState = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    dateOfBirth: "",
    formType: isVolunteer ? "volunteer" : "job",
    resume: "",
    pincode: "",
  };

  const [values, setValues] = React.useState(initialState);

  const [removeFileName, setRemoveFileName] = React.useState(false);

  const [errorValues, setErrorValues] = React.useState({
    firstName: "",
    lastName: "",
    mobile_number: "",
    email: "",
    address: "",
    city: "",
    state: "",
    dateOfBirth: "",
    resume: "",
    pincode: "",
  });

  const [isError, setIsError] = React.useState(false);
  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    if (selectedDate) {
      setValues({
        ...values,
        dateOfBirth: moment(selectedDate).format("YYYY-MM-DD"),
      });
    }
  }, [selectedDate]);

  function submitApplcation() {
    if (validateData()) {
      const postData = new FormData();
      postData.set("first_name", values?.firstName);
      postData.set("last_name", values?.lastName);
      postData.set("mobile_number", values?.mobileNumber);
      postData.set("email", values?.email);
      postData.set("address", values?.address);
      postData.set("pincode", values?.pincode);
      postData.set("city", values?.city);
      postData.set("state", values?.state);
      postData.set("dob", values?.dateOfBirth);
      postData.set("form_type", values?.formType);
      postData.set("resume", values?.resume);

      setIsLoading(true);
      postRequest("miscellaneous_tables/create_job_volunteer_form/", postData)
        .then((res) => {
          if (res?.data?.data) {
            alert.success(
              "Application submitted succesfully!.We will get back to you shortly"
            );
            setValues(initialState);
            setSelectedDate("");
            setRemoveFileName(true);
          } else {
            // alert.error("Something went wrong!!")
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          // alert.error("Something went wrong!!")
        });
    }
  }

  function validateData() {
    let error = false;
    let emailError = "";
    let firstNameError = "";
    let lastNameError = "";
    let cityError = "";
    let stateError = "";
    let mobileNumberError = "";
    let dateOfBirthError = "";
    let resumeError = "";
    let pincodeError = "";
    let addressError = "";

    setIsError(false);

    if (!values?.lastName) {
      lastNameError = "*Please Enter last name";
      setIsError(true);
      error = true;
    }

    if (!values?.firstName) {
      firstNameError = "*Please Enter first name";
      setIsError(true);
      error = true;
    }

    if (!values?.email) {
      emailError = "*Please Enter Email Id";
      setIsError(true);
      error = true;
    }

    if (!values?.mobileNumber) {
      mobileNumberError = "*Please Enter Mobile No.";
      setIsError(true);
      error = true;
    }

    if (!values?.city) {
      cityError = "*Please Enter City";
      setIsError(true);
      error = true;
    }

    if (!values?.state) {
      stateError = "*Please Enter State";
      setIsError(true);
      error = true;
    }
    if (!values?.address) {
      addressError = "*Please Enter Communication Address";
      setIsError(true);
      error = true;
    }

    if (!values?.dateOfBirth) {
      dateOfBirthError = "*Please Enter Date Of Birth";
      setIsError(true);
      error = true;
    }

    if (!values?.resume) {
      resumeError = "*Please Enter Resume";
      setIsError(true);
      error = true;
    }

    if (!values?.pincode) {
      pincodeError = "*Please Enter Pincode";
      setIsError(true);
      error = true;
    }

    setErrorValues({
      email: emailError,
      firstName: firstNameError,
      lastName: lastNameError,
      city: cityError,
      state: stateError,
      mobileNumber: mobileNumberError,
      dateOfBirth: dateOfBirthError,
      resume: resumeError,
      pincode: pincodeError,
      address: addressError,
    });

    return error ? false : true;
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: " " });
  };

  function getSelectedCity(value) {
    if (allCities) {
      const item = allCities?.find((opt) => {
        if (opt?.city_name === value) return opt;
      });
      return item || {};
    }
    return {};
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            First Name
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            value={values?.firstName}
            onChange={handleChange("firstName")}
            error={isError}
            helperText={isError ? errorValues?.firstName : ""}
          />
        </Grid>
        <Grid item md={6} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Last Name
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            value={values?.lastName}
            onChange={handleChange("lastName")}
            error={isError}
            helperText={isError ? errorValues?.lastName : ""}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography
            style={{
              fontSize: "12px",
              textAlign: "left",
              marginBottom: "7px",
            }}
          >
            Mobile Number
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <PhoneInput
            style={{ width: "100%" }}
            value={values?.mobileNumber}
            country={"in"}
            inputProps={{
              name: "phone",
            }}
            onChange={(event, value) => {
              setValues({ ...values, mobileNumber: event });
            }}
          />
          {isError && errorValues?.mobileNumber && (
            <FormHelperText error id="accountId-error">
              {errorValues?.mobileNumber}
            </FormHelperText>
          )}
        </Grid>

        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Email Address
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            value={values?.email}
            onChange={handleChange("email")}
            error={isError}
            helperText={isError ? errorValues?.firstName : ""}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Communication Address
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>

          <TextField
            variant="outlined"
            multiline
            rows={6}
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            value={values?.address}
            onChange={handleChange("address")}
            error={isError}
            helperText={isError ? errorValues?.address : ""}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Pin Code
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            type="number"
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            value={values?.pincode}
            onChange={handleChange("pincode")}
            error={isError}
            helperText={isError ? errorValues?.pincode : ""}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography className={classes.fieldtext}>
            State
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <Autocomplete
            id="country-select-demo"
            style={{ backgroundColor: "#fff" }}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            options={states}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "State",
                }}
                error={isError}
                helperText={isError ? errorValues?.state : ""}
              />
            )}
            onChange={(event, item) => {
              if (item) {
                setValues({ ...values, state: item.name, city: "" });
              } else {
                setValues({ ...values, city: "" });
              }
              setCities(item?.city ? item?.city : []);
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography className={classes.fieldtext}>
            District
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <Autocomplete
            id="country-select-demo"
            style={{ backgroundColor: "#fff" }}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            options={cities}
            getOptionLabel={(option) => option.city_name}
            disabled={cities && cities?.length > 0 ? false : true}
            value={cities ? getSelectedCity(values?.city) : {}}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "district",
                }}
                error={isError}
                helperText={isError ? errorValues?.city : ""}
              />
            )}
            onChange={(event, item) => {
              if (item) {
                setValues({ ...values, city: item?.city_name });
              } else {
                setValues({ ...values, city: "" });
              }
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={classes.fieldtext}>
            Date of Birth
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <Calendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          {isError && errorValues?.dateOfBirth && (
            <FormHelperText error id="accountId-error">
              {errorValues?.dateOfBirth}
            </FormHelperText>
          )}
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid
            item
            md={4}
            style={{
              display: "flex",

              alignItems: "center",
            }}
          >
            <Typography className={classes.reqfieldtext}>
              Resume/CV
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <FileUploader
              setFile={(file) => {
                setValues({ ...values, resume: file });
              }}
              removeFileName={removeFileName}
            />
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Button
          className={classes.btn}
          color="primary"
          variant="contained"
          onClick={() => {
            submitApplcation();
          }}
        >
          {isLoading ? <CustomButtonCircularProgress /> : "APPLY"}
        </Button>
      </div>
    </>
  );
}

const demo = [{ title: "Option 1" }, { title: "option 2" }];
