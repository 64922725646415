import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ngoicon from "../../assets/png/icon/ngo-icon.png";
import Ngo from "../../assets/png/team-work/ngo.jpg";


const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    boxShadow: "none",
    border: "none",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

export default function Partner({data}) {
  const classes = useStyles();
  
  return (
    <Card className={classes.root}>
      {/* <CardActionArea> */}
      <CardMedia
        component="img"
        alt="Contemplative Reptile"
        height="240"
        image={data?.image ? data?.image : Ngo}
        style={{ borderRadius: "10px" }}
        title="Contemplative Reptile"
      />
      <CardContent style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <Typography gutterBottom variant="h5" component="h2">
          {data?.ngo_data ? data.ngo_data : "NGO NAME"}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {data?.location}
        </Typography>
        <div style={{ display: "flex", marginTop: "10px" }}>
          <img src={ngoicon} height="20px" />
          <Typography
            style={{
              fontSize: "12px",
              fontWeight: "bold ",
              marginLeft: "10px",
            }}
            color="primary"
          >
            {data?.subcategory}
          </Typography>
        </div>
      </CardContent>
      {/* </CardActionArea> */}
    </Card>
  );
}
