import {
  Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton,
  InputAdornment, OutlinedInput, TextField, Typography
} from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useHistory } from "react-router-dom";
import { CountriesData } from "../../../constant";
import CustomButtonCircularProgress from "../../CircularProgress";
import Calendar from "../../Common/Calendar/Calendar";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "none",
    textAlign: "center",
    padding: 20,
    borderRadius: 22,
  },
  btn: {
    color: "#fff",
    paddingLeft: "60px",
    paddingRight: "60px",
    boxShadow: "none",
    marginTop: "50px",
    marginBottom: "50px",
    borderRadius: 8,
    height: 46,
  },
  googlebtn: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "30px",
    paddingLeft: "15px",
    paddingRight: "15px",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      border: "1px solid",
    },
  },
  fieldtext: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "10px",
    display: "flex",
  },
  reqfieldtext: {
    fontSize: "12px",
    textAlign: "left",
    display: "flex",
  },
}));

function UserReg({registerUser, isLoading, signupData}) {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState();
  const history = useHistory();
  const alert = useAlert();

  useEffect (() => {
    if(selectedDate){
    var a = moment(selectedDate);
    var b = moment(new Date());
    var d = b.diff(a, 'years');
    var minor = false;
    if(d < 18){
      minor = true;
    }
    setValues({...values, isMinor: minor, dateOfBirth: moment(selectedDate).format("YYYY-MM-DD")});
    }
  }, [selectedDate])

  useEffect(() => {
    if(signupData){
      setValues({...values, ...signupData});
    }
  }, [signupData])

  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    dateOfBirth: "",
    isMinor: false,
    guardianName: "",
    guardianRelation: "",
    guardianMobileNo: "",
    guardianEmail: "",
    guardianAddress: "",
    guardianProfession: "",
    address: "",
    city: "",
    country: "",
    profession: "",
    weight: "",
    weightRange: "",
    showPassword: false,
    showConfirmPassword: false,
    isSignupChecked: false
  });

  const [errorValues, setErrorValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    dateOfBirth: "",
    isMinor: "",
    guardianName: "",
    guardianRelation: "",
    guardianMobileNo: "",
    guardianEmail: "",
    guardianAddress: "",
    guardianProfession: "",
    address: "",
    city: "",
    country: "",
    profession: "",
  });

  const [gender, setGender] = React.useState("male");

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const [isError, setIsError] = React.useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({...errorValues, [prop]: ""});
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateForm = () => {
    let error = false;
    let firstNameError = "";
    let lastNameError = "";
    let phoneError = "";
    let emailError = "";
    let passwordError = "";
    let confirmPasswordError = "";
    let dateOfBirthError = "";
    let guardianNameError = "";
    let guardianRelationError = "";
    let guardianMobileNoError = "";
    let guardianEmailError = "";
    let guardianAddressError = "";
    let guardianProfessionError = "";
    let addressError = "";
    let cityError = "";
    let countryError =  "";
    let professionError =  "";
    if(!values?.firstName)
    {
      // setErrorValues({...errorValues, firstName: "Please Enter First Name"});
      firstNameError = "*Please Enter First Name";
      setIsError(true);
      error = true;
    }
    if(!values?.lastName)
    {
      // setErrorValues({...errorValues, lastName: "Please Enter Last Name"});
      lastNameError = "*Please Enter Last Name";
      setIsError(true);
      error = true;
    }
    if(!values?.phone)
    {
      // setErrorValues({...errorValues, phone: "Please Enter Phone No."});
      phoneError = "*Please Enter Phone No.";
      setIsError(true);
      error = true;
    }
    if(!values?.email)
    {
      // setErrorValues({...errorValues, email: "Please Enter Email Id"});
      emailError = "*Please Enter Email Id";
      setIsError(true);
      error = true;
    }
    if(!values?.password)
    {
      // setErrorValues({...errorValues, password: "Please Enter Password"});
      passwordError = "*Please Enter Password";
      setIsError(true);
      error = true;
    } 
    // else if(checkPassword(values?.password)){
    //   passwordError = "*Password should be alphanumeric with atleast one character and one number.";
    //   setIsError(true);
    //   error = true;
    // }
    if(!values?.confirmPassword || values?.password !== values?.confirmPassword)
    {
      // setErrorValues({...errorValues, password: "Please Enter Password"});
      confirmPasswordError = "*Password & Confirm doesn't matched";
      setIsError(true);
      error = true;
    }
    if(!values?.dateOfBirth)
    {
      // setErrorValues({...errorValues, password: "Please Enter Password"});
      dateOfBirthError = "*Please Enter Date of Birth";
      setIsError(true);
      error = true;
    }
  //   if(values?.isMinor){
  //   if(!values?.guardianName)
  //   {
  //     // setErrorValues({...errorValues, password: "Please Enter Password"});
  //     guardianNameError = "*Please Enter Guardian's Name";
  //     setIsError(true);
  //     error = true;
  //   }
  //   if(!values?.guardianEmail)
  //   {
  //     // setErrorValues({...errorValues, password: "Please Enter Password"});
  //     guardianEmailError = "*Please Enter Guardian's Email";
  //     setIsError(true);
  //     error = true;
  //   }
  //   if(!values?.guardianRelation)
  //   {
  //     // setErrorValues({...errorValues, password: "Please Enter Password"});
  //     guardianRelationError = "*Please Enter Guardian's Relation";
  //     setIsError(true);
  //     error = true;
  //   }
  //   if(!values?.guardianMobileNo)
  //   {
  //     // setErrorValues({...errorValues, password: "Please Enter Password"});
  //     guardianMobileNoError = "*Please Enter Guardian's Mobile No.";
  //     setIsError(true);
  //     error = true;
  //   }
  //   if(!values?.guardianAddress)
  //   {
  //     // setErrorValues({...errorValues, password: "Please Enter Password"});
  //     guardianAddressError = "*Please Enter Guardian's Address";
  //     setIsError(true);
  //     error = true;
  //   }
  //   if(!values?.guardianProfession)
  //   {
  //     // setErrorValues({...errorValues, password: "Please Enter Password"});
  //     guardianProfessionError = "*Please Enter Guardian's Profession.";
  //     setIsError(true);
  //     error = true;
  //   }
  // }
  // if(!values?.city)
  //   {
  //     // setErrorValues({...errorValues, password: "Please Enter Password"});
  //     cityError = "*Please Enter City";
  //     setIsError(true);
  //     error = true;
  //   }

    if(!values?.country)
    {
      // setErrorValues({...errorValues, password: "Please Enter Password"});
      countryError = "*Please Select Nationality";
      setIsError(true);
      error = true;
    }
  
  //   if(!values?.address)
  //   {
  //     // setErrorValues({...errorValues, password: "Please Enter Password"});
  //     addressError = "*Please Enter Address";
  //     setIsError(true);
  //     error = true;
  //   }
  //   if(!values?.profession)
  //   {
  //     // setErrorValues({...errorValues, password: "Please Enter Password"});
  //     professionError = "*Please Enter Profession";
  //     setIsError(true);
  //     error = true;
  //   }
    setErrorValues({
      firstName: firstNameError,
      lastName: lastNameError,
      email: emailError,
      phone: phoneError,
      password: passwordError,
      profession: professionError,
      guardianEmail: guardianEmailError,
      guardianMobileNo: guardianMobileNoError,
      guardianRelation: guardianRelationError,
      guardianName: guardianNameError,
      address: addressError,
      profession: professionError,
      guardianAddress: guardianAddressError,
      guardianProfession: guardianProfessionError,
      city: cityError,
      country: countryError,
      dateOfBirth: dateOfBirthError
    })
    return error ? false : true;
  }

  const onSignUp = () => {

      if(validateForm()){
        if(!values?.isSignupChecked){
          alert.error("Please tick the accept terms and conditions checkbox");
          return;
        }
        registerUser(values);
      }
  }


  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            First Name
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            onChange={handleChange("firstName")}
            error={isError}
            helperText={isError ? errorValues?.firstName : ""}
            value={values?.firstName}
          />
        </Grid>
        <Grid item md={6} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Last Name
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            onChange={handleChange("lastName")}
            error={isError}
            helperText={isError ? errorValues?.lastName : ""}
            value={values?.lastName}
          
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography className={classes.fieldtext}>Date of Birth
          <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
        </Grid>
        {values?.isMinor && 
        <Grid item md={12}>
          <div style={{ display: "flex" }}>
            <FormControlLabel
              style={{ display: "flex", alignItems: "flex-start" }}
              control={<Checkbox name="checkedB" color="primary" disabled checked={values?.isMinor ? values?.isMinor : false}/>}
              label={
                <span style={{ color: "#A6A6A6", textAlign: "left" }}>
                  You are a minor. Kindly fill your guardian's details{" "}
                </span>
              }
            />
          </div>
        </Grid>
      }
        <Grid item md={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            {values?.isMinor ? "Gaurdian's Email Address" :  "Email Address"}
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            onChange={handleChange("email")}
            error={isError}
            helperText={isError ? errorValues?.email : ""}
            value={values?.email}
          />
        </Grid>
        <Grid item md={12}>
          <Typography className={classes.fieldtext}>
          {values?.isMinor ? "Gaurdian's Mobile Number" :  "Mobile Number"}
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <FormControl  style={{ display: "flex" }}>
          <PhoneInput
            style={{ width: "100%" }}
            country={"in"}
            inputProps={{
              name: "phone",
            }}
            onChange={(event) => {
              setValues({ ...values, phone: event });
            }}
          />
          {isError && errorValues?.phone && (
                <FormHelperText error id="accountId-error">
                  {errorValues?.phone}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Grid item md={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Password
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <FormControl className={classes.margin}>
            <OutlinedInput
              style={{
                backgroundColor: "#fff",
                paddingTop: 26,
                paddingBottom: 26,
              }}
              id="outlined-adornment-password"
              type={values?.showPassword ? "text" : "password"}
              value={values?.password}
              onChange={handleChange("password")}
              // error={isError}
              // helperText={isError ? errorValues?.password : ""}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              //labelWidth={70}
            />
            {isError && errorValues?.password && (
                <FormHelperText error id="accountId-error1">
                  {errorValues?.password}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Confirm Password
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <FormControl className={classes.margin}>
            <OutlinedInput
              style={{
                backgroundColor: "#fff",
                paddingTop: 25,
                paddingBottom: 25,
              }}
              id="outlined-adornment-password"
              type={values.showConfirmPassword ? "text" : "password"}
              value={values.confirmPassword}
              onChange={handleChange("confirmPassword")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              //labelWidth={70}
            />
            {isError && errorValues?.confirmPassword && (
                <FormHelperText error id="accountId-error1">
                  {errorValues?.confirmPassword}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        
        {/* {values?.isMinor && 
        <Grid item md={12}>
          <div style={{ display: "flex" }}>
            <FormControlLabel
              style={{ display: "flex", alignItems: "flex-start" }}
              control={<Checkbox name="checkedB" color="primary" disabled checked={values?.isMinor ? values?.isMinor : false}/>}
              label={
                <span style={{ color: "#A6A6A6", textAlign: "left" }}>
                  You are a minor. Kindly fill your guardian's details{" "}
                </span>
              }
            />
          </div>
        </Grid>
} */}
        {/* {values?.isMinor && 
        <>
        <Grid item md={12}>
          <Divider />
        </Grid>
        
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>Guardian's Name
          
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            onChange={handleChange("guardianName")}
            error={isError}
            helperText={isError ? errorValues?.guardianName : ""}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>Guardian's Relation

          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            onChange={handleChange("guardianRelation")}
            error={isError}
            helperText={isError ? errorValues?.guardianRelation : ""}
          />
        </Grid>
        
        <Grid item md={12}>
          <Typography className={classes.fieldtext}>
          Guardian's Mobile Number
            
          </Typography>
          <FormControl  style={{ display: "flex" }}>
          <PhoneInput
            style={{ width: "100%" }}
            country={"in"}
            inputProps={{
              name: "guardianMobileNo",
            }}
            onChange={(event) => {
              setValues({ ...values, guardianMobileNo: event });
            }}
          />
          {isError && errorValues?.guardianMobileNo && (
                <FormHelperText error id="accountId-error">
                  {errorValues?.guardianMobileNo}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>Guardian's Email Address
          
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            onChange={handleChange("guardianEmail")}
            error={isError}
            helperText={isError ? errorValues?.guardianEmail : ""}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Guardian's Address
            
          </Typography>

          <TextField
            variant="outlined"
            multiline
            rows={6}
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            onChange={handleChange("guardianAddress")}
            error={isError}
            helperText={isError ? errorValues?.guardianAddress : ""}
          />
        </Grid>

        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>Guardian's Profession
          
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            onChange={handleChange("guardianProfession")}
            error={isError}
            helperText={isError ? errorValues?.guardianProfession : ""}
          />
        </Grid>

        <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Divider />
        </Grid>
        </>
} */}

        {/* <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.reqfieldtext}>
            Address
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>

          <TextField
            variant="outlined"
            multiline
            rows={6}
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            onChange={handleChange("address")}
            error={isError}
            helperText={isError ? errorValues?.address : ""}
          />
        </Grid> */}
        
        <Grid item md={12} xs={12}>
          <Typography className={classes.fieldtext}>{values?.isMinor ? "Gaurdian's Nationality" :  "Nationality"}
          <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <Autocomplete
            id="country-select-demo"
            style={{ backgroundColor: "#fff" }}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            options={CountriesData}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "State",
                }}
                error={isError}
            helperText={isError ? errorValues?.country : ""}
              />
            )}
            onChange={(e, value) => {
              setValues({...values, country:value.name});
              setErrorValues({...errorValues, country:""});
            }}
          />
          {/* <TextField
            variant="outlined"
            // multiline
            rows={6}
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            onChange={handleChange("country")}
            error={isError}
            helperText={isError ? errorValues?.country : ""}
          /> */}
        </Grid>
        {/* <Grid item md={12} xs={12} style={{ display: "grid" }}>
          <Typography className={classes.fieldtext}>Profession
          <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            onChange={handleChange("profession")}
            error={isError}
            helperText={isError ? errorValues?.profession : ""}
          />
        </Grid>
         */}
      <Grid item md={12}>
          <div style={{ display: "flex" }}>
            <FormControlLabel
              style={{ display: "flex", alignItems: "flex-start" }}
              control={<Checkbox name="checkedB" color="primary" checked={values?.isSignupChecked ? values?.isSignupChecked : false}
              onChange={(event, value) => {
                setValues({...values, isSignupChecked: !values.isSignupChecked})
              }}
              />}
              // label={
                
              // }
            />
            <span style={{ color: "#A6A6A6", textAlign: "left" }}>
                  By signing up you indicate that you have read and agree to the <span style={{ color: "#C88FEF", cursor: "pointer" }} onClick={() => {
                    window.open('terms-and-conditions', '_blank')
                  }}>Terms and Conditions</span>{" "}
                </span>
          </div>
        </Grid>
      </Grid>
      <div>
        <Button className={classes.btn} color="primary" variant="contained" onClick={() => onSignUp()}>
          {isLoading ?  <CustomButtonCircularProgress /> : "SIGN UP"}
        </Button>

        <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            
                <Typography variant="body2">
                <div>
            Already have an account?<span style={{ color: "blue", fontSize: "16px", cursor:"pointer" }}  onClick={() => {
              history.push("member-login")
            }}> Login Here! </span>
            </div>
                </Typography>
                </div>
      </div>
    </>
  );
}

export default UserReg;
